import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import DividerStack from 'atoms/DividerStack';
import { EstatePermissions } from 'consts/permissions';
import { addressOrder } from 'formData/common/address/order';
import { estateOrder, estateSchema } from 'formData/estate';
import useGetRegion from 'hooks/useGetRegion';
import PageCard from 'templates/PageCard';
import SectionRows from 'templates/SectionRows';
import getLng from 'utils/getLng';
import hasPermission from 'utils/hasPermission';
import sortObject from 'utils/object/sortObject';
import { resolver } from 'utils/yup';
import { updateEstate, updateEstateDetails } from 'api/estate/requests';
import { EstateCardProps, EstateDataWithoutPhoto } from '../types';
import { getEstateDefaultValues, getEstateDisplayData } from '../utils';
import { EstateGeneralEdition } from './EstateGeneralEdition';
import { prepareDetailsData } from './utils';

const EstateGeneralCard = ({
  estate,
  estateId,
  fetchEstate,
  estateDetails,
  fetchEstateDetails,
}: EstateCardProps) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const { i18n } = useTranslation();

  const methods = useForm<EstateDataWithoutPhoto>({
    mode: 'onChange',
    resolver: resolver(estateSchema),
  });

  const {
    handleSubmit,
    reset,
    trigger,
    formState: { isDirty, errors },
    getValues,
  } = methods;

  useEffect(() => {
    const defaultValues = getEstateDefaultValues(estate);
    reset(defaultValues, { keepDirty: false });
  }, []);

  const region = useGetRegion(estate.address.postCode, estate.address.city?.en);

  const postCode = getValues('address.postCode');
  const city = getValues('address.city');

  const currentRegion = useGetRegion(postCode, city);

  const regionChanged = useMemo(
    () => !!estateDetails?.hasEpc && region !== currentRegion,
    [currentRegion, estateDetails?.hasEpc],
  );

  const onSave: SubmitHandler<EstateDataWithoutPhoto> = async (data) => {
    setSaving(true);
    const { ok } = await updateEstate(estateId, data);
    if (ok) {
      await fetchEstate();
      setEditing(false);
      reset(data);
    }
    if (regionChanged && estateDetails) {
      const details = await prepareDetailsData(data.address.postCode, estateDetails);
      const { ok } = await updateEstateDetails(estateId, details);
      if (ok) {
        await fetchEstateDetails();
      }
    }
    setSaving(false);
  };

  const onEdit = () => {
    trigger();
    setEditing(true);
  };

  const onCancel = () => {
    reset();
    setEditing(false);
  };

  const { address, details, purpose } = useMemo(
    () => getEstateDisplayData(estate, getLng(i18n)),
    [estate, i18n.language],
  );

  return (
    <PageCard
      tKey="estate"
      headerProps={{
        titleProps: { translationValues: { context: 'residents' } },
        editionProps: {
          hidden: !hasPermission(EstatePermissions.Write),
          isEditing: editing,
          saving,
          saveDisabled: !isDirty || !!Object.keys(errors ?? {}).length,
          onEdit,
          onCancel,
          onSave: handleSubmit(onSave),
        },
      }}
    >
      <Stack spacing={4}>
        {editing ? (
          <FormProvider {...methods}>
            <EstateGeneralEdition regionChanged={regionChanged} />
          </FormProvider>
        ) : (
          <DividerStack>
            <SectionRows
              data={details}
              translateValue={{ status: `enums:estateStatus.${details.status}` }}
            />
            <SectionRows
              data={sortObject(address, addressOrder)}
              translationPrefix="address"
            />
            <SectionRows
              data={sortObject(purpose, estateOrder)}
              translateValue={{
                type: `enums:estateType.${purpose.type}`,
                subType: `enums:estateSubtype.${purpose.subType}`,
                destination: `enums:estateDestination.${purpose.destination}`,
              }}
            />
          </DividerStack>
        )}
      </Stack>
    </PageCard>
  );
};

export default EstateGeneralCard;
