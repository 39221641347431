import { fetchPersonOptions } from 'hooks/useSearchInput/utils';
import { LegalPersonIcon, NaturalPersonIcon } from 'icons';
import routes from 'router/routes';
import { SetState } from 'types/setState';
import transformName from 'utils/transformName';
import { PersonInDossier } from 'api/dossier/types';
import { getEstates } from 'api/estate/requests';
import { FetchedEstateListItem } from 'api/estate/types';
import { FetchedPersonData } from 'api/person/types';
import { getPrivateRelation } from 'api/relations/requests';
import { getUsers } from 'api/users/requests';
import { FetchedUserData } from 'api/users/types';
import {
  FetchOptions,
  RelationData,
  RelationOption,
  RelationResponses,
  SaveRelations,
} from './types';

export const relationToDossierPerson = ({
  id,
  details: _details,
  link: _link,
  errorMessage: _errorMessage,
  ...data
}: RelationData<FetchedPersonData>): PersonInDossier => ({ ...data, personId: id });

export const personToRelation = (
  person: FetchedPersonData,
): RelationData<FetchedPersonData> => ({
  ...person,
  details: person.address,
  link: routes.personTab(person.id, person.type).details,
});

export const personToRelationOption = (
  person: FetchedPersonData,
): RelationOption<FetchedPersonData> => ({
  value: person.id,
  label: person.name,
  Icon: person.type === 'LegalPerson' ? LegalPersonIcon : NaturalPersonIcon,
  caption: person.address,
  id: person.id,
  query: person.name,
  data: personToRelation(person),
  suffix: person.email,
});

export const fetchPersonRelationOptions: FetchOptions<FetchedPersonData> = async (
  params,
) => {
  const persons = await fetchPersonOptions(params);
  return persons.map(personToRelationOption);
};

export const estateToRelation = (
  estate: FetchedEstateListItem,
): RelationData<FetchedEstateListItem> => ({
  ...estate,
  name: estate.address,
  details: `${estate.postCode} ${estate.city}`,
  link: routes.estateTab(estate.id).estateInfo,
});

export const estateToRelationOption = (
  estate: FetchedEstateListItem,
): RelationOption<FetchedEstateListItem> => ({
  value: estate.id,
  label: estate.address,
  caption: `${estate.postCode} ${estate.city}`,
  id: estate.id,
  query: estate.address,
  data: estateToRelation(estate),
});

export const fetchEstateOptions: FetchOptions<FetchedEstateListItem> = async ({
  query,
}) => {
  const { ok, response } = await getEstates({ address: query, limit: 15 });
  if (ok) {
    return response.data.map(estateToRelationOption);
  }
  return [];
};

export const userToRelation = (user: FetchedUserData): RelationData<FetchedUserData> => ({
  ...user,
  name: transformName(user),
  link: routes.user(user.id),
});

export const userToRelationOption = (
  user: FetchedUserData,
): RelationOption<FetchedUserData> => ({
  value: user.id,
  label: transformName(user),
  id: user.id,
  data: userToRelation(user),
});

export const fetchUsersOptions: FetchOptions<FetchedUserData> = async () => {
  // It should return all users or there should an option to filter by name - maybe it should be discussed with backend
  const { ok, response } = await getUsers({ limit: 100 });
  if (ok) {
    return response.data.map(userToRelationOption);
  }
  return [];
};

export const saveRelations =
  <T extends object>(responses: RelationResponses): SaveRelations<T> =>
  async (relationsToPreview, setRelationsToPreview, showSaved) => {
    const failedResponses = responses
      .map((res) => (!res.ok ? res : null))
      .filter((res): res is NonNullable<typeof res> => res !== null);

    const allSucceed = !failedResponses.length;
    const allFailed = failedResponses.length === relationsToPreview.length;

    if (allSucceed && !showSaved) {
      setRelationsToPreview([]);
    } else {
      setRelationsToPreview((prev) =>
        prev
          .filter(({ id }) =>
            !showSaved ? failedResponses.some((res) => res.id === id) : true,
          )
          .map((relation) => ({
            ...relation,
            savedId: showSaved
              ? responses.find(({ ok, id }) => ok && id === relation.id)?.savedId
              : '',
            errorMessage: failedResponses.find((res) => res.id === relation.id)?.message,
          })),
      );
    }
    return { allSucceed, allFailed };
  };

export const findPersonRelative = async (person: RelationData<FetchedPersonData>) => {
  if (person.hasPrivateRelation) {
    const { ok, response } = await getPrivateRelation(person.id);
    if (ok && response) {
      return personToRelation(response.relative);
    }
  }
};

export const getPersonsIdsToFilter = <
  T extends Partial<{
    id: string;
    personId: string;
    representativeId: string | null;
    representeeId: string | null;
  }>,
>(
  relations: T[],
) =>
  relations
    .flatMap(({ id, personId, representativeId, representeeId }) => [
      id,
      personId,
      representativeId,
      representeeId,
    ])
    .filter((id): id is string => !!id);

export const selectPersonWithPartner =
  (setState: SetState<RelationData<FetchedPersonData>[]>, personsIdsToFilter: string[]) =>
  async (person: RelationData<FetchedPersonData>) => {
    setState((prev) => [person, ...prev]);
    const relative = await findPersonRelative(person);
    if (relative) {
      setState((persons) =>
        !personsIdsToFilter.find((id) => id === relative.id)
          ? [relative, ...persons]
          : persons,
      );
    }
  };
