import { Box, Stack, Typography } from '@mui/material';
import CustomLink from 'atoms/Link';
import { EstateIcon } from 'icons';
import routes from 'router/routes';
import { OwnedEstate } from '../types';
import Action from './Action';

export const Estate = ({
  estateId,
  estate: { address, city, postCode, type, action },
}: Omit<OwnedEstate, 'ownershipId'>) => (
  <Box sx={({ mixins }) => mixins.cardItem}>
    <Box display="flex" alignItems="center" gap={2}>
      <EstateIcon />
      <Stack>
        <Typography
          component={CustomLink}
          to={routes.estateTab(estateId).estateInfo}
          sx={({ mixins }) => mixins.link}
          variant="h5"
        >
          {address}
        </Typography>
        <Typography variant="caption">{`${postCode} ${city}`.trim()}</Typography>
      </Stack>
    </Box>
    {type && <Typography variant="h5">{type}</Typography>}
    {!type && action && <Action {...action} />}
  </Box>
);
