import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslateColumns } from 'templates/Table/hooks/useTranslateColumns';
import tableStyles from 'templates/Table/styles';
import { getMigrationQueue } from 'api/businesses/requests';
import { Migration } from 'api/businesses/types';
import PageTitle from 'components/PageTitle';
import { migrationColumns } from './static-data';

const MigrationQueueList = () => {
  const { t } = useTranslation('table');
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<Migration[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const { ok, response } = await getMigrationQueue();
    if (ok) {
      setRows(response);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const translatedColumns = useTranslateColumns(t, 'migrationQueue', migrationColumns);

  return (
    <Box sx={({ mixins }) => mixins.tablePageWrapper}>
      <PageTitle page="migrationQueue" />
      <Box width="100%">
        <DataGrid
          sx={tableStyles}
          columns={translatedColumns}
          autoHeight
          getRowHeight={() => 'auto'}
          loading={loading}
          rows={rows}
          disableColumnMenu
          hideFooter
        />
      </Box>
    </Box>
  );
};

export default MigrationQueueList;
