import { useState } from 'react';
import useAddressFields from 'hooks/useAddressFields';
import { AddressFormInterface } from 'api/common/types';
import { GooglePlacesSelect } from 'components/inputs/GooglePlacesSelect';
import { GooglePlaceDetails } from 'components/inputs/types';
import AddressFields from './AddressFields';
import { AddressFieldsWithGoogleProps } from './types';
import { getAddressFields } from './utils';

const AddressFieldsWithGoogle = ({
  disableCountrySelection = false,
  separateLabel = false,
  regionChanged,
  onCountryChange,
  prefix,
}: Partial<AddressFieldsWithGoogleProps>) => {
  const [googleParams, setGoogleParams] = useState<Partial<AddressFormInterface>>();

  const onChange = ({ address_components }: GooglePlaceDetails) => {
    const fields = getAddressFields(address_components);
    setGoogleParams(fields);
  };

  const fields = useAddressFields({
    prefix,
    disableCountrySelection,
    regionChanged,
    googleParams,
    onCountryChange,
  });

  return (
    <>
      <GooglePlacesSelect
        onChange={onChange}
        country={disableCountrySelection ? 'be' : undefined}
        isLabel={!separateLabel}
      />
      <AddressFields separateLabel={separateLabel} prefix={prefix} fields={fields} />
    </>
  );
};

export default AddressFieldsWithGoogle;
