import { sortFields } from 'formData/utils';
import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import {
  EstateDetailsForm,
  EstateElectricity,
  EstateElectricityType,
  EstateEpc,
  EstateGas,
} from 'api/estate/types';
import { InputField } from 'components/controlledInputs/types';
import { EAN_NUMBER_START } from './eanNumber/static-data';
import {
  estateDetailsOrder,
  estateElectricityOrder,
  estateEpcOrder,
  estateGasOrder,
} from './order';
import { electricityTypeOptions, managementTypeOptions } from './selectOptions';
import { isEstateElectricityMeterNumber2 } from './utils';

export const HABITABLE_SURFACE_UNIT = 'm²';
export const EPC_CONSUMPTION_UNIT = 'kWh/m²';

export const estateDetailsFields: InputField<EstateDetailsForm>[] = sortFields(
  [
    {
      name: 'habitableSurface',
      type: 'number',
      endAdornment: HABITABLE_SURFACE_UNIT,
    },
    {
      name: 'managementType',
      options: managementTypeOptions,
    },
    {
      name: 'smokeDetectors',
      type: 'number',
      isInteger: true,
    },
    {
      name: 'isFurnished',
      type: 'checkbox',
    },
    {
      name: 'others',
      multiline: true,
      rows: 2,
    },
  ],
  estateDetailsOrder,
);

export const getEstateElectricityFields = (
  type: EstateElectricityType,
): InputField<NonNullable<EstateElectricity>>[] =>
  sortFields(
    [
      {
        name: 'type',
        options: electricityTypeOptions,
      },
      {
        name: 'eanNumber',
        mask: new Array(18).fill(/\d/),
        placeholder: `${EAN_NUMBER_START}1234567890123`,
      },
      {
        name: 'meterNumber',
        type: 'text',
        suffix: isEstateElectricityMeterNumber2(type) ? 1 : '',
      },
      {
        name: 'meterNumber2',
        type: 'text',
        hidden: !isEstateElectricityMeterNumber2(type),
      },
    ],
    estateElectricityOrder,
  );

export const estateEpcFields: InputField<NonNullable<EstateEpc>>[] = sortFields(
  [
    {
      name: 'type',
      options: [],
    },
    {
      name: 'primaryEnergyConsumption',
      disabled: true,
      withSpecialChars: true,
      endAdornment: EPC_CONSUMPTION_UNIT,
    },
    {
      name: 'referenceEnergyConsumption',
      type: 'number',
      allowNegative: true,
      precision: 2,
      endAdornment: EPC_CONSUMPTION_UNIT,
    },
    {
      name: 'validUntil',
      type: 'date',
    },
  ],
  estateEpcOrder,
);

export const estateGasFields: InputField<NonNullable<EstateGas>>[] = sortFields(
  [
    {
      name: 'eanNumber',
      mask: new Array(18).fill(/\d/),
      placeholder: '541234567890123456',
    },
    {
      name: 'meterNumber',
      type: 'text',
    },
  ],
  estateGasOrder,
);

export const estateCompositionFields = getObjectKeys(
  namespaces.enums.estateComposition,
).map((key) => ({
  name: key,
  type: 'number',
  isInteger: true,
}));
