import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import useLoader from 'hooks/useLoader';
import { LegalPersonDetailsCard, PersonViewHeader } from 'modules/PersonView';
import { RepresentationData } from 'modules/RepresentationCard/types';
import { getRepresentationsList } from 'modules/RepresentationCard/utils';
import routes, { personTabRoutes } from 'router/routes';
import { getLegalPersonRepresentatives } from 'api/relations/requests';
import { RouteTabs } from 'components/tabs';
import { getLegalPersonConfig, getLegalPersonData } from './utils';

const LegalPersonDetails = () => {
  const [representatives, setRepresentatives] = useState<RepresentationData[]>([]);
  const [loadingRepresentatives, setLoadingRepresentatives] = useState(true);

  const { legalPersonId } = useParams();
  if (!legalPersonId) return null;

  const { data, updateData, Loader } = useLoader(getLegalPersonConfig(legalPersonId));

  const legalPersonData = useMemo(() => getLegalPersonData(data), [data]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const personRoutes = useMemo(() => {
    const { details, ...routesWithoutDetails } = personTabRoutes;
    return matches ? routesWithoutDetails : { details, ...routesWithoutDetails };
  }, [matches]);

  const fetchRepresentatives = useCallback(async () => {
    setLoadingRepresentatives(true);
    const { ok, response } = await getLegalPersonRepresentatives(legalPersonId);
    if (ok) {
      const list = await getRepresentationsList(response);
      setRepresentatives(list);
    }
    setLoadingRepresentatives(false);
  }, [legalPersonId]);

  useEffect(() => {
    fetchRepresentatives();
  }, [fetchRepresentatives]);

  return (
    <Loader>
      {data && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              <PersonViewHeader
                type="LegalPerson"
                id={legalPersonId}
                data={legalPersonData}
              />
              <RouteTabs
                routes={personRoutes}
                parentPath={routes.legalPerson(legalPersonId)}
                translationKey="personView"
              />
            </Stack>
            <Outlet
              context={{
                legalPersonId,
                legalPersonData,
                fetchLegalPersonData: updateData,
                representatives,
                fetchRepresentatives,
                loadingRepresentatives,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            mb="auto"
            display={{ xs: 'none', lg: 'block' }}
            gap={3}
          >
            <LegalPersonDetailsCard
              legalPersonId={legalPersonId}
              legalPersonData={legalPersonData}
              fetchLegalPersonData={updateData}
            />
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default LegalPersonDetails