import { Box } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid';
import TableFilter from './TableFilter';
import { TableFiltersProps } from './types';

const TableFilters = ({ visibleColumns }: TableFiltersProps) => {
  const isAnyFilter = visibleColumns.some(({ filterable }) => filterable);
  const isSelectable = visibleColumns.find(
    ({ field }) => field === GRID_CHECKBOX_SELECTION_FIELD,
  );

  return isAnyFilter ? (
    <Box
      display="flex"
      gap={1.5}
      p={1}
      pl={isSelectable ? 0 : 1}
      borderBottom={({ shape }) => shape.border}
    >
      {visibleColumns.map((column) =>
        column.field !== GRID_CHECKBOX_SELECTION_FIELD ? (
          <TableFilter key={column.field} {...column} />
        ) : (
          <Box key={column.field} width={42} />
        ),
      )}
    </Box>
  ) : null;
};

export default TableFilters;
