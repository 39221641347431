import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EstateFormInterface, UseEstateFormStep } from 'modules/EstateForm/types';
import DetailsStep from './DetailsStep';

export const useDetailsStep: UseEstateFormStep = ({ onCancel, saveForm }) => {
  const { t } = useTranslation();

  const { handleSubmit, formState } = useFormContext<EstateFormInterface>();

  return {
    isValid: formState.isValid,
    Component: <DetailsStep />,
    cancelButton: {
      onClick: onCancel,
    },
    backButton: true,
    nextButton: true,
    submitButton: {
      label: t('save'),
      onClick: handleSubmit(saveForm),
      saving: formState.isSubmitting,
    },
  };
};
