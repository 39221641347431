import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EstateFormInterface, UseEstateGeneralStep } from 'modules/EstateForm/types';
import GeneralStep from './GeneralStep';
import { getEstateCompositionValue } from './utils';

export const useGeneralStep: UseEstateGeneralStep = ({
  onCancel,
  saveForm,
  hideOwners,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, formState, setValue } = useFormContext<EstateFormInterface>();

  const updateComposition = handleSubmit((data) => {
    const estateComposition = getEstateCompositionValue(data);
    setValue('estateDetails.estateComposition', estateComposition);
  });

  const saveAndClose = handleSubmit((data, event) => {
    const estateComposition = getEstateCompositionValue(data);
    return saveForm(
      {
        estate: data.estate,
        estateDetails: { ...data.estateDetails, estateComposition },
      },
      event,
    );
  });

  return {
    isValid: formState.isValid,
    Component: <GeneralStep hideOwners={hideOwners} />,
    cancelButton: {
      onClick: onCancel,
    },
    nextButton: {
      onClick: updateComposition,
    },
    labelButton: {
      onClick: updateComposition,
    },
    submitButton: {
      label: t('save'),
      onClick: saveAndClose,
      saving: formState.isSubmitting,
    },
    required: true,
  };
};
