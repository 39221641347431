import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import { AbolishmentReason, DepositDistribution } from './types';

export const abolishmentReasons: AbolishmentReason[] = getObjectKeys(
  namespaces.modals.prepareReleaseModal.sections.abolishmentReason.fields,
);

export const depositDistributions: DepositDistribution[] = getObjectKeys(
  namespaces.modals.prepareReleaseModal.sections.depositDistribution.fields,
);
