import { useContext, useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { getNaturalPersonDetailsFields } from 'formData/naturalPerson/fields';
import useBicFromIban from 'hooks/useBicFromIban';
import { HandleBlurChange } from 'types/handleChange';
import getFormMethods from 'utils/getFormMethods';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { mapNationalities } from './utils';

interface Config {
  formType?: 'edition' | 'creation';
  isFormForMissingData?: boolean;
  prefix?: string;
}

export const useNaturalPersonDetailsFields = <T extends FieldValues>(
  methods: UseFormReturn<T>,
  config?: Config,
) => {
  const { formType = 'creation', isFormForMissingData, prefix } = config ?? {};
  const { nationalities } = useContext(ConfigContext);
  const { getBicFromIban, loadingBic } = useBicFromIban();

  const { getValues, setValue, trigger, getError } =
    getFormMethods<NaturalPersonFormData>(methods, prefix);

  const onNationalityChange = (nationality: string) => {
    setValue('nationality', nationality);

    if (!getValues('taxResidenceBelgium') && nationality === 'Belgian') {
      setValue('taxResidenceBelgium', 'Belgium');
    }
    if (
      !isFormForMissingData &&
      getValues('taxResidenceBelgium') &&
      nationality !== 'Belgian'
    ) {
      setValue('taxResidenceBelgium', null);
      setValue('taxResidences', []);
    }

    if (getError('taxResidenceBelgium')) {
      trigger('taxResidenceBelgium');
    }

    if (getError('nationalRegisterNumber')) {
      trigger('nationalRegisterNumber');
    }
  };

  const onIbanBlur: HandleBlurChange = async (e) => {
    const iban = e.target.value;
    const bic = await getBicFromIban(iban);
    setValue('bic', bic);

    if (getError('taxResidenceBelgium')) {
      trigger('taxResidenceBelgium');
    }
  };

  const groupedNationalities = useMemo(
    () => ({
      main: nationalities.main.map(mapNationalities),
      other: nationalities.other.map(mapNationalities),
    }),
    [nationalities],
  );

  return getNaturalPersonDetailsFields(
    {
      nationalities: groupedNationalities,
      onNationalityChange,
      onIbanBlur,
      loadingBic,
    },
    formType,
  );
};
