import { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { DocumentData } from 'api/documents/types';
import DocumentExpander from '../cells/DocumentExpander';
import DocumentFiles from '../DocumentFiles';
import { actionCellStyles, tableCellStyles } from '../styles';
import { DocumentsContextInterface } from '../types';
import DocumentActions from './DocumentActions';
import { DocumentRowProps } from './types';
import { getDocumentFieldValue } from './utils';

const ReadOnlyDocumentRow = <
  TData extends DocumentData,
  TContext extends DocumentsContextInterface<TData>,
>({
  columns,
  document,
  context,
  getActions,
  updateDocuments,
}: DocumentRowProps<TData, TContext>) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded((prev) => !prev);

  return (
    <>
      <TableRow>
        <DocumentExpander
          expanded={expanded}
          toggleExpand={toggleExpand}
          document={document}
        />
        {columns.map(({ name, Component, valueGetter }) => (
          <TableCell key={name} sx={tableCellStyles}>
            {Component ? (
              <Component
                document={document}
                updateDocuments={updateDocuments}
                {...context}
              />
            ) : (
              valueGetter?.(document, context.scope) ||
              getDocumentFieldValue(name, document)
            )}
          </TableCell>
        ))}
        <TableCell sx={actionCellStyles}>
          <DocumentActions
            document={document}
            actions={getActions(document, context.entityId, context.scope)}
            updateDocuments={updateDocuments}
          />
        </TableCell>
      </TableRow>
      <DocumentFiles
        open={expanded}
        document={document}
        context={context}
        updateDocuments={updateDocuments}
      />
    </>
  );
};

export default ReadOnlyDocumentRow;
