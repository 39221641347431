import { Stack, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import AccountMenu from 'modules/AccountMenu';
import Breadcrumbs from 'modules/Breadcrumbs';
import NotificationsMenu from 'modules/NotificationsMenu';
import hasPermission from 'utils/hasPermission';
import { getHeaderToolbarStyles, headerStyles } from './styles';

interface Props {
  isSidebarExpanded: boolean;
  matchesBreakpoint: boolean;
}

export const Header = ({ isSidebarExpanded, matchesBreakpoint }: Props) => (
  <AppBar sx={headerStyles}>
    <Toolbar sx={getHeaderToolbarStyles(isSidebarExpanded, matchesBreakpoint)}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        width="100%"
      >
        <Breadcrumbs />
        <Stack direction="row" alignItems="center" gap={2}>
          {hasPermission('BusinessData') ? <NotificationsMenu /> : null}
          <AccountMenu />
        </Stack>
      </Stack>
    </Toolbar>
  </AppBar>
);
