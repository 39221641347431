import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import useRentalConditionsFields from 'hooks/useRentalConditionsFields';
import { RentalConditions } from 'api/dossier/types';
import { ControlledInput } from 'components/controlledInputs';

interface Props {
  methods: UseFormReturn<RentalConditions>;
}

const RentalConditionsStep = ({ methods }: Props) => {
  const { t } = useTranslation('formFields');

  const fields = useRentalConditionsFields(methods);
  const { control, formState } = methods;

  return (
    <Stack width="100%" maxWidth={750}>
      <Grid container rowSpacing={1} columnSpacing={2}>
        {fields
          .filter(({ hidden }) => !hidden)
          .map(({ name, sizes, ...field }) => (
            <Grid item key={name} xs={12} md={sizes?.md ?? 6}>
              <ControlledInput
                label={t(name)}
                name={name}
                control={control}
                error={formState.errors[name]}
                {...field}
              />
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

export default RentalConditionsStep;
