import AccountNumberInput from 'modules/AccountNumberField';
import {
  DepositDistribution,
  DistributionPersonType,
} from 'modules/ProductRentalDeposit/PrepareRelease/types';
import { RentalDepositDistributionData } from 'api/dossier/products/rentalDeposit/types';
import { InputField } from 'components/controlledInputs/types';

export const getRentalDepositDistributionFields = (
  handleAmountChange: () => void,
  personType: DistributionPersonType,
  depositDistribution?: DepositDistribution,
  emailConsent?: boolean,
  noEmail?: boolean,
): InputField<RentalDepositDistributionData>[] => [
  {
    name: 'amount',
    label: personType === 'tenant' ? 'balance' : 'amount',
    type: 'number',
    precision: 2,
    endAdornment: 'EUR',
    hidden: depositDistribution !== 'depositDivided',
    disabled: personType === 'tenant',
    onInputChange: handleAmountChange,
  },
  {
    name: 'accountNumber',
    Component: AccountNumberInput,
    componentProps: {
      prefixName: personType,
      disabled: personType === 'tenant' && !!emailConsent,
    },
    hidden: depositDistribution === 'depositUnknown',
  },
  {
    name: 'email',
    label: depositDistribution === 'depositUnknown' ? 'tenantEmail' : 'email',
    hidden: personType !== 'tenant' || noEmail,
  },
];
