import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { HandleCheckboxChange } from 'types/handleChange';
import { EstateDetailsForm } from 'api/estate/types';
import { ControlledDateInput } from 'components/controlledInputs';

export const ConformityEdition = () => {
  const { t } = useTranslation('formFields');
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<EstateDetailsForm>();

  const onChange: HandleCheckboxChange = (_e, checked) => {
    if (!checked) {
      setValue('conformity', null);
    }
  };

  const updateCheckbox = () => {
    if (!getValues('hasConformity')) {
      setValue('hasConformity', true);
    }
  };

  const initiallyExpanded = !!(getValues('hasConformity') && getValues('conformity'));

  return (
    <Stack direction="row" spacing={1}>
      <AccordionWithCheckbox
        control={control}
        error={errors.conformity}
        name="hasConformity"
        label={t('conformity')}
        onChange={onChange}
        initiallyExpanded={initiallyExpanded}
      >
        <InputLabelWrapper label={t('date')}>
          <ControlledDateInput
            name="conformity"
            control={control}
            error={errors.conformity}
            onChange={updateCheckbox}
            helperText={t('helperText.conformity', { ns: 'common' })}
            disableFuture
          />
        </InputLabelWrapper>
      </AccordionWithCheckbox>
    </Stack>
  );
};
