import { Avatar } from '@mui/material';
import { EstateIcon } from 'icons';
import { EstateData } from 'api/estate/types';

interface Props {
  editable: boolean;
  estate: EstateData;
  onClick: () => void;
}

const EstatePhoto = ({ editable, estate, onClick }: Props) =>
  estate.photo ? (
    <Avatar
      src={estate.photo}
      sx={({ mixins }) => ({
        ...mixins.estatePhoto,
        cursor: editable ? 'pointer' : 'default',
      })}
      onClick={editable ? onClick : undefined}
    />
  ) : (
    <Avatar
      sx={({ mixins, palette }) => ({
        ...mixins.estatePhoto,
        backgroundColor: palette.grey[100],
        cursor: editable ? 'pointer' : 'default',
      })}
      onClick={editable ? onClick : undefined}
    >
      <EstateIcon color="disabled" fontSize="large" />
    </Avatar>
  );

export default EstatePhoto;
