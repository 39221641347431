import { useOutletContext } from 'react-router-dom';
import { DossierObjectDocumentsCard } from 'modules/DocumentsCard';
import { DossierDocumentsContext } from './types';

const DossierEstateDocuments = () => {
  const { dossierData, dossierDocuments, fetchDocuments } =
    useOutletContext<DossierDocumentsContext>();

  return (
    <DossierObjectDocumentsCard
      entityType="estate"
      entityId={dossierData.estate.estateId}
      scope="estate"
      titleOptions={{ context: 'estate' }}
      fetchDocuments={fetchDocuments}
      dossierStatus={dossierData.status}
      showTitle={false}
      {...dossierDocuments.estate}
    />
  );
};

export default DossierEstateDocuments;
