import { useState } from 'react';
import { Checkbox } from '@mui/material';
import { DocumentsComponentProps } from 'templates/DocumentsTable/types';
import { useUpdateEffect } from 'usehooks-ts';
import isDocumentExpired from 'utils/isDocumentExpired';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { DossierDocumentsContextInterface, DossierTableDocumentData } from '../types';
import { handleAttachDocument } from './utils';

const AttachDocumentCell = ({
  document,
  documentConfigData,
  dossierStatus,
  updateDocuments,
  ...context
}: DocumentsComponentProps<
  DossierTableDocumentData,
  DossierDocumentsContextInterface
>) => {
  const [attached, setAttached] = useState(document.attached);
  const [isProcessing, setIsProcessing] = useState(false);

  useUpdateEffect(() => {
    setAttached(document.attached);
  }, [document.attached]);

  if (document.isMissing || document.documentClassification === 'DocumentPlaceholder') {
    return null;
  }

  const toggleAttachDocuments = async () => {
    setIsProcessing(true);
    const { ok } = await handleAttachDocument(document, context);
    if (ok) {
      await updateDocuments();
      setAttached((prev) => !prev);
    }
    setIsProcessing(false);
  };

  const isExpired = isDocumentExpired(documentConfigData, document.date);

  const isDisabled =
    !shouldDossierFeatureBeActive(dossierStatus) ||
    isProcessing ||
    document.sentOut ||
    !document.isAttachable ||
    (isExpired && !document.attached);

  return (
    <Checkbox
      color="primary"
      checked={attached}
      onChange={toggleAttachDocuments}
      disabled={isDisabled}
    />
  );
};

export default AttachDocumentCell;
