import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { PersonFormContext } from 'contexts/personForm/personFormContext';
import { usePersonForm } from 'modules/PersonForm/usePersonForm';
import { UseNaturalPersonStep } from '../../types';
import RelationsAndDocumentsStep from './RelationsAndDocumentsStep';

export const useRelationsAndDocumentsStep: UseNaturalPersonStep = ({
  naturalPersonId,
  onCancel,
  handleSave,
}) => {
  const { submitting, relationsToPreview, ownershipsToPreview } =
    useContext(PersonFormContext);
  const { validateDocuments, formMethods, isAnyPending, documents } =
    useContext(FilesUploadContext);

  const { t } = useTranslation();
  const isDirty = useMemo(
    () => !!(relationsToPreview.length || documents.length || ownershipsToPreview.length),
    [relationsToPreview.length, documents.length, ownershipsToPreview.length],
  );
  const { saveForm } = usePersonForm(naturalPersonId, handleSave, isDirty);

  return {
    isValid: formMethods.formState.isValid,
    Component: naturalPersonId ? (
      <RelationsAndDocumentsStep naturalPersonId={naturalPersonId} />
    ) : (
      <></>
    ),
    cancelButton: {
      onClick: onCancel,
    },
    backButton: {
      onClick: validateDocuments,
    },
    submitButton: {
      label: t('saveAndClose'),
      onClick: saveForm,
      saving: submitting || isAnyPending,
    },
  };
};
