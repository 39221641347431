import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import usePrompt from 'hooks/usePrompt';
import useUserFields from 'hooks/useUserFields';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { UserFormData } from 'api/users/types';
import { ControlledInput } from 'components/controlledInputs';

const UserDetailsEdition = () => {
  const {
    control,
    formState: { errors, isDirty },
  } = useFormContext<UserFormData>();

  usePrompt({ when: isDirty, id: 'userDetails' });

  const { t } = useTranslation('formFields');

  const fields = useUserFields();

  return (
    <Stack spacing={1} p={2.5} pt={0}>
      {fields
        .filter(({ name }) => name !== 'email')
        .map(({ name, testId, ...field }) => (
          <InputLabelWrapper label={t(name)} key={name}>
            <ControlledInput
              name={name}
              control={control}
              error={errors[name]}
              hideHelperTextGap
              data-testid={testId}
              {...field}
            />
          </InputLabelWrapper>
        ))}
    </Stack>
  );
};

export default UserDetailsEdition;
