import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { HelpIcon } from 'icons';
import { CountryCode } from 'libphonenumber-js';
import { MY_KORFINE_COUNTRY_SHEET_URL } from 'api/const';

export const TaxResidenceTooltip = ({ countryCode }: { countryCode: CountryCode }) => {
  const { t } = useTranslation('common');

  return (
    <IconTooltip
      title={
        <Trans
          t={t}
          i18nKey="tooltips.taxResidence"
          context={countryCode}
          components={{
            myKorfineLink: (
              <Link
                href={`${MY_KORFINE_COUNTRY_SHEET_URL}?countryCode=${countryCode}`}
                sx={({ palette }) => ({
                  textDecoration: 'underline',
                  color: palette.secondary.main,
                })}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      }
      sx={{ pointerEvents: 'auto', cursor: 'default' }}
    >
      <HelpIcon fontSize="small" color="primary" />
    </IconTooltip>
  );
};
