import { useOutletContext } from 'react-router-dom';
import DossierDocumentsTable from 'templates/DossierDocumentsTable';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';

export const ProductDocuments = () => {
  // const [productDocuments, setProductDocuments] = useState<any[]>([]);
  // const [loading, setLoading] = useState(true);

  const { dossierId, dossierData } = useOutletContext<DossierOutletContext>();

  // const fetchDocuments = useCallback(async () => {
  //   setLoading(true);
  //   const documents = await fetchProductDocuments(dossierId, dossierData);
  //   setProductDocuments(documents);
  //   setLoading(false);
  // }, [dossierId]);

  // useEffect(() => {
  //   fetchDocuments();
  // }, [fetchDocuments]);

  // TODO: Add grouping as it was in CustomTable
  return (
    <DossierDocumentsTable
      data={[]}
      openDocumentsModal={() => {}}
      updateDocuments={async () => {}}
      // loading={loading}
      context={{
        dossierId,
        dossierStatus: dossierData.status,
        entityId: dossierId,
        entityType: 'dossier',
        scope: 'dossier',
      }}
    />
  );
};
