import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import TextDivider from 'atoms/TextDivider';
import { depositDistributions } from '../static-data';
import { AbolishmentReason, DepositDistribution } from '../types';
import DepositDistributionFieldsWrapper from './DepositDistributionFieldsWrapper';

interface Props {
  abolishmentReason: AbolishmentReason | null;
  depositDistribution: DepositDistribution | null;
  withoutUnknown?: boolean;
  noEmail?: boolean;
}

export const DepositDistributionSection = ({
  abolishmentReason,
  depositDistribution,
  withoutUnknown = false,
  noEmail = false,
}: Props) => {
  const { control } = useFormContext();

  const { t } = useTranslation('modals', {
    keyPrefix: 'prepareReleaseModal.sections.depositDistribution',
  });

  const distributions = useMemo(
    () =>
      abolishmentReason === 'verdict' || withoutUnknown
        ? depositDistributions.filter((value) => value !== 'depositUnknown')
        : depositDistributions,
    [abolishmentReason],
  );

  return (
    abolishmentReason && (
      <Box>
        <TextDivider label={t('title')} />
        <Box mt={1}>
          <Controller
            name="depositDistribution"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <RadioGroup
                name="reasons"
                value={field.value ?? ''}
                onChange={onChange}
                ref={ref}
              >
                {distributions.map((value) => (
                  <Box key={value}>
                    <FormControlLabel
                      value={value}
                      control={<Radio />}
                      label={t(`fields.${value}`)}
                      sx={{ pt: 1 }}
                    />
                    {value === depositDistribution && (
                      <DepositDistributionFieldsWrapper
                        abolishmentReason={abolishmentReason}
                        depositDistribution={value}
                        noEmail={noEmail}
                      />
                    )}
                  </Box>
                ))}
              </RadioGroup>
            )}
          />
        </Box>
      </Box>
    )
  );
};
