import { useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import { PersonFormContext } from 'contexts/personForm/personFormContext';
import { TKey } from 'i18next';
import AddLegalPersonModal from 'modules/ModalForm/AddLegalPersonModal';
import AddNaturalPersonModal from 'modules/ModalForm/AddNaturalPersonModal';
import { MAX_ESTATES } from 'modules/OwnershipCard/static-data';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import { FetchOptions } from 'modules/Relation/types';
import { fetchEstateOptions, fetchPersonRelationOptions } from 'modules/Relation/utils';
import { MAX_RELATIONS } from 'modules/RepresentationCard/static-data';
import { FetchedPersonData, PersonType } from 'api/person/types';

interface Props {
  personType: PersonType;
}

const PersonRelations = ({ personType }: Props) => {
  const {
    relationsToPreview,
    setRelationsToPreview,
    ownershipsToPreview,
    setOwnershipsToPreview,
  } = useContext(PersonFormContext);

  const personsToFilter = useMemo(
    () => relationsToPreview.map(({ id }) => id),
    [relationsToPreview],
  );

  const estatesToFilter = useMemo(
    () => relationsToPreview.map(({ id }) => id),
    [relationsToPreview],
  );

  const {
    fetchOptions,
    translationKey,
  }: {
    translationKey: TKey<'common', 'relationInput', false>;
    fetchOptions: FetchOptions<FetchedPersonData>;
  } = useMemo(() => {
    if (personType === 'NaturalPerson') {
      return {
        translationKey: 'naturalPersonForm',
        fetchOptions: (params) =>
          fetchPersonRelationOptions({ ...params, type: 'LegalPerson' }),
      };
    }
    return {
      translationKey: 'legalPersonForm',
      fetchOptions: (params) =>
        fetchPersonRelationOptions({ ...params, type: 'NaturalPerson' }),
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <AddRelation
          translationKey={translationKey}
          maxRelations={MAX_RELATIONS}
          fetchOptions={fetchOptions}
          setRelationsToPreview={setRelationsToPreview}
          relationsToFilter={personsToFilter}
          AddNewOptionModal={
            personType === 'NaturalPerson' ? AddLegalPersonModal : AddNaturalPersonModal
          }
        />
        <RelationPreviewList
          relationsToPreview={relationsToPreview}
          setRelationsToPreview={setRelationsToPreview}
        />
      </Grid>
      <Grid item xs={12}>
        <AddRelation
          translationKey="estate"
          maxRelations={MAX_ESTATES}
          fetchOptions={fetchEstateOptions}
          setRelationsToPreview={setOwnershipsToPreview}
          relationsToFilter={estatesToFilter}
        />
        <RelationPreviewList
          relationsToPreview={ownershipsToPreview}
          setRelationsToPreview={setOwnershipsToPreview}
        />
      </Grid>
    </>
  );
};

export default PersonRelations;
