import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import DividerStack from 'atoms/DividerStack';
import { addressOrder } from 'formData/common/address/order';
import { naturalPersonOrder, naturalPersonSchema } from 'formData/naturalPerson';
import { NaturalPersonIcon } from 'icons';
import PageCard from 'templates/PageCard';
import SectionRows from 'templates/SectionRows';
import getLng from 'utils/getLng';
import { isTruthyValueInObject, sortObject } from 'utils/object';
import transformName from 'utils/transformName';
import { resolver } from 'utils/yup';
import { updateNaturalPerson } from 'api/person/natural/requests';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { NaturalPersonOutletContext } from 'pages/Persons/NaturalPersons/Details/types';
import NaturalPersonDetailsEdition from './NaturalPersonDetailsEdition';
import { getNaturalPersonDefaultValues, getNaturalPersonDisplayData } from './utils';

type NaturalPersonDetailsCardProps = Pick<
  NaturalPersonOutletContext,
  'naturalPersonData' | 'naturalPersonId' | 'fetchNaturalPersonData'
>;

const NaturalPersonDetailsCard = ({
  naturalPersonData,
  naturalPersonId,
  fetchNaturalPersonData,
}: NaturalPersonDetailsCardProps) => {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const { i18n } = useTranslation();

  const methods = useForm<NaturalPersonFormData>({
    mode: 'onChange',
    resolver: resolver(naturalPersonSchema),
  });

  const {
    formState: { isDirty, errors },
    reset,
    trigger,
    handleSubmit,
  } = methods;

  useEffect(() => {
    const defaultValues = getNaturalPersonDefaultValues(naturalPersonData);
    reset(defaultValues, { keepDirty: false });
  }, [naturalPersonData]);

  useEffect(() => {
    if (editing) {
      setEditing(false);
    }
  }, [naturalPersonId]);

  const onSave: SubmitHandler<NaturalPersonFormData> = async (data) => {
    setSaving(true);
    const { ok } = await updateNaturalPerson(naturalPersonId, data);
    if (ok) {
      await fetchNaturalPersonData();
      setEditing(false);
      reset(data);
    }
    setSaving(false);
  };

  const onEdit = () => {
    trigger();
    setEditing(true);
  };

  const onCancel = () => {
    reset();
    setEditing(false);
  };

  const { address, details, general } = useMemo(
    () => getNaturalPersonDisplayData(getLng(i18n), naturalPersonData),
    [i18n.language, naturalPersonData],
  );

  return (
    <PageCard
      headerProps={{
        variant: 'light',
        size: 'large',
        titleProps: {
          title: transformName(naturalPersonData),
          startIcon: <NaturalPersonIcon />,
        },
        editionProps: {
          isEditing: editing,
          saving,
          saveDisabled: !isDirty || !!Object.keys(errors ?? {}).length,
          onEdit,
          onCancel,
          onSave: handleSubmit(onSave),
        },
      }}
    >
      <Box>
        {editing ? (
          <FormProvider {...methods}>
            <NaturalPersonDetailsEdition naturalPersonId={naturalPersonId} />
          </FormProvider>
        ) : (
          <DividerStack>
            <SectionRows
              data={sortObject(general, naturalPersonOrder)}
              links={{ email: `mailto:${general.email}` }}
              translateValue={{
                language: `enums:language.${general.language}`,
                sex: `enums:sex.${general.sex}`,
              }}
            />
            <SectionRows
              data={sortObject(address, addressOrder)}
              translationPrefix="address"
            />
            {isTruthyValueInObject(details) && (
              <SectionRows
                data={sortObject(details, naturalPersonOrder)}
                translateValue={{
                  taxResidenceBelgium: `enums:taxResidences.${details.taxResidenceBelgium}`,
                }}
              />
            )}
          </DividerStack>
        )}
      </Box>
    </PageCard>
  );
};

export default NaturalPersonDetailsCard;
