import { Navigate, RouteObject } from 'react-router-dom';
import App from 'App';
import ErrorElement from 'atoms/ErrorElement';
import {
  BackofficePermissions,
  DashboardPermissions,
  DossierPermissions,
  EstatePermissions,
  PersonPermissions,
  UserPermissions,
} from 'consts/permissions';
import BusinessesList from 'pages/Businesses/List';
import Dashboard from 'pages/Dashboard';
import DossierDetails from 'pages/Dossiers/Details';
import DossiersList from 'pages/Dossiers/List';
import AddNewDossier from 'pages/Dossiers/New';
import EstateDetails from 'pages/Estates/Details';
import EstatesList from 'pages/Estates/List';
import AddNewEstate from 'pages/Estates/New';
import MigrationQueueList from 'pages/MigrationQueue/List/MigrationQueueList';
import NotificationsList from 'pages/Notifications/List';
import LegalPersonDetails from 'pages/Persons/LegalPersons/Details';
import PersonsList from 'pages/Persons/List';
import NaturalPersonDetails from 'pages/Persons/NaturalPersons/Details';
import AddNewPerson from 'pages/Persons/NewPerson';
import Translations from 'pages/Translations';
import UserDetails from 'pages/Users/Details';
import UsersList from 'pages/Users/List';
import AddNewUser from 'pages/Users/New/';
import ProtectedRoute from './ProtectedRoute';
import routes from './routes';
import { dossierTabs, estateTabs, legalPersonTabs, naturalPersonTabs } from './tabRoutes';

//TODO due to impersonate feature permission value for some protected routes should be updated
export const mainRoute: RouteObject = {
  path: routes.dashboard,
  element: <App />,
  errorElement: <Navigate to={routes.dashboard} />,
  children: [
    {
      path: routes.dashboard,
      element: (
        <ProtectedRoute
          permission={DashboardPermissions.Read}
          fallback={(permissions) =>
            permissions.includes('BackofficeData') ? routes.businesses : undefined
          }
        >
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.estates,
      element: (
        <ProtectedRoute permission={EstatePermissions.Read}>
          <EstatesList />
        </ProtectedRoute>
      ),
    },
    {
      id: 'estate',
      path: routes.estate(':estateId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute permission={EstatePermissions.Read}>
          <EstateDetails />
        </ProtectedRoute>
      ),
      children: estateTabs,
    },
    {
      path: routes.newEstate,
      element: (
        <ProtectedRoute permission={EstatePermissions.Write} fallback={routes.estates}>
          <AddNewEstate />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.persons,
      element: (
        <ProtectedRoute permission={PersonPermissions.Read}>
          <PersonsList />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.newPerson,
      element: (
        <ProtectedRoute permission={PersonPermissions.Write} fallback={routes.persons}>
          <AddNewPerson />
        </ProtectedRoute>
      ),
    },
    {
      id: 'naturalPerson',
      path: routes.naturalPerson(':naturalPersonId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute permission={PersonPermissions.Read}>
          <NaturalPersonDetails />
        </ProtectedRoute>
      ),
      children: naturalPersonTabs,
    },
    {
      id: 'legalPerson',
      path: routes.legalPerson(':legalPersonId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute permission={PersonPermissions.Read}>
          <LegalPersonDetails />
        </ProtectedRoute>
      ),
      children: legalPersonTabs,
    },
    {
      path: routes.dossiers,
      element: (
        <ProtectedRoute permission={DossierPermissions.Read}>
          <DossiersList />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.newDossier,
      element: (
        <ProtectedRoute permission={DossierPermissions.Write} fallback={routes.dossiers}>
          <AddNewDossier />
        </ProtectedRoute>
      ),
    },
    {
      id: 'dossier',
      path: routes.rentalDossier(':dossierId'),
      ErrorBoundary: ErrorElement,
      element: (
        <ProtectedRoute permission={DossierPermissions.Read}>
          <DossierDetails />
        </ProtectedRoute>
      ),
      children: dossierTabs,
    },
    {
      path: routes.users,
      element: (
        <ProtectedRoute permission={UserPermissions.Write}>
          <UsersList />
        </ProtectedRoute>
      ),
    },
    {
      id: 'user',
      path: routes.user(':userId'),
      element: (
        <ProtectedRoute permission={UserPermissions.Write}>
          <UserDetails />
        </ProtectedRoute>
      ),
      ErrorBoundary: ErrorElement,
    },
    {
      path: routes.newUser,
      element: (
        <ProtectedRoute permission={UserPermissions.Write}>
          <AddNewUser />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.businesses,
      element: (
        <ProtectedRoute permission={BackofficePermissions.Read}>
          <BusinessesList />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.migrationQueue,
      element: (
        <ProtectedRoute permission={BackofficePermissions.Read}>
          <MigrationQueueList />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.translations,
      element: (
        <ProtectedRoute permission={BackofficePermissions.Read}>
          <Translations />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.notifications,
      element: (
        <ProtectedRoute permission={'BusinessData'}>
          <NotificationsList />
        </ProtectedRoute>
      ),
    },
  ],
};
