import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@mui/material';
import { ClearIcon } from 'icons';
import replaceSpecialChars from 'utils/replaceSpecialChars';
import { BaseInput } from './BaseInput';
import { charactersLimit } from './static-data';
import { TextInputProps } from './types';

export const TextInput = ({
  helperText,
  warning,
  value,
  withSpecialChars,
  clearable,
  onClear,
  ...props
}: TextInputProps) => {
  const { t } = useTranslation();
  const inputLength = String(value).length;
  const isMultiline = !!props.multiline;

  return (
    <BaseInput
      type="text"
      value={withSpecialChars && value ? replaceSpecialChars(String(value)) : value}
      helperText={
        helperText ||
        (isMultiline ? (
          <Stack direction="row">
            {inputLength === charactersLimit.textArea && (
              <Typography variant="caption">
                {t('helperText.maxNumberOfCharacters')}
              </Typography>
            )}
            <Typography
              ml="auto"
              variant="caption"
            >{`${inputLength}/${charactersLimit.textArea}`}</Typography>
          </Stack>
        ) : undefined)
      }
      endAdornment={
        clearable &&
        !!value && (
          <IconButton size="small" onClick={onClear}>
            <ClearIcon fontSize="small" />
          </IconButton>
        )
      }
      warning={
        warning || (isMultiline ? inputLength === charactersLimit.textArea : undefined)
      }
      {...props}
    />
  );
};
