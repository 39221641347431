import { FieldArrayWithId } from 'react-hook-form';
import { estateCompositionFields } from 'formData/estate/details/fields';
import i18n from 'i18n';
import getLng from 'utils/getLng';
import mapObject from 'utils/object/mapObject';
import { TranslationFieldInterface } from 'api/common/types';
import { EstateDetailsForm } from 'api/estate/types';

export const formatInput = (input: TranslationFieldInterface) => {
  if (Object.values(input).every(Boolean)) {
    return input;
  }

  return mapObject(
    input,
    (value) => String(value) || Object.values(input).find(Boolean) || '',
  );
};

export const nameExists = (
  input: TranslationFieldInterface,
  fields: FieldArrayWithId<EstateDetailsForm, 'estateComposition.additionalItems'>[],
) => {
  const lng = getLng();
  const currentInput = input[lng].toLowerCase();

  const staticFieldExists = estateCompositionFields.some(
    ({ name }) =>
      i18n.t(`enums:estateComposition.${name}`).toLowerCase() === currentInput,
  );

  const dynamicFieldExists = fields.some(
    ({ name }) => name[lng].toLowerCase() === currentInput,
  );

  return staticFieldExists || dynamicFieldExists;
};
