import { getEstateEpcTypes } from 'api/config/requests';
import { EstateDetailsForm } from 'api/estate/types';

export const prepareDetailsData = async (
  postCode: string,
  estateDetails: EstateDetailsForm,
): Promise<EstateDetailsForm> => {
  const { ok, response } = await getEstateEpcTypes(postCode);
  const epcType = ok && response.find(({ type }) => type === estateDetails.epc?.type);

  return {
    ...estateDetails,
    hasEpc: !!estateDetails.epc?.referenceEnergyConsumption || !!epcType,
    epc:
      estateDetails.epc && epcType
        ? {
            ...estateDetails.epc,
            ...epcType,
          }
        : null,
  };
};
