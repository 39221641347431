import { useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { PersonFormContext } from 'contexts/personForm/personFormContext';
import { legalPersonSchema } from 'formData/legalPerson';
import { usePersonForm } from 'modules/PersonForm/usePersonForm';
import setValidationErrors from 'utils/setValidationErrors';
import { resolver } from 'utils/yup';
import { addLegalPerson } from 'api/person/legal/requests';
import { LegalPersonFormData } from 'api/person/legal/types';
import { UseLegalPersonGeneralStep } from '../types';
import { GeneralStep } from './GeneralStep';

export const useGeneralStep: UseLegalPersonGeneralStep = ({
  onCancel,
  legalPersonId,
  setLegalPersonId,
  handleSave,
}) => {
  const [saving, setSaving] = useState(false);

  const { t } = useTranslation();
  const { updateGeneral, submitting, setSaved } = useContext(PersonFormContext);

  const methods = useForm<LegalPersonFormData>({
    defaultValues: legalPersonSchema.getDefault(),
    resolver: resolver(legalPersonSchema),
  });

  const {
    handleSubmit,
    setError,
    formState: { isValid, isDirty },
    reset,
  } = methods;

  const { saveForm } = usePersonForm(legalPersonId, handleSave, isDirty, setError);

  const saveAndContinue =
    (nextStep: () => void): SubmitHandler<LegalPersonFormData> =>
    async (data) => {
      setSaving(true);
      const res = await addLegalPerson(data);
      if (res.ok) {
        setLegalPersonId(res.response.id);
        updateGeneral(data);
        nextStep();
        setSaved(true);
        reset(data, { keepDirty: false });
        toast.success(t('successMessages.PersonAddedSuccessfully'));
      } else {
        setValidationErrors(res.validation, setError);
      }
      setSaving(false);
    };

  const saveAndClose: SubmitHandler<LegalPersonFormData> = async (data, event) => {
    saveForm(event, data);
  };

  const { isAnyPending } = useContext(FilesUploadContext);
  return {
    isValid,
    Component: (
      <FormProvider {...methods}>
        <GeneralStep />
      </FormProvider>
    ),
    cancelButton: {
      onClick: onCancel,
    },
    nextButton: !legalPersonId
      ? {
          changeStepManually: true,
          label: t('saveAndContinue'),
          onClick: (nextStep) => handleSubmit(saveAndContinue(nextStep)),
          saving,
        }
      : {
          onClick: handleSubmit(updateGeneral),
        },
    labelButton: {
      onClick: handleSubmit(updateGeneral),
    },
    submitButton: {
      label: t('saveAndClose'),
      onClick: handleSubmit(saveAndClose),
      saving: submitting || isAnyPending,
    },
    required: true,
  };
};
