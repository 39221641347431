import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { VatInput } from 'components/inputs/VatInput';
import { ControlledVatInputProps } from './types';
import { getErrorMessage } from './utils';

export const ControlledVatInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledVatInputProps<T, N>,
) => React.ReactElement = ({ name, error, warning, helperText, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, onChange, value, ...field } }) => (
      <VatInput
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        vatNumber={value?.number}
        vatPrefix={value?.prefix}
        onNumberChange={(number) => onChange({ ...value, number })}
        onPrefixChange={(prefix) => onChange({ ...value, prefix })}
        helperText={getErrorMessage(error ?? warning) || helperText}
        {...field}
        {...props}
      />
    )}
  />
);
