import getLng from 'utils/getLng';
import api from 'api';
import { apiUrl } from 'api/const';
import { UrlParams } from 'api/types';
import { parseResponse } from 'api/utils';
import { FetchedPersonData, FetchedPersonList } from './types';

export const getPersons = (params: UrlParams) =>
  api.get<FetchedPersonList>(apiUrl.personsWithParams({ ...params, lng: getLng() }), {
    transformResponse: [
      parseResponse,
      ({ meta, data }) => ({
        meta,
        data: data.map((person: FetchedPersonData) => ({
          ...person,
          address: person.address ?? '',
        })),
      }),
    ],
  });
