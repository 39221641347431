import { Box, Divider, Stack } from '@mui/material';
import usePrompt from 'hooks/usePrompt';
import { SetState } from 'types/setState';
import RelationPreviewItem from './RelationPreviewItem';
import { RelationData } from './types';

interface Props<T extends object> {
  relationsToPreview: RelationData<T>[];
  setRelationsToPreview?: SetState<RelationData<T>[]>;
  isDivider?: boolean;
  onRemove?: (relation: RelationData<T>) => void;
  withPrompt?: boolean;
  showIcons?: boolean;
  withLink?: boolean;
}

const RelationPreviewList = <T extends object>({
  relationsToPreview,
  setRelationsToPreview,
  isDivider,
  onRemove,
  withPrompt = false,
  withLink = true,
  showIcons,
}: Props<T>) => {
  const handleRemove = (data: RelationData<T>) => {
    if (!setRelationsToPreview) return;
    if (onRemove) {
      onRemove(data);
    }
    setRelationsToPreview((prev) => prev.filter(({ id }) => id !== data.id));
  };

  usePrompt({ when: withPrompt && !!relationsToPreview.length, id: 'relations' });

  return (
    <>
      <Stack paddingX={2.5}>
        <span data-testid="relation-list">
          {relationsToPreview.map((relation) => (
            <RelationPreviewItem
              key={relation.id}
              relation={relation}
              removable={!!setRelationsToPreview}
              handleRemove={handleRemove}
              showIcon={showIcons}
              withLink={withLink}
            />
          ))}
        </span>
      </Stack>
      {!!relationsToPreview.length && isDivider && (
        <Box pt={1}>
          <Divider />
        </Box>
      )}
    </>
  );
};

export default RelationPreviewList;
