import { getFormAddress } from 'formData/common/address/utils';
import { LegalPersonIcon, NaturalPersonIcon } from 'icons';
import {
  AddendumFormPerson,
  RegisterAddendumForm,
  RentalContractData,
} from 'api/dossier/products/rentalContract/types';
import { PersonInDossier } from 'api/dossier/types';
import { FetchedLegalPersonData } from 'api/person/legal/types';
import { FetchedNaturalPersonData } from 'api/person/natural/types';
import { PersonType } from 'api/person/types';
import { AddendumContactOption } from './types';

export const getAddendumDefaultValues = ({
  biv,
  contractSigningDate,
  ...initialValues
}: RentalContractData): RegisterAddendumForm => ({
  ...initialValues,
  uploadedAddendum: {
    biv,
    addendumSigningDate: contractSigningDate,
    addendumFile: null,
    documentName: '',
    language: 'Dutch',
  },
});

export const transformToAddendumFormPerson = (
  person: FetchedNaturalPersonData | FetchedLegalPersonData,
  personType: PersonType,
  companyName?: string,
): AddendumFormPerson => {
  if (personType === 'NaturalPerson') {
    const naturalPerson = person as FetchedNaturalPersonData;
    return {
      ...naturalPerson,
      type: personType,
      address: getFormAddress(naturalPerson.address),
      companyName,
    };
  }
  const legalPerson = person as FetchedLegalPersonData;
  return {
    ...legalPerson,
    type: personType,
    address: getFormAddress(legalPerson.address),
  };
};

export const getPersonsOptions = (
  addedPersons: AddendumFormPerson[],
  options?: PersonInDossier[],
): AddendumContactOption[] => {
  return (options || [])
    .filter(
      ({ personId, isRepresentative }) =>
        !addedPersons.find((option) => option.id === personId) && !isRepresentative,
    )
    .map((person) => ({
      value: person.personId,
      label: person.firstName ? `${person.name} ${person.firstName}` : person.name,
      Icon: person.type === 'NaturalPerson' ? NaturalPersonIcon : LegalPersonIcon,
      representativeId: person.representativeId,
      type: person.type,
    }));
};
