import { useState } from 'react';
import { useFilters } from 'templates/Table/hooks';
import { Select } from 'components/inputs';
import { TranslatedTableColumn } from '../types';

const TableSelectFilter = ({
  field,
  filterName = field,
  filterOptions,
  filterLabel,
}: TranslatedTableColumn) => {
  const { onFilterChange, initState } = useFilters(filterName);

  const [option, setOption] = useState(initState);

  const onChange = (option: string) => {
    setOption(option);
    onFilterChange(option);
  };

  return (
    <Select
      hideHelperTextGap
      placeholder={filterLabel}
      value={option}
      options={filterOptions ?? []}
      onChange={onChange}
      onClear={() => onChange('')}
      clearable
    />
  );
};

export default TableSelectFilter;
