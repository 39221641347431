import { useContext } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import { StepInterface } from 'templates/StepWizard/types';
import { RentalConditions } from 'api/dossier/types';
import { DossierFormStep } from '../types';
import RentalConditionsStep from './RentalConditionsStep';

const useRentalConditionsStep = ({ onCancel }: DossierFormStep): StepInterface => {
  const { t } = useTranslation();

  const {
    rentalConditionsMethods,
    saving,
    saveForm,
    updateErrors,
    updateRentalConditionsForm,
  } = useContext(DossierFormContext);

  const onSave: SubmitHandler<RentalConditions> = (rentalConditions) => {
    saveForm({ rentalConditions });
  };

  const onError = () => updateErrors({ isRentalConditionsFormValid: false });

  return {
    Component: <RentalConditionsStep methods={rentalConditionsMethods} />,
    labelButton: {
      onClick: updateRentalConditionsForm,
    },
    cancelButton: {
      onClick: onCancel,
    },
    backButton: {
      onClick: updateRentalConditionsForm,
    },
    nextButton: { disable: true },
    submitButton: {
      label: t('saveAndClose'),
      onClick: rentalConditionsMethods.handleSubmit(onSave, onError),
      saving,
    },
  };
};

export default useRentalConditionsStep;
