import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import useCountryOptions from 'hooks/useCountryOptions';
import getFormError from 'utils/getFormError';
import { ControlledVatInput } from 'components/controlledInputs/ControlledVatInput';
import { mapVatOptions } from './utils';

interface Props {
  label?: ReactNode;
  hideHelperTextGap?: boolean;
  disabled?: boolean;
  name?: string;
}
/** Must be used inside FormProvider (react-hook-form) */
export const VatField = ({ name = 'vat', ...props }: Props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const [options, groupedOptions] = useCountryOptions(mapVatOptions);
  // const [vatWarning, setVatWarning] = useState<FieldWarning>();

  // const vatInput = useWatch({ control, name });
  // const debouncedVat = useDebounce(vatInput, 500);

  // const validate = useCallback(async () => {
  //   if (debouncedVat?.number) {
  //     const { number, prefix } = debouncedVat;
  //     let validMask = true;
  //     if (!isSubmitted) {
  //       const mask = getVatMask(prefix);
  //       validMask = mask && number.length === mask.length;
  //     }
  //     if (validMask && !getFormError(name, errors)) {
  //       const { ok, response } = await validateVat({
  //         prefix,
  //         number: clearVatMask(number),
  //       });
  //       if (ok) {
  //         setVatWarning(!response.isValid ? { message: 'vat.warning' } : undefined);
  //       }
  //     }
  //   }
  // }, [debouncedVat?.number]);

  // useEffect(() => {
  //   validate();
  // }, [validate]);

  return (
    <ControlledVatInput
      name={name}
      control={control}
      error={getFormError(name, errors)?.number}
      // warning={vatWarning}
      options={options}
      groupedOptions={groupedOptions}
      data-testid="vatInput"
      disabled={props.disabled}
      {...props}
    />
  );
};
