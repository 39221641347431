import { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CustomLink } from 'atoms/Link/CustomLink';
import routes from 'router/routes';
import transformPhone from 'utils/transformPhone';
import { renderPersonIcon } from 'utils/ui';
import { PersonInDetails } from '../types';

const Person = ({ name, personId, type, representative, ...person }: PersonInDetails) => {
  const contactData = useMemo(() => {
    const email = representative?.email ?? person.email;
    const phoneData = representative?.phone ?? person.phone;
    const phone = phoneData?.number ? transformPhone(phoneData) : undefined;
    return { email, phone };
  }, [person.email, person.phone, representative?.email, representative?.phone]);

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {renderPersonIcon(type)}
      <Stack>
        <Typography
          variant="h5"
          component={CustomLink}
          to={routes.personTab(personId, type).details}
          sx={({ mixins }) => mixins.link}
        >
          {name}
        </Typography>
        {representative && (
          <Typography
            component={CustomLink}
            to={routes.personTab(representative.personId, 'NaturalPerson').details}
            sx={({ mixins }) => mixins.link}
          >
            {representative.name}
          </Typography>
        )}
        {contactData.email && (
          <Typography
            fontWeight={600}
            variant="caption"
            component="a"
            href={`mailto:${contactData.email}`}
            sx={({ mixins }) => ({ ...mixins.link, color: 'grey.500' })}
          >
            {contactData.email}
          </Typography>
        )}
        {contactData.phone && (
          <Typography color="grey.500" fontWeight={600} variant="caption">
            {contactData.phone}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default Person;
