import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { PaymentInput } from 'components/inputs/PaymentInput';
import { ControlledPaymentInputProps } from './types';
import { getErrorMessage } from './utils';

export const ControlledPaymentInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledPaymentInputProps<T, N>,
) => React.ReactElement = ({ name, error, warning, helperText, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, onChange, value: v, ...field } }) => (
      <PaymentInput
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        value={v?.value ?? ''}
        currency={v?.currency}
        onValueChange={(e) =>
          onChange({ ...v, value: e.target.value ? Number(e.target.value) : null })
        }
        onCurrencyChange={(currency) => onChange({ ...v, currency })}
        helperText={
          getErrorMessage((error && 'value' in error ? error.value : error) ?? warning) ||
          helperText
        }
        {...field}
        {...props}
      />
    )}
  />
);
