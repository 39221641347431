import { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { rentalDepositChoosePartiesSchema } from 'formData/dossier/products/rentalDeposit/schema';
import { ProductPartiesV2 } from 'modules/ProductParties';
import { resolver } from 'utils/yup';
import { ProductActionContent } from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import { rentalDepositLandlordsConfig, rentalDepositTenantsConfig } from './static-data';
import { RentalDepositChoosePartiesForm } from './types';
import {
  getFormEditionValues,
  savePrivateRelations,
  saveRepresentatives,
  transformRentalDepositChoosePartiesData,
} from './utils';

export const RentalDepositChooseParties = ({
  sendRequest,
  setLoading,
  loading,
  setDisableSubmitButton,
  dossier: { dossierId, tenants, landlords },
  product: { productId, chosenParties },
}: ProductActionComponentProps<'RentalDeposit'>) => {
  const methods = useForm<RentalDepositChoosePartiesForm>({
    defaultValues: rentalDepositChoosePartiesSchema.getDefault(),
    resolver: resolver(rentalDepositChoosePartiesSchema),
  });

  const setDataForEdition = useCallback(async () => {
    if (chosenParties) {
      setLoading(true);
      const editionValues = await getFormEditionValues(chosenParties, tenants, landlords);
      methods.reset(editionValues, { keepDirty: false });
      setLoading(false);
    }
  }, [dossierId, productId]);

  useEffect(() => {
    setDataForEdition();
  }, [setDataForEdition]);

  useEffect(() => {
    if (chosenParties) {
      setDisableSubmitButton(!methods.formState.isDirty);
    }
  }, [methods.formState.isDirty]);

  const onSubmit: SubmitHandler<RentalDepositChoosePartiesForm> = async (data) => {
    if (data.tenants.length === 2) {
      await savePrivateRelations(data.tenants);
    }
    if (data.landlords.length === 2) {
      await savePrivateRelations(data.landlords);
    }

    if (data.tenants.some(({ type }) => type === 'LegalPerson')) {
      await saveRepresentatives(data.tenants);
    }
    if (data.landlords.some(({ type }) => type === 'LegalPerson')) {
      await saveRepresentatives(data.landlords);
    }

    const transformedData = transformRentalDepositChoosePartiesData(data);
    sendRequest(transformedData);
  };

  return (
    <ProductActionContent
      onSubmit={methods.handleSubmit(onSubmit)}
      spaceForScroll={false}
    >
      <FormProvider {...methods}>
        {!loading && (
          <Stack gap={2} pt={2}>
            <ProductPartiesV2
              persons={landlords}
              partyType="landlords"
              config={rentalDepositLandlordsConfig}
            />
            <ProductPartiesV2
              persons={tenants}
              partyType="tenants"
              config={rentalDepositTenantsConfig}
            />
          </Stack>
        )}
      </FormProvider>
    </ProductActionContent>
  );
};
