import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { HandleSearchClose, HandleSearchInputChange } from 'components/inputs/types';
import { SearchInputData, SearchOptionsParams, UseSearchInputProps } from './types';
import { filterOptions, groupOptions } from './utils';

export const useSearchInput = <T extends SearchInputData>({
  contextOptions = [],
  optionsToFilter,
  fetchOptions,
  initParams,
}: UseSearchInputProps<T>) => {
  const [options, setOptions] = useState(contextOptions);
  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState<SearchOptionsParams>({
    ...initParams,
    query: initParams?.query ?? '',
  });
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 500);

  useUpdateEffect(() => {
    if (params.query !== debouncedInput) {
      setParams((prev) => ({ ...prev, query: debouncedInput }));
    }
  }, [debouncedInput]);

  const clearQuery = () => {
    if (input) {
      setInput('');
    }
  };

  const updateParams = (newParams?: Partial<SearchOptionsParams>) => {
    if (!newParams?.query) {
      clearQuery();
    }

    if (
      JSON.stringify({ ...newParams, query: newParams?.query ?? '' }) !==
      JSON.stringify(params)
    ) {
      setParams((prev) => ({ ...prev, ...newParams }));
    }
  };

  const onInputChange: HandleSearchInputChange = (input, reason) => {
    if (reason === 'input') {
      setInput(input);
    } else if (reason === 'clear') {
      clearQuery();
    }
  };

  const onClose: HandleSearchClose = (_option, reason) => {
    if (reason === 'blur') {
      clearQuery();
    }
  };

  const getOptions = useCallback(async () => {
    setLoading(true);
    const options = await fetchOptions(params);
    setOptions(options);
    setLoading(false);
  }, [params]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  const filteredOptions = useMemo(
    () => filterOptions(options, contextOptions, optionsToFilter, params.query, loading),
    [options, optionsToFilter, loading],
  );

  return {
    updateParams,
    clearQuery,
    searchInputProps: {
      loading,
      options: filteredOptions,
      onInputChange,
      onClose,
      groupBy: groupOptions(contextOptions),
    },
  };
};
