import { createContext, PropsWithChildren, useState } from 'react';
import { addNewOwners } from 'modules/OwnershipCard/utils';
import { RelationData } from 'modules/Relation/types';
import { FetchedPersonData } from 'api/person/types';
import { EstateFormContextInterface } from './types';

const EstateFormContext = createContext<EstateFormContextInterface>({
  ownersToPreview: [],
  setOwnersToPreview: () => {},
  saveOwners: async () => false,
});

const EstateFormProvider = ({ children }: PropsWithChildren) => {
  const [ownersToPreview, setOwnersToPreview] = useState<
    RelationData<FetchedPersonData>[]
  >([]);

  const saveOwners = async (estateId: string) => {
    if (ownersToPreview.length) {
      const { allSucceed } = await addNewOwners(estateId)(
        ownersToPreview,
        setOwnersToPreview,
        true,
      );
      return allSucceed;
    }
    return true;
  };

  return (
    <EstateFormContext.Provider
      value={{ ownersToPreview, setOwnersToPreview, saveOwners }}
    >
      {children}
    </EstateFormContext.Provider>
  );
};

export { EstateFormProvider, EstateFormContext };
