import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { DossierPermissions } from 'consts/permissions';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import hasPermission from 'utils/hasPermission';
import { apiUrl } from 'api/const';
import DossierStatusButton from './DossierStatusButton';
import { DossierViewHeaderProps } from './types';

const DossierViewHeader = ({
  dossierId,
  dossierData: { status, referenceNumber, deletable },
  fetchDossier,
}: DossierViewHeaderProps) => {
  const { t } = useTranslation(['common', 'enums', 'formFields']);
  const navigate = useCustomNavigate();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const onDelete = () =>
    setUpAndOpenConfirmationModal({
      translationKey: 'rentalDossiers',
      url: apiUrl.rentalDossier(dossierId),
      onSuccess: () => {
        navigate(routes.dossiers);
      },
      translationValues: { referenceNumber },
    });

  return (
    <Box sx={({ mixins }) => mixins.entityViewHeader}>
      <Box display="flex" gap={0.5} mr="auto">
        <Typography variant="h5" color="primary.main" fontWeight="normal">
          {t('formFields:status')}:
        </Typography>
        <Typography variant="h5" color="primary.main">
          {t(`enums:dossierStatuses:${status}`, status)}
        </Typography>
      </Box>
      <DossierStatusButton
        dossierId={dossierId}
        fetchDossier={fetchDossier}
        status={status}
      />
      <Tooltip
        placement="top"
        title={!deletable ? t('tooltips.dossierNotDeletable') : ''}
      >
        <span>
          <Button
            onClick={onDelete}
            disabled={!deletable || !hasPermission(DossierPermissions.Write)}
            role="unsafe"
            variant="outlined"
            size="small"
          >
            {t('delete')}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default DossierViewHeader;
