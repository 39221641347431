import getObjectOrder from 'utils/object/getObjectOrder';
import electricitySchema from './electricity/schema';
import epcSchema from './epc/schema';
import gasSchema from './gas/schema';
import estateDetailsSchema from './schema';

export const estateDetailsOrder = getObjectOrder(estateDetailsSchema.fields);
export const estateElectricityOrder = getObjectOrder(electricitySchema.fields);
export const estateEpcOrder = getObjectOrder(epcSchema.fields);
export const estateGasOrder = getObjectOrder(gasSchema.fields);
