import { firstCharLowerCase } from 'utils/string';
import { getRentalDepositAbolishmentData } from 'api/dossier/products/rentalDeposit/requests';
import { AbolishmentForm } from '../PrepareRelease/types';

export const getAbolishmentData = async (dossierId: string, productId: string) => {
  const { ok, response } = await getRentalDepositAbolishmentData(dossierId, productId);
  if (ok) {
    const { depositDistribution, abolishmentReason, ...data } = response;
    return {
      ...data,
      abolishmentReason: abolishmentReason ?? 'identicalParties',
      depositDistribution: depositDistribution
        ? firstCharLowerCase(depositDistribution)
        : null,
    } as AbolishmentForm;
  }
  return null;
};
