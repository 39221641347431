import { Navigate, useOutletContext } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Parties from 'modules/DossierView/Parties';
import routes from 'router/routes';
import { DossierOutletContext } from '../types';

const DossierPartiesTab = () => {
  const context = useOutletContext<DossierOutletContext>();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return matches ? (
    <Navigate to={routes.rentalDossierTab(context.dossierId).products} replace />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} marginBottom="auto">
        <Parties partyType="landlords" {...context} />
      </Grid>
      <Grid item xs={12} md={6} marginBottom="auto">
        <Parties partyType="tenants" {...context} />
      </Grid>
    </Grid>
  );
};

export default DossierPartiesTab;
