import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, MenuList } from '@mui/material';
import Button from 'atoms/Button';
import useMenu from 'hooks/useMenu';
import { updateRentalDossierStatus } from 'api/dossier/requests';
import { DossierStatus } from 'api/dossier/types';
import { DossierStatusButtonProps } from './types';
import { filterDossierStatuses } from './utils';

const DossierStatusButton = ({
  dossierId,
  fetchDossier,
  status,
}: DossierStatusButtonProps) => {
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const { t } = useTranslation(['common', 'enums']);
  const { anchorEl, closeMenu, openMenu } = useMenu();

  const onChangeStatus = async (status: DossierStatus) => {
    closeMenu();
    setUpdatingStatus(true);
    const { ok } = await updateRentalDossierStatus(dossierId, status);
    if (ok) {
      await fetchDossier();
    }
    setUpdatingStatus(false);
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={openMenu} loading={updatingStatus}>
        {t('changeStatus')}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuList
          disablePadding
          sx={{
            '&:focus-visible, .MuiTouchRipple-root:focus-visible': {
              outline: 'none',
            },
          }}
        >
          {filterDossierStatuses(status).map(({ translationKey, value }) => (
            <MenuItem
              key={value}
              sx={{ minWidth: 100 }}
              onClick={() => onChangeStatus(value)}
            >
              {t(translationKey, value)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default DossierStatusButton;
