import { Navigate, RouteObject } from 'react-router-dom';
import { personTabRoutes } from 'router/routes';
import NaturalPersonContactsTab from 'pages/Persons/NaturalPersons/Details/tabs/Contacts';
import NaturalPersonDetailsTab from 'pages/Persons/NaturalPersons/Details/tabs/Details';
import NaturalPersonDocumentsTab from 'pages/Persons/NaturalPersons/Details/tabs/Documents';
import NaturalPersonDossiersTab from 'pages/Persons/NaturalPersons/Details/tabs/Dossiers';
import NaturalPersonEstatesTab from 'pages/Persons/NaturalPersons/Details/tabs/Estates';

export const naturalPersonTabs: RouteObject[] = [
  { path: personTabRoutes.details, element: <NaturalPersonDetailsTab /> },
  { path: personTabRoutes.contacts, element: <NaturalPersonContactsTab /> },
  { path: personTabRoutes.estates, element: <NaturalPersonEstatesTab /> },
  { path: personTabRoutes.dossiers, element: <NaturalPersonDossiersTab /> },
  { path: personTabRoutes.documents, element: <NaturalPersonDocumentsTab /> },
  { path: '*', element: <Navigate to={personTabRoutes.contacts} /> },
];
