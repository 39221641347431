import { FetchedDossierChecklistItem } from 'api/dossier/types';
import { getEstates } from 'api/estate/requests';
import { FetchedEstateListItem } from 'api/estate/types';
import { getPersons } from 'api/person/requests';
import { FetchedPersonData } from 'api/person/types';
import { getLegalPersonEstates, getNaturalPersonEstates } from 'api/relations/requests';
import {
  DossierFormChecklistItem,
  DossierFormInterface,
  DossierLocationState,
} from './types';

export const fetchEstateOwners = async (
  estate: FetchedEstateListItem | undefined,
  personsIdsToFilter: string[] = [],
) => {
  const ownersIds = estate?.owners
    .filter(({ id }) => !personsIdsToFilter.includes(id))
    .map(({ id }) => id)
    .join(',');

  if (ownersIds?.length) {
    const { ok, response } = await getPersons({ ids: ownersIds });
    if (ok) {
      return response.data;
    }
  }
  return [];
};

export const fetchDefaultValues = async (state: DossierLocationState | null) => {
  let estate: DossierFormInterface['estate'] = null;
  let landlords: DossierFormInterface['landlords'] = [];
  let tenants: DossierFormInterface['tenants'] = [];

  if (state?.estate) {
    estate = state.estate;
    landlords = await fetchEstateOwners(state.estate);
  } else if (state?.estateId) {
    const { ok, response } = await getEstates({ ids: state.estateId });
    if (ok) {
      estate = response.data[0];
      landlords = await fetchEstateOwners(estate);
    }
  }

  if (state?.landlordIds) {
    const { ok, response } = await getPersons({ ids: state.landlordIds.join(',') });
    if (ok) {
      landlords = response.data;
    }
  }

  if (state?.tenantIds) {
    const { ok, response } = await getPersons({ ids: state.tenantIds.join(',') });
    if (ok) {
      tenants = response.data;
    }
  }

  return { estate, landlords, tenants };
};

export const fetchOwnedEstates = async (
  person: FetchedPersonData,
): Promise<FetchedEstateListItem[]> => {
  const { ok, response } =
    person.type === 'NaturalPerson'
      ? await getNaturalPersonEstates(person.id)
      : await getLegalPersonEstates(person.id);
  if (ok) {
    return response.map(({ estate }) => estate);
  }
  return [];
};

export const formatRentalDossierChecklist = (
  props: FetchedDossierChecklistItem,
): DossierFormChecklistItem => ({
  ...props,
  checked:
    props.name === 'RentalDeposit' ||
    props.name === 'FireInsurance' ||
    props.name === 'LegalAssistance',
  disabled: props.name === 'IntermediateStateReport' || props.name === 'EndStateReport',
});
