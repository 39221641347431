import { residentsSchema } from 'formData/dossier/residents';
import i18n from 'i18n';
import { Residents, ResidentsForm } from 'api/dossier/types';

export const getResidentsDefaultValues = (
  residents: Residents | null,
): ResidentsForm => ({
  ...residentsSchema.getDefault(),
  ...(residents || residentsSchema.getDefault()),
  childrenDateOfBirth:
    residents?.childrenDateOfBirth?.map((dateOfBirth) => ({ dateOfBirth })) ?? null,
});

export const transformChildrenAge = (childrenDateOfBirth?: string[] | null) =>
  (childrenDateOfBirth ?? []).reduce(
    (prev, dateOfBirth, index) => ({
      ...prev,
      [i18n.t(`childrenDateOfBirthLabel`, {
        ns: 'formFields',
        count: (childrenDateOfBirth ?? []).length > 1 ? index + 1 : undefined,
      })]: dateOfBirth,
    }),
    {},
  );

export const createChildrenFields = (
  currentValueOfChildren: number,
  prevNumberOfChildren: number,
) =>
  [...new Array(currentValueOfChildren - prevNumberOfChildren)].map(() => ({
    dateOfBirth: null,
  }));
