import { ThemedStyle } from 'types/themedStyle';

export const headerStyles: ThemedStyle = ({ palette }) => ({
  width: `100%`,
  boxShadow: 'unset',
  backgroundColor: palette.background.default,
  color: palette.grey[700],
});

export const getHeaderToolbarStyles =
  (isSidebarExpanded: boolean, matchesBreakpoint: boolean): ThemedStyle =>
  ({ mixins }) => ({
    marginLeft: 'auto',
    width: `calc(100% - ${
      isSidebarExpanded && matchesBreakpoint
        ? mixins.openedDrawer.width
        : mixins.closedDrawer.width
    }px)`,
    transition: mixins.openedDrawer.transition,
  });
