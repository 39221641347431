import { Outlet, useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import useLoader from 'hooks/useLoader';
import { useUpdateEffect } from 'usehooks-ts';
import { DossierOutletContext } from '../../types';
import { getDossierDocumentsConfig } from '../../utils';
import { DossierDocumentsContext } from './types';

const DossierDocumentsTab = () => {
  const context = useOutletContext<DossierOutletContext>();

  const {
    data: dossierDocuments,
    updateData: fetchDocuments,
    Loader,
  } = useLoader(getDossierDocumentsConfig(context.dossierId));

  useUpdateEffect(() => {
    fetchDocuments();
  }, [context.dossierData]);

  return (
    <Loader>
      {dossierDocuments && (
        <Grid container item spacing={3}>
          <Grid item xs={12} marginBottom="auto">
            <Outlet
              context={
                {
                  ...context,
                  dossierDocuments,
                  fetchDocuments,
                } as DossierDocumentsContext
              }
            />
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default DossierDocumentsTab;
