import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  SetValueConfig,
  TriggerConfig,
  UseFormReturn,
} from 'react-hook-form';
import getFormError from 'utils/getFormError';

export const getFormMethods = <TForm extends FieldValues>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>,
  prefix?: string,
) => {
  const getValues = <TFieldName extends FieldPath<TForm>>(
    name?: TFieldName,
  ): FieldPathValue<TForm, TFieldName> => {
    if (prefix && name) {
      return methods.getValues(String(`${prefix}.${name}`));
    }
    if (!name && prefix) {
      return methods.getValues(prefix);
    }
    if (name && !prefix) {
      return methods.getValues(String(name));
    }
    return methods.getValues();
  };

  const setValue = <TFieldName extends FieldPath<TForm>>(
    name: TFieldName,
    value: FieldPathValue<TForm, TFieldName>,
    options?: SetValueConfig,
  ) =>
    methods.setValue(
      prefix ? `${prefix}.${name}` : name,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value as any,
      options,
    );

  const trigger = <TFieldName extends FieldPath<TForm>>(
    name?: TFieldName | TFieldName[],
    options?: TriggerConfig,
  ) => methods.trigger(prefix ? `${prefix}.${name}` : name, options);

  const watch = <TFieldName extends FieldPath<TForm>>(name: TFieldName) =>
    methods.watch(prefix ? `${prefix}.${name}` : name);

  const getError = (name: FieldPath<TForm>) =>
    getFormError(prefix ? `${prefix}.${name}` : name, methods.formState.errors);

  const clearErrors = (name: FieldPath<TForm>) =>
    methods.clearErrors(prefix ? `${prefix}.${name}` : name);

  return { getValues, setValue, trigger, getError, watch, clearErrors };
};
