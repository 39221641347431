import { useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import useAddressFields from 'hooks/useAddressFields';
import useEstatePurposeFields from 'hooks/useEstatePurposeFields';
import AddressFields from 'modules/AddressFields';
import { getDefaultRentType } from 'modules/DossierView/RentalConditions/utils';
import routes from 'router/routes';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { EstateFormData, EstateType } from 'api/estate/types';
import { ControlledInput } from 'components/controlledInputs';
import { MissingDataForm } from '../types';
import MissingRequiredFieldsError from './MissingRequiredFieldsError';
import { EstateMissingDataFormProps } from './types';
import {
  getMissingRequiredFields,
  getRequiredAndNotRequiredFields,
  isAnyAddressFieldMissing,
} from './utils';

const EstateMissingDataForm = ({
  id,
  methods,
  fields = [],
  isRentTypeMissing = false,
}: EstateMissingDataFormProps) => {
  const missingImportantFields = getMissingRequiredFields(
    methods.formState.errors.estate,
    fields,
  );

  const { t } = useTranslation('formFields');
  const { formState, getValues, setValue } = useFormContext<MissingDataForm>();

  const onEstateTypeChange = (type: EstateType) => {
    if (isRentTypeMissing && !formState.touchedFields.rentalConditions?.rentType) {
      const rentType = getDefaultRentType({
        type,
        destination: getValues('estate.destination'),
      });
      setValue('rentalConditions.rentType', rentType);
    }
  };

  const purposeFields = useEstatePurposeFields('estate', onEstateTypeChange);

  const { requiredFields, notRequiredFields } = useMemo(
    () =>
      getRequiredAndNotRequiredFields<EstateFormData>(fields || [], [...purposeFields]),
    [fields, purposeFields],
  );

  const addressFields = useAddressFields({
    prefix: 'estate',
    disableCountrySelection: true,
  });

  const displayAddressFields = isAnyAddressFieldMissing(fields, addressFields);

  return (
    <Stack spacing={1}>
      {displayAddressFields && (
        <AddressFields
          separateLabel
          fields={addressFields}
          prefix="estate"
          withAsterisk
        />
      )}
      {requiredFields
        .filter(({ hidden }) => !hidden)
        .map(({ name, label, ...field }) => (
          <InputLabelWrapper key={name} label={`${t(label ?? String(name), name)}*`}>
            <ControlledInput
              name={`estate.${name}`}
              control={methods.control}
              error={methods.formState.errors.estate?.[name] as FieldError}
              hideHelperTextGap
              {...field}
            />
          </InputLabelWrapper>
        ))}
      {notRequiredFields
        .filter(({ hidden }) => !hidden)
        .map(({ name, label, ...field }) => (
          <InputLabelWrapper key={name} label={t(label ?? String(name), name)}>
            <ControlledInput
              name={`estate.${name}`}
              control={methods.control}
              error={methods.formState.errors.estate?.[name] as FieldError}
              hideHelperTextGap
              {...field}
            />
          </InputLabelWrapper>
        ))}
      {missingImportantFields.length ? (
        <MissingRequiredFieldsError
          tKey="missingEstateRequiredFields"
          to={routes.estateTab(id).estateInfo}
        />
      ) : null}
    </Stack>
  );
};

export default EstateMissingDataForm;
