import { Navigate, RouteObject } from 'react-router-dom';
import { estateTabRoutes } from 'router/routes';
import EstateCompositionTab from 'pages/Estates/Details/tabs/Composition';
import EstateContactsTab from 'pages/Estates/Details/tabs/Contacts';
import EstateDocumentsTab from 'pages/Estates/Details/tabs/Documents';
import EstateDossiersTab from 'pages/Estates/Details/tabs/Dossiers';
import EstateInfoTab from 'pages/Estates/Details/tabs/EstateInfo';

export const estateTabs: RouteObject[] = [
  { path: estateTabRoutes.estateInfo, element: <EstateInfoTab /> },
  { path: estateTabRoutes.composition, element: <EstateCompositionTab /> },
  { path: estateTabRoutes.contacts, element: <EstateContactsTab /> },
  { path: estateTabRoutes.dossiers, element: <EstateDossiersTab /> },
  { path: estateTabRoutes.documents, element: <EstateDocumentsTab /> },
  { path: '*', element: <Navigate to={estateTabRoutes.estateInfo} /> },
];
