import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { WarningIcon } from 'icons';
import { DocumentsComponentProps } from 'templates/DocumentsTable/types';
import { DossierDocumentsContextInterface, DossierTableDocumentData } from '../types';

const DocumentTypeCell = ({
  document: { isMissing, documentType },
  scope,
}: DocumentsComponentProps<
  DossierTableDocumentData,
  DossierDocumentsContextInterface
>) => {
  const { t } = useTranslation('table');

  const content = t(`documents:${scope}.${documentType}`, documentType);

  return isMissing ? (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap={0.5}>
      {content}
      <IconTooltip title={t('documents.tooltips.uploadFilePlaceholder')}>
        <WarningIcon color="error" />
      </IconTooltip>
    </Box>
  ) : (
    content
  );
};

export default DocumentTypeCell;
