import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import WarningField from 'atoms/WarningField';
import { SearchInput } from 'components/inputs';
import { ControlledSearchInputProps } from './types';
import { getErrorMessage } from './utils';

export const ControlledSearchInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
  TOption extends object = object,
>(
  props: ControlledSearchInputProps<T, N, TOption>,
) => React.ReactElement = ({
  name,
  error,
  warning,
  helperText,
  warningKey,
  control,
  onChange: handleChange,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, onChange, ...field } }) => (
      <SearchInput
        error={!!error}
        warning={!!warning}
        fullWidthWarning={!!warningKey}
        helperText={
          getErrorMessage(error ?? warning) ||
          (warningKey && <WarningField tKey={warningKey} fullWidth />) ||
          helperText
        }
        inputRef={ref}
        onChange={(option, reason) => {
          onChange(option?.value ?? '');
          if (handleChange) {
            handleChange(option, reason);
          }
        }}
        {...inputProps}
        {...field}
      />
    )}
  />
);
