import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { estateCompositionFields } from 'formData/estate/details/fields';
import useComposition, { NewFieldInput } from 'hooks/useComposition';
import usePrompt from 'hooks/usePrompt';
import { DeleteIcon, EditIcon } from 'icons';
import { EditNewCompositionField } from 'modules/EstateForm/steps/composition/EditNewCompositionField';
import { InputLabelWrapper } from 'templates/InputLabelWrapper/InputLabelWrapper';
import getLng from 'utils/getLng';
import { EstateDetailsForm } from 'api/estate/types';
import { ControlledNumberInput } from 'components/controlledInputs';

export const CompositionEdition = () => {
  const { t } = useTranslation('enums');

  const {
    control,
    formState: { errors, isDirty },
  } = useFormContext<EstateDetailsForm>();

  usePrompt({ when: isDirty, id: 'estateComposition' });
  const { fields, removeItem, newFieldInputProps, editItem, editAdditionalFieldProps } =
    useComposition();

  return (
    <Stack p={2.5}>
      <Grid container rowSpacing={2} columnSpacing={4}>
        {estateCompositionFields.map(({ name, ...props }) => (
          <Grid item key={name} xs={12} sm={6} md={4} lg={6} xl={4}>
            <InputLabelWrapper label={t(`estateComposition.${name}`)}>
              <ControlledNumberInput
                control={control}
                name={`estateComposition.${name}`}
                error={errors.estateComposition?.[name]}
                hideHelperTextGap
                {...props}
              />
            </InputLabelWrapper>
          </Grid>
        ))}
      </Grid>
      <NewFieldInput {...newFieldInputProps} />
      <Grid container rowGap={2}>
        {fields.map(({ id, name }, index) => (
          <Grid item key={id} xs={12} md={6} width="100%">
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={1}
              pl={{ md: index % 2 === 0 ? 0 : 4 }}
            >
              <Typography flex={1} py={1}>
                {name[getLng()]}
              </Typography>
              <Box flex={1}>
                <ControlledNumberInput
                  control={control}
                  error={errors.estateComposition?.additionalItems?.[index]?.count}
                  name={`estateComposition.additionalItems.${index}.count`}
                  isInteger
                  hideHelperTextGap
                />
              </Box>
              <IconButton onClick={editItem(index)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={removeItem(index)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Grid>
        ))}
        {editAdditionalFieldProps?.editingIndex !== undefined && (
          <EditNewCompositionField {...editAdditionalFieldProps} />
        )}
      </Grid>
    </Stack>
  );
};
