import { useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  rentalConditionsOrder,
  rentalConditionsSchema,
} from 'formData/dossier/rentalConditions';
import useGetRegion from 'hooks/useGetRegion';
import PageCard from 'templates/PageCard';
import SectionRows from 'templates/SectionRows';
import { useUpdateEffect } from 'usehooks-ts';
import sortObject from 'utils/object/sortObject';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { resolver } from 'utils/yup';
import { updateRentalConditions } from 'api/dossier/requests';
import { RentalConditions } from 'api/dossier/types';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import RentalConditionsEdition from './RentalConditionsEdition';
import {
  getRentalConditionsDefaultValues,
  getRentalConditionsDisplayData,
} from './utils';

const RentalConditionsCard = ({
  dossierId,
  rentalConditions,
  dossierData: { estate, status },
  fetchRentalConditions,
  fetchRentalDossier,
}: DossierOutletContext) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const region = useGetRegion(estate.postCode, estate.city);

  const methods = useForm<RentalConditions>({
    resolver: resolver(rentalConditionsSchema),
    defaultValues: getRentalConditionsDefaultValues(rentalConditions, estate),
    context: { region },
  });

  const { handleSubmit, reset, formState } = methods;

  useUpdateEffect(() => {
    const defaultValues = getRentalConditionsDefaultValues(rentalConditions, estate);
    reset(defaultValues);
  }, [rentalConditions]);

  const toggleEditing = () => setEditing((prev) => !prev);

  const submitAction: SubmitHandler<RentalConditions> = async (data) => {
    setSaving(true);
    const { ok } = await updateRentalConditions(dossierId, data);
    if (ok) {
      await fetchRentalConditions();
      await fetchRentalDossier();
      toggleEditing();
    }
    setSaving(false);
  };

  const cancelEditing = () => {
    reset();
    toggleEditing();
  };

  const displayData = useMemo(
    () => (rentalConditions ? getRentalConditionsDisplayData(rentalConditions) : {}),
    [rentalConditions],
  );

  const editable = useMemo(() => shouldDossierFeatureBeActive(status, true), [status]);

  return (
    <PageCard
      blank={!rentalConditions && !editing}
      tKey="dossierDetails"
      headerProps={{
        titleProps: { translationValues: { context: 'rentalConditions' } },
        editionProps: {
          editDisabled: !editable,
          isEditing: editing,
          saveDisabled: !formState.isDirty,
          saving,
          onEdit: toggleEditing,
          onSave: handleSubmit(submitAction),
          onCancel: cancelEditing,
        },
      }}
    >
      {editing ? (
        <FormProvider {...methods}>
          <RentalConditionsEdition />
        </FormProvider>
      ) : (
        <SectionRows
          data={sortObject(displayData, rentalConditionsOrder)}
          translateValue={{
            rentType: `enums:rentType.${displayData.rentType}`,
            duration: `enums:rentalDossierDuration.${displayData.duration}`,
            numberOfMonthlyRent: `enums:dossierNumberOfMonthlyRent.${displayData.numberOfMonthlyRent}`,
            rentFrequency: `enums:rentFrequency.${displayData.rentFrequency}`,
          }}
        />
      )}
    </PageCard>
  );
};

export default RentalConditionsCard;
