import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import CustomLink from 'atoms/Link';
import { CheckIcon } from 'icons';
import { ThemedStyle } from 'types/themedStyle';
import { formatDate, isISODateString } from 'utils/date';
import isTruthyValue from 'utils/isTruthyValue';
import { normalizeString } from 'utils/string';
import { SectionRowProps } from './types';

const rowStyle: ThemedStyle = ({ palette }) => ({
  py: 1.5,
  px: 2.5,
  margin: 0,
  borderBottom: `1px solid ${palette.other.border}`,
  ':nth-of-type(even)': {
    backgroundColor: palette.other.evenRow,
  },
  ':last-child': {
    border: 'none',
  },
});

const SectionRow = ({
  title,
  value,
  link,
  translationPrefix,
  translateValue,
  translateOptions,
  titleGrow = 1,
  isHeader,
}: SectionRowProps) => {
  const { t } = useTranslation('formFields');

  const renderContent = () => {
    const formattedValue = isISODateString(value) ? formatDate(value) : String(value);

    const content = translateValue
      ? t(translateValue, { defaultValue: formattedValue ?? '' })
      : formattedValue;

    if (link) {
      return link.startsWith('mailto:') ? (
        <a href={link}>{content}</a>
      ) : (
        <CustomLink to={link}>{content}</CustomLink>
      );
    }

    return content;
  };

  return (
    <Stack key={title} sx={rowStyle} gap={1}>
      <Stack
        direction="row"
        minWidth={200}
        gap={2}
        data-testid={`row-container-${title}`}
        alignItems="center"
      >
        <Typography
          flex={titleGrow}
          data-testid={`row-title-${title}`}
          color={isHeader ? 'grey.500' : 'primary.main'}
          fontWeight={isHeader ? 800 : 500}
        >
          {t(translationPrefix ? `${translationPrefix}.${title}` : title, {
            defaultValue: normalizeString(title),
            ...translateOptions,
          })}
        </Typography>
        {isTruthyValue(value) && !Array.isArray(value) && (
          <Stack flex={1} data-testid={`row-content-${title}`}>
            {typeof value === 'boolean' ? (
              <CheckIcon color="primary" />
            ) : (
              <Typography
                fontWeight={600}
                sx={({ mixins }) => ({ wordBreak: 'break-word', a: mixins.link })}
              >
                {renderContent()}
              </Typography>
            )}
          </Stack>
        )}
        {isTruthyValue(value) && Array.isArray(value) && (
          <Stack flex={1} spacing={1} data-testid={`row-content-${title}`}>
            {value.map((v, index) => (
              <Typography
                key={`${v}-${index}`}
                fontWeight={600}
                sx={{ wordBreak: 'break-word' }}
              >
                {translateValue ? t(translateValue, v) : v}
              </Typography>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default SectionRow;
