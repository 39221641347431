import { TKey } from 'i18next';
import yup from 'utils/yup';
import { productPartyListSchema, productPartyListV2Schema } from '../schema';
import { getAbolishmentPersonSchema } from './utils';

export const orderRentalDepositSchema = yup.object({
  tenants: productPartyListSchema,
  landlords: productPartyListSchema,
  multipleTenants: yup.boolean().default(false),
  isFireInsurance: yup.boolean().default(false),
  isLegalAssistance: yup.boolean().default(false),
});

export const orderRentalDepositSchemaV2 = yup.object({
  isFireInsurance: yup.boolean().default(false),
  isLegalAssistance: yup.boolean().default(false),
  multipleTenants: yup.boolean().default(false),
});

export const rentalDepositChoosePartiesSchema = yup.object({
  tenants: productPartyListV2Schema,
  landlords: productPartyListV2Schema,
  multipleTenants: yup.boolean().default(false),
});

export const releaseRentalDepositSchema = yup.object({
  abolishmentReason: yup
    .string<TKey<'modals', 'prepareReleaseModal.sections.abolishmentReason.fields'>>()
    .required(),
  depositDistribution: yup
    .string<TKey<'modals', 'prepareReleaseModal.sections.depositDistribution.fields'>>()
    .required(),
  emailConsent: yup.boolean(),
  tenant: getAbolishmentPersonSchema('tenant'),
  landlord: getAbolishmentPersonSchema('landlord'),
  broker: getAbolishmentPersonSchema('broker'),
  deceasedPersonReference: yup
    .string()
    .nullable()
    .when('abolishmentReason', {
      is: (
        reason: TKey<'modals', 'prepareReleaseModal.sections.abolishmentReason.fields'>,
      ) => reason === 'oneOfTenantsDied',
      then: (schema) => schema.required(),
    }),
});
