import { Box } from '@mui/material';
import { PersonInDetails } from '../types';
import Action from './Action';
import Person from './Person';

export const Details = (person: PersonInDetails) => {
  return (
    <Box key={person.personId} sx={({ mixins }) => mixins.cardItem}>
      <Person {...person} />
      {person.action && <Action {...person.action} />}
    </Box>
  );
};
