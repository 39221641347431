import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@mui/material';
import Button from 'atoms/Button';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { ProductContext } from 'contexts/product/productContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { DeleteIcon } from 'icons';
import routes from 'router/routes';
import { apiUrl } from 'api/const';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';

export const ProductDeletionButton = () => {
  const { t } = useTranslation();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);
  const { productId, productName } = useContext(ProductContext);
  const { dossierId } = useOutletContext<DossierOutletContext>();

  const navigate = useCustomNavigate();

  const onDelete = () => {
    setUpAndOpenConfirmationModal({
      translationKey: 'product',
      translationValues: { name: productName },
      url: apiUrl.product(dossierId, productId),
      onSuccess: () => {
        navigate(routes.rentalDossierTab(dossierId).products);
      },
    });
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button size="small" role="unsafe" onClick={onDelete} startIcon={<DeleteIcon />}>
        {t('delete')}
      </Button>
    </Box>
  );
};
