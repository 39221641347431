import { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import i18n from 'i18n';
import { DossierFormInterface } from 'modules/DossierForm/types';
import { RelationOption } from 'modules/Relation/types';
import { estateToRelationOption, personToRelationOption } from 'modules/Relation/utils';
import { RentalConditions } from 'api/dossier/types';
import { FetchedEstateListItem } from 'api/estate/types';
import { FetchedPersonData } from 'api/person/types';
import { DossierFormContextInterface } from './types';
import useDossierForm from './useDossierForm';

export const DossierFormContext = createContext<DossierFormContextInterface>({
  estateOptions: [],
  landlordOptions: [],
  tenantOptions: [],
  updateEstatesOptions: () => {},
  updateLandlordOptions: () => {},
  updateTenantOptions: () => {},
  saving: false,
  errors: null,
  updateDossierForm: () => {},
  updateErrors: () => {},
  updateRentalConditionsForm: () => {},
  saveForm: async () => {},
  dossierMethods: {} as UseFormReturn<DossierFormInterface>,
  rentalConditionsMethods: {} as UseFormReturn<RentalConditions>,
});

export const DossierFormProvider = ({ children }: PropsWithChildren) => {
  const [estateOptions, setEstateOptions] = useState<
    RelationOption<FetchedEstateListItem>[]
  >([]);
  const [landlordOptions, setLandlordOptions] = useState<
    RelationOption<FetchedPersonData>[]
  >([]);
  const [tenantOptions, setTenantOptions] = useState<RelationOption<FetchedPersonData>[]>(
    [],
  );

  const updateEstatesOptions = useCallback((estates: FetchedEstateListItem[]) => {
    setEstateOptions(
      estates.map((data) => ({
        ...estateToRelationOption(data),
        subHeader: i18n.t('searchGroup.contextResults'),
      })),
    );
  }, []);

  const updatePersonOptions = useCallback(
    (setter: typeof setLandlordOptions | typeof setTenantOptions) =>
      (persons: FetchedPersonData[]) => {
        setter(
          persons.map((data) => ({
            ...personToRelationOption(data),
            subHeader: i18n.t('searchGroup.contextResults'),
          })),
        );
      },
    [],
  );

  const formProps = useDossierForm();

  return (
    <DossierFormContext.Provider
      value={{
        estateOptions,
        updateEstatesOptions,
        landlordOptions,
        updateLandlordOptions: updatePersonOptions(setLandlordOptions),
        tenantOptions,
        updateTenantOptions: updatePersonOptions(setTenantOptions),
        ...formProps,
      }}
    >
      {children}
    </DossierFormContext.Provider>
  );
};
