import { useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { RemoveIcon } from 'icons';
import RelationDetails from './RelationDetails';
import { RelationData } from './types';

interface Props<T extends object> {
  handleRemove: (relation: RelationData<T>) => void;
  relation: RelationData<T>;
  removable?: boolean;
  showIcon?: boolean;
  withLink?: boolean;
}

const RelationPreviewItem = <T extends object>({
  handleRemove,
  relation,
  removable = true,
  showIcon,
  withLink,
}: Props<T>) => {
  const [removing, setRemoving] = useState(false);

  const onRemove = (relation: RelationData<T>) => async () => {
    setRemoving(true);
    await handleRemove(relation);
    setRemoving(false);
  };

  return (
    <Stack
      direction="row"
      minWidth={200}
      gap={2}
      py={1}
      alignItems="center"
      justifyContent="space-between"
      data-testid="relation"
    >
      <RelationDetails
        relation={relation}
        isPreview
        showIcon={showIcon}
        withLink={withLink}
      />
      {removable && (
        <IconButton onClick={onRemove(relation)} disabled={removing}>
          <RemoveIcon />
        </IconButton>
      )}
    </Stack>
  );
};
export default RelationPreviewItem;
