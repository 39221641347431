import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DossierPermissions } from 'consts/permissions';
import hasPermission from 'utils/hasPermission';
import {
  getCountries,
  getDocumentsConfig,
  getEstateDestinationsConfig,
  getNationalities,
  getUeCountries,
} from 'api/config/requests';
import { getRentalDossierChecklist } from 'api/dossier/requests';
import { FetchedDossierChecklistItem } from 'api/dossier/types';
import { ConfigContextValues } from './types';
import { defaultValue, mapConfiguration } from './utils';

const ConfigContext = createContext<ConfigContextValues>(defaultValue);

const ConfigProvider = ({ children }: PropsWithChildren) => {
  const [destinations, setDestinations] = useState(defaultValue.destinationConfig);
  const [subTypes, setSubtypes] = useState(defaultValue.subTypesConfig);

  const [countries, setCountries] = useState(defaultValue.countries);
  const [ueCountries, setUeCountries] = useState(defaultValue.ueCountries);
  const [nationalities, setNationalities] = useState(defaultValue.nationalities);

  const [documentsConfig, setDocumentsConfig] = useState(defaultValue.documentsConfig);

  const { i18n } = useTranslation();

  const fetchCountries = useCallback(async () => {
    const { ok, response } = await getCountries(i18n.language);
    if (ok) {
      setCountries(response);
    }
  }, []);

  const fetchUeCountries = useCallback(async () => {
    const { ok, response } = await getUeCountries(i18n.language);
    if (ok) {
      setUeCountries(response);
    }
  }, []);

  useEffect(() => {
    fetchCountries();
    fetchUeCountries();
  }, [i18n.language]);

  const fetchNationalities = useCallback(async () => {
    const { ok, response } = await getNationalities();
    if (ok) {
      setNationalities(response);
    }
  }, []);

  const [loadingChecklist, setLoadingChecklist] = useState(false);
  const [rentalDossierChecklist, setRentalDossierChecklist] = useState<
    FetchedDossierChecklistItem[]
  >([]);

  const fetchChecklist = useCallback(async () => {
    if (hasPermission(DossierPermissions.Write)) {
      setLoadingChecklist(true);
      const { ok, response } = await getRentalDossierChecklist();
      if (ok) {
        setRentalDossierChecklist(response);
      }
      setLoadingChecklist(false);
    }
  }, []);

  const fetchEstateTypes = useCallback(async () => {
    const { ok, response } = await getEstateDestinationsConfig();
    if (ok) {
      const { destinationConfig, subTypesConfig } = mapConfiguration(response);
      setDestinations(destinationConfig);
      setSubtypes(subTypesConfig);
    }
  }, []);

  const fetchDocumentsConfig = useCallback(async () => {
    const { ok, response } = await getDocumentsConfig();
    if (ok) {
      setDocumentsConfig({
        dossier: response.dossierDocumentsConfig,
        estate: response.estateDocumentsConfig,
        legalPerson: response.legalPersonDocumentsConfig,
        naturalPerson: response.naturalPersonDocumentsConfig,
      });
    }
  }, []);

  useEffect(() => {
    fetchEstateTypes();
    fetchNationalities();
    fetchChecklist();
    fetchDocumentsConfig();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        destinationConfig: destinations,
        subTypesConfig: subTypes,
        nationalities,
        countries,
        ueCountries,
        loadingChecklist,
        rentalDossierChecklist,
        documentsConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigProvider };
