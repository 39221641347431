import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TranslationField from 'modules/TranslationField';
import { TextInput } from 'components/inputs';
import UploadPhoto from 'components/UploadPhoto';
import useEstateDescription from './useEstateEdition';

type Props = Pick<
  ReturnType<typeof useEstateDescription>,
  'descriptions' | 'onDescriptionChange' | 'uploadPhotoProps'
>;

const EstateDescriptionEdition = ({
  descriptions,
  onDescriptionChange,
  uploadPhotoProps,
}: Props) => {
  const { t } = useTranslation('formFields');

  return (
    <>
      <TranslationField
        render={(lng) => (
          <Box
            p={2}
            pb={0}
            mb={2}
            sx={({ mixins }) =>
              mixins.scrollableCardContent(
                240,
                `calc(100vh - (${mixins.toolbar.minHeight}px + 165px))`, // height of translation tabs added
              )
            }
          >
            <TextInput
              name={`descriptions.${lng}`}
              onChange={onDescriptionChange(lng)}
              value={descriptions[lng]}
              placeholder={t('description')}
              hideHelperTextGap
              multiline
              minRows={7}
            />
          </Box>
        )}
      />
      <Box px={2} pb={2}>
        <UploadPhoto {...uploadPhotoProps} />
      </Box>
    </>
  );
};

export default EstateDescriptionEdition;
