import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IconButton, TableCell } from '@mui/material';
import { DocumentData } from 'api/documents/types';
import { actionCellStyles } from '../styles';

interface Props {
  expanded: boolean;
  toggleExpand: () => void;
  document: DocumentData;
}

const DocumentExpander = ({ expanded, toggleExpand, document }: Props) => (
  <TableCell sx={actionCellStyles}>
    <IconButton
      onClick={toggleExpand}
      size="small"
      disabled={!document.files.length || document.documentClassification !== 'Document'}
    >
      {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
    </IconButton>
  </TableCell>
);

export default DocumentExpander;
