import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRevalidator } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import CustomLink from 'atoms/Link';
import { LoadersContext } from 'contexts/loaders/loadersContext';
import { useUpdateEffect } from 'usehooks-ts';
import { BreadcrumbsInterface } from './types';
import { getBreadcrumbs } from './utils';

export const Breadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsInterface[]>([]);
  const { loaderData } = useContext(LoadersContext);

  const { i18n } = useTranslation();
  const { revalidate } = useRevalidator();

  useUpdateEffect(() => {
    revalidate();
  }, [i18n.language]);

  const fetchBreadcrumbs = useCallback(() => {
    const breadcrumbs = getBreadcrumbs(loaderData);
    setBreadcrumbs(breadcrumbs);
  }, [loaderData?.id, i18n.language]);

  useEffect(() => {
    fetchBreadcrumbs();
  }, [fetchBreadcrumbs]);

  return (
    <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="medium" color="secondary" />}>
      {breadcrumbs.map(({ label, to }) => (
        <Box key={label} fontSize="1rem">
          {to ? (
            <Link
              component={CustomLink}
              to={to}
              fontSize="inherit"
              sx={({ mixins }) => mixins.link}
            >
              {label}
            </Link>
          ) : (
            <Typography color="primary.main" fontWeight="bold" fontSize="inherit">
              {label}
            </Typography>
          )}
        </Box>
      ))}
    </MuiBreadcrumbs>
  );
};
