import { PropsWithChildren } from 'react';
import { Divider, Grid, GridProps } from '@mui/material';
import TextDivider from 'atoms/TextDivider';
import { WithRequired } from 'types/withRequired';

export const FormContainer = ({
  children,
  rowSpacing = 1,
  ...props
}: WithRequired<GridProps, 'maxWidth'>) => (
  <Grid container width="100%" rowSpacing={rowSpacing} {...props}>
    {children}
  </Grid>
);

export const FormSection = ({
  children,
  label,
}: PropsWithChildren & { label: string }) => (
  <Grid item xs={12}>
    <TextDivider label={label} />
    <Grid container spacing={1} mt={1.5}>
      {children}
    </Grid>
  </Grid>
);

export const FormSide = ({ children }: PropsWithChildren) => (
  <Grid container item xs={12} md={5.5} rowSpacing={1}>
    {children}
  </Grid>
);

export const FormVerticalDivider = () => (
  <Grid item xs={0} md={1} justifyContent="center" alignItems="center" display="flex">
    <Divider orientation="vertical" />
  </Grid>
);
