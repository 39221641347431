import { SyntheticEvent, useState } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { ErrorIcon } from 'icons';
import ControlledCheckbox from 'components/controlledInputs/ControlledCheckbox';
import { AccordionWithCheckboxProps } from './types';

export const AccordionWithCheckbox = <T extends FieldValues>({
  control,
  label,
  name,
  onChange,
  children,
  error = {},
  initiallyExpanded = false,
}: AccordionWithCheckboxProps<T>) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const checked = useWatch({ control, name });

  const toggleAccordion = (event: SyntheticEvent<Element, Event>) => {
    if (
      (event.target as HTMLInputElement).type === 'checkbox' &&
      ((expanded && !checked) || (!expanded && checked))
    ) {
      return;
    }
    setExpanded((prev) => !prev);
  };

  return (
    <Accordion
      disableGutters
      variant="outlined"
      expanded={expanded}
      onChange={toggleAccordion}
    >
      <AccordionSummary>
        <ControlledCheckbox
          control={control}
          name={name}
          label={
            <Box display="flex" alignItems="center" gap={1}>
              {label}
              {!!Object.keys(error).length && <ErrorIcon color="error" />}
            </Box>
          }
          labelPlacement="start"
          onChange={onChange}
          sx={{
            justifyContent: 'space-between',
            pointerEvents: 'none',
            '.MuiCheckbox-root': { pointerEvents: 'auto' },
          }}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
