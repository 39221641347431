import { TKey } from 'i18next';
import { ProductName } from 'api/dossier/products/types';
import {
  EstateInDossier,
  FetchedDossierChecklistItem,
  RentalConditions,
} from 'api/dossier/types';

export const getFireInsuranceMessage = (
  { rentType, rentalPrice }: RentalConditions,
  { type, destination }: EstateInDossier,
  habitableSurface?: number | null,
): TKey<'common', 'helperText'> | null => {
  if (type === 'GarageParking') {
    return null;
  }

  if (rentType !== 'Habitation') {
    return 'fireInsuranceNotAvailable.rentType';
  }

  if (destination !== 'Living') {
    return 'fireInsuranceNotAvailable.estateDestinationNotLiving';
  }

  const isRentalPriceExceeded = type === 'House' && (rentalPrice?.value ?? 0) > 1250;

  if (isRentalPriceExceeded && !habitableSurface) {
    return 'fireInsuranceNotAvailable.renTypeAndPrice';
  }

  if (isRentalPriceExceeded && (habitableSurface ?? 0) > 300) {
    return 'fireInsuranceNotAvailable.rentTypeAndPriceAndSurface';
  }

  return null;
};

export const isProductAvailable = (
  productName: ProductName,
  rentalDossierChecklist: FetchedDossierChecklistItem[],
) => rentalDossierChecklist.findIndex(({ name }) => name === productName) > -1;
