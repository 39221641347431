import { useCallback, useState } from 'react';
import { EstatePermissions } from 'consts/permissions';
import { OwnersDetails } from 'modules/DetailsCards';
import { AddPersonModal } from 'modules/ModalForm/AddPersonModal';
import RelationCard from 'modules/Relation/RelationCard';
import {
  fetchPersonRelationOptions,
  selectPersonWithPartner,
} from 'modules/Relation/utils';
import hasPermission from 'utils/hasPermission';
import { getEstateOwners } from 'api/relations/requests';
import { FetchedEstateOwner } from 'api/relations/types';
import { MAX_OWNERS } from './static-data';
import { addNewOwners } from './utils';

interface Props {
  estateId: string;
}

export const OwnerCard = ({ estateId }: Props) => {
  const [ownerships, setOwnerships] = useState<FetchedEstateOwner[]>([]);
  const [loading, setLoading] = useState(true);

  const getOwners = useCallback(async () => {
    setLoading(true);
    const { ok, response } = await getEstateOwners(estateId);
    if (ok) {
      setOwnerships(response);
    }
    setLoading(false);
  }, [estateId]);

  return (
    <RelationCard
      translationKey="owner"
      maxRelations={MAX_OWNERS}
      saveRelation={addNewOwners(estateId)}
      fetchOptions={fetchPersonRelationOptions}
      fetchRelations={getOwners}
      relations={ownerships.map(({ owner }) => owner.id)}
      AddNewOptionModal={AddPersonModal}
      editable={hasPermission(EstatePermissions.Write)}
      loading={loading}
      onRelationSelect={selectPersonWithPartner}
      showListIcons
    >
      <OwnersDetails ownerships={ownerships} updateList={getOwners} />
    </RelationCard>
  );
};
