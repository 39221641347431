import { Box, Typography } from '@mui/material';
import { formatDate } from 'utils/date';
import getDocumentDateLabel from 'utils/getDocumentDateLabel';
import { DocumentData } from 'api/documents/types';
import { DocumentsComponentProps, DocumentsContextInterface } from '../types';

const DocumentDateCell = <
  TData extends DocumentData,
  TContext extends DocumentsContextInterface<TData>,
>({
  document,
  documentConfigData,
}: DocumentsComponentProps<TData, TContext>) => {
  if (!document.date) {
    return null;
  }

  const dateType = getDocumentDateLabel(
    document.documentType,
    documentConfigData?.date?.type,
  );

  return (
    <Box>
      <Typography>{formatDate(document.date)}</Typography>
      <Typography
        variant="subtitle2"
        sx={({ palette }) => ({ color: palette.grey[500], fontSize: 12 })}
      >
        {dateType}
      </Typography>
    </Box>
  );
};

export default DocumentDateCell;
