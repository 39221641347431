import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import PersonChip from 'atoms/PersonChip';
import { AssignedDossierPartiesProps } from './types';

const AssignedDossierParties = ({
  role,
  landlords,
  tenants,
}: AssignedDossierPartiesProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'assignedDossierCard' });

  const parties = useMemo(() => (role === 'Tenant' ? landlords : tenants), [role]);
  const partiesToDisplay = useMemo(() => parties.slice(0, 2), [parties]);
  const partyName = useMemo(
    () => (role === 'Tenant' ? t('landlords') : t('tenants')),
    [role],
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={1}
      alignItems="center"
      height="100%"
    >
      <Typography color="grey.500">{partyName}</Typography>
      <Stack gap={1}>
        <Box display="flex" flexWrap="wrap" gap={1} justifyContent="flex-end">
          {partiesToDisplay.map((person) => (
            <PersonChip key={person.personId} {...person} />
          ))}
        </Box>
        {parties.length > 2 && (
          <Typography variant="caption" color="grey.500" textAlign="end">
            {t('morePartiesLabel', { partiesCount: parties.length, partyName })}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default AssignedDossierParties;
