import { ThemedStyle } from 'types/themedStyle';

export const statusChipStyle: ThemedStyle = ({ palette }) => ({
  color: palette.grey[500],
  fontWeight: 600,
  bgcolor: palette.other.lightBlue,
  border: `1px solid ${palette.grey[400]}`,
  borderRadius: 2,
  py: 1,
  pr: 0.5,
  '.MuiChip-deleteIcon': {
    fontSize: 16,
    color: palette.grey[500],
  },
});
