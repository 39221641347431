import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BelgiumCountry, BrusselCity } from 'formData/common/address/static-data';
import useCountryOptions from 'hooks/useCountryOptions';
import useFormMethods from 'hooks/useFormMethods';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import getLng from 'utils/getLng';
import getObjectEntries from 'utils/object/getObjectEntries';
import getObjectKeys from 'utils/object/getObjectKeys';
import { AddressFormInterface, TranslationFieldInterface } from 'api/common/types';
import { getPostCodesWithCity, getStreetTranslations } from 'api/config/requests';
import {
  Country,
  PostCodesWithCities,
  PostCodesWithCitiesTranslations,
} from 'api/config/types';
import {
  HandleSearchInputChange,
  SelectInputProps,
  SelectOption,
} from 'components/inputs/types';
import { HandleChange, HandleClose, UseAddressData } from './types';
import {
  createCityOption,
  createCountryOption,
  createPostCodeOption,
  getInitValues,
  translateCity,
} from './utils';

const defaultParams: PostCodesWithCities = {
  postCode: '',
  city: '',
};

export const useAddressData: UseAddressData = ({
  onCountryChange,
  googleParams,
  prefix,
}) => {
  const { getValues, setValue, setError, defaultValues, trigger, formState } =
    useFormMethods<{
      address: AddressFormInterface;
    }>(prefix);

  const { initCityValue, initIsBelgium, initParams, initPostCodeValue } = getInitValues(
    defaultValues?.address,
  );

  const [isFetchingDefaultValues, setIsFetchingDefaultValues] = useState(
    !defaultValues?.address,
  );
  const [isBelgiumSelected, setIsBelgiumSelected] = useState(
    isFetchingDefaultValues ? true : initIsBelgium,
  );

  useUpdateEffect(() => {
    if (isFetchingDefaultValues) {
      setIsBelgiumSelected(initIsBelgium);
      setCurrentPostCode(initPostCodeValue);
      setCurrentCity(initCityValue);
      setParams(initParams);
      setInputs(initParams);
      setIsFetchingDefaultValues(false);
    }
  }, [defaultValues?.address]);

  const [currentPostCode, setCurrentPostCode] = useState(initPostCodeValue);
  const [currentCity, setCurrentCity] = useState(initCityValue);

  const [postCodes, setPostCodes] = useState<SelectOption[]>([]);
  const [cities, setCities] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);

  const [streetParam, setStreetParam] = useState('');
  const [streetInput, setStreetInput] = useState('');
  const debouncedStreetInput = useDebounce(streetInput, 500);

  const [params, setParams] = useState(initParams);
  const [inputs, setInputs] = useState(initParams);
  const debouncedInputs = useDebounce<PostCodesWithCities>(inputs, 500);

  const [initPostCodesWithCities, setInitPostCodesWithCities] =
    useState<PostCodesWithCitiesTranslations[]>();

  const { i18n, t } = useTranslation();

  const lng = useMemo(() => getLng(i18n), [i18n.language]);
  const [countriesOptions, groupedCountriesOptions] =
    useCountryOptions(createCountryOption);

  useEffect(() => {
    setParams(debouncedInputs);
  }, [debouncedInputs.city, debouncedInputs.postCode]);

  useEffect(() => {
    setStreetParam(debouncedStreetInput);
  }, [debouncedStreetInput]);

  const updateParams = (params: Partial<PostCodesWithCities>) => {
    setParams((prev) => ({ ...prev, ...params }));
    setInputs((prev) => ({ ...prev, ...params }));
  };

  const fetchStreetTranslations = useCallback(async () => {
    const translationTo = lng === 'en' || lng === 'nl' ? 'fr' : 'nl';
    const { ok, response } = await getStreetTranslations({
      name: getValues('address.street'),
      translationTo,
    });
    if (ok && response.name && !getValues('address.streetTranslation')) {
      setValue('address.streetTranslation', response.name);
    }
  }, [lng]);

  useEffect(() => {
    if (
      streetParam &&
      getValues('address.street') &&
      !getValues('address.streetTranslation')
    ) {
      fetchStreetTranslations();
    }
  }, [streetParam]);

  const fetchPostCodesWithCities = useCallback(
    async ({ postCode, city }: PostCodesWithCities) => {
      setLoading(true);
      const { ok, response } = await getPostCodesWithCity({
        postCode,
        city: translateCity(city),
        lng,
      });
      setLoading(false);
      if (ok && response) {
        const data = response.map(({ city, postCode }) => ({
          city: JSON.stringify(city),
          postCode,
        }));
        setPostCodes(data.map(createPostCodeOption));
        setCities(data.map(createCityOption));
        return response;
      }
      setPostCodes([]);
      setCities([]);
    },
    [lng],
  );

  const validateInitPostCodeAndCity = async () => {
    const { city, postCode } = defaultValues?.address ?? {};

    if (
      !isFetchingDefaultValues &&
      isBelgiumSelected &&
      city &&
      city === getValues('address.city') &&
      postCode &&
      postCode === getValues('address.postCode')
    ) {
      let options = initPostCodesWithCities;

      if (!options) {
        options = await fetchPostCodesWithCities({
          postCode,
          city: translateCity(city),
        });
        setInitPostCodesWithCities(options);
      }

      const isValid = options?.some(
        (option) => JSON.stringify(option.city) === city && option.postCode === postCode,
      );

      if (!isValid) {
        setError('address.postCode', { message: 'address.postCodeDoesNotMatchCity' });
        setError('address.city', { message: 'address.cityDoesNotMatchPostcode' });
      }
    }
  };

  useEffect(() => {
    validateInitPostCodeAndCity();
  }, [formState.submitCount, isFetchingDefaultValues]);

  useEffect(() => {
    if (isBelgiumSelected && !isFetchingDefaultValues) {
      fetchPostCodesWithCities(params);
    }
  }, [isBelgiumSelected, params.city, params.postCode]);

  const updateFieldsWithGoogleParams = useCallback(async () => {
    if (!googleParams) {
      return;
    }

    const { city = '', postCode = '', ...fields } = googleParams;
    const isBelgium = getObjectKeys(BelgiumCountry.name).some(
      (lng) => BelgiumCountry.name[lng] === fields.country,
    );
    const isBrussel = getObjectKeys(BrusselCity).some((lng) => BrusselCity[lng] === city);

    setIsBelgiumSelected(isBelgium);
    if (googleParams.street !== getValues('address.street')) {
      setValue('address.streetTranslation', undefined);
    }

    if (fields.street && isBelgium && isBrussel) {
      setStreetInput(fields.street);
      setStreetParam(fields.street);
      setValue('address.street', fields.street);
    }
    const countryOption = countriesOptions.find((option) => {
      const translationFields = JSON.parse(option.value);
      return Object.keys(translationFields).some(
        (lng) => translationFields[lng] === fields.country,
      );
    });

    let matchedCity: string | TranslationFieldInterface = { en: '', nl: '', fr: '' };
    let matchedPostCode = postCode;
    if (isBelgium && postCode) {
      const options = await fetchPostCodesWithCities({ postCode, city: '' });
      const option = options?.find((option) => option.city[lng] === city);

      if (city && option) {
        matchedCity = option.city;
      } else if (options?.length === 1) {
        matchedCity = options[0].city;
      }
      matchedCity = JSON.stringify(matchedCity);
      setCurrentCity(createCityOption({ postCode, city: matchedCity }));
      setCurrentPostCode(createPostCodeOption({ postCode, city: matchedCity }));
    } else if (isBelgium && city) {
      const options = await fetchPostCodesWithCities({ postCode: '', city });
      const matched = options?.find((option) => option.city[lng] === city);
      if (matched) {
        matchedCity = JSON.stringify(matched.city);
        matchedPostCode = matched.postCode;
        setCurrentCity(
          createCityOption({ postCode: matchedPostCode, city: matchedCity }),
        );
        setCurrentPostCode(
          createPostCodeOption({ postCode: matchedPostCode, city: matchedCity }),
        );
      } else {
        matchedCity = JSON.stringify(matchedCity);
      }
    } else {
      matchedCity = city;
    }

    getObjectEntries({
      ...fields,
      country: (countryOption?.value as string) || '',
      city: matchedCity,
      postCode: matchedPostCode !== '' ? matchedPostCode : getValues('address.postCode'),
      streetTranslation: getValues('address.streetTranslation'),
    }).forEach(([key, value]) => {
      setValue(`address.${key}`, value, { shouldDirty: true, shouldValidate: true });
    });
  }, [googleParams, lng]);

  useEffect(() => {
    updateFieldsWithGoogleParams();
  }, [updateFieldsWithGoogleParams]);

  const handleCountryChange: SelectInputProps['onChange'] = (value) => {
    const country = countriesOptions.find((country) => country.value === value)
      ?.country as Country;
    if (onCountryChange) {
      onCountryChange(country as Country);
    }
    setIsBelgiumSelected(country.name.en === 'Belgium');
    setCurrentPostCode(null);
    setCurrentCity(null);
    setValue(`address.postCode`, '');
    setValue(`address.city`, '');
    setParams(defaultParams);
  };

  const onCityChange: HandleChange = (option) => {
    if (option?.postCode && option?.city) {
      const { postCode, city } = option;
      setValue(`address.postCode`, postCode, { shouldDirty: true });
      trigger(['address.city', 'address.postCode']);
      updateParams({ postCode, city: translateCity(city, 'en') });
      setCurrentPostCode(createPostCodeOption({ postCode, city }));
      setCurrentCity(createCityOption({ postCode, city }));
    }
  };

  const onPostCodeChange: HandleChange = (option) => {
    if (option?.postCode && option?.city) {
      const { postCode, city } = option;
      setValue(`address.city`, city, { shouldDirty: true });
      trigger(['address.city', 'address.postCode']);
      updateParams({ postCode, city: translateCity(city, 'en') });
      setCurrentCity(createCityOption({ postCode, city }));
    }
  };

  const onStreetChange = (street: string) => {
    setStreetInput(street);
    setValue('address.street', street, { shouldDirty: true });
  };

  const onInputChange =
    (key: keyof PostCodesWithCities): HandleSearchInputChange =>
    (input, reason) => {
      if (reason === 'input') {
        if (input.length > 1) {
          setInputs({ ...defaultParams, [key]: input });
        } else {
          setInputs(defaultParams);
        }
      } else if (reason === 'clear') {
        updateParams({ [key]: '' });
      }
    };

  const onPostCodeClose: HandleClose = (option, reason) => {
    if (reason === 'blur') {
      if (option && params.postCode !== option.value) {
        const city = translateCity(option.city ?? '', 'en');
        updateParams({ city, postCode: option.value });
      } else if (!option && params.postCode) {
        updateParams({ postCode: '' });
      }
    }
  };

  const onCityClose: HandleClose = (option, reason) => {
    if (reason === 'blur') {
      if (option) {
        const city = translateCity(option.value, 'en');
        if (params.city !== city) {
          updateParams({ postCode: option.postCode ?? '', city });
        }
      } else if (!option && params.city) {
        updateParams({ city: '' });
      }
    }
  };

  return {
    isBelgiumSelected,
    cityField: {
      keyToRender: 'translatedCity',
      options: cities,
      currentOption: currentCity,
      onChange: onCityChange,
      onInputChange: onInputChange('city'),
      onClose: onCityClose,
      placeholder: t('selectInput.placeholder.city'),
      loading,
    },
    postCodeField: {
      keyToRender: 'postCode',
      options: postCodes,
      currentOption: currentPostCode,
      onChange: onPostCodeChange,
      onInputChange: onInputChange('postCode'),
      onClose: onPostCodeClose,
      placeholder: t('selectInput.placeholder.postCode'),
      loading,
    },
    countryField: {
      onSelectChange: handleCountryChange,
      options: countriesOptions,
      groupedOptions: groupedCountriesOptions,
    },
    streetField: {
      onInputChange: (street) => onStreetChange(street as string),
    },
  };
};
