import { EstatePermissions, PersonPermissions } from 'consts/permissions';
import hasPermission from 'utils/hasPermission';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import transformName from 'utils/transformName';
import { apiUrl } from 'api/const';
import { updateRentalDossier } from 'api/dossier/requests';
import { PersonInDossier } from 'api/dossier/types';
import { FetchedEstateOwner, FetchedOwnedEstates } from 'api/relations/types';
import {
  ActionProps,
  EstateActionProps,
  OwnedEstate,
  Owner,
  OwnerActionProps,
  PersonActionProps,
  PersonInDetails,
} from './types';

const getPersonRepresentative = (
  persons: PersonInDossier[],
  representativeId?: string | null,
) => persons.find(({ personId }) => personId === representativeId);

const getPersonAction = (
  id: string,
  { setUpAndOpenConfirmationModal, type, updateList, data }: PersonActionProps,
): ActionProps => ({
  name: 'delete',
  onClick: () => {
    if (data) {
      const { dossierData, dossierId } = data;
      const filtered = dossierData[type].filter(({ personId }) => personId !== id);
      const personToDelete = dossierData[type].find(({ personId }) => personId === id);
      setUpAndOpenConfirmationModal({
        onSubmit: async () =>
          await updateRentalDossier(dossierId, {
            ...dossierData,
            [type]: filtered,
          }),
        translationKey: 'relation',
        onSuccess: updateList,
        translationValues: {
          context: type,
          personName: personToDelete ? transformName(personToDelete) : undefined,
          relationType: type,
        },
      });
    }
  },
});

const getOwnerAction = (
  ownershipId: string,
  owner: Owner,
  { setUpAndOpenConfirmationModal, updateList }: OwnerActionProps,
) => ({
  name: 'delete',
  hidden: !hasPermission(EstatePermissions.Write),
  onClick: () =>
    setUpAndOpenConfirmationModal({
      url: apiUrl.deleteOwnership(ownershipId),
      translationKey: 'ownership',
      onSuccess: updateList,
      translationValues: { personName: transformName(owner), relationType: 'owner' },
    }),
});

const getEstateAction = (
  ownershipId: string,
  estate: OwnedEstate['estate'],
  { setUpAndOpenConfirmationModal, updateList }: EstateActionProps,
) => ({
  name: 'delete',
  disabled: !hasPermission(PersonPermissions.Write),
  onClick: () =>
    setUpAndOpenConfirmationModal({
      url: apiUrl.deleteOwnership(ownershipId),
      translationKey: 'ownership',
      onSuccess: updateList,
      translationValues: {
        estateAddress: estate.address,
        relationType: 'ownedEstate',
      },
    }),
});

export const getOwners = (
  ownerships: FetchedEstateOwner[],
  { setUpAndOpenConfirmationModal, updateList }: OwnerActionProps,
): PersonInDetails[] =>
  ownerships.map(({ owner: { id, ...owner }, id: ownershipId }) => ({
    ...owner,
    personId: id,
    action: getOwnerAction(
      ownershipId,
      { personId: id, ...owner },
      { setUpAndOpenConfirmationModal, updateList },
    ),
  }));

export const getDossierPersons = (
  persons: PersonInDossier[],
  personActionProps: PersonActionProps,
): PersonInDetails[] =>
  persons.map((person) => ({
    ...person,
    action: shouldDossierFeatureBeActive(personActionProps.data.dossierData.status, true)
      ? getPersonAction(person.personId, personActionProps)
      : null,
    representative: getPersonRepresentative(persons, person.representativeId),
  }));

export const getEstates = (
  ownerships: FetchedOwnedEstates[],
  actionProps: EstateActionProps,
): OwnedEstate[] =>
  ownerships.map(
    ({
      estate: { type: _type, subType: _subType, id: estateId, address, city, postCode },
      id: ownershipId,
    }) => {
      const estate = {
        address,
        city,
        postCode,
        action: getEstateAction(ownershipId, { address, city, postCode }, actionProps),
      };

      return {
        estate,
        estateId,
        ownershipId,
      };
    },
  );
