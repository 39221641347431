import { LoaderData } from 'contexts/loaders/types';
import i18n from 'i18n';
import routes from 'router/routes';
import addressToString from 'utils/addressToString';
import transformName from 'utils/transformName';
import { BreadcrumbsConstructor, BreadcrumbsInterface } from './types';

export const getBreadcrumbs = (loaderData: LoaderData | null): BreadcrumbsInterface[] => {
  if (!loaderData) {
    return [];
  }

  let breadcrumbs: BreadcrumbsConstructor[] = [];

  const { data, scope } = loaderData;
  switch (scope) {
    case 'estate':
      breadcrumbs = [
        { translationKey: 'Estates', to: routes.estates },
        {
          label: addressToString(data.address, { withPostCode: false }),
        },
      ];
      break;
    case 'dossier':
      breadcrumbs = [
        { translationKey: 'Dossiers', to: routes.dossiers },
        {
          label: data.referenceNumber,
        },
      ];
      break;
    case 'legalPerson':
      breadcrumbs = [
        { translationKey: 'Persons', to: routes.persons },
        { label: data.name },
      ];
      break;
    case 'naturalPerson':
      breadcrumbs = [
        { translationKey: 'Persons', to: routes.persons },
        { label: transformName(data) },
      ];
      break;
    case 'user':
      breadcrumbs = [
        { translationKey: 'Users', to: routes.users },
        { label: transformName(data) },
      ];
      break;
  }

  return breadcrumbs.map(({ label = '', translationKey, to }) => ({
    label: translationKey
      ? i18n.t(`breadcrumbs.${translationKey}`, { ns: 'enums' })
      : label,
    to,
  }));
};
