import { TableColumn } from 'templates/Table/types';
import { formatDate } from 'utils/date';
import { Migration } from 'api/businesses/types';
import BoolCell from '../../../templates/Table/Cells/BooleanCell';

export const migrationColumns: TableColumn<Migration, 'migrationQueue'>[] = [
  { field: 'name', flex: 5 },
  { field: 'businessId', flex: 5 },
  { field: 'externalGuid', flex: 5 },
  { field: 'fileFolderPrefix', flex: 5 },
  {
    field: 'withSync',
    flex: 2,
    renderCell: BoolCell,
  },
  {
    field: 'withSafetyCheck',
    flex: 2,
    renderCell: BoolCell,
  },
  {
    field: 'isCompleted',
    flex: 2,
    renderCell: BoolCell,
  },
  {
    field: 'migrationDate',
    flex: 4,
    valueFormatter: (migrationDate) =>
      formatDate(migrationDate, 'dd/MM/yyyy HH:mm') || '',
  },
];
