import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import WarningField from 'atoms/WarningField';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { ControlledInput } from 'components/controlledInputs';
import { InputField } from 'components/controlledInputs/types';

interface Props<T extends FieldValues> {
  fields: InputField<T>[];
  namePrefix?: string;
  onInputChange?: (name: keyof T) => () => void;
}

const ProductInputFields = <T extends FieldValues>({
  fields,
  namePrefix,
  onInputChange,
}: Props<T>) => {
  const { t } = useTranslation('formFields');
  const { control, formState } = useFormContext();

  return (
    <Stack spacing={1}>
      {fields.map(({ name, warningKey, ...field }) => (
        <Stack key={name} spacing={1}>
          <InputLabelWrapper label={t(name, name)}>
            <ControlledInput
              name={namePrefix ? `${namePrefix}.${name}` : name}
              control={control}
              error={
                namePrefix
                  ? (
                      formState.errors?.[namePrefix] as Merge<
                        FieldError,
                        FieldErrorsImpl<FieldValues>
                      >
                    )?.[name]
                  : formState.errors?.[name]
              }
              onInputChange={onInputChange?.(name)}
              hideHelperTextGap
              {...field}
            />
          </InputLabelWrapper>
          {warningKey && <WarningField tKey={warningKey} fullWidth />}
        </Stack>
      ))}
    </Stack>
  );
};

export default ProductInputFields;
