import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import getFormMethods from 'utils/getFormMethods';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { ControlledTaxResidenceInput } from 'components/controlledInputs';
import { TaxResidenceTooltip } from './TaxResidenceTooltip';
import { TaxResidencesFieldProps } from './types';

export const TaxResidencesField = ({
  separateLabel = true,
  isRequired,
  prefix,
}: TaxResidencesFieldProps) => {
  const { t } = useTranslation('formFields');

  const methods = useFormContext();
  const { watch, getError } = getFormMethods<NaturalPersonFormData>(methods, prefix);

  const taxResidences = watch('taxResidences');
  const taxResidenceBelgium = watch('taxResidenceBelgium');

  const isOptionalFieldDisabled = useMemo(() => {
    return !taxResidences?.at(0)?.taxIdentificationNumber;
  }, [taxResidences?.at(0)?.taxIdentificationNumber]);

  if (!taxResidenceBelgium || taxResidenceBelgium === 'Belgium') {
    return null;
  }

  if (separateLabel) {
    return (
      <Stack spacing={1} justifyContent={'center'}>
        <InputLabelWrapper label={`${t('taxResidence')}${isRequired ? '*' : ''}`}>
          <ControlledTaxResidenceInput
            name={prefix ? `${prefix}.taxResidences.0` : 'taxResidences.0'}
            control={methods.control}
            error={getError('taxResidences.0')}
            hideHelperTextGap
            endAdornment={
              <TaxResidenceTooltip
                countryCode={taxResidences.at(0)?.taxResidenceCountry || 'NL'}
              />
            }
          />
        </InputLabelWrapper>
        <InputLabelWrapper label={t('taxResidenceOptional')}>
          <ControlledTaxResidenceInput
            name={prefix ? `${prefix}.taxResidences.1` : 'taxResidences.1'}
            control={methods.control}
            disabled={isOptionalFieldDisabled}
            error={getError('taxResidences.1')}
            hideHelperTextGap
            endAdornment={
              !isOptionalFieldDisabled && (
                <TaxResidenceTooltip
                  countryCode={taxResidences.at(1)?.taxResidenceCountry || 'NL'}
                />
              )
            }
          />
        </InputLabelWrapper>
      </Stack>
    );
  }

  return (
    <Stack direction="column" rowGap={1}>
      <ControlledTaxResidenceInput
        name={prefix ? `${prefix}.taxResidences.0` : 'taxResidences.0'}
        control={methods.control}
        label={t('taxResidence')}
        error={getError('taxResidences.0')}
        endAdornment={
          <TaxResidenceTooltip
            countryCode={taxResidences.at(0)?.taxResidenceCountry || 'NL'}
          />
        }
      />
      <ControlledTaxResidenceInput
        name={prefix ? `${prefix}.taxResidences.1` : 'taxResidences.1'}
        control={methods.control}
        label={t('taxResidenceOptional')}
        disabled={isOptionalFieldDisabled}
        error={getError('taxResidences.1')}
        endAdornment={
          !isOptionalFieldDisabled && (
            <TaxResidenceTooltip
              countryCode={taxResidences.at(1)?.taxResidenceCountry || 'NL'}
            />
          )
        }
      />
    </Stack>
  );
};
