import { Navigate, useOutletContext } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import NaturalPersonDetailsCard from 'modules/PersonView/NaturalPersonDetailsCard';
import routes from 'router/routes';
import { NaturalPersonOutletContext } from '../types';

const NaturalPersonDetailsTab = () => {
  const context = useOutletContext<NaturalPersonOutletContext>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return matches ? (
    <Navigate to={routes.naturalPersonTab(context.naturalPersonId).contacts} replace />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NaturalPersonDetailsCard {...context} />
      </Grid>
    </Grid>
  );
};

export default NaturalPersonDetailsTab;
