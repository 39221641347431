import { useContext, useEffect, useMemo, useState } from 'react';
import { Collapse, Stack } from '@mui/material';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { RelationCardProps, RelationData } from 'modules/Relation/types';
import PageCard from 'templates/PageCard';
import AddRelation from './AddRelation';
import RelationPreviewList from './RelationPreviewList';

const RelationCard = <T extends object, TRelationData extends object | undefined>({
  translationKey,
  relations,
  fetchRelations,
  shouldFetchOnMount = true,
  saveRelation,
  editable = true,
  showListIcons,
  children,
  loading = false,
  hideTitle = false,
  onRelationSelect,
  modalData,
  ...addRelationProps
}: RelationCardProps<T, TRelationData>) => {
  const [relationsToPreview, setRelationsToPreview] = useState<RelationData<T>[]>([]);
  const [saving, setSaving] = useState(false);
  const [editing, setIsEditing] = useState(false);

  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const toggleEditing = () => setIsEditing((prev) => !prev);

  useEffect(() => {
    if (shouldFetchOnMount) {
      fetchRelations();
    }
  }, [fetchRelations, shouldFetchOnMount]);

  const saveRelations = async (withLoader = true, modalData?: TRelationData) => {
    setSaving(withLoader);
    const { allSucceed, allFailed } = await saveRelation(
      relationsToPreview,
      setRelationsToPreview,
      false,
      modalData,
    );

    if (allSucceed) {
      toggleEditing();
    }
    if (!allFailed) {
      await fetchRelations();
    }
    setSaving(false);
  };

  const onSave = async () => {
    if (modalData) {
      setUpAndOpenConfirmationModal({
        translationKey: modalData.translationKey,
        translationValues: modalData.translationValues,
        onSubmit: () => saveRelations(false, modalData.relationData),
        onCancel: () => saveRelations(false),
        type: 'confirmation',
        shouldCloseOnSubmit: true,
      });
    } else {
      saveRelations();
    }
  };

  const onCancel = () => {
    toggleEditing();
    setRelationsToPreview([]);
  };

  const relationsToFilter = useMemo(
    () => [...relations.map((id) => id), ...relationsToPreview.map(({ id }) => id)],
    [relations, relationsToPreview],
  );

  return (
    <PageCard
      blank={!editing && !relations.length}
      loading={loading}
      tKey="relation"
      emptyMessageOptions={{ context: translationKey }}
      headerProps={{
        titleProps: {
          translationValues: { context: translationKey },
          hidden: hideTitle,
        },
        editionProps: {
          isEditing: editing,
          onEdit: toggleEditing,
          onCancel,
          onSave,
          saving,
          saveDisabled: !relationsToPreview.length,
          hidden: !editable,
          label: 'add',
        },
      }}
    >
      <Collapse in={editing} timeout="auto" unmountOnExit id="collapse">
        <Stack sx={{ ':last-child': { pb: 0 } }}>
          <AddRelation
            withPadding
            relationsToFilter={relationsToFilter}
            setRelationsToPreview={setRelationsToPreview}
            onSelectOption={onRelationSelect?.(setRelationsToPreview, relationsToFilter)}
            translationKey={translationKey}
            {...addRelationProps}
          />
          {editing && (
            <RelationPreviewList
              relationsToPreview={relationsToPreview}
              setRelationsToPreview={setRelationsToPreview}
              isDivider={!!relations.length}
              withPrompt
              showIcons={showListIcons}
            />
          )}
        </Stack>
      </Collapse>
      {children}
    </PageCard>
  );
};

export default RelationCard;
