import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { estateStatuses } from 'formData/estate/selectOptions';
import useEstatePurposeFields from 'hooks/useEstatePurposeFields';
import usePrompt from 'hooks/usePrompt';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import {
  ControlledInput,
  ControlledSelect,
  ControlledTextInput,
} from 'components/controlledInputs';
import { EstateDataWithoutPhoto } from '../types';

export const EstateGeneralEdition = ({ regionChanged }: { regionChanged?: boolean }) => {
  const { t } = useTranslation('formFields');

  const {
    control,
    formState: { errors, isDirty },
  } = useFormContext<EstateDataWithoutPhoto>();

  usePrompt({ when: isDirty, id: 'estateGeneral' });

  const fields = useEstatePurposeFields();

  return (
    <Stack spacing={4} py={2.5}>
      <Stack spacing={1}>
        <Stack spacing={1} px={2.5}>
          <InputLabelWrapper label={t('status')}>
            <ControlledSelect
              name="status"
              control={control}
              error={errors.status}
              hideHelperTextGap
              options={estateStatuses}
            />
          </InputLabelWrapper>
          <InputLabelWrapper label={t('ownReference')}>
            <ControlledTextInput
              name={'ownReference'}
              control={control}
              error={errors['ownReference']}
              hideHelperTextGap
            />
          </InputLabelWrapper>
        </Stack>
      </Stack>
      <Stack spacing={1} px={2.5}>
        <AddressFieldsWithGoogle
          separateLabel
          disableCountrySelection
          regionChanged={regionChanged}
        />
      </Stack>
      <Stack spacing={1} px={2.5}>
        {fields
          .filter(({ hidden }) => !hidden)
          .map(({ name, ...field }) => (
            <InputLabelWrapper key={name} label={t(name)}>
              <ControlledInput
                name={name}
                control={control}
                error={errors[name]}
                hideHelperTextGap
                {...field}
              />
            </InputLabelWrapper>
          ))}
      </Stack>
    </Stack>
  );
};
