import { ChangeEventHandler, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { getResidentsFields } from 'formData/dossier/residents/fields';
import { MAX_CHILDREN_NUMBER } from 'formData/dossier/residents/static-data';
import usePrompt from 'hooks/usePrompt';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { ResidentsForm } from 'api/dossier/types';
import { ControlledDateInput, ControlledInput } from 'components/controlledInputs';
import { createChildrenFields } from './utils';

const ResidentsEdition = () => {
  const {
    control,
    formState: { errors, isDirty },
    getValues,
  } = useFormContext<ResidentsForm>();

  usePrompt({ when: isDirty, id: 'residents' });

  const [input, setInput] = useState<number | null>(null);
  const [prevNumberOfChildren, setPrevNumberOfChildren] = useState<number | null>(
    getValues('numberOfChildren') || null,
  );
  const debouncedInput = useDebounce(input, 500);
  const { t } = useTranslation('formFields');

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInput(e.target.valueAsNumber);
  };

  const fields = getResidentsFields(onInputChange, debouncedInput);

  const {
    fields: childrenFields,
    append,
    prepend,
    remove,
  } = useFieldArray({ control, name: 'childrenDateOfBirth' });

  useUpdateEffect(() => {
    const currentValue = Number(getValues('numberOfChildren'));
    if (currentValue > MAX_CHILDREN_NUMBER) return;
    if (currentValue && prevNumberOfChildren) {
      if (currentValue < prevNumberOfChildren) {
        const indexesToRemove = [...new Array(prevNumberOfChildren - currentValue)].map(
          (_, index) => currentValue + index,
        );
        remove(indexesToRemove);
      }
      if (currentValue > prevNumberOfChildren) {
        append(createChildrenFields(currentValue, prevNumberOfChildren));
      }
    } else if (currentValue) {
      prepend(createChildrenFields(currentValue, 0));
    } else {
      remove();
    }
    setPrevNumberOfChildren(debouncedInput);
  }, [debouncedInput]);

  return (
    <Stack spacing={1} flex={1} padding={2.5}>
      {fields.map(({ name, hidden, ...field }) =>
        hidden ? null : (
          <InputLabelWrapper key={name} label={t(name)}>
            <ControlledInput
              name={name}
              control={control}
              error={errors[name]}
              hideHelperTextGap
              {...field}
            />
          </InputLabelWrapper>
        ),
      )}
      {childrenFields.map(({ id }, index) => (
        <InputLabelWrapper
          key={id}
          label={t('childrenDateOfBirthLabel', {
            count: childrenFields.length > 1 ? index + 1 : undefined,
          })}
        >
          <ControlledDateInput
            name={`childrenDateOfBirth.${index}.dateOfBirth`}
            error={errors.childrenDateOfBirth?.[index]?.dateOfBirth}
            control={control}
            disableFuture
            hideHelperTextGap
          />
        </InputLabelWrapper>
      ))}
    </Stack>
  );
};

export default ResidentsEdition;
