import { ThemedStyle } from 'types/themedStyle';

export const todoItemContainerStyles =
  (isUnderDrop: boolean): ThemedStyle =>
  ({ palette }) => ({
    cursor: 'grab',
    backgroundColor: isUnderDrop ? palette['primary']['200'] : '',
    transform: 'translate3d(0,0,0)',
    ':nth-of-type(odd)': {
      backgroundColor: isUnderDrop
        ? palette['primary']['200']
        : palette['background']['default'],
    },
  });
