import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';
import { ClearIcon } from 'icons';
import { statusChipStyle } from './styles';

interface Props {
  filterStatuses: string[];
  handleRemoveFilter: (status: string) => void;
}

const AssignedDossiersActiveFilters = ({ filterStatuses, handleRemoveFilter }: Props) => {
  const { t } = useTranslation(['common', 'enums']);

  const getChipLabel = (status: string) =>
    `${t('selectInput.placeholder.status')}: ${t(
      `enums:dossierStatuses.${status}`,
      status,
    )}`;

  if (!filterStatuses.length) {
    return null;
  }

  return (
    <Box display="flex" gap={2}>
      {filterStatuses.map((status) => (
        <Chip
          key={status}
          label={getChipLabel(status)}
          deleteIcon={<ClearIcon />}
          onDelete={() => handleRemoveFilter(status)}
          sx={statusChipStyle}
        />
      ))}
    </Box>
  );
};

export default AssignedDossiersActiveFilters;
