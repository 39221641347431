import { TKey } from 'i18next';
import yup from 'utils/yup';

const privateRelationSchema = yup.object({
  type: yup.string<TKey<'enums', 'privateRelation'>>().default('Marriage').required(),
  side2: yup
    .string()
    .label('field')
    .when('$isRelation', {
      is: false,
      then: (schema) => schema.required(),
    }),
});

export default privateRelationSchema;
