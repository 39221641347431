import { RefCallback, useCallback } from 'react';
import { BaseInput } from './BaseInput';
import { NumberInputProps } from './types';

export const NumberInput = ({
  inputRef = null,
  isInteger = false,
  allowNegative = false,
  precision,
  error = false,
  ...props
}: NumberInputProps) => {
  const handleNumberChange = useCallback(
    (e: KeyboardEvent) => {
      const key = e.key;
      const value = (e.target as HTMLInputElement).value;

      const blockDecimal =
        precision &&
        value.includes('.') &&
        value.split('.')[1].length === precision &&
        !isNaN(Number(key));

      if (
        (!value && key === '.') ||
        (!allowNegative && key === '-') ||
        (isInteger && key === '.') ||
        (isInteger && key === ',') ||
        blockDecimal ||
        key === '+' ||
        key === 'e'
      ) {
        e.preventDefault();
      }
    },
    [isInteger, allowNegative, precision],
  );

  const ignoreScrollOnNumber = useCallback((e: WheelEvent) => {
    e.preventDefault();
  }, []);

  const handleInputRef = (ref: HTMLInputElement) => {
    if (ref) {
      if (inputRef) {
        (inputRef as RefCallback<HTMLInputElement>)(ref);
      }
      ref.addEventListener('wheel', ignoreScrollOnNumber);
      ref.addEventListener('keydown', handleNumberChange);
    }
  };

  return <BaseInput type="number" inputRef={handleInputRef} error={error} {...props} />;
};
