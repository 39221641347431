import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { SelectOption } from './types';

export const MenuItemContent = ({
  label,
  caption,
  suffix,
  Icon,
  translationKey,
}: SelectOption) => {
  const { t } = useTranslation('enums');

  const Label = () => (
    <Typography sx={{ whiteSpace: 'normal' }}>
      {translationKey ? t(translationKey, label) : label}
    </Typography>
  );

  const Main = () => (
    <Stack width="100%" flexGrow={1}>
      {suffix ? (
        <Stack direction="row" gap={1} justifyContent="space-between">
          <Label />
          <Typography color="GrayText">{suffix}</Typography>
        </Stack>
      ) : (
        <Label />
      )}
      {caption && (
        <Typography sx={{ whiteSpace: 'normal' }} variant="caption" color="GrayText">
          {caption}
        </Typography>
      )}
    </Stack>
  );

  return Icon ? (
    <Stack direction="row" alignItems="center" columnGap={1.5} flexGrow={1}>
      <Icon />
      <Main />
    </Stack>
  ) : (
    <Main />
  );
};
