import { RelationResponses, SaveRelations } from 'modules/Relation/types';
import { saveRelations } from 'modules/Relation/utils';
import { FetchedEstateListItem } from 'api/estate/types';
import { FetchedPersonData, PersonType } from 'api/person/types';
import { addOwnership } from 'api/relations/requests';
import { AddOwnership, FetchedPrivateRelation } from 'api/relations/types';

const createOwnership = (
  ownerId: string,
  ownerType: PersonType,
  estateId: string,
): AddOwnership => ({
  ownerId,
  ownerType,
  estateId,
});

export const addNewOwners =
  (estateId: string): SaveRelations<FetchedPersonData> =>
  async (relationsToPreview, setRelationsToPreview, showSaved) => {
    const responses: RelationResponses = [];

    for (const { id, type } of relationsToPreview) {
      const newOwnership = createOwnership(id, type, estateId);
      const res = await addOwnership(newOwnership);
      responses.push({
        ok: res.ok,
        message: !res.ok ? res.message.replace(id, '') : '',
        savedId: res.ok ? res.response.id : id,
        id,
      });
    }

    return saveRelations<FetchedPersonData>(responses)(
      relationsToPreview,
      setRelationsToPreview,
      showSaved,
    );
  };

export const addNewOwnedEstates =
  (
    personId: string,
    personType: PersonType,
  ): SaveRelations<FetchedEstateListItem, FetchedPrivateRelation> =>
  async (relationsToPreview, setRelationsToPreview, showSaved, privateRelationData) => {
    const responses: RelationResponses = [];

    for (const { id } of relationsToPreview) {
      const newOwnership = createOwnership(personId, personType, id);
      const res = await addOwnership(newOwnership);
      responses.push({
        ok: res.ok,
        message: !res.ok ? res.message.replace(id, '') : '',
        id,
      });
      if (privateRelationData) {
        const newOwnership = createOwnership(
          privateRelationData.relative.id,
          privateRelationData.relative.type,
          id,
        );
        const res = await addOwnership(newOwnership);
        responses.push({
          ok: res.ok,
          message: !res.ok ? res.message.replace(id, '') : '',
          id,
        });
      }
    }

    return saveRelations<FetchedEstateListItem>(responses)(
      relationsToPreview,
      setRelationsToPreview,
      showSaved,
    );
  };
