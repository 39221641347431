import yup from 'utils/yup';
import eanNumberSchema from '../eanNumber/schema';

const gasSchema = yup
  .object({
    eanNumber: eanNumberSchema.label('gas.eanNumber').customWhen({
      is: (form, parent) =>
        parent?.eanNumber && parent.eanNumber === form.electricity?.eanNumber,
      then: (schema) => schema.invalid('eanNumber.sameGasAndElectricity'),
    }),
    meterNumber: yup
      .string()
      .label('gas.meterNumber')
      .nullable()
      .default(null)
      .customWhen({
        is: (form, parent) =>
          parent?.meterNumber && parent.meterNumber === form.electricity?.meterNumber,
        then: (schema) => schema.invalid('meterNumber.sameGasAndElectricity'),
      })
      .customWhen({
        is: (form, parent) =>
          parent?.meterNumber && parent.meterNumber === form.electricity?.meterNumber2,
        then: (schema) => schema.invalid('meterNumber.sameGasAndElectricity'),
      }),
  })
  .nullable()
  .when('hasGas', { is: false, then: (schema) => schema.transform(() => null) });

export default gasSchema;
