import {
  EPC_CONSUMPTION_UNIT,
  HABITABLE_SURFACE_UNIT,
} from 'formData/estate/details/fields';
import {
  estateDetailsOrder,
  estateElectricityOrder,
  estateEpcOrder,
  estateGasOrder,
} from 'formData/estate/details/order';
import { isEstateElectricityMeterNumber2 } from 'formData/estate/details/utils';
import { SectionRowsProps } from 'templates/SectionRows/types';
import { formatDate } from 'utils/date';
import { isTruthyValueInObject, objectToSortedArray, sortObject } from 'utils/object';
import replaceSpecialChars from 'utils/replaceSpecialChars';
import { EstateEpc } from 'api/estate/types';
import { EstateDetailsContentProps } from './types';

const getDetailsData = ({
  managementType,
  conformity,
  electricity,
  epc,
  gas,
  habitableSurface,
  hasConformity,
  hasElectricity,
  hasEpc,
  hasGas,
  hasSyndic,
  isFurnished,
  others,
  smokeDetectors,
  syndic,
}: EstateDetailsContentProps): Record<string, SectionRowsProps> => ({
  main: {
    data: sortObject(
      {
        habitableSurface: habitableSurface
          ? `${habitableSurface} ${HABITABLE_SURFACE_UNIT}`
          : null,
        managementType,
        smokeDetectors,
        isFurnished,
        others,
        conformity: hasConformity ? formatDate(conformity) ?? true : null,
        syndic: hasSyndic ? syndic ?? true : null,
      },
      estateDetailsOrder,
    ),
    translateValue: {
      managementType: `enums:estateManagementTypes.${managementType}`,
    },
  },
  electricity: {
    data:
      hasElectricity && electricity
        ? sortObject(electricity, estateElectricityOrder)
        : {},
    header: hasElectricity ? { title: 'electricity.self', value: true } : null,
    translationPrefix: 'electricity',
    translateValue: {
      type: `enums:estateElectricityTypes.${electricity?.type}`,
    },
    translateOptions: {
      meterNumber: {
        suffix: isEstateElectricityMeterNumber2(electricity?.type ?? null) ? 1 : '',
      },
    },
  },
  epc: {
    data: hasEpc && epc ? getEpcObject(epc) : {},
    header: hasEpc ? { title: 'epc.self', value: true } : null,
    translationPrefix: 'epc',
    translateValue: {
      type: `enums:estateEpcTypes.${epc?.type}`,
    },
  },
  gas: {
    data: hasGas && gas ? sortObject(gas, estateGasOrder) : {},
    header: hasGas ? { title: 'gas.self', value: true } : null,
    translationPrefix: 'gas',
  },
});

export const getSectionsData = (details: EstateDetailsContentProps) => {
  const object = getDetailsData(details);
  return objectToSortedArray(object, estateDetailsOrder).filter(
    ({ value }) => isTruthyValueInObject(value.data) || !!value.header,
  );
};

const getEpcObject = (epc: EstateEpc) =>
  sortObject(
    {
      ...epc,
      primaryEnergyConsumption: epc?.primaryEnergyConsumption
        ? `${replaceSpecialChars(epc.primaryEnergyConsumption)} ${EPC_CONSUMPTION_UNIT}`
        : null,
      referenceEnergyConsumption: epc?.referenceEnergyConsumption
        ? `${epc.referenceEnergyConsumption} ${EPC_CONSUMPTION_UNIT}`
        : null,
    },
    estateEpcOrder,
  );
