import { ThemedStyle } from 'types/themedStyle';

export const navListStyles: ThemedStyle = ({ mixins }) => ({
  ...mixins.hideScroll,
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  '& > .MuiList-root': {
    mb: 1.5,
    zIndex: 0,
    py: 0,

    '& > .MuiListSubheader-root': {
      lineHeight: 1.4,
      pb: 1,
      pt: 2,
      textTransform: 'uppercase',
      fontSize: 11,
      fontWeight: 'bold',
      color: 'text.secondary',
      ml: 1,
    },

    '&:first-of-type': {
      '& > .MuiListSubheader-root': {
        pt: 1,
      },
    },
  },
});

const navButtonStyles: ThemedStyle = {
  display: 'flex',
  justifyContent: 'center',
  '&:first-of-type': { mt: 0 },
  height: '56px',
};

export const expandedNavButtonStyles: ThemedStyle = ({ palette }) => ({
  ...navButtonStyles,
  columnGap: 2,
  '&:hover': {
    bgcolor: palette.common.white,
    color: palette.secondary.main,
    '& .MuiListItemIcon-root': {
      color: palette.secondary.main,
    },
  },
  '&.Mui-selected': {
    bgcolor: palette.secondary.light,
    color: palette.secondary.main,
    '&:hover': {
      bgcolor: palette.secondary.light,
      color: palette.secondary.dark,
      '& .MuiListItemIcon-root': {
        color: palette.secondary.dark,
      },
    },
    '& .MuiListItemIcon-root': {
      color: palette.secondary.main,
    },
  },
  '& .MuiListItemIcon-root': {
    color: palette.primary.main,
  },
});

export const collapsedNavButtonStyles: ThemedStyle = ({ palette }) => ({
  ...navButtonStyles,
  '&:hover': {
    bgcolor: palette.common.white,
    '& .MuiAvatar-root': {
      color: palette.secondary.main,
    },
  },
  '&.Mui-selected': {
    bgcolor: palette.secondary.light,
    '&:hover': {
      bgcolor: palette.secondary.light,
      '& .MuiAvatar-root': {
        color: palette.secondary.dark,
      },
    },
    '& .MuiAvatar-root': {
      color: palette.secondary.main,
    },
  },
  '& .MuiAvatar-root': {
    bgcolor: 'inherit',
    color: palette.primary.main,
  },
});

export const impersonatingNavStyle = (isSidebarExpanded: boolean): ThemedStyle => ({
  mt: 2,
  bgcolor: ({ palette }) => palette.primary[100],
  cursor: !isSidebarExpanded ? 'pointer' : 'default',
  '&, .MuiTypography-root': {
    color: ({ palette }) => palette.primary.light,
  },
  '& .MuiButtonBase-root.MuiListItemButton-root': {
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: ({ palette }) => palette.primary.light,
      },
      '& .MuiAvatar-root': {
        color: ({ palette }) => palette.primary.light,
      },
      bgcolor: ({ palette }) => palette.common.white,
      color: ({ palette }) => palette.primary.light,
    },
  },
});
