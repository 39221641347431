import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import useSearchInput from 'hooks/useSearchInput';
import {
  AddRelationProps,
  HandleNewOptionSubmit,
  RelationData,
  RelationOption,
} from 'modules/Relation/types';
import { SearchInput } from 'components/inputs';
import { HandleSearchChange } from 'components/inputs/types';

const AddRelation = <T extends object>({
  relationsToFilter,
  selectedRelations = relationsToFilter,
  setRelationsToPreview,
  maxRelations,
  fetchOptions,
  translationKey,
  AddNewOptionModal,
  onSelectOption,
  errorMessage,
  contextOptions = [],
  withPadding,
  withHelperText,
  shouldFilter,
  creatable = true,
  hideHelperTextGap = true,
  disabled,
  withLabel = true,
}: AddRelationProps<T>) => {
  const [openAddNewModal, setOpenAddNewModal] = useState(false);

  const { clearQuery, searchInputProps } = useSearchInput({
    contextOptions,
    optionsToFilter: relationsToFilter,
    fetchOptions,
  });

  const { t } = useTranslation();

  const disableAdding = useMemo(
    () => !!maxRelations && selectedRelations.length >= maxRelations,
    [selectedRelations.length],
  );

  const setRelations = async (data: RelationData<T>) => {
    if (onSelectOption) {
      onSelectOption(data);
    } else if (setRelationsToPreview && maxRelations === 1) {
      setRelationsToPreview([data]);
    } else if (setRelationsToPreview) {
      setRelationsToPreview((prev) => [data, ...prev]);
    }
  };

  const onChange: HandleSearchChange<RelationOption<T>> = async (option) => {
    if (option?.created) {
      setOpenAddNewModal(true);
    } else if (option) {
      setRelations(option.data);
    }
    clearQuery();
  };

  const onModalSubmit: HandleNewOptionSubmit<T> = async (data) => {
    setRelations(data);
  };

  const onModalClose = () => {
    setOpenAddNewModal(false);
  };

  return (
    <Stack padding={withPadding ? 2.5 : 0}>
      <SearchInput
        label={withLabel ? t(`relationInput.${translationKey}.label`) : undefined}
        name={`${translationKey}_search`}
        creatable={creatable}
        onChange={onChange}
        disabled={disabled || disableAdding}
        error={!!errorMessage}
        helperText={
          errorMessage ||
          (disableAdding || withHelperText
            ? t(`relationInput.${translationKey}.helperText`)
            : '')
        }
        placeholder={t(`relationInput.${translationKey}.placeholder`)}
        fillInputWithOption={false}
        hideHelperTextGap={hideHelperTextGap}
        shouldFilter={shouldFilter}
        {...searchInputProps}
      />
      {AddNewOptionModal ? (
        <AddNewOptionModal
          onSubmit={onModalSubmit}
          open={openAddNewModal}
          closeModal={onModalClose}
        />
      ) : null}
    </Stack>
  );
};

export default AddRelation;
