import { DossierLocationState } from 'modules/DossierForm/types';
import { getPrivateRelation } from 'api/relations/requests';
import { DOSSIER_ROLES } from './types';

export const getNavigationState = async (
  entityId: string,
  role?: (typeof DOSSIER_ROLES)[number],
): Promise<DossierLocationState> => {
  if (role) {
    const personIds: string[] = [entityId];
    const { ok, response } = await getPrivateRelation(entityId);
    if (ok && response) {
      personIds.push(response.relative.id);
    }
    return { [`${role}Ids`]: personIds };
  }
  return { estateId: entityId };
};
