import { useEffect, useMemo, useState } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { msalScopes } from 'authConfig';
import { UserPermissions } from 'consts/permissions';
import StorageKeys from 'consts/storage';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import hasPermission from 'utils/hasPermission';
import { cleanObject } from 'utils/object';
import { getBusiness } from 'api/businesses/requests';
import { BusinessDetails } from 'api/businesses/types';
import { getUserSettings } from 'api/config/requests';
import { getUser } from 'api/users/requests';
import { FetchedUserData } from 'api/users/types';

export const useLoginUser = () => {
  const [logged, setLogged] = useState(false);
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState<FetchedUserData | null>(null);
  const [username, setUsername] = useState('');
  const [roles, setRoles] = useState<string[]>([]);

  const [businessData, setBusinessData] = useState<BusinessDetails | null>(null);

  const { inProgress, instance, accounts } = useMsal();

  useEffect(() => {
    if (!inProgress || inProgress === InteractionStatus.None) {
      const account = accounts[0];
      instance
        .acquireTokenSilent({ scopes: msalScopes, account })
        .then(async () => {
          const res = await getUserSettings();

          if (res.ok) {
            const { businessId, language, permissions, roles } = res.response;
            if (!roles.includes('SuperAdmin')) {
              Cookies.remove(StorageKeys.ImpersonatedBusinessId);
            }
            setRoles(roles);
            localStorage.setItem(StorageKeys.BusinessId, businessId);
            localStorage.setItem(StorageKeys.Permissions, JSON.stringify(permissions));
            if (i18n.language !== language) {
              i18n.changeLanguage(language);
            }
            const { ok, response } = await getBusiness(true);
            if (ok) {
              setBusinessData(cleanObject(response));
            }
            setError(false);
            setLogged(true);
            setUsername(account.username);
          } else {
            localStorage.removeItem(StorageKeys.BusinessId);
            localStorage.removeItem(StorageKeys.Permissions);
            setError(true);
          }
          if (account?.localAccountId && res.ok && hasPermission(UserPermissions.Read)) {
            const { ok, response } = await getUser(account?.localAccountId);
            if (ok) {
              setUserData(response);
            }
          }
        })
        .catch((_error) =>
          instance.acquireTokenRedirect({
            scopes: msalScopes,
          }),
        );
    }
  }, [instance, accounts, inProgress]);

  const loading = useMemo(() => {
    return (
      inProgress === InteractionStatus.HandleRedirect ||
      inProgress === InteractionStatus.Login ||
      inProgress === InteractionStatus.Logout ||
      (!logged && !error)
    );
  }, [inProgress, logged, error]);

  return { loading, error, userData, businessData, username, roles } as const;
};
