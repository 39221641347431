import { conformToMask } from 'react-text-mask';

export const getVatNumberWithMask = (
  number: string,
  mask: Array<string | RegExp> | false,
) => {
  if (!number || !mask || mask.length === 0) {
    return number;
  }

  const value = conformToMask(number, mask, { guide: false }).conformedValue;

  return value.length === mask.length ? value : number;
};
