import { useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { PersonFormContext } from 'contexts/personForm/personFormContext';
import { naturalPersonSchema } from 'formData/naturalPerson';
import { UseNaturalPersonGeneralStep } from 'modules/PersonForm/NaturalPersonForm/types';
import { usePersonForm } from 'modules/PersonForm/usePersonForm';
import setValidationErrors from 'utils/setValidationErrors';
import { resolver } from 'utils/yup';
import { addNaturalPerson } from 'api/person/natural/requests';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { GeneralStep } from './GeneralStep';

export const useGeneralStep: UseNaturalPersonGeneralStep = ({
  onCancel,
  naturalPersonId,
  setNaturalPersonId,
  handleSave,
}) => {
  const [saving, setSaving] = useState(false);

  const { t } = useTranslation();
  const { updateGeneral, submitting, setSaved } = useContext(PersonFormContext);

  const methods = useForm<NaturalPersonFormData>({
    defaultValues: naturalPersonSchema.getDefault(),
    resolver: resolver(naturalPersonSchema),
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
    setError,
  } = methods;

  const { saveForm } = usePersonForm(naturalPersonId, handleSave, isDirty, setError);

  const saveAndContinue =
    (nextStep: () => void): SubmitHandler<NaturalPersonFormData> =>
    async (data) => {
      setSaving(true);
      const res = await addNaturalPerson(data);
      if (res.ok) {
        nextStep();
        updateGeneral(data);
        setNaturalPersonId(res.response.id);
        setSaved(true);
        reset(data, { keepDirty: false });
        toast.success(t('successMessages.PersonAddedSuccessfully'));
      } else {
        setValidationErrors(res.validation, setError);
      }

      setSaving(false);
    };

  const saveAndClose: SubmitHandler<NaturalPersonFormData> = async (data, event) => {
    saveForm(event, data);
  };

  const { isAnyPending } = useContext(FilesUploadContext);
  return {
    isValid,
    Component: (
      <FormProvider {...methods}>
        <GeneralStep />
      </FormProvider>
    ),
    cancelButton: {
      onClick: onCancel,
    },
    nextButton: !naturalPersonId
      ? {
          changeStepManually: true,
          label: t('saveAndContinue'),
          onClick: (nextStep) => handleSubmit(saveAndContinue(nextStep)),
          saving,
        }
      : {
          onClick: handleSubmit(updateGeneral),
        },
    labelButton: {
      onClick: handleSubmit(updateGeneral),
    },
    submitButton: {
      label: t('saveAndClose'),
      onClick: handleSubmit(saveAndClose),
      saving: submitting || isAnyPending,
    },
    required: true,
  };
};
