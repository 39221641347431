import { TranslatedOption } from 'formData/types';
import { UserRoles } from 'api/users/types';

export const getUserRolesOptions = (
  roles: UserRoles[],
  userRole?: UserRoles,
): TranslatedOption<UserRoles>[] =>
  roles
    .filter((role) => (userRole == 'BusinessAdmin' ? role !== 'BackofficeUser' : role))
    .map((role) => ({
      value: role,
      label: role,
      translationKey: `userRoles.${role}`,
    }));
