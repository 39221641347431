import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { getRentalDepositDistributionFields } from 'formData/dossier/products/rentalDeposit/fields';
import { getOtherToDivide } from 'formData/dossier/products/rentalDeposit/utils';
import { HandleCheckboxChange } from 'types/handleChange';
import { ControlledInput } from 'components/controlledInputs';
import ControlledCheckbox from 'components/controlledInputs/ControlledCheckbox';
import { AbolishmentForm, DepositDistribution, DistributionPersonType } from '../types';

interface Props {
  type: DistributionPersonType;
  depositDistribution?: DepositDistribution;
  noEmail?: boolean;
}

const DepositDistributionFields = ({
  type,
  depositDistribution,
  noEmail = false,
}: Props) => {
  const {
    control,
    clearErrors,
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext<AbolishmentForm>();

  const { t } = useTranslation('formFields');

  const emailConsent = useWatch({ control, name: 'emailConsent' });

  const onCheckboxChange: HandleCheckboxChange = (_e, checked) => {
    if (checked) {
      clearErrors(`${type}.accountNumber`);
    } else if (errors[type]?.email && !getValues(`${type}.email`)) {
      clearErrors(`${type}.email`);
    }
  };

  const handleAmountChange = () => {
    if (depositDistribution === 'depositDivided' && type !== 'tenant') {
      const fieldToTrigger = getOtherToDivide(type);
      if (errors?.[fieldToTrigger]?.amount) {
        trigger(`${getOtherToDivide(type)}.amount`);
      }
    }
  };

  const fields = getRentalDepositDistributionFields(
    handleAmountChange,
    type,
    depositDistribution,
    emailConsent,
    noEmail,
  );

  return (
    <Stack spacing={2} my={1}>
      {fields.map(
        ({ name, label, hidden, placeholder, Component, componentProps, ...field }) =>
          !hidden && (
            <Stack key={name} gap={1}>
              {type === 'tenant' &&
                !noEmail &&
                depositDistribution === 'depositUnknown' && (
                  <ControlledCheckbox
                    control={control}
                    onChange={onCheckboxChange}
                    name="emailConsent"
                    label={t('emailConsent')}
                  />
                )}
              {Component ? (
                <Stack>
                  <Component {...componentProps} />
                  {type === 'tenant' &&
                    !noEmail &&
                    depositDistribution !== 'depositUnknown' && (
                      <ControlledCheckbox
                        control={control}
                        onChange={onCheckboxChange}
                        name="emailConsent"
                        label={t('emailConsent')}
                      />
                    )}
                </Stack>
              ) : (
                <ControlledInput
                  label={t(label ?? name, name)}
                  control={control}
                  error={errors[type]?.[name]}
                  name={`${type}.${name}`}
                  placeholder={placeholder ? t(placeholder, placeholder) : undefined}
                  hideHelperTextGap
                  {...field}
                />
              )}
            </Stack>
          ),
      )}
    </Stack>
  );
};

export default DepositDistributionFields;
