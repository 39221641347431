import { useContext } from 'react';
import { IconButton } from '@mui/material';
import { BackOfficeContext } from 'contexts/backofficeContext';
import { CancelIcon, PlayIcon } from 'icons';

const ImpersonateAction = ({ id }: { id: string }) => {
  const { impersonate, finishImpersonating, impersonateId } =
    useContext(BackOfficeContext);
  const handleClick = () => {
    impersonate(id);
  };
  return impersonateId === id ? (
    <IconButton onClick={() => finishImpersonating()}>
      <CancelIcon />
    </IconButton>
  ) : (
    <IconButton onClick={handleClick}>
      <PlayIcon />
    </IconButton>
  );
};

export default ImpersonateAction;
