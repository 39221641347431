import { Navigate, useOutletContext } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { ResidentsCard } from 'modules/DossierView';
import routes from 'router/routes';
import { DossierOutletContext } from '../types';

const DossierResidentsTab = () => {
  const { dossierId, dossierData, fetchRentalDossier } =
    useOutletContext<DossierOutletContext>();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return !matches ? (
    <Navigate to={routes.rentalDossierTab(dossierId).rentalConditions} replace />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <ResidentsCard
          dossierId={dossierId}
          dossierData={dossierData}
          fetchRentalDossier={fetchRentalDossier}
        />
      </Grid>
    </Grid>
  );
};

export default DossierResidentsTab;
