import { Navigate, RouteObject } from 'react-router-dom';
import { dossierDocumentsTabRoutes, dossierTabRoutes } from 'router/routes';
import DossierDetailsTab from 'pages/Dossiers/Details/tabs/Details';
import DossierDocumentsTab from 'pages/Dossiers/Details/tabs/documents/Documents';
import DossierEstateDocuments from 'pages/Dossiers/Details/tabs/documents/DossierEstateDocuments';
import DossierPersonDocuments from 'pages/Dossiers/Details/tabs/documents/DossierPersonsDocuments';
import DossierProductsDocuments from 'pages/Dossiers/Details/tabs/documents/DossierProductsDocuments';
import DossierPartiesTab from 'pages/Dossiers/Details/tabs/Parties';
import DossierProductsGrid from 'pages/Dossiers/Details/tabs/Products/ProductsGrid';
import DossierSingleProduct from 'pages/Dossiers/Details/tabs/Products/SingleProduct';
import DossierRentalConditionsTab from 'pages/Dossiers/Details/tabs/RentalConditions';
import DossierResidentsTab from 'pages/Dossiers/Details/tabs/Residents';

export const dossierTabs: RouteObject[] = [
  { path: dossierTabRoutes.products, element: <DossierProductsGrid /> },
  {
    path: `${dossierTabRoutes.products}/:productId`,
    element: <DossierSingleProduct />,
  },
  { path: dossierTabRoutes.details, element: <DossierDetailsTab /> },
  {
    path: dossierTabRoutes.documents,
    element: <DossierDocumentsTab />,
    children: [
      { path: '', element: <Navigate to="products" /> },
      { path: dossierDocumentsTabRoutes.products, element: <DossierProductsDocuments /> },
      { path: dossierDocumentsTabRoutes.estate, element: <DossierEstateDocuments /> },
      {
        path: dossierDocumentsTabRoutes.tenants,
        element: <DossierPersonDocuments type="tenant" />,
      },
      {
        path: dossierDocumentsTabRoutes.landlords,
        element: <DossierPersonDocuments type="landlord" />,
      },
      { path: '*', element: <Navigate to="products" /> },
    ],
  },
  { path: dossierTabRoutes.rentalConditions, element: <DossierRentalConditionsTab /> },
  { path: dossierTabRoutes.residents, element: <DossierResidentsTab /> },
  { path: dossierTabRoutes.parties, element: <DossierPartiesTab /> },
  { path: '*', element: <Navigate to={dossierTabRoutes.products} /> },
];
