import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import DossierStatusChip from 'atoms/DossierStatusChip';
import { AssignedDossierHeaderProps } from './types';

const AssignedDossierHeader = ({
  ownReference,
  referenceNumber,
  role,
  status,
}: AssignedDossierHeaderProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'assignedDossierCard' });

  return (
    <Box display="flex" justifyContent="space-between">
      <Stack>
        <Typography color="primary.main" variant="h5">
          {referenceNumber}
        </Typography>
        {ownReference && <Typography color="primary.main">{ownReference}</Typography>}
        {role && (
          <Typography variant="h6" fontWeight="bold" color="secondary.main">
            {t(role === 'Tenant' ? 'tenants' : 'landlords', { count: 1 })}
          </Typography>
        )}
      </Stack>
      <DossierStatusChip status={status} />
    </Box>
  );
};

export default AssignedDossierHeader;
