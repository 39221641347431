import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DividerStack from 'atoms/DividerStack';
import { userFieldsOrder, userSchema } from 'formData/users';
import SectionRows from 'templates/SectionRows';
import sortObject from 'utils/object/sortObject';
import setValidationErrors from 'utils/setValidationErrors';
import transformName from 'utils/transformName';
import { resolver } from 'utils/yup';
import { updateUser } from 'api/users/requests';
import { UserFormData } from 'api/users/types';
import MainCardHeader from 'components/MainCardHeader';
import { PageCard } from 'components/PageCard';
import UserDetailsEdition from './UserDetailsEdition';
import { getUserActions } from './utils';

interface Props {
  userData: UserFormData;
  userId: string;
  fetchUserData: () => Promise<void>;
}

export const UserDetailsCard = ({ userData, userId, fetchUserData }: Props) => {
  const { t } = useTranslation('errors');
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => setEditing((prev) => !prev);

  const methods = useForm<UserFormData>({
    defaultValues: userData,
    resolver: resolver(userSchema.omit(['email'])),
  });

  const {
    formState: { isDirty },
    reset,
    setError,
    handleSubmit,
  } = methods;

  const cancelEditing = () => {
    reset();
    toggleEditing();
  };

  const submitAction: SubmitHandler<UserFormData> = async (data) => {
    setSaving(true);

    const res = await updateUser(userId, data);
    if (res.ok) {
      await fetchUserData();
      toggleEditing();
      reset(data);
    } else {
      setValidationErrors(res.validation, setError);
      if (res.status === 404) {
        toast.error(t('routes.user.404'));
      }
    }
    setSaving(false);
  };

  return (
    <PageCard
      header={
        <MainCardHeader
          title={transformName(userData)}
          withBorder
          actions={getUserActions(userId, transformName(userData), toggleEditing)}
          edition={{
            editing,
            saving,
            saveDisabled: !isDirty,
            onCancel: cancelEditing,
            onSave: handleSubmit(submitAction),
          }}
        />
      }
    >
      {editing ? (
        <FormProvider {...methods}>
          <SectionRows data={{ email: userData.email }} />
          <UserDetailsEdition />
        </FormProvider>
      ) : (
        <DividerStack sx={{ overflowY: 'auto' }}>
          <SectionRows data={sortObject(userData, userFieldsOrder)} />
        </DividerStack>
      )}
    </PageCard>
  );
};
