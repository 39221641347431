import api from 'api';
import { apiUrl, BACKEND_ADDRESS } from 'api/const';
import { UrlParams } from 'api/types';
import { getBaseUrl } from 'api/utils';
import { BusinessDetails, FetchedBusinessList, Migration } from './types';

export const getBusiness = (myBusiness?: boolean) =>
  api.get<BusinessDetails>(getBaseUrl(myBusiness));

export const getBusinesses = (params: UrlParams) =>
  api.get<FetchedBusinessList>(apiUrl.businessesWithParams(params), {
    baseURL: BACKEND_ADDRESS,
  });

export const migrateBusinesses = async (data: { idsToMigrate: string[] }) =>
  api.post(apiUrl.runMigration, data, { baseURL: BACKEND_ADDRESS });

export const getMigrationQueue = async () =>
  api.get<Migration[]>(apiUrl.migrationQueue, { baseURL: BACKEND_ADDRESS });
