import { CountryCode } from 'libphonenumber-js';
import createVatIdMask from 'text-mask-vat-id';

export const customValidationCountries = ['BG', 'CZ', 'GB', 'IE', 'LT', 'RO'];

const getCustomVatValidation = (
  value?: string,
): Partial<Record<CountryCode, Array<string | RegExp>>> => ({
  BG: new Array(10).fill(/\d/),
  CZ: new Array(10).fill(/\d/),
  GB:
    value && value[0].match(/[a-zA-Z]/)
      ? new Array(5).fill(/[A-Za-z0-9]/)
      : [/[a-zA-Z0-9]/, ...new Array(11).fill(/\d/)],
  IE: new Array(8).fill(/[A-Za-z0-9]/),
  LT: new Array(12).fill(/\d/),
  RO: new Array(10).fill(/\d/),
});

const createInitVatMask = (vatPrefix: string, withPrefix: boolean, value?: string) => {
  if (customValidationCountries.includes(vatPrefix)) {
    const initMask = getCustomVatValidation(value)[vatPrefix as CountryCode] || [];
    return withPrefix ? [/[A-Za-z]/, /[A-Za-z]/, ' ', ...initMask] : initMask;
  }
  const initMask = createVatIdMask({ localeFormat: true })(vatPrefix);
  return withPrefix ? initMask : initMask.slice(2);
};

export const getVatMask = (vatPrefix: string, withPrefix = false, value?: string) => {
  // check empty string
  if (!vatPrefix) return false;

  const mask = createInitVatMask(vatPrefix, withPrefix, value);
  const formattedMask = mask[0] === ' ' ? mask.slice(1) : mask;
  return isMask(formattedMask, withPrefix) ? formattedMask : false;
};

const isMask = (mask: (string | RegExp)[], withPrefix: boolean) => {
  if (
    (mask.length === 1 && String(mask[0]) === String(/\w|\d/)) ||
    (withPrefix && mask.length === 4 && String(mask[3]) === String(/\w|\d/))
  ) {
    return false;
  }
  return true;
};
