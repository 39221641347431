import { UseFormReturn } from 'react-hook-form';
import { normalizeString } from 'utils/string';
import { AddressFormInterface } from 'api/common/types';
import { RentalConditions } from 'api/dossier/types';
import { MissingField } from 'api/missingData/types';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { InputField } from 'components/controlledInputs/types';
import { MissingDataForm } from '../types';

export const getRequiredAndNotRequiredFields = <T extends object>(
  missingFields: MissingField[],
  fields: InputField<T>[],
) => {
  const requiredFields = fields.filter(({ name }) =>
    missingFields.find(
      ({ propertyName, required }) =>
        propertyName.toLowerCase() === name.toLowerCase() && required,
    ),
  );

  const notRequiredFields = fields.filter(({ name }) =>
    missingFields.find(
      ({ propertyName, required }) =>
        propertyName.toLowerCase() === name.toLowerCase() && !required,
    ),
  );

  return { requiredFields, notRequiredFields };
};

export const getRentalConditionsFields = (
  missingFields: MissingField[],
  inputFields: InputField<RentalConditions>[],
) => {
  const { notRequiredFields, requiredFields } = getRequiredAndNotRequiredFields(
    missingFields,
    inputFields,
  );

  if (
    requiredFields.some(({ name }) => name === 'duration') &&
    !requiredFields.find(({ name }) => name === 'durationNumberOfMonths')
  ) {
    const durationNumberOfMonthsField = inputFields.find(
      ({ name }) => name === 'durationNumberOfMonths',
    );

    if (durationNumberOfMonthsField) {
      const durationIndex = requiredFields.findIndex(({ name }) => name === 'duration');
      const fields = [
        ...requiredFields.slice(0, durationIndex + 1),
        durationNumberOfMonthsField,
        ...requiredFields.slice(durationIndex + 1),
      ];
      return { requiredFields: fields, notRequiredFields };
    }
  }

  return { requiredFields, notRequiredFields };
};

export const getNaturalPersonsFields = (
  missingFields: MissingField[],
  inputFields: InputField<NaturalPersonFormData>[],
  prefix: string,
) => {
  let { notRequiredFields, requiredFields } = getRequiredAndNotRequiredFields(
    missingFields,
    inputFields,
  );

  if (requiredFields.some(({ name }) => name === 'taxResidences')) {
    const [taxResidences] = requiredFields.filter(({ name }) => name === 'taxResidences');
    requiredFields = requiredFields.filter((field) => field.name !== 'taxResidences');
    requiredFields.unshift({
      ...taxResidences,
      componentProps: { prefix, isRequired: true },
    });
  }

  if (notRequiredFields.some(({ name }) => name === 'taxResidences')) {
    const [taxResidences] = notRequiredFields.filter(
      ({ name }) => name === 'taxResidences',
    );
    notRequiredFields = notRequiredFields.filter(
      (field) => field.name !== 'taxResidences',
    );
    notRequiredFields.unshift({
      ...taxResidences,
      componentProps: { prefix, isRequired: false },
    });
  }

  return { requiredFields, notRequiredFields };
};

export const getMissingRequiredFields = <T extends keyof MissingDataForm>(
  errors: NonNullable<UseFormReturn<MissingDataForm>['formState']['errors'][T]> = {},
  fields: MissingField[],
) => {
  const fieldExists = (key: string) => {
    return !!fields.find(({ propertyName }) =>
      key === 'vat'
        ? key.toUpperCase() === propertyName.toUpperCase()
        : normalizeString(key) === normalizeString(propertyName),
    );
  };

  return Object.keys(errors).filter((key) => {
    if (key === 'address' && 'address' in errors) {
      return !Object.keys(errors.address ?? {}).some(fieldExists);
    }

    return !fieldExists(key);
  });
};

export const isAnyAddressFieldMissing = (
  fields: MissingField[],
  addressFields: InputField<AddressFormInterface>[],
) =>
  fields.some(
    ({ propertyName }) =>
      !!addressFields.find(
        ({ name }) => normalizeString(name) === normalizeString(propertyName),
      ),
  );
