import { useState } from 'react';
import { useFilters } from 'templates/Table/hooks';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { TextInput } from 'components/inputs';
import { TranslatedTableColumn } from '../types';

const TableTextFilter = ({
  field,
  filterName = field,
  filterLabel,
}: TranslatedTableColumn) => {
  const { onFilterChange, initState } = useFilters(filterName);

  const [input, setInput] = useState(initState);
  const debouncedInput = useDebounce(input, 500);

  useUpdateEffect(() => {
    onFilterChange(debouncedInput);
  }, [debouncedInput]);

  return (
    <TextInput
      hideHelperTextGap
      placeholder={filterLabel}
      value={input}
      onChange={(e) => setInput(e.target.value)}
      clearable
      onClear={() => {
        onFilterChange('');
        setInput('');
      }}
    />
  );
};

export default TableTextFilter;
