import { Grid } from '@mui/material';
import { NumberInput } from './NumberInput';
import { Select } from './Select';
import { PaymentInputProps } from './types';

export const PaymentInput = ({
  value = '',
  currency = 'EUR',
  options = [],
  hideHelperTextGap,
  onValueChange,
  onCurrencyChange,
  ...props
}: PaymentInputProps) => (
  <Grid container spacing={0.5} justifyContent="space-between">
    <Grid item width={options ? 'calc(100% - 75px)' : '100%'}>
      <NumberInput
        name="payment_value"
        onChange={onValueChange}
        value={value ?? ''}
        hideHelperTextGap={hideHelperTextGap}
        {...props}
      />
    </Grid>
    <Grid item width={75}>
      <Select
        options={options}
        name="payment_currency"
        value={currency}
        onChange={onCurrencyChange}
        hideHelperTextGap={hideHelperTextGap}
        disabled={props.disabled}
      />
    </Grid>
  </Grid>
);
