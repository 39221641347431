export const DashboardPermissions = {
  Read: 'BusinessData',
  Write: 'BusinessData',
} as const;

export const EstatePermissions = {
  Read: 'BusinessData',
  Write: 'BusinessData',
} as const;

export const PersonPermissions = {
  Read: 'BusinessData',
  Write: 'BusinessData',
} as const;

export const DossierPermissions = {
  Read: 'BusinessData',
  Write: 'BusinessData',
} as const;

export const UserPermissions = {
  Read: 'UsersDataRead',
  Write: 'UsersDataWrite',
} as const;

export const BackofficePermissions = {
  Read: 'BackofficeData',
  Write: 'BackofficeData',
} as const;
