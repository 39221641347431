import { useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import residentsSchema from 'formData/dossier/residents/schema';
import { DossierCardProps } from 'modules/DossierView/types';
import PageCard from 'templates/PageCard';
import SectionRows from 'templates/SectionRows';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { resolver } from 'utils/yup';
import { updateResidents } from 'api/dossier/requests';
import { ResidentsForm } from 'api/dossier/types';
import ResidentsEdition from './ResidentsEdition';
import { getResidentsDefaultValues, transformChildrenAge } from './utils';

const ResidentsCard = ({
  dossierId,
  dossierData: { residents, status },
  fetchRentalDossier,
}: DossierCardProps) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const methods = useForm<ResidentsForm>({
    resolver: resolver(residentsSchema),
    defaultValues: getResidentsDefaultValues(residents),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const toggleEditing = () => setEditing((prev) => !prev);

  const submitAction: SubmitHandler<ResidentsForm> = async (data) => {
    setSaving(true);
    const { ok } = await updateResidents(dossierId, data);
    if (ok) {
      await fetchRentalDossier();
      reset(data);
      toggleEditing();
    }
    setSaving(false);
  };

  const cancelEditing = () => {
    reset();
    toggleEditing();
  };
  const editable = useMemo(() => shouldDossierFeatureBeActive(status), [status]);

  return (
    <PageCard
      blank={!residents?.numberOfAdults && !residents?.numberOfChildren && !editing}
      tKey="dossierDetails"
      headerProps={{
        titleProps: { translationValues: { context: 'residents' } },
        editionProps: {
          hidden: !editable,
          isEditing: editing,
          saving,
          saveDisabled: !isDirty,
          onEdit: toggleEditing,
          onSave: handleSubmit(submitAction),
          onCancel: cancelEditing,
        },
      }}
    >
      {editing ? (
        <FormProvider {...methods}>
          <ResidentsEdition />
        </FormProvider>
      ) : (
        <SectionRows
          data={{
            numberOfAdults: residents?.numberOfAdults,
            numberOfChildren: residents?.numberOfChildren,
            ...transformChildrenAge(residents?.childrenDateOfBirth),
          }}
        />
      )}
    </PageCard>
  );
};

export default ResidentsCard;
