import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Button from 'atoms/Button';
import TranslationField from 'modules/TranslationField';
import { TextInput } from 'components/inputs';
import { useComposition } from './useComposition';

type Props = ReturnType<typeof useComposition>['newFieldInputProps'];

export const NewFieldInput = ({ addItem, onInputChange, input, errorMessage }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack
      component="form"
      onSubmit={addItem}
      width="100%"
      direction="row"
      alignItems="flex-start"
      columnGap={2}
      pt={4}
      pb={2}
    >
      <TranslationField
        render={(lng) => (
          <TextInput
            placeholder={t('newCompositionField', { ns: 'formFields' })}
            onChange={onInputChange(lng)}
            helperText={errorMessage}
            error={!!errorMessage}
            value={input[lng]}
            sx={{ mt: 1 }}
          />
        )}
      />
      <Button
        type="submit"
        disabled={!Object.values(input).some(Boolean)}
        sx={{ mt: 7.25 }}
        size="small"
      >
        {t('add')}
      </Button>
    </Stack>
  );
};
