import { useContext, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
} from '@mui/material';
import { ProductContext } from 'contexts/product/productContext';
import useDocumentsModal from 'hooks/useDocumentsModal';
import useProductStage from 'hooks/useProductStage';
import { HourglassIcon } from 'icons';
import { attachDocument, getDocumentLabel, mapMissingDocuments } from 'utils/products';
import getMissingFieldsLabel from 'utils/products/getMissingFieldsLabel';
import { ProductStageInterface } from 'api/dossier/products/types';
import { DocumentsModal, ProductActionModal } from 'components/modals';
import { ProductActionModalData } from 'components/modals/ProductActionModal/types';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import ProductStageRequirements from './ProductStageRequirements';
import ProductStageTitle from './ProductStageTitle';
import { getElementContentStyles, getElementStatusStyles } from './styles';
import { ProductRequirement } from './types';

const ProductStage = ({
  name,
  isCompleted,
  isAvailable,
  requiredDocuments,
  httpMethod,
  link,
  missingFields,
}: ProductStageInterface) => {
  const [actionData, setActionData] = useState<ProductActionModalData | null>(null);

  const { closeModal, isOpen, openModal } = useDocumentsModal();
  const { fetchProduct, productId, productName, chosenParties, reportedErrors } =
    useContext(ProductContext);
  const {
    dossierData,
    dossierId,
    rentalConditions,
    fetchRentalDossier,
    fetchRentalConditions,
  } = useOutletContext<DossierOutletContext>();

  const missingDocuments = useMemo(
    () => requiredDocuments?.filter(({ isCompleted }) => !isCompleted) ?? [],
    [requiredDocuments],
  );

  const requirements: ProductRequirement[] = useMemo(() => {
    switch (name) {
      case 'UploadDocuments':
        return (
          requiredDocuments?.map((document) => ({
            name: getDocumentLabel(document),
            isCompleted: document.isCompleted,
          })) ?? []
        );
      case 'RentalDepositFillMissingData':
      case 'RentalContractFillMissingData':
        return (
          missingFields?.map((field) => ({
            name: getMissingFieldsLabel(field),
            isCompleted: field.isCompleted,
          })) ?? []
        );
      default:
        return [];
    }
  }, [requiredDocuments, missingFields]);

  const handleStageAction = () => {
    switch (name) {
      case 'UploadDocuments':
        return openModal();
      default: {
        setActionData({
          productId,
          productName,
          name,
          httpMethod,
          link,
          reportedErrors,
        });
      }
    }
  };

  const { isStageAwaitingForExternalAction } = useProductStage(name);

  const isActionDisabled = useMemo(
    () => !isAvailable || isStageAwaitingForExternalAction,
    [isAvailable, isStageAwaitingForExternalAction],
  );

  const submitStageAction = async () => {
    await fetchProduct();
    await fetchRentalDossier();
    await fetchRentalConditions();
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={1} className="flow-tree-element">
        <Box
          sx={getElementStatusStyles(
            isAvailable,
            isCompleted,
            isStageAwaitingForExternalAction,
          )}
        >
          <Box className="status-box-top" />
          <Avatar>
            <IconButton disabled={isActionDisabled} onClick={handleStageAction}>
              <StatusIcon
                isCompleted={isCompleted}
                isStageAwaitingForExternalAction={isStageAwaitingForExternalAction}
              />
            </IconButton>
          </Avatar>
          <Box className="status-box-bottom" />
        </Box>
        <Card
          sx={getElementContentStyles(
            isAvailable,
            isCompleted,
            isStageAwaitingForExternalAction,
          )}
          elevation={0}
        >
          <Box className="content-indicator" />
          <CardActionArea disabled={isActionDisabled} onClick={handleStageAction}>
            <CardContent sx={{ height: '100%' }}>
              <ProductStageTitle name={name} isAvailable={isAvailable} />
              {!!requirements.length &&
                (isAvailable || (!isAvailable && isCompleted)) && (
                  <ProductStageRequirements requirements={requirements} />
                )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      {isOpen && missingDocuments.length ? (
        <DocumentsModal
          onClose={closeModal}
          onSubmit={fetchProduct}
          missingDocuments={mapMissingDocuments(missingDocuments)}
          onSingleDocumentSave={attachDocument(dossierId, missingDocuments)}
        />
      ) : null}
      {actionData && (
        <ProductActionModal
          actionData={actionData}
          dossier={{ ...dossierData, dossierId, rentalConditions }}
          onSubmit={submitStageAction}
          onClose={() => setActionData(null)}
          chosenParties={chosenParties}
        />
      )}
    </>
  );
};

const StatusIcon = ({
  isCompleted,
  isStageAwaitingForExternalAction,
}: {
  isCompleted: boolean;
  isStageAwaitingForExternalAction: boolean;
}) => {
  if (isCompleted) {
    return <CheckIcon fontSize="inherit" />;
  }
  if (isStageAwaitingForExternalAction) {
    return <HourglassIcon fontSize="inherit" />;
  }
  return null;
};

export default ProductStage;
