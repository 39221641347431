import { firstLetterUppercaseInputConfig, sortFields } from 'formData/utils';
import { UseAddressData } from 'hooks/useAddressFields/types';
import { TKey } from 'i18next';
import getLng from 'utils/getLng';
import { AddressFormInterface } from 'api/common/types';
import { InputField } from 'components/controlledInputs/types';
import { addressOrder } from './order';
import { BrusselCity } from './static-data';

const staticAddressFields: InputField<AddressFormInterface>[] = [
  {
    name: 'houseNumber',
    type: 'number',
    isInteger: true,
    sizes: { xs: 4, sm: 4 },
  },
  {
    name: 'extension',
    mask: [/[A-Za-z]/],
    placeholder: 'A',
    sizes: { xs: 4, sm: 4 },
  },
  {
    name: 'apartmentNumber',
    sizes: { xs: 4, sm: 4 },
  },
];

export const getDynamicAddressFields = (
  {
    isBelgiumSelected,
    cityField,
    countryField,
    postCodeField,
    streetField,
  }: ReturnType<UseAddressData>,
  disableCountrySelection?: boolean,
  regionChanged?: boolean,
): InputField<AddressFormInterface>[] => {
  const hideStreetTranslation =
    !isBelgiumSelected ||
    cityField.currentOption?.translatedCity !== BrusselCity[getLng()];

  return sortFields(
    [
      ...staticAddressFields,
      {
        name: 'country',
        disabled: disableCountrySelection,
        sizes: { sm: 12 },
        ...countryField,
      },
      {
        name: 'street',
        onInputChange: streetField.onInputChange,
        sizes: { xs: 12, sm: hideStreetTranslation ? 12 : 6 },
        ...firstLetterUppercaseInputConfig,
      },
      {
        name: 'streetTranslation',
        ...firstLetterUppercaseInputConfig,
        hidden: hideStreetTranslation,
      },
      {
        name: 'postCode',
        type: isBelgiumSelected ? 'number' : 'text',
        searchInputProps: isBelgiumSelected ? postCodeField : undefined,
        ...(regionChanged && {
          warningKey: 'regionChanged' as TKey<'common', 'warnings'>,
        }),
      },
      {
        name: 'city',
        ...(isBelgiumSelected
          ? {
              searchInputProps: cityField,
            }
          : firstLetterUppercaseInputConfig),
      },
    ],
    addressOrder,
  );
};
