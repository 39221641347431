import { UrlParams } from './types';
import { getSearchParamsUrl } from './utils';

export const SAMPLE_BUSINESS_ID = 'FF29E44D-3CB2-401E-8F22-A9093F53612D';
export const BACKEND_ADDRESS =
  process.env.NODE_ENV === 'test' ? '' : window._env_.REACT_APP_BACKEND_URL;
export const MY_KORFINE_COUNTRY_SHEET_URL = window._env_.MY_KORFINE_COUNTRY_SHEET_URL;

export const apiUrl = {
  //ESTATES
  estates: 'estates',
  estatesWithParams: (params: UrlParams) =>
    getSearchParamsUrl('estates', { ...params, statuses: 'Active,Archived' }),
  estate: (estateId: string) => `estates/${estateId}`,
  estateDocuments: (estateId: string) => `estates/${estateId}/documents`,
  estateDocument: (estateId: string, documentId: number) =>
    `estates/${estateId}/documents/${documentId}`,
  estateDetails: (estateId: string) => `estates/${estateId}/details`,
  estatePhoto: (estateId: string) => `estates/${estateId}/photo`,
  estateDocumentFile: (estateId: string, documentId: number, fileId: number) =>
    `estates/${estateId}/documents/${documentId}/files/${fileId}`,

  //PERSONS
  persons: 'persons',
  personsWithParams: (params: UrlParams) => getSearchParamsUrl('persons', params),

  // NATURAL PERSON
  naturalPersons: 'naturalPersons',
  naturalPerson: (naturalPersonId: string) => `naturalPersons/${naturalPersonId}`,
  naturalPersonDocuments: (naturalPersonId: string) =>
    `naturalPersons/${naturalPersonId}/documents`,
  naturalPersonDocument: (naturalPersonId: string, documentId: number) =>
    `naturalPersons/${naturalPersonId}/documents/${documentId}`,
  naturalPersonDocumentFile: (
    naturalPersonId: string,
    documentId: number,
    fileId: number,
  ) => `naturalPersons/${naturalPersonId}/documents/${documentId}/files/${fileId}`,

  // LEGAL PERSON
  legalPersons: 'legalPersons',
  legalPerson: (legalPersonId: string) => `legalPersons/${legalPersonId}`,
  legalPersonDocuments: (legalPersonId: string) =>
    `legalPersons/${legalPersonId}/documents`,

  legalPersonDocument: (legalPersonId: string, documentId: number) =>
    `legalPersons/${legalPersonId}/documents/${documentId}`,
  legalPersonDocumentFile: (legalPersonId: string, documentId: number, fileId: number) =>
    `legalPersons/${legalPersonId}/documents/${documentId}/files/${fileId}`,

  // PRIVATE RELATIONS
  getPersonPrivateRelation: (naturalPersonId: string) =>
    `/naturalPersons/${naturalPersonId}/privateRelations`,
  addPrivateRelation: '/privateRelations',
  privateRelation: (relationId: string) => `/privateRelations/${relationId}`,

  // REPRESENTATIVE RELATIONS
  addRepresentation: '/representations',
  deleteRepresentation: (relationId: string) => `/representations/${relationId}`,
  getNaturalPersonRepresentatives: (naturalPersonId: string) =>
    `/naturalPersons/${naturalPersonId}/representations`,
  getLegalPersonRepresentatives: (legalPersonId: string) =>
    `/legalPersons/${legalPersonId}/representations`,

  // OWNERSHIPS
  getEstateOwners: (estateId: string) => `/estates/${estateId}/owners`,
  getNaturalPersonEstates: (personId: string) => `/naturalPersons/${personId}/estates`,
  getLegalPersonEstates: (personId: string) => `/legalPersons/${personId}/estates`,
  addOwnership: '/ownerships',
  deleteOwnership: (ownershipId: string) => `/ownerships/${ownershipId}`,

  // DOSSIERS
  dossiersWithParams: (params: UrlParams) => getSearchParamsUrl('dossiers', params),
  rentalDossiersChecklist: '/rentalDossiers/checklist',
  rentalDossiers: '/rentalDossiers',
  rentalDossier: (dossierId: string) => `/rentalDossiers/${dossierId}`,
  rentalDossierStatus: (dossierId: string) => `/rentalDossiers/${dossierId}/status`,
  rentalConditions: (dossierId: string) =>
    `/rentalDossiers/${dossierId}/rentalConditions`,
  residents: (dossierId: string) => `/rentalDossiers/${dossierId}/residents`,
  dossierTodoList: (dossierId: string) => `/rentalDossiers/${dossierId}/toDoList`,

  dossierDocuments: (dossierId: string) => `/rentalDossiers/${dossierId}/documents`,
  dossierDocument: (dossierId: string, documentId: number) =>
    `/rentalDossiers/${dossierId}/documents/${documentId}`,
  rentalDossierDocumentFile: (dossierId: string, documentId: number, fileId: number) =>
    `/rentalDossiers/${dossierId}/documents/${documentId}/files/${fileId}`,
  attachDossierDocument: (dossierId: string) =>
    `/rentalDossiers/${dossierId}/attachments`,
  attachDossierTypeDocument: (dossierId: string, documentId: number) =>
    `/rentalDossiers/${dossierId}/documents/${documentId}/attach`,
  removeDossierDocument: (dossierId: string, documentId: number) =>
    `/rentalDossiers/${dossierId}/attachments/${documentId}`,

  productMissingFields: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/products/${productId}/missingFields`,

  customTodoItems: (dossierId: string) => `/rentalDossiers/${dossierId}/customToDoItems`,
  updateCustomTodoList: (dossierId: string) =>
    `/rentalDossiers/${dossierId}/customToDoItems/order`,
  customTodoItem: (dossierId: string, todoItemId: string) =>
    `rentalDossiers/${dossierId}/customToDoItems/${todoItemId}`,

  rentalDeposit: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/products/${productId}/rentalDeposit`,
  rentalDepositAbolishmentData: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/products/${productId}/rentalDeposit/abolishmentData`,

  rentalContract: (dossierId: string, productId: string) =>
    `rentalDossiers/${dossierId}/products/${productId}/rentalContract`,

  // PRODUCTS
  products: (dossierId: string) => `/rentalDossiers/${dossierId}/products`,
  product: (dossierId: string, productId: string) =>
    `/rentalDossiers/${dossierId}/products/${productId}`,
  productsOrder: (dossierId: string) => `/rentalDossiers/${dossierId}/productsOrder`,

  // USERS
  users: '/users',
  user: (userId: string) => `/users/${userId}`,
  deleteUser: (userId: string) => `/users/${userId}`,
  usersWithParams: (params: UrlParams) => getSearchParamsUrl('users', { ...params }),

  // BUSINESSES
  businessesWithParams: (params: UrlParams) =>
    getSearchParamsUrl('/migrations/getBusinesses', { ...params }),
  // MIGRATION
  runMigration: '/migrations/runBusinessesMigration',
  migrationQueue: '/migrations/migrationQueue',

  //NOTIFICATIONS
  notificationsSummary: '/notifications/notificationsSummary',
  notifications: (params: UrlParams) =>
    getSearchParamsUrl('notifications', { ...params }),
  notificationsClear: '/notifications/clear',
  notificationsClearAll: '/notifications/clearAll',

  // GENERIC
  myMoveRedirection: '/myMove/directLogin',
  checknetRedirection: '/checknet/directLogin',
};
