import { useState } from 'react';
import { useFilters } from 'templates/Table/hooks';
import { TableSidebarFilterComponentProps } from 'templates/Table/types';
import { formatDate } from 'utils/date';
import { DateInput } from 'components/inputs';

interface Props
  extends Pick<TableSidebarFilterComponentProps, 'filterName' | 'updateFilter'> {
  label?: string;
}

const DateFilter = ({ filterName, updateFilter, label }: Props) => {
  const { initState } = useFilters(filterName);
  const [input, setInput] = useState(initState);

  const onInputChange = (date: Date | null) => {
    const value = formatDate(date, 'yyyy-MM-dd') ?? '';
    setInput(value);
    updateFilter(filterName, value);
  };

  return (
    <DateInput label={label} hideHelperTextGap value={input} onChange={onInputChange} />
  );
};

export default DateFilter;
