import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import { BackOfficeContext } from 'contexts/backofficeContext';
import { CancelIcon, InfoIcon } from 'icons';
import { getBusiness } from 'api/businesses/requests';
import { BusinessDetails } from 'api/businesses/types';
import NavItem from './NavItem';
import { lists } from './static-data';
import { impersonatingNavStyle } from './styles';

const BusinessData = ({
  isSidebarExpanded,
  selected,
  select,
  expandSidebar,
}: {
  isSidebarExpanded: boolean;
  selected: string;
  select: (item: string) => () => void;
  expandSidebar: () => void;
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'aside' });

  const { impersonateId, finishImpersonating } = useContext(BackOfficeContext);
  const [businessData, setBusinessData] = useState<BusinessDetails | null>(null);

  const fetchBusinessData = async () => {
    if (impersonateId) {
      const { ok, response } = await getBusiness();
      if (ok) {
        setBusinessData(response);
      }
    }
  };

  useEffect(() => {
    fetchBusinessData();
  }, [impersonateId]);

  const list = lists.mainMenu.filter(({ label }) => label !== 'dashboard');

  return impersonateId ? (
    <List sx={impersonatingNavStyle(isSidebarExpanded)}>
      <ListItem sx={{ minHeight: 56 }}>
        {isSidebarExpanded ? (
          <Stack gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                color={'primary.light'}
                fontWeight={600}
                fontSize={14}
                lineHeight={1}
                sx={{
                  textWrap: 'wrap',
                }}
              >
                {businessData?.name}
              </Typography>
              <CancelIcon
                onClick={() => finishImpersonating()}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {},
                }}
              />
            </Stack>
            <Typography
              fontWeight={500}
              component="a"
              href={`mailto:${businessData?.email}`}
              sx={({ mixins }) => ({ ...mixins.link, color: 'unset' })}
            >
              {businessData?.email}
            </Typography>
          </Stack>
        ) : (
          <Stack width="100%" alignItems="center" onClick={expandSidebar}>
            <InfoIcon fontSize="medium" />
          </Stack>
        )}
      </ListItem>
      <Box>
        {list.map(({ to, label, onClick, ...props }) => (
          <NavItem
            key={`${label}-${to}`}
            to={to}
            label={t(`items.${label}`)}
            isSelected={selected === label}
            select={onClick || select(label)}
            isSidebarExpanded={isSidebarExpanded}
            resetSelection={select('')}
            {...props}
          />
        ))}
      </Box>
    </List>
  ) : null;
};

export default BusinessData;
