import { useOutletContext } from 'react-router-dom';
import { Badge } from '@mui/material';
import TooltipIconButton from 'atoms/TooltipIconButton';
import useDocumentsModal from 'hooks/useDocumentsModal';
import { FileIcon } from 'icons';
import { attachDocument, mapMissingDocuments } from 'utils/products';
import { DocumentsModal } from 'components/modals';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import DocumentButtonTooltip from './DocumentButtonTooltip';
import { ProductCardFooterProps } from './types';

interface Props extends Pick<ProductCardFooterProps, 'missingDocuments'> {
  fetchProduct: () => Promise<void>;
}

const ProductDocumentsAction = ({ fetchProduct, missingDocuments = [] }: Props) => {
  const { dossierId } = useOutletContext<DossierOutletContext>();
  const { isOpen, openModal, closeModal } = useDocumentsModal();

  return (
    <>
      <TooltipIconButton
        onClick={openModal}
        tooltipTitle={<DocumentButtonTooltip missingDocuments={missingDocuments} />}
        Icon={
          <Badge
            color="error"
            sx={{ '.MuiBadge-badge': { lineHeight: 0 } }}
            badgeContent={missingDocuments.length}
          >
            <FileIcon />
          </Badge>
        }
      />
      {isOpen && (
        <DocumentsModal
          missingDocuments={mapMissingDocuments(missingDocuments)}
          onClose={closeModal}
          onSubmit={fetchProduct}
          onSingleDocumentSave={attachDocument(dossierId, missingDocuments)}
        />
      )}
    </>
  );
};

export default ProductDocumentsAction;
