import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { DateInput } from 'components/inputs';
import { ControlledDateInputProps } from './types';
import { getErrorMessage } from './utils';

export const ControlledDateInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledDateInputProps<T, N>,
) => React.ReactElement = ({
  name,
  error,
  helperText,
  warning,
  control,
  onChange: handleChange,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, ref, onChange, ...field } }) => (
      <DateInput
        value={value ?? null}
        onChange={(date) => {
          onChange(date);
          if (handleChange) {
            handleChange(date);
          }
        }}
        error={!!error}
        warning={!!warning}
        inputRef={ref}
        helperText={getErrorMessage(error ?? warning) || helperText}
        {...inputProps}
        {...field}
      />
    )}
  />
);
