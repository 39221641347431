import { createContext, PropsWithChildren } from 'react';
import { ProductChosenParties, ProductName } from 'api/dossier/products/types';

interface ProductContext {
  productId: string;
  productName: ProductName;
  fetchProduct: () => Promise<void>;
  chosenParties: ProductChosenParties<ProductName> | null;
  reportedErrors: string[] | null;
}

const ProductContext = createContext<ProductContext>({
  productId: '',
  productName: '' as ProductName,
  fetchProduct: async () => {},
  chosenParties: null,
  reportedErrors: null,
});

const ProductContextProvider = ({
  children,
  ...props
}: PropsWithChildren<ProductContext>) => (
  <ProductContext.Provider value={props}>{children}</ProductContext.Provider>
);

export { ProductContext, ProductContextProvider };
