import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import LoadingElement from 'atoms/LoadingElement';
import { msalInstance, msalScopes, signOutFromOtherTabs } from 'authConfig';
import StorageKeys from 'consts/storage';
import router from 'router';
import './i18n';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

// if you want to use ms mock server, uncomment below code and import worker
// if (process.env.NODE_ENV === 'development') {
//   worker.start();
// }
declare global {
  interface Window {
    _env_: Record<string, string>;
  }
}
if (process.env.NODE_ENV === 'production' && window._env_.REACT_APP_CONNECTION_STRING) {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: window._env_.REACT_APP_CONNECTION_STRING,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
    },
  });
  appInsights.loadAppInsights();
}

window.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    signOutFromOtherTabs();
  }
});

window.addEventListener('focus', signOutFromOtherTabs);
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
} else {
  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload &&
      'account' in event.payload &&
      event.payload.account
    ) {
      localStorage.setItem(StorageKeys.LoggedId, event.payload.account.localAccountId);
      msalInstance.setActiveAccount(event.payload.account);
    }
  });
}

window.addEventListener('focus', () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    msalInstance.acquireTokenRedirect({
      scopes: msalScopes,
    });
  } else {
    msalInstance
      .acquireTokenSilent({
        scopes: msalScopes,
        account,
      })
      .catch(() =>
        msalInstance.acquireTokenRedirect({
          scopes: msalScopes,
        }),
      );
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MsalProvider instance={msalInstance}>
    <RouterProvider router={router} fallbackElement={<LoadingElement />} />
    <ToastContainer />
  </MsalProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
