import { ChangeEvent, useState } from 'react';
import { ControlledTextInput } from 'components/controlledInputs';
import { compareEmails } from './utils';

export const EmailField: typeof ControlledTextInput = ({ onChange, ...props }) => {
  const [warning, setWarning] = useState<ReturnType<typeof compareEmails>>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checkDomain = compareEmails(e.target.value);
    setWarning(checkDomain);
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <ControlledTextInput
      onChange={handleChange}
      warning={
        warning
          ? {
              message: { key: warning.message, values: { emails: warning.emails || '' } },
            }
          : undefined
      }
      {...props}
    />
  );
};
