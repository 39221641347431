import i18n from 'i18n';
import { getPersons } from 'api/person/requests';
import { SearchInputOption } from 'components/inputs/types';
import { SearchInputData, SearchOptionsParams } from './types';

export const filterOptions = <T extends SearchInputData>(
  options: T[],
  contextOptions: T[],
  optionsToFilter: string[],
  query: string,
  loading: boolean,
): T[] => {
  const filteredContextOptions = contextOptions.filter(
    ({ id, label }) =>
      !optionsToFilter.includes(id) && label.toLowerCase().includes(query.toLowerCase()),
  );

  const filteredOptions = options.filter(
    ({ id }) =>
      !contextOptions.find((option) => option.id === id) && !optionsToFilter.includes(id),
  );

  if (loading) {
    return [
      ...filteredContextOptions,
      { id: '', value: '', label: i18n.t('selectInput.loadingText.default') } as T,
    ];
  }

  return [...filteredContextOptions, ...filteredOptions];
};

export const groupOptions = <T extends SearchInputData>(contextOptions: T[]) =>
  !contextOptions.length
    ? undefined
    : (option: SearchInputOption) =>
        typeof option.subHeader === 'string'
          ? option.subHeader
          : i18n.t('searchGroup.searchResults');

export const fetchPersonOptions = async ({ query, ...params }: SearchOptionsParams) => {
  const { ok, response } = await getPersons({
    limit: 25,
    name: query,
    ...params,
  });
  if (ok) {
    return response.data;
  }
  return [];
};
