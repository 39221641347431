import StorageKeys from 'consts/storage';

export const getPermissions = () => {
  const storedPermissions = localStorage.getItem(StorageKeys.Permissions);
  return storedPermissions ? JSON.parse(storedPermissions) : [];
};

/**
 * @returns true when logged user has provided permission
 */
const hasPermission = (permission: string) => {
  const permissions = getPermissions();
  return permissions.includes(permission);
};

export default hasPermission;
