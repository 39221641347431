import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { EstatePermissions } from 'consts/permissions';
import { Documents } from 'modules/DocumentsCard';
import hasPermission from 'utils/hasPermission';
import { EstateOutletContext } from '../types';

const EstateDocumentsTab = () => {
  const { estateId } = useOutletContext<EstateOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} marginBottom="auto">
        <Documents
          entityId={estateId}
          scope="estate"
          editable={hasPermission(EstatePermissions.Write)}
        />
      </Grid>
    </Grid>
  );
};

export default EstateDocumentsTab;
