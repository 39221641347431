import { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import { releaseRentalDepositSchema } from 'formData/dossier/products/rentalDeposit/schema';
import { resolver } from 'utils/yup';
import {
  ProductActionContent,
  ProductActionEstateInfo,
} from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import RentalDepositPartiesDetails from '../RentalDepositPartiesDetails';
import { RentalDepositData } from '../types';
import { fetchRentalDepositDetails } from '../utils';
import DepositDistributionSection from './DepositDistributionSection';
import ReleaseDocumentsInfo from './ReleaseDocumentsInfo';
import { AbolishmentForm } from './types';
import { formatAbolishmentData, formatAbolishmentValues } from './utils';

const RentalDepositAmicableRelease = ({
  sendRequest,
  setDisableSubmitButton,
  setLoading,
  dossier: { dossierId, rentalConditions, estate },
  product: { productId },
}: ProductActionComponentProps) => {
  const [data, setData] = useState<RentalDepositData | null>(null);

  const methods = useForm<AbolishmentForm>({
    //TODO: temporarily, abolishmentReason for amicable settlement is displayed without option to be compatible with current version of extranet/4D API;
    //right after it is changed to new version change defaultValues to releaseRentalDepositSchema.getDefault(),
    defaultValues: {
      ...releaseRentalDepositSchema.getDefault(),
      abolishmentReason: 'identicalParties',
    },
    resolver: resolver(releaseRentalDepositSchema),
    context: { depositValue: rentalConditions?.depositAmount?.value },
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const details = await fetchRentalDepositDetails(dossierId, productId);

    // TODO: temporarily - when AbolishmentReason will be available, delete below two lines
    const values = formatAbolishmentValues(details, 'identicalParties');
    methods.reset({ ...methods.getValues(), ...values });

    setData(details);
    setLoading(false);
  }, [dossierId, productId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit: SubmitHandler<AbolishmentForm> = (data) => {
    sendRequest(formatAbolishmentData(data));
  };

  const abolishmentReason = useWatch({
    control: methods.control,
    name: 'abolishmentReason',
  });

  const depositDistribution = useWatch({
    control: methods.control,
    name: 'depositDistribution',
  });

  const deceasedPersonReference = useWatch({
    control: methods.control,
    name: 'deceasedPersonReference',
  });

  useEffect(() => {
    setDisableSubmitButton(!abolishmentReason || !depositDistribution);
  }, [abolishmentReason, depositDistribution]);

  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      {data ? (
        <Stack gap={2}>
          <ProductActionEstateInfo estate={estate} />
          <RentalDepositPartiesDetails data={data} />
          <FormProvider {...methods}>
            {/*//TODO: temporarily, abolishmentReason for amicable settlement is displayed without option to be compatible with current version of extranet/4D API; 
              right after it is changed to new version uncomment code below */}
            {/* <AbolishmentReasonsSection selectedReason={abolishmentReason} data={data} /> */}
            {abolishmentReason && (
              <ReleaseDocumentsInfo
                data={data}
                selectedReason={abolishmentReason}
                deceasedPersonReference={deceasedPersonReference}
              />
            )}
            <DepositDistributionSection
              abolishmentReason={abolishmentReason}
              depositDistribution={depositDistribution}
            />
          </FormProvider>
        </Stack>
      ) : null}
    </ProductActionContent>
  );
};

export default RentalDepositAmicableRelease;
