import { normalizeString } from 'utils/string';
import { EstateDestination, EstateSubType, EstateType } from 'api/estate/types';
import { SelectOption } from 'components/inputs/types';

export const createDestinationOptions = (
  destinations: EstateDestination[],
): SelectOption[] =>
  destinations.map((destination) => ({
    value: destination,
    label: normalizeString(destination),
    translationKey: `estateDestination.${destination as Exclude<EstateDestination, ''>}`,
  }));

export const createTypeOptions = (types: NonNullable<EstateType>[]): SelectOption[] =>
  types.map((type) => ({
    value: type,
    label: normalizeString(type),
    translationKey: `estateType.${type}`,
  }));

export const createSubTypeOptions = (
  subTypes: NonNullable<EstateSubType>[],
): SelectOption[] =>
  subTypes.map((subType) => ({
    value: subType,
    label: normalizeString(subType),
    translationKey: `estateSubtype.${subType}`,
  }));
