import { TKey } from 'i18next';
import { formatDate } from 'utils/date';
import { isKeyInObject } from 'utils/object';
import { DocumentData, DocumentScope } from 'api/documents/types';
import { updateDossierDocument } from 'api/dossier/requests';
import { updateEstateDocument } from 'api/estate/requests';
import { updateLegalPersonDocument } from 'api/person/legal/requests';
import { updateNaturalPersonDocument } from 'api/person/natural/requests';
import { DocumentsTableForm, DocumentUpdate } from './types';

export const updateDocument: DocumentUpdate = {
  estate: updateEstateDocument,
  naturalPerson: updateNaturalPersonDocument,
  legalPerson: updateLegalPersonDocument,
  dossier: updateDossierDocument,
};

export const saveEditedDocument = (
  {
    documentId,
    entityId,
    scope,
  }: { documentId: number; entityId: string; scope: DocumentScope },
  { date, ...newData }: DocumentsTableForm,
) => {
  const data = {
    ...newData,
    date: date ? formatDate(new Date(date), 'yyyy-MM-dd') : null,
  };
  return updateDocument[scope](entityId, documentId, data);
};

export const getDocumentFieldValue = (
  name: TKey<'table', 'documents.columns'>,
  document: DocumentData,
) => {
  const value = isKeyInObject(name, document) ? document[name] : null;
  return typeof value === 'string' ? value : null;
};
