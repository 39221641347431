import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PersonPermissions } from 'consts/permissions';
import RepresentationCard from 'modules/RepresentationCard';
import hasPermission from 'utils/hasPermission';
import { LegalPersonOutletContext } from '../types';

const LegalPersonContactsTab = () => {
  const {
    legalPersonData,
    legalPersonId,
    fetchRepresentatives,
    representatives,
    loadingRepresentatives,
  } = useOutletContext<LegalPersonOutletContext>();

  return (
    <Grid container spacing={3} marginBottom="auto">
      <Grid item xs={12}>
        <RepresentationCard
          personId={legalPersonId}
          personName={legalPersonData.name}
          personType="LegalPerson"
          editable={hasPermission(PersonPermissions.Write)}
          shouldFetchOnMount={false}
          fetchRepresentations={fetchRepresentatives}
          representations={representatives}
          loadingRepresentations={loadingRepresentatives}
        />
      </Grid>
    </Grid>
  );
};

export default LegalPersonContactsTab;
