import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import DividerStack from 'atoms/DividerStack';
import { addressOrder } from 'formData/common/address/order';
import { legalPersonOrder, legalPersonSchema } from 'formData/legalPerson';
import { LegalPersonIcon } from 'icons';
import PageCard from 'templates/PageCard';
import SectionRows from 'templates/SectionRows';
import getLng from 'utils/getLng';
import { isTruthyValueInObject, sortObject } from 'utils/object';
import setValidationErrors from 'utils/setValidationErrors';
import { resolver } from 'utils/yup';
import { updateLegalPerson } from 'api/person/legal/requests';
import { LegalPersonFormData } from 'api/person/legal/types';
import { LegalPersonOutletContext } from 'pages/Persons/LegalPersons/Details/types';
import LegalPersonDetailsEdition from './LegalPersonDetailsEdition';
import { getLegalPersonDefaultValues, getLegalPersonDisplayData } from './utils';

const LegalPersonDetailsCard = ({
  legalPersonData,
  legalPersonId,
  fetchLegalPersonData,
}: Pick<
  LegalPersonOutletContext,
  'legalPersonData' | 'legalPersonId' | 'fetchLegalPersonData'
>) => {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const { i18n } = useTranslation();

  const methods = useForm<LegalPersonFormData>({
    mode: 'onChange',
    resolver: resolver(legalPersonSchema),
  });

  const {
    formState: { isDirty, errors },
    reset,
    setError,
    trigger,
    handleSubmit,
  } = methods;

  useEffect(() => {
    const defaultValues = getLegalPersonDefaultValues(legalPersonData);
    reset(defaultValues, { keepDirty: false });
  }, []);

  const onSave: SubmitHandler<LegalPersonFormData> = async (data) => {
    setSaving(true);
    const res = await updateLegalPerson(legalPersonId, data);
    if (res.ok) {
      await fetchLegalPersonData();
      setEditing(false);
      reset(data);
    } else {
      setValidationErrors(res.validation, setError);
    }
    setSaving(false);
  };

  const onEdit = () => {
    trigger();
    setEditing(true);
  };

  const onCancel = () => {
    reset();
    setEditing(false);
  };

  const { address, details, general } = useMemo(
    () => getLegalPersonDisplayData(legalPersonData, getLng(i18n)),
    [legalPersonData, i18n.language],
  );

  return (
    <PageCard
      headerProps={{
        variant: 'light',
        size: 'large',
        titleProps: {
          title: legalPersonData.name,
          startIcon: <LegalPersonIcon />,
        },
        editionProps: {
          isEditing: editing,
          saving,
          saveDisabled: !isDirty || !!Object.keys(errors ?? {}).length,
          onEdit,
          onCancel,
          onSave: handleSubmit(onSave),
        },
      }}
    >
      <Box>
        {editing ? (
          <FormProvider {...methods}>
            <LegalPersonDetailsEdition />
          </FormProvider>
        ) : (
          <DividerStack>
            <SectionRows
              data={sortObject(general, legalPersonOrder)}
              translateValue={{ language: `enums:language.${general.language}` }}
            />
            <SectionRows
              data={sortObject(address, addressOrder)}
              translationPrefix="address"
            />
            {isTruthyValueInObject(details) && (
              <SectionRows
                data={sortObject(details, legalPersonOrder)}
                translateOptions={{ vat: { context: 'field' } }}
              />
            )}
          </DividerStack>
        )}
      </Box>
    </PageCard>
  );
};

export default LegalPersonDetailsCard;
