import { ComponentProps, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import Button from 'atoms/Button';
import { ProductContext } from 'contexts/product/productContext';
import { ProductActionInterface } from 'api/dossier/products/types';
import { ProductActionModal } from 'components/modals';
import { ProductActionModalData } from 'components/modals/ProductActionModal/types';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';

interface ProductActionProps {
  action: ProductActionInterface;
  buttonProps: Omit<ComponentProps<typeof Button>, 'disabled' | 'onClick'>;
}

const ProductAction = ({
  action: { name, httpMethod, link, isAvailable },
  buttonProps,
}: ProductActionProps) => {
  const [actionData, setActionData] = useState<ProductActionModalData | null>(null);

  const { t } = useTranslation('dossierProducts');
  const { fetchProduct, productId, productName, chosenParties, reportedErrors } =
    useContext(ProductContext);
  const {
    dossierData,
    dossierId,
    rentalConditions,
    fetchRentalConditions,
    fetchRentalDossier,
  } = useOutletContext<DossierOutletContext>();

  const handleAction = () => {
    setActionData({ httpMethod, name, link, productId, productName, reportedErrors });
  };

  const submitStageAction = async () => {
    await fetchProduct();
    await fetchRentalDossier();
    await fetchRentalConditions();
  };

  return (
    <>
      <Button disabled={!isAvailable} onClick={handleAction} {...buttonProps}>
        {t(`${productName}.actions.${name}`, name)}
      </Button>
      {actionData && (
        <ProductActionModal
          actionData={actionData}
          dossier={{ ...dossierData, dossierId, rentalConditions }}
          onClose={() => setActionData(null)}
          onSubmit={submitStageAction}
          chosenParties={chosenParties}
        />
      )}
    </>
  );
};

export default ProductAction;
