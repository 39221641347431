import { SearchOptionsParams } from 'hooks/useSearchInput/types';
import { fetchPersonOptions } from 'hooks/useSearchInput/utils';
import { LegalPersonIcon, NaturalPersonIcon } from 'icons';
import transformName from 'utils/transformName';
import { PersonInDossier } from 'api/dossier/types';
import { PartyOption, ProductParty } from './types';

export const fetchPartyOptions = async (
  params: SearchOptionsParams,
  subHeader: string,
): Promise<PartyOption[]> => {
  const persons = await fetchPersonOptions(params);
  return persons.map((person) => ({
    value: person.id,
    label: transformName(person),
    subHeader,
    personId: person.id,
    Icon: person.type === 'NaturalPerson' ? NaturalPersonIcon : LegalPersonIcon,
    ...person,
  }));
};

export const formatPersonOptions = (
  persons: PersonInDossier[],
  subHeader: string,
  hideLegalPerson = true,
): PartyOption[] =>
  persons
    .filter(({ type }) => (hideLegalPerson ? type === 'NaturalPerson' : true))
    .map(({ personId, ...person }) => ({
      id: personId,
      value: personId,
      label: transformName(person),
      subHeader,
      personId,
      Icon: person.type === 'NaturalPerson' ? NaturalPersonIcon : LegalPersonIcon,
      ...person,
    }));

export const getRelation = (
  primaryContact: ProductParty,
  secondaryContact: ProductParty,
) => {
  if (primaryContact.partnerId === secondaryContact.personId) {
    return primaryContact.relation;
  }
  if (
    !primaryContact.hasPrivateRelation &&
    !secondaryContact.hasPrivateRelation &&
    primaryContact.type === 'NaturalPerson'
  ) {
    return 'Other';
  }
  return null;
};
