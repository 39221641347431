import { Grid } from '@mui/material';
import PersonRelations from 'modules/PersonForm/PersonRelations';
import { UploadDocumentsWithContext } from 'modules/UploadDocuments';

interface Props {
  naturalPersonId: string;
}

const RelationAndDocumentsStep = ({ naturalPersonId }: Props) => (
  <Grid container marginBottom="auto" rowSpacing={4} width={{ lg: 1024 }} maxWidth={1024}>
    <PersonRelations personType="NaturalPerson" />
    <Grid item xs={12}>
      <UploadDocumentsWithContext entityId={naturalPersonId} scope="naturalPerson" />
    </Grid>
  </Grid>
);

export default RelationAndDocumentsStep;
