import { isEstateComposition } from 'formData/estate/details/utils';
import { LoaderConfig } from 'hooks/useLoader/types';
import routes from 'router/routes';
import { getEstate } from 'api/estate/requests';
import { EstateData, FetchedEstateData } from 'api/estate/types';

export const getEstateData = ({
  id: _id,
  businessId: _businessId,
  korfineReference: _korfineRef,
  ...data
}: FetchedEstateData): EstateData => data;

export const getEstateConfig = (estateId: string): LoaderConfig<FetchedEstateData> => ({
  id: estateId,
  scope: 'estate',
  fetch: getEstate,
  shouldRedirect: (pathname, estate) =>
    pathname === routes.estate(estateId) ||
    (pathname === routes.estateTab(estateId).composition && !isEstateComposition(estate)),
  redirectUrl: routes.estateTab(estateId).estateInfo,
});
