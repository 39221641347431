import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import { HandleCheckboxChange } from 'types/handleChange';
import { ProductName } from 'api/dossier/products/types';
import ControlledCheckbox from 'components/controlledInputs/ControlledCheckbox';
import { ProductField } from './types';

interface Props extends ProductField {
  subProducts: ProductField[];
  handleInputChange: (name: ProductName) => void;
}

const ChecklistItem = ({
  subProducts,
  index,
  name,
  parentId,
  disabled,
  handleInputChange,
}: Props) => {
  const { t } = useTranslation('dossierProducts');
  const { dossierMethods } = useContext(DossierFormContext);

  const handleParentChange: HandleCheckboxChange = (_e, checked) => {
    handleInputChange(name);
    if (subProducts.length) {
      subProducts.forEach(({ index }) => {
        dossierMethods.setValue(`checklistItems.${index}.checked`, checked);
      });
    }
  };

  return (
    <Box mb={1}>
      <ControlledCheckbox
        control={dossierMethods.control}
        name={`checklistItems.${index}.checked`}
        label={t(`${name}.name`, name)}
        onChange={handleParentChange}
        disabled={disabled || !!parentId}
        color="secondary"
        sx={{
          '& .MuiTypography-root': {
            wordBreak: 'break-word',
          },
        }}
      />
    </Box>
  );
};

export default ChecklistItem;
