import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import LoadingElement from 'atoms/LoadingElement';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { ConfigProvider } from 'contexts/config/ConfigContext';
import { ConfirmationProvider } from 'contexts/confirmation/ConfirmationContext';
import { LoadersProvider } from 'contexts/loaders/loadersContext';
import { useUpdateEffect } from 'usehooks-ts';
import { PromptModal } from 'components/modals';
import ErrorPage from './ErrorPage';
import Header from './Header';
import Sidebar from './Sidebar';
import { layoutContainerStyles, layoutWrapperStyles } from './styles';

export const Layout = () => {
  const theme = useTheme();
  const matchesBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));

  const [isSidebarExpanded, setIsSidebarOpen] = useState(matchesBreakpoint);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  useUpdateEffect(() => {
    if (!matchesBreakpoint) {
      setIsSidebarOpen(false);
    }
  }, [matchesBreakpoint]);

  const { loading, error } = useContext(AccountContext);

  if (loading) {
    return <LoadingElement />;
  } else if (error) {
    return <ErrorPage />;
  }

  return (
    <AuthenticatedTemplate>
      <ConfigProvider>
        <LoadersProvider>
          <ConfirmationProvider>
            <Box sx={layoutWrapperStyles}>
              <Header
                isSidebarExpanded={isSidebarExpanded}
                matchesBreakpoint={matchesBreakpoint}
              />
              <Sidebar
                expanded={isSidebarExpanded}
                toggleSidebar={toggleSidebar}
                matchesBreakpoint={matchesBreakpoint}
              />
              <Box component="main" sx={layoutContainerStyles}>
                <Outlet />
              </Box>
            </Box>
          </ConfirmationProvider>
          <PromptModal />
        </LoadersProvider>
      </ConfigProvider>
    </AuthenticatedTemplate>
  );
};
