import { ProductParty } from 'modules/ProductParties/types';
import { RegisterRentalContractForm } from './types';

const transformPersonToRegisterContract = ({
  personId,
  personType,
  representativeId,
  partnerId,
}: ProductParty) => ({
  personId,
  personType,
  representativeId,
  partnerId,
});

export const transformRentalContractData = ({
  tenants,
  landlords,
  ...rest
}: RegisterRentalContractForm): RegisterRentalContractForm => ({
  ...rest,
  landlords: landlords
    .filter(({ personId }) => personId)
    .map(transformPersonToRegisterContract),
  tenants: tenants
    .filter(({ personId }) => personId)
    .map(transformPersonToRegisterContract),
});
