import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useFilters } from 'templates/Table/hooks';
import { TableSidebarFilterComponentProps } from 'templates/Table/types';
import { HandleCheckboxChange } from 'types/handleChange';

const CheckboxesFilter = ({
  filterName,
  options,
  updateFilter,
}: TableSidebarFilterComponentProps) => {
  const { initState } = useFilters(filterName);
  const initItems = useMemo(() => initState.split(','), []);
  const [selectedItems, setSelectedItems] = useState<string[]>(initItems);

  const { t } = useTranslation();

  if (!options?.length) {
    return null;
  }

  const onChange =
    (name: string): HandleCheckboxChange =>
    (_e, checked) => {
      let updatedItems = selectedItems;
      if (checked) {
        updatedItems = Array.from(new Set([...selectedItems, name]));
      } else {
        updatedItems = selectedItems.filter((item) => item !== name);
      }

      setSelectedItems(updatedItems);
      updateFilter(filterName, updatedItems.join(','));
    };

  return (
    <Grid container spacing={2}>
      {options.map(({ name, label }) => (
        <Grid item key={name} xs={6}>
          <FormControlLabel
            label={t(label, label)}
            control={
              <Checkbox
                onChange={onChange(name)}
                defaultChecked={initItems.includes(name)}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CheckboxesFilter;
