import { Box, CircularProgress, IconButton } from '@mui/material';
import { CancelIcon, SaveIcon } from 'icons';

interface Props {
  submitting: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const DocumentEditionButtons = ({ onCancel, onSave, submitting }: Props) => (
  <Box display="flex" gap={0.5} alignItems="center">
    <IconButton size="small" onClick={onSave} disabled={submitting}>
      {!submitting ? <SaveIcon fontSize="small" /> : <CircularProgress size={20} />}
    </IconButton>
    <IconButton size="small" onClick={onCancel} disabled={submitting}>
      <CancelIcon fontSize="small" />
    </IconButton>
  </Box>
);

export default DocumentEditionButtons;
