import { FetchedDossiersList } from 'api/dossier/types';
import { AssignedDossier } from './types';

export const getAssignedDossierData = (
  dossierData: FetchedDossiersList,
): AssignedDossier[] =>
  dossierData.data.map(
    ({ id, referenceNumber, ownReference, status, landlords, tenants, estate }) => ({
      id,
      referenceNumber,
      ownReference,
      status,
      landlords,
      tenants,
      estate,
    }),
  );
