import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PersonPermissions } from 'consts/permissions';
import PrivateRelationCard from 'modules/PrivateRelationCard';
import RepresentationCard from 'modules/RepresentationCard';
import hasPermission from 'utils/hasPermission';
import transformName from 'utils/transformName';
import { NaturalPersonOutletContext } from '../types';

const NaturalPersonContactsTab = () => {
  const { naturalPersonId, naturalPersonData } =
    useOutletContext<NaturalPersonOutletContext>();

  const editable = hasPermission(PersonPermissions.Write);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={12} xl={6} marginBottom="auto">
        <PrivateRelationCard naturalPersonId={naturalPersonId} editable={editable} />
      </Grid>
      <Grid item xs={12} md={6} lg={12} xl={6} marginBottom="auto">
        <RepresentationCard
          personId={naturalPersonId}
          personName={transformName(naturalPersonData)}
          personType="NaturalPerson"
          editable={editable}
        />
      </Grid>
    </Grid>
  );
};

export default NaturalPersonContactsTab;
