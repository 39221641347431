import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ClearIcon } from 'icons';
import { HelperText } from './BaseInput';
import { MenuItemContent } from './MenuItemContent';
import { getBaseInputStyles } from './styles';
import { SelectInputProps, SelectProps } from './types';

const menuItemStyle = {
  pt: 1,
  pb: 1,
};

export const Select = ({
  hideHelperTextGap = false,
  helperText,
  name,
  onChange = () => {},
  clearable,
  onClear,
  fullWidth = true,
  size = 'small',
  variant = 'outlined',
  options,
  noOptionsText = 'default',
  loading,
  error = false,
  warning = false,
  loadingText = 'default',
  groupedOptions,
  renderValue,
  value,
  staticWidth, // 0 would be treated same as undefined,
  placeholder,
  multiple,
  ...props
}: SelectInputProps) => {
  const { t } = useTranslation(['common', 'enums']);

  const helperTextContent = useMemo(() => {
    if (helperText) {
      return <HelperText helperText={helperText} warning={warning} error={error} />;
    }
    return hideHelperTextGap ? undefined : ' ';
  }, [helperText, warning, error]);

  const renderItems = () => {
    if (loading) {
      return (
        <MenuItem sx={menuItemStyle} disabled>
          {t(`selectInput.loadingText.${loadingText}`)}
        </MenuItem>
      );
    }
    if (groupedOptions && Object.keys(groupedOptions).length) {
      return Object.entries(groupedOptions).map(([group, options]) =>
        options.length
          ? [
              <ListSubheader
                key={`selectInput.subheader-${group}`}
                disableSticky
                sx={{ lineHeight: 2.5 }}
              >
                {t(`selectInput.subHeader.${group}`, group)}
              </ListSubheader>,
              ...options.map((option) => (
                <MenuItem
                  sx={menuItemStyle}
                  key={option.label}
                  value={option.value}
                  disabled={!!option.disabled}
                >
                  <MenuItemContent {...option} />
                </MenuItem>
              )),
            ]
          : null,
      );
    }

    if (options.length && !groupedOptions) {
      return options.map((option) => (
        <MenuItem
          sx={menuItemStyle}
          key={option.label}
          value={option.value}
          disabled={!!option.disabled}
        >
          <MenuItemContent {...option} />
        </MenuItem>
      ));
    }
    return (
      <MenuItem disabled>{t(`selectInput.noOptionsText.${noOptionsText}`)}</MenuItem>
    );
  };

  const renderSelectedValue = useCallback(
    (selected: unknown) => {
      if (multiple && Array.isArray(selected) && selected.length) {
        return selected
          .map((value) => {
            const { translationKey, label } = options.filter(
              ({ value: optionValue }) => optionValue === value,
            )[0];
            return translationKey ? t(translationKey, label) : label;
          })
          .join(', ');
      }

      const selectedOption = options.filter(({ value }) => value === selected)[0];
      if (renderValue) {
        return renderValue(selectedOption);
      }
      return selectedOption ? (
        <MenuItemContent {...selectedOption} />
      ) : (
        <Typography color="grey.400">{placeholder}</Typography>
      );
    },
    [multiple, options, renderValue],
  );

  const SelectProps: SelectProps = useMemo(
    () => ({
      displayEmpty: !!placeholder,
      renderValue: renderSelectedValue,
      MenuProps: {
        elevation: 2,
        PaperProps: {
          'data-testid': `${name}-menu`,

          sx: { maxHeight: 250, ...(staticWidth ? { width: staticWidth } : {}) },
        },
      },
      multiple,
    }),
    [staticWidth, renderSelectedValue],
  );

  const isClearable = useMemo(() => {
    if (props.disabled || !clearable) {
      return false;
    }
    return Array.isArray(value) ? !!value.length : !!value;
  }, [value, props.disabled, clearable]);

  return (
    <TextField
      select
      sx={{
        ...getBaseInputStyles(error, warning),
        ...(staticWidth ? { width: staticWidth } : {}),
      }}
      SelectProps={SelectProps}
      id={name}
      size={size}
      onChange={({ target }) =>
        onChange(
          target.value,
          options.find(({ value }) => value === target.value),
        )
      }
      variant={variant}
      fullWidth={fullWidth}
      error={error}
      // @ts-ignore it exists
      FormHelperTextProps={{ component: 'div' }}
      helperText={helperTextContent}
      inputProps={{
        'data-testid': name,
      }}
      InputProps={{
        endAdornment: isClearable && (
          <IconButton
            size="small"
            sx={{ position: 'absolute', right: 25, fontSize: 18 }}
            onClick={onClear}
          >
            <ClearIcon fontSize="inherit" />
          </IconButton>
        ),
        sx: { '& .MuiTypography-root': { lineHeight: 1.4 } },
      }}
      // removes mui warning - value out of range
      value={options.length ? value : ''}
      {...props}
    >
      {renderItems()}
    </TextField>
  );
};
