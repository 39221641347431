import { dateOfBirthOptions } from 'formData/naturalPerson/dateOfBirth/schema';
import {
  NaturalPersonDetailsFields,
  NaturalPersonGeneralFields,
} from 'formData/naturalPerson/fields';
import { firstLetterUppercaseInputConfig } from 'formData/utils';
import { LegalPersonData } from 'api/person/legal/types';
import { PersonType } from 'api/person/types';
import { InputField } from 'components/controlledInputs/types';

export const addendumNaturalPersonFields: InputField<
  Pick<NaturalPersonGeneralFields, 'firstName' | 'name'> &
    Pick<
      NaturalPersonDetailsFields,
      'dateOfBirth' | 'placeOfBirth' | 'nationalRegisterNumber'
    >
>[] = [
  {
    name: 'name',
    disabled: true,
    ...firstLetterUppercaseInputConfig,
  },
  {
    name: 'firstName',
    disabled: true,
    ...firstLetterUppercaseInputConfig,
  },
  {
    name: 'dateOfBirth',
    type: 'date',
    dateInputProps: dateOfBirthOptions,
    disabled: true,
  },
  {
    name: 'placeOfBirth',
    disabled: true,
    ...firstLetterUppercaseInputConfig,
  },
  {
    name: 'nationalRegisterNumber',
    mask: [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
    ],
    disabled: true,
  },
];

const addendumLegalPersonFields: InputField<Pick<LegalPersonData, 'name' | 'vat'>>[] = [
  {
    name: 'name',
    disabled: true,
  },
  {
    name: 'vat',
    type: 'vat',
    disabled: true,
  },
];

export const getBasicInformationFields = (personType: PersonType) =>
  personType === 'NaturalPerson'
    ? addendumNaturalPersonFields
    : addendumLegalPersonFields;
