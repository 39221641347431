import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  CircularProgress,
} from '@mui/material';
import { SearchIcon } from 'icons';
import { useUpdateEffect } from 'usehooks-ts';
import { MenuItemContent } from './MenuItemContent';
import { NumberInput } from './NumberInput';
import { TextInput } from './TextInput';
import { SearchInputOption, SearchInputProps } from './types';
import { getFilterOptions, getInitOption } from './utils';

const renderOption: <T extends object = object>(
  props: React.HTMLAttributes<HTMLLIElement>,
  option: SearchInputOption<T>,
) => React.ReactNode = (props, option) => (
  <li {...props} key={option.label + option.value}>
    <MenuItemContent {...option} />
  </li>
);

const isOptionEqualToValue = <T extends object = object>(
  option: SearchInputOption<T>,
  input: SearchInputOption<T>,
) => option.label + option.value === input.label + input.value;

export const SearchInput = <T extends object = object>({
  options,
  noOptionsText = 'default',
  currentOption,
  value,
  onChange = () => {},
  onInputChange,
  onClose,
  creatable = false,
  filterConfig,
  loading = false,
  loadingText = 'default',
  disableClearable,
  shouldFilter = false,
  type = 'text',
  keyToRender = 'label',
  disabled,
  groupBy,
  fillInputWithOption = true,
  Icon,
  ...inputProps
}: SearchInputProps<T>) => {
  const [focused, setFocused] = useState(false);
  // TODO: With new way of setting initOption, maybe currentOption can be removed
  const [option, setOption] = useState<SearchInputOption<T> | null>(
    currentOption ?? getInitOption(options, value),
  );
  const [input, setInput] = useState('');

  useUpdateEffect(() => {
    if (currentOption !== undefined) {
      setOption(currentOption);
    }
  }, [currentOption]);

  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    option: SearchInputOption<T> | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (
      (reason === 'clear' && e.type !== 'change') ||
      (!creatable && reason === 'createOption')
    ) {
      setOption(null);
      onChange(null, reason);
    } else if (option) {
      if (fillInputWithOption) {
        setOption(option);
      } else {
        setInput(reason === 'selectOption' ? '' : input);
      }
      onChange(option, reason);
    }
  };

  const handleInputChange = (
    _e: SyntheticEvent<Element, Event>,
    input: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setInput(input);
    if (onInputChange) {
      onInputChange(input, reason);
    }
  };

  const handleClose = (
    _e: SyntheticEvent<Element, Event> | null,
    reason: AutocompleteCloseReason,
  ) => {
    if (onClose) {
      onClose(option, reason);
    }
  };

  const { t } = useTranslation();

  const Input = useMemo(() => (type === 'number' ? NumberInput : TextInput), [type]);

  const getOptionLabel = useCallback(
    (option: SearchInputOption<object>) => {
      const { value, translationKey } = option;
      const label = String(option[keyToRender]);
      if (translationKey) {
        return t(translationKey, { ns: 'enums', defaultValue: label });
      }
      return option?.created ? String(value) : label;
    },
    [keyToRender],
  );

  const popupIcon = useMemo(
    () =>
      loading && focused ? (
        <CircularProgress size={20} />
      ) : (
        Icon || <SearchIcon fontSize="small" />
      ),
    [loading, focused],
  );

  const filterOptions = useMemo(
    () => getFilterOptions({ config: filterConfig, shouldFilter, creatable, loading }),
    [filterConfig, shouldFilter, creatable, loading],
  );

  return (
    <Autocomplete
      value={option}
      onChange={(e, value, reason) => handleChange(e, value as typeof option, reason)}
      inputValue={input}
      onInputChange={handleInputChange}
      onClose={handleClose}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      noOptionsText={t(`selectInput.noOptionsText.${noOptionsText}`)}
      disabled={disabled}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionDisabled={(option) => !option.value}
      disableClearable={disableClearable}
      loading={loading}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      popupIcon={popupIcon}
      loadingText={t(`selectInput.loadingText.${loadingText}`)}
      filterOptions={filterOptions}
      renderInput={(params) => <Input {...params} {...inputProps} />}
      data-testid={inputProps.label}
      groupBy={groupBy}
      ListboxProps={{
        //@ts-ignore a can pass whatever i want as html attribute
        'data-testid': `${inputProps.name}-menu`,
      }}
    />
  );
};
