import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import useSearchInput from 'hooks/useSearchInput';
import { ControlledSearchInput } from 'components/controlledInputs';
import { ProductPartiesForm, RepresentativeInputProps } from './types';
import { fetchPartyOptions, formatPersonOptions } from './utils';

const SELECTOR_WIDTH = 260;

const RepresentativeInput = ({
  index,
  partyType,
  optionsToFilter,
  assignedRepresentatives,
  disabled,
}: RepresentativeInputProps) => {
  const { t } = useTranslation('modals', {
    keyPrefix: 'productActionModal.contactComponent',
  });

  const {
    control,
    formState: { errors },
  } = useFormContext<ProductPartiesForm>();

  const { searchInputProps } = useSearchInput({
    contextOptions: formatPersonOptions(
      assignedRepresentatives,
      t('assignedRepresentatives'),
    ),
    fetchOptions: (params) => fetchPartyOptions(params, t('notAssignedRepresentatives')),
    optionsToFilter,
    initParams: { type: 'NaturalPerson' },
  });

  return (
    <Box width={SELECTOR_WIDTH}>
      <ControlledSearchInput
        hideHelperTextGap
        label={t('representative')}
        control={control}
        error={errors?.[partyType]?.[index]?.representativeId}
        name={`${partyType}.${index}.representativeId`}
        fullWidth
        disableClearable
        disabled={disabled}
        {...searchInputProps}
      />
    </Box>
  );
};

export default RepresentativeInput;
