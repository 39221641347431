import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';
import { EstateFormProvider } from 'contexts/estateForm/estateFormContext';
import { FilesUploadProvider } from 'contexts/fileUpload/filesUploadContext';
import { UploadPhotoProvider } from 'contexts/photoUpload/uploadPhotoContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import usePrompt from 'hooks/usePrompt';
import StepWizard from 'templates/StepWizard';
import { StepInterface } from 'templates/StepWizard/types';
import { resolver } from 'utils/yup';
import { estateFormSchema } from './static-data';
import {
  useCompositionStep,
  useDescriptionStep,
  useDetailsStep,
  useDocumentsStep,
  useGeneralStep,
} from './steps';
import { EstateFormInterface, EstateFormProps } from './types';
import useEstateForm from './useEstateForm';
import { useEstateFormErrors } from './useEstateFormErrors';

const EstateFormStepper = ({
  handleCancel,
  handleSave,
  type = 'page',
}: EstateFormProps) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      navigate(-1);
    }
  };

  const { formState } = useFormContext<EstateFormInterface>();
  const errors = useEstateFormErrors();
  const {
    loading,
    saveForm,
    shouldCreateCopyAfterSave,
    setShouldCreateCopyAfterSave,
    shouldResetStepper,
  } = useEstateForm(handleSave);

  const stepProps = { onCancel, saveForm };
  const generalStep = useGeneralStep({ ...stepProps, hideOwners: type === 'modal' });
  const descriptionStep = useDescriptionStep(stepProps);
  const detailsStep = useDetailsStep(stepProps);
  const compositionStep = useCompositionStep({
    ...stepProps,
    isLastStep: type === 'modal',
  });
  const documentsStep = useDocumentsStep(stepProps);

  const steps: StepInterface[] = [
    generalStep,
    descriptionStep,
    detailsStep,
    compositionStep,
  ];

  return (
    <StepWizard
      steps={type === 'page' ? [...steps, documentsStep] : steps}
      loadingContent={loading}
      translationKey="estate"
      disabledLabels={!formState.isValid || formState.isSubmitting}
      errors={errors}
      minWidth={{ md: 750 }}
      hideTitle={type === 'modal'}
      withDialogComponents={type === 'modal'}
      shouldResetStepper={shouldResetStepper}
      FooterElements={
        <FormControlLabel
          sx={{ width: '100%' }}
          label={t('stepWizard.estate.createCopy')}
          value={shouldCreateCopyAfterSave}
          control={
            <Checkbox
              checked={shouldCreateCopyAfterSave}
              onChange={(_, checked) => setShouldCreateCopyAfterSave(checked)}
            />
          }
        />
      }
    />
  );
};

const EstateForm = (props: EstateFormProps) => {
  const methods = useForm<EstateFormInterface>({
    resolver: resolver(estateFormSchema),
    defaultValues: estateFormSchema.getDefault(),
  });

  usePrompt({ when: methods.formState.isDirty, id: 'estateForm' });

  return (
    <FilesUploadProvider scope="estate">
      <UploadPhotoProvider>
        <EstateFormProvider>
          <FormProvider {...methods}>
            <EstateFormStepper {...props} />
          </FormProvider>
        </EstateFormProvider>
      </UploadPhotoProvider>
    </FilesUploadProvider>
  );
};

export default EstateForm;
