import { UserPermissions } from 'consts/permissions';
import i18n from 'i18n';
import routes from 'router/routes';
import { BoldCell } from 'templates/Table/Cells';
import { TableAction, TableColumn } from 'templates/Table/types';
import hasPermission from 'utils/hasPermission';
import { transformName } from 'utils/transformName/transformName';
import { apiUrl } from 'api/const';
import { FetchedUserData } from 'api/users/types';

export const getLinkToUser = (id: string) => routes.user(id);

export const usersColumns: TableColumn<FetchedUserData, 'users'>[] = [
  {
    field: 'name',
    renderCell: BoldCell,
    valueFormatter: (_value, row) => transformName(row),
    flex: 3,
  },
  {
    field: 'role',
    flex: 2,
    translateValue: (value) =>
      value ? i18n.t(`enums:userRoles.${value}`, value) : value,
  },
  { field: 'email', flex: 3 },
  {
    field: 'language',
    flex: 2,
    translateValue: (value) => (value ? i18n.t(`enums:language.${value}`, value) : value),
  },
];

export const getUsersActions = (user: FetchedUserData): TableAction<'users'>[] => [
  {
    name: 'delete',
    hidden: !hasPermission(UserPermissions.Write),
    onClick: ({ setUpAndOpenConfirmationModal, updateRows }) => {
      setUpAndOpenConfirmationModal({
        url: apiUrl.deleteUser(user.id),
        translationKey: 'user',
        onSuccess: updateRows,
        translationValues: { userName: transformName(user) },
      });
    },
  },
];
