import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ibanConfig } from 'formData/utils';
import useBicFromIban from 'hooks/useBicFromIban';
import { HandleBlurChange } from 'types/handleChange';
import { ControlledMaskedInput, ControlledTextInput } from 'components/controlledInputs';

interface Props {
  prefixName?: string;
  disabled?: boolean;
}

export const AccountNumberInput = ({ prefixName, disabled }: Props) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { getBicFromIban, loadingBic } = useBicFromIban();
  const { t } = useTranslation('formFields');

  const getField = (fieldName: 'iban' | 'bic') =>
    prefixName
      ? {
          name: `${prefixName}.accountNumber.${fieldName}`,
          // @ts-ignore errors doesn't accept nested fields
          error: errors[prefixName]?.accountNumber?.[fieldName],
        }
      : {
          name: `accountNumber.${fieldName}`,
          // @ts-ignore errors doesn't accept nested fields
          error: errors?.accountNumber?.[fieldName],
        };

  const onIbanBlur: HandleBlurChange = async (e) => {
    const iban = e.target.value;
    const bic = await getBicFromIban(iban);
    setValue(getField('bic').name, bic);
  };

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={8}>
        <ControlledMaskedInput
          {...ibanConfig}
          label={t('iban')}
          control={control}
          name={getField('iban').name}
          error={getField('iban').error}
          disabled={disabled}
          onBlur={onIbanBlur}
          hideHelperTextGap
        />
      </Grid>
      <Grid item xs={4}>
        <ControlledTextInput
          control={control}
          label={t('bic')}
          loading={loadingBic}
          name={getField('bic').name}
          error={getField('bic').error}
          disabled={disabled}
          hideHelperTextGap
        />
      </Grid>
    </Grid>
  );
};
