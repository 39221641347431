import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import AssignedDossiersGrid from 'modules/AssignedDossiersGrid';
import { NaturalPersonOutletContext } from '../types';

const NaturalPersonDossiersTab = () => {
  const { naturalPersonId } = useOutletContext<NaturalPersonOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AssignedDossiersGrid entityId={naturalPersonId} type="Person" />
      </Grid>
    </Grid>
  );
};

export default NaturalPersonDossiersTab;
