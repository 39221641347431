import { createContext, PropsWithChildren, useState } from 'react';
import { addNewOwnedEstates } from 'modules/OwnershipCard/utils';
import { RelationData } from 'modules/Relation/types';
import { addNewRepresentations } from 'modules/RepresentationCard/utils';
import { StepperErrors } from 'templates/StepWizard/types';
import transformName from 'utils/transformName';
import { FetchedEstateListItem } from 'api/estate/types';
import { FetchedPersonData, PersonType } from 'api/person/types';
import { PersonFormContextInterface, PersonFormData } from './types';

export const PersonFormContext = createContext<PersonFormContextInterface>({
  general: null,
  updateGeneral: () => {},
  submitting: false,
  setSubmitting: () => {},
  relationsToPreview: [],
  setRelationsToPreview: () => {},
  ownershipsToPreview: [],
  setOwnershipsToPreview: () => {},
  saveRelations: async () => false,
  errors: null,
  setErrors: () => {},
  saved: true,
  setSaved: () => {},
});

export const PersonFormProvider = ({ children }: PropsWithChildren) => {
  const [submitting, setSubmitting] = useState(false);
  const [general, setGeneral] = useState<PersonFormData | null>(null);
  const [errors, setErrors] = useState<StepperErrors>(null);
  const [saved, setSaved] = useState(true);

  const updateGeneral = (data: PersonFormData) => {
    if (Object.values(data).some((value) => value !== null)) {
      setGeneral((prev) => ({ ...prev, ...data }));
    }
  };

  const [relationsToPreview, setRelationsToPreview] = useState<
    RelationData<FetchedPersonData>[]
  >([]);

  const [ownershipsToPreview, setOwnershipsToPreview] = useState<
    RelationData<FetchedEstateListItem>[]
  >([]);

  const saveRelations = async (personId: string, personType: PersonType) => {
    // TODO: Fix filtering person options (now it is possible to add two the same options)
    let allSaved = true;
    let personName = '';
    if (general) {
      personName = transformName(general);
    }

    if (relationsToPreview.length) {
      const { allSucceed } = await addNewRepresentations(
        personId,
        personType,
        personName,
      )(relationsToPreview, setRelationsToPreview, true);
      allSaved = allSucceed;
    }
    if (ownershipsToPreview.length) {
      const { allSucceed } = await addNewOwnedEstates(personId, personType)(
        ownershipsToPreview,
        setOwnershipsToPreview,
        true,
      );
      allSaved = allSaved && allSucceed;
    }
    return allSaved;
  };

  return (
    <PersonFormContext.Provider
      value={{
        general,
        updateGeneral,
        submitting,
        setSubmitting,
        relationsToPreview,
        setRelationsToPreview,
        ownershipsToPreview,
        setOwnershipsToPreview,
        saveRelations,
        errors,
        setErrors,
        saved,
        setSaved,
      }}
    >
      {children}
    </PersonFormContext.Provider>
  );
};
