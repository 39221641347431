import { languageOptions } from 'formData/common/selectOptions';
import { firstLetterUppercaseInputConfig, ibanConfig, sortFields } from 'formData/utils';
import { TaxResidencesField } from 'modules/TaxResidencesField';
import { HandleBlurChange } from 'types/handleChange';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { InputField } from 'components/controlledInputs/types';
import { GroupedOptions } from 'components/inputs/types';
import { dateOfBirthOptions } from './dateOfBirth/schema';
import { naturalPersonOrder } from './order';
import { sexOptions, taxResidencesOptions } from './selectOptions';

export type NaturalPersonGeneralFields = Pick<
  NaturalPersonFormData,
  'name' | 'firstName' | 'givenName' | 'sex' | 'language' | 'email' | 'phone'
>;

export type NaturalPersonDetailsFields = Omit<
  NaturalPersonFormData,
  'address' | keyof NaturalPersonGeneralFields
>;

export const naturalPersonGeneralFields: InputField<NaturalPersonGeneralFields>[] =
  sortFields(
    [
      {
        name: 'name',
        ...firstLetterUppercaseInputConfig,
      },
      {
        name: 'firstName',
        ...firstLetterUppercaseInputConfig,
      },
      {
        name: 'givenName',
        ...firstLetterUppercaseInputConfig,
      },
      {
        name: 'sex',
        options: sexOptions,
      },
      {
        name: 'language',
        options: languageOptions,
      },
      {
        name: 'email',
        type: 'email',
      },
      {
        name: 'phone',
        type: 'tel',
        sizes: { md: 12 },
      },
    ],
    naturalPersonOrder,
  );

interface DetailsFieldsData {
  nationalities: GroupedOptions;
  onNationalityChange: (nationality: string) => void;
  onIbanBlur: HandleBlurChange;
  loadingBic: boolean;
}

export const getNaturalPersonDetailsFields = (
  { nationalities, onNationalityChange, onIbanBlur, loadingBic }: DetailsFieldsData,
  formType: 'edition' | 'creation' = 'creation',
): InputField<NaturalPersonDetailsFields>[] =>
  sortFields(
    [
      {
        name: 'dateOfBirth',
        type: 'date',
        dateInputProps: dateOfBirthOptions,
      },
      {
        name: 'placeOfBirth',
        ...firstLetterUppercaseInputConfig,
      },
      {
        name: 'nationality',
        groupedOptions: nationalities,
        options: [...nationalities.main, ...nationalities.other],
        onSelectChange: onNationalityChange,
      },
      {
        name: 'nationalRegisterNumber',
        mask: [
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
        ],
      },
      {
        name: 'iban',
        onBlur: onIbanBlur,
        ...ibanConfig,
        sizes: { md: 12, lg: 6 },
      },
      {
        name: 'bic',
        loading: loadingBic,
        sizes: { md: 12, lg: 6 },
      },
      {
        name: 'isPoliticallyExposedPerson',
        type: 'checkbox',
        sizes: { sm: 12, md: 12 },
      },
      {
        name: 'taxResidenceBelgium',
        options: taxResidencesOptions,
      },
      {
        name: 'taxResidences',
        Component: TaxResidencesField,
        componentProps: { separateLabel: formType === 'edition' },
        sizes: { sm: 12, md: 12 },
      },
    ],
    naturalPersonOrder,
  );
