import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@mui/material';
import { TooltipIconButton } from 'atoms/TooltipIconButton/TooltipIconButton';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { DeleteIcon } from 'icons';
import { MAX_RESPONSIBLE_PEOPLE } from 'modules/OwnershipCard/static-data';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import { fetchUsersOptions } from 'modules/Relation/utils';
import { useDossierDetails } from './useDossierDetails';

const DossierResponsibleUsers = ({
  responsibleUsers,
  createdBy,
  deleteResponsibleUser,
  responsibleUsersToPreview,
  setResponsibleUsersToPreview,
}: ReturnType<typeof useDossierDetails>['responsibleUsersProps']) => {
  const { t } = useTranslation();

  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const onDeleteClick = (id: string, name: string) => {
    setUpAndOpenConfirmationModal({
      translationKey: 'responsibleUser',
      onSubmit: () => deleteResponsibleUser(id),
      translationValues: { responsibleUserName: name },
    });
  };

  const usersToFilter = useMemo(
    () => [
      ...responsibleUsersToPreview.map(({ id }) => id),
      ...responsibleUsers.map(({ id }) => id),
    ],
    [responsibleUsersToPreview, responsibleUsers],
  );

  return (
    <Stack>
      <AddRelation
        translationKey="responsibleUser"
        maxRelations={MAX_RESPONSIBLE_PEOPLE}
        relationsToFilter={usersToFilter}
        fetchOptions={fetchUsersOptions}
        setRelationsToPreview={setResponsibleUsersToPreview}
        shouldFilter
      />
      <RelationPreviewList
        relationsToPreview={responsibleUsersToPreview}
        setRelationsToPreview={setResponsibleUsersToPreview}
        isDivider={!!responsibleUsers.length}
      />
      {!!responsibleUsers.length && (
        <Stack px={2.5} mt={2} spacing={1}>
          {responsibleUsers.map(({ id, name }) => (
            <Stack
              key={id}
              direction="row"
              minWidth={200}
              gap={2}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Typography>{name}</Typography>
              {createdBy?.id === id ? (
                <TooltipIconButton
                  disabled
                  tooltipTitle={t('tooltips.responsibleUsersDeletion')}
                  onClick={() => onDeleteClick(id, name)}
                  Icon={<DeleteIcon />}
                />
              ) : (
                <IconButton onClick={() => onDeleteClick(id, name)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default DossierResponsibleUsers;
