import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { UploadPhotoContext } from 'contexts/photoUpload/uploadPhotoContext';
import { StepperErrors } from 'templates/StepWizard/types';
import { useUpdateEffect } from 'usehooks-ts';
import { EstateSteps } from './static-data';
import { EstateFormInterface } from './types';

const hasObjectAnyProperty = (object = {}) => !!Object.keys(object).length;

export const useEstateFormErrors = () => {
  const [stepperErrors, setStepperErrors] = useState<StepperErrors>(null);

  const { ownersToPreview } = useContext(EstateFormContext);
  const { uploadedFile } = useContext(UploadPhotoContext);
  const { documents } = useContext(FilesUploadContext);

  const {
    formState: { errors },
  } = useFormContext<EstateFormInterface>();

  useUpdateEffect(() => {
    const isError =
      hasObjectAnyProperty(errors.estate) ||
      ownersToPreview.some(({ errorMessage }) => errorMessage);

    setStepperErrors((prev) => ({ ...prev, [EstateSteps.General]: isError }));
  }, [ownersToPreview.length, Object.keys(errors.estate ?? {}).length]);

  useUpdateEffect(() => {
    const isError =
      hasObjectAnyProperty(errors.estateDetails?.descriptions) ||
      uploadedFile?.status === 'ERROR';

    setStepperErrors((prev) => ({ ...prev, [EstateSteps.Description]: isError }));
  }, [errors.estateDetails?.descriptions, uploadedFile?.status]);

  useUpdateEffect(() => {
    const {
      descriptions: _descriptionsErrors,
      estateComposition: _compositionErrors,
      ...estateDetailsErrors
    } = errors.estateDetails ?? {};

    setStepperErrors((prev) => ({
      ...prev,
      [EstateSteps.Details]: hasObjectAnyProperty(estateDetailsErrors),
    }));
  }, [Object.keys(errors.estateDetails ?? {}).length]);

  useUpdateEffect(() => {
    setStepperErrors((prev) => ({
      ...prev,
      [EstateSteps.Composition]: hasObjectAnyProperty(
        errors.estateDetails?.estateComposition,
      ),
    }));
  }, [errors.estateDetails?.estateComposition]);

  useUpdateEffect(() => {
    const isError = documents.some(
      ({ status }) => status === 'ERROR' || status === 'WARNING',
    );
    setStepperErrors((prev) => ({ ...prev, [EstateSteps.Documents]: isError }));
  }, [documents]);

  return stepperErrors;
};
