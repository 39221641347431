import { useContext, useState } from 'react';
import { FilesUploadProvider } from 'contexts/fileUpload/filesUploadContext';
import {
  PersonFormContext,
  PersonFormProvider,
} from 'contexts/personForm/personFormContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import StepWizard from 'templates/StepWizard';
import { useGeneralStep, useRelationsAndDocumentsStep } from './steps';
import { NaturalPersonFormProps } from './types';

const PersonFormStepper = ({
  handleCancel,
  handleSave,
  type = 'page',
}: NaturalPersonFormProps) => {
  const [naturalPersonId, setNaturalPersonId] = useState<string | null>(null);
  const navigate = useCustomNavigate();

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      // probably here should be added confirmation modal, if user wants to cancel current progress, if form is not saved
      navigate(-1);
    }
  };

  const props = { naturalPersonId, onCancel, handleSave };
  const generalStep = useGeneralStep({ ...props, setNaturalPersonId });
  const relationsAndDocumentsStep = useRelationsAndDocumentsStep(props);

  const { submitting, errors } = useContext(PersonFormContext);

  return (
    <StepWizard
      steps={[generalStep, relationsAndDocumentsStep]}
      translationKey="naturalPerson"
      disabledLabels={!naturalPersonId || submitting}
      errors={errors}
      minWidth={{ lg: 1024 }}
      hideTitle
      withDialogComponents={type === 'modal'}
    />
  );
};

export const NaturalPersonForm = (props: NaturalPersonFormProps) => (
  <FilesUploadProvider scope="naturalPerson">
    <PersonFormProvider>
      <PersonFormStepper {...props} />
    </PersonFormProvider>
  </FilesUploadProvider>
);
