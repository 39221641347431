import { useOutletContext } from 'react-router-dom';
import { Box } from '@mui/material';
import useLoader from 'hooks/useLoader';
import AddNewProductCard from 'modules/AddNewProductCard';
import ProductCard from 'modules/ProductCard';
import { useUpdateEffect } from 'usehooks-ts';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';
import { getProductsConfig } from './utils';

export const ProductsGrid = () => {
  const { dossierId, dossierData, rentalConditions } =
    useOutletContext<DossierOutletContext>();

  const {
    Loader,
    data: products,
    updateData: fetchProducts,
  } = useLoader(getProductsConfig(dossierId));

  useUpdateEffect(() => {
    fetchProducts();
  }, [dossierData.tenants.length, dossierData.landlords.length, rentalConditions]);

  return (
    <Loader>
      <Box
        display="grid"
        gap={3}
        gridTemplateColumns="repeat(auto-fit, minmax(350px, 1fr))"
      >
        {/* TODO: Add sorting depending on dossierChecklist order */}
        {products?.map((product) => (
          <ProductCard key={product.id} {...product} fetchProducts={fetchProducts} />
        ))}
        <AddNewProductCard products={products ?? []} fetchProducts={fetchProducts} />
      </Box>
    </Loader>
  );
};
