import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import AssignedDossiersGrid from 'modules/AssignedDossiersGrid';
import { EstateOutletContext } from '../types';

const EstateDossiersTab = () => {
  const { estateId } = useOutletContext<EstateOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} marginBottom="auto">
        <AssignedDossiersGrid entityId={estateId} type="Estate" />
      </Grid>
    </Grid>
  );
};

export default EstateDossiersTab;
