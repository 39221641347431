import { useTranslation } from 'react-i18next';
import { Box, Card, CircularProgress, Typography } from '@mui/material';
import { PageCardProps } from './types';

export const PageCard = ({
  children,
  header = null,
  footer = null,
  loading = false,
  blank = false,
}: PageCardProps) => {
  const { t } = useTranslation('table');

  return (
    <Card elevation={0} sx={({ mixins }) => mixins.pageCard}>
      {header}
      {loading || blank ? (
        <Box display="flex" justifyContent="center" p={2}>
          {loading ? <CircularProgress /> : <Typography>{t('defaultEmpty')}</Typography>}
        </Box>
      ) : (
        children
      )}
      {footer}
    </Card>
  );
};
