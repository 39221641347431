import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useEstatePurposeFields from 'hooks/useEstatePurposeFields';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { FormContainer, FormSection } from 'templates/FormTemplates';
import { ControlledInput } from 'components/controlledInputs';
import EstateOwners from './Owners';

const GeneralStep = ({ hideOwners }: { hideOwners?: boolean }) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    control,
  } = useFormContext<EstateFormInterface>();

  const fields = useEstatePurposeFields('estate');

  return (
    <FormContainer maxWidth={750}>
      <FormSection label={t('stepWizard.estate.sections.address')}>
        <AddressFieldsWithGoogle disableCountrySelection prefix="estate" />
      </FormSection>
      <FormSection label={t('stepWizard.estate.sections.estatePurpose')}>
        {fields
          .filter(({ hidden }) => !hidden)
          .map(({ name, ...field }) => (
            <Grid key={name} item xs={6}>
              <ControlledInput
                control={control}
                name={`estate.${name}`}
                label={t(name, { ns: 'formFields' })}
                error={errors.estate?.[name] as FieldError}
                {...field}
              />
            </Grid>
          ))}
      </FormSection>
      {!hideOwners && (
        <FormSection label={t('stepWizard.estate.sections.owners')}>
          <Grid item xs={12}>
            <EstateOwners />
          </Grid>
        </FormSection>
      )}
    </FormContainer>
  );
};

export default GeneralStep;
