import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useElectricityFields from 'hooks/useElectricityFields';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { getOnInputChange } from 'modules/EstateForm/utils';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import { isTruthyValueInObject } from 'utils/object';
import { ControlledInput } from 'components/controlledInputs';

const ElectricityFields = () => {
  const { t } = useTranslation('formFields');
  const {
    control,
    formState: { errors, isSubmitted },
    getValues,
    trigger,
  } = useFormContext<EstateFormInterface>();

  const { fields, onCheckboxChange, updateCheckbox } =
    useElectricityFields('estateDetails');

  const initiallyExpanded = !!(
    getValues('estateDetails.hasElectricity') &&
    isTruthyValueInObject(getValues('estateDetails.electricity'))
  );

  return (
    <Grid item xs={12}>
      <AccordionWithCheckbox
        control={control}
        error={errors.estateDetails?.electricity as FieldError}
        name="estateDetails.hasElectricity"
        label={t('electricity.self')}
        onChange={onCheckboxChange}
        initiallyExpanded={initiallyExpanded}
      >
        <Grid item container spacing={2}>
          {fields.map(({ name, hidden, suffix, ...field }) =>
            !hidden ? (
              <Grid key={name} item xs={6}>
                <ControlledInput
                  name={`estateDetails.electricity.${name}`}
                  label={t(`electricity.${name}`, { suffix })}
                  onInputChange={getOnInputChange({
                    fieldName: name,
                    isSubmitted,
                    trigger,
                    typeToTrigger: 'estateDetails.gas',
                    updateCheckbox,
                  })}
                  onSelectChange={updateCheckbox}
                  control={control}
                  error={errors?.estateDetails?.electricity?.[name] as FieldError}
                  {...field}
                />
              </Grid>
            ) : null,
          )}
        </Grid>
      </AccordionWithCheckbox>
    </Grid>
  );
};

export default ElectricityFields;
