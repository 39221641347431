import { DocumentDateCell } from 'templates/DocumentsTable/cells';
import { DocumentsColumn, GetDocumentActions } from 'templates/DocumentsTable/types';
import {
  handleDeleteDocument,
  handleDownloadDocument,
} from 'templates/DocumentsTable/utils';
import { formatDate } from 'utils/date';
import { AttachDocumentCell, DocumentTypeCell } from './cells';
import {
  DossierDocumentsContextInterface,
  DossierTableDocumentData,
  HandleOpenDocumentsModal,
} from './types';

export const getDossierDocumentActions =
  (
    openDocumentsModal: HandleOpenDocumentsModal,
  ): GetDocumentActions<DossierTableDocumentData> =>
  (document, entityId, scope, turnEditMode) => {
    if (document.isMissing) {
      return [
        {
          name: 'upload',
          onClick: () => openDocumentsModal(document.documentType),
        },
      ];
    }

    const hidden =
      (document.source && document.source !== 'Internal') ||
      document.documentClassification !== 'Document';

    return [
      {
        name: 'download',
        hidden: document.documentClassification !== 'Document',
        onClick: handleDownloadDocument(document, entityId, scope),
      },
      {
        name: 'edit',
        hidden: hidden || !turnEditMode,
        onClick: () => turnEditMode?.(),
      },
      {
        name: 'delete',
        hidden,
        disabled: document.sentOut,
        onClick: handleDeleteDocument(document, entityId, scope, 'dossier'),
      },
    ];
  };

export const dossierDocumentsColumns: DocumentsColumn<
  DossierTableDocumentData,
  DossierDocumentsContextInterface
>[] = [
  {
    name: 'attachedToDossier',
    type: 'checkbox',
    headerTooltip: 'attachmentInformation',
    Component: AttachDocumentCell,
  },
  {
    name: 'documentName',
    type: 'text',
    editable: true,
  },
  {
    name: 'documentType',
    Component: DocumentTypeCell,
  },
  {
    name: 'date',
    type: 'date',
    editable: true,
    Component: DocumentDateCell,
  },
  {
    name: 'createdAt',
    valueGetter: ({ createdAt }) => formatDate(createdAt, 'dd/MM/yyyy HH:mm') || '',
  },
];
