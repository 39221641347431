import { BaseSyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, DialogActions, DialogContent } from '@mui/material';
import Button from 'atoms/Button';
import useFileUpload from 'hooks/useFileUpload';
import { DocumentsForm, HandleSingleDocumentSave } from 'hooks/useFileUpload/types';
import { getDocumentToUpload } from 'hooks/useFileUpload/utils';
import UploadDocuments from 'modules/UploadDocuments';
import { UploadDocumentsProps } from 'modules/UploadDocuments/types';
import ModalTitle from './ModalTitle';
import ModalWrapper from './ModalWrapper';

interface Props extends UploadDocumentsProps {
  onClose: () => void;
  onSubmit: () => Promise<void>;
  onSingleDocumentSave?: HandleSingleDocumentSave;
  missingDocuments?: DocumentsForm['documents'];
}

export const DocumentsModal = ({
  onClose,
  onSingleDocumentSave,
  onSubmit,
  missingDocuments,
  scope,
  entityId,
  ...uploadDocumentsProps
}: Props) => {
  const [modalWidth, setModalWidth] = useState<Breakpoint>('sm');
  const { t } = useTranslation(['modals', 'common']);
  const {
    isAnyPending,
    removeDocument,
    addDocument,
    saveDocuments,
    documents,
    formMethods,
    updateDocument,
  } = useFileUpload({
    onSingleDocumentSave,
    defaultFields:
      missingDocuments ??
      (scope && entityId ? [getDocumentToUpload({ scope, entityId })] : []),
  });

  const closeModal = () => {
    if (!isAnyPending) {
      onClose();
    }
  };

  const handleSave = async (event?: BaseSyntheticEvent) => {
    const status = await saveDocuments(event);
    if (
      status === 'ALL_SAVED' ||
      status === 'SOME_SAVED' ||
      status === 'SOME_SAVED_WITHOUT_ERROR'
    ) {
      onSubmit();
      if (
        status === 'ALL_SAVED' ||
        (status === 'SOME_SAVED_WITHOUT_ERROR' && !!missingDocuments?.length)
      ) {
        closeModal();
      }
    }
  };

  return (
    <ModalWrapper open onClose={closeModal} fullWidth maxWidth={modalWidth}>
      <ModalTitle title={t('title.uploadDocuments')} closeModal={closeModal} />
      <DialogContent sx={{ '&.MuiDialogContent-root': { pt: 1, pb: 0 } }}>
        <UploadDocuments
          scope={scope}
          entityId={entityId}
          documents={documents}
          formMethods={formMethods}
          removeDocument={removeDocument}
          addDocument={addDocument}
          updateDocument={updateDocument}
          isUploadingMissingDocuments={!!missingDocuments?.length}
          setModalWidth={setModalWidth}
          {...uploadDocumentsProps}
        />
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button
          role="text"
          onClick={closeModal}
          disabled={isAnyPending}
          data-testid="cancel"
        >
          {t('common:close')}
        </Button>
        <Button
          disableElevation
          onClick={handleSave}
          disabled={
            isAnyPending ||
            !documents.length ||
            documents.every(({ files }) => !files.length)
          }
          data-testid="save"
        >
          {t('common:save')}
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};
