import { toast } from 'react-toastify';
import axios from 'axios';
import i18n from 'i18n';
import getAuthorizationHeader from 'utils/getAuthorizationHeader';
import { firstCharLowerCase } from 'utils/string';
import { ApiErrorReturn, ApiRequestConfig, ApiReturn, RequestConfig } from './types';
import { formatPropertyName, getBaseUrl, getCustomErrorMessage } from './utils';

const request = async <TResponse, TErrorResponse = unknown>({
  baseURL = getBaseUrl(),
  customErrorMessage,
  ...config
}: RequestConfig): Promise<ApiReturn<TResponse, TErrorResponse>> => {
  try {
    const Authorization = await getAuthorizationHeader();
    const { data, status } = await axios<TResponse>({
      baseURL,
      headers: { Authorization, ...config.headers },
      ...config,
    });
    return {
      ok: true,
      status,
      response: data,
    };
  } catch (error) {
    let message = '';
    let status: ApiErrorReturn['status'] = undefined;
    let validation: ApiErrorReturn['validation'] = undefined;
    let response: TErrorResponse | undefined = undefined;

    if (axios.isAxiosError(error)) {
      message = error.message;
      status = error.status;

      if (error.response) {
        const data = error.response.data;
        status = error.response.status;
        message = data?.error ?? message;

        if (Array.isArray(data)) {
          validation = data.map(({ errorCode, propertyName, errorMessage }) => ({
            // Address.Street -> address.street
            field: formatPropertyName(propertyName),
            // Invalid -> invalid
            errorCode: firstCharLowerCase(errorCode),
            defaultValue: errorMessage,
          }));
        }

        const customMessage = getCustomErrorMessage(
          customErrorMessage,
          status,
          data?.errorCode,
        );
        if (customMessage) {
          toast.error(i18n.t(`errors:${customMessage}`, message), {
            toastId: `${status}-error-message`,
          });
        } else if (status >= 409) {
          response = data;
          if (data?.errorCode) {
            message = i18n.t(`errors:${data.errorCode}`, {
              ...data.data,
              defaultMessage: message,
            });
          } else {
            message = i18n.t(
              `errors:status.${status}`,
              message || i18n.t('errors:status.default'),
            );
          }
          toast.error(message, { toastId: `${status}-error` });
        }
      }

      console.log({ message, error });
    } else {
      message = 'An unexpected error occurred';
      console.log({ message, error });
    }

    return {
      ok: false,
      status,
      validation,
      message,
      response,
    };
  }
};

const api = {
  get: <T = unknown, E = unknown>(url: string, config?: ApiRequestConfig) =>
    request<T, E>({ method: 'GET', url, ...config }),
  post: <T = unknown, E = unknown, D = unknown>(
    url: string,
    data: D,
    config?: Omit<ApiRequestConfig, 'data'>,
  ) => request<T, E>({ method: 'POST', url, data, ...config }),
  put: <T = unknown, E = unknown, D = unknown>(
    url: string,
    data: D,
    config?: Omit<ApiRequestConfig, 'data'>,
  ) => request<T, E>({ method: 'PUT', url, data, ...config }),
  patch: <T = unknown, E = unknown, D = unknown>(
    url: string,
    data: D,
    config?: Omit<ApiRequestConfig, 'data'>,
  ) => request<T, E>({ method: 'PATCH', url, data, ...config }),
  delete: <T = unknown, E = unknown>(url: string, config?: ApiRequestConfig) =>
    request<T, E>({ method: 'DELETE', url, ...config }),
};

export default api;
