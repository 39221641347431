import { FormEventHandler, MouseEventHandler, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import Button from 'atoms/Button';

type Props = PropsWithChildren<{
  title: string;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  submitting?: boolean;
}>;

export const FormComponent = ({
  children,
  title,
  onCancel,
  onSubmit,
  submitting,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={4} component="form" onSubmit={onSubmit}>
      <Typography variant="h5">{title}</Typography>
      <Box>{children}</Box>
      <Stack justifyContent="flex-end" direction="row" spacing={2}>
        <Button role="text" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button disableElevation type="submit" loading={submitting}>
          {t('save')}
        </Button>
      </Stack>
    </Stack>
  );
};
