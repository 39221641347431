import { createContext, PropsWithChildren, useState } from 'react';
import StorageKeys from 'consts/storage';
import Cookies from 'js-cookie';

const BackOfficeContext = createContext<{
  impersonate: (id: string) => void;
  finishImpersonating: () => void;
  impersonateId: string;
}>({
  impersonate: () => {},
  finishImpersonating: () => {},
  impersonateId: '',
});

const BackOfficeProvider = ({ children }: PropsWithChildren) => {
  const [impersonateId, setImpersonateId] = useState(
    () => Cookies.get(StorageKeys.ImpersonatedBusinessId) || '',
  );

  const impersonate = (id: string) => {
    Cookies.set(StorageKeys.ImpersonatedBusinessId, id);
    setImpersonateId(id);
  };

  const finishImpersonating = () => {
    Cookies.remove(StorageKeys.ImpersonatedBusinessId);
    setImpersonateId('');
  };
  return (
    <BackOfficeContext.Provider
      value={{ impersonate, finishImpersonating, impersonateId }}
    >
      {children}
    </BackOfficeContext.Provider>
  );
};

export { BackOfficeContext, BackOfficeProvider };
