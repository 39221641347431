import { TranslatedOption } from 'formData/types';
import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import { NaturalPersonFormData } from 'api/person/natural/types';

export const sexOptions: TranslatedOption<NaturalPersonFormData['sex']>[] = getObjectKeys(
  namespaces.enums.sex,
).map((value) => ({
  value,
  label: value,
  translationKey: `sex.${value}`,
}));

export const taxResidencesOptions: TranslatedOption<
  NonNullable<NaturalPersonFormData['taxResidenceBelgium']>
>[] = getObjectKeys(namespaces.enums.taxResidences).map((value) => ({
  value,
  label: value,
  translationKey: `taxResidences.${value}`,
}));
