import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useFormMethods from 'hooks/useFormMethods';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { AddressFormInterface } from 'api/common/types';
import { ControlledInput } from 'components/controlledInputs';
import { AddressFieldsProps } from './types';

const optionalFields: (keyof AddressFormInterface)[] = ['apartmentNumber', 'extension'];

const AddressFields = ({
  separateLabel,
  prefix,
  fields,
  withAsterisk,
}: AddressFieldsProps) => {
  const { t } = useTranslation('formFields');
  const { control, getError, getName } = useFormMethods<{
    address: AddressFormInterface;
  }>(prefix);

  return (
    <>
      {fields.map(({ name, hidden, sizes, ...field }) =>
        !hidden ? (
          separateLabel ? (
            <InputLabelWrapper
              key={name}
              label={`${t(`address.${name}`)}${
                withAsterisk && !optionalFields.includes(name) ? '*' : ''
              }`}
            >
              <ControlledInput
                name={getName(`address.${name}`)}
                error={getError(`address.${name}`)}
                control={control}
                hideHelperTextGap
                {...field}
              />
            </InputLabelWrapper>
          ) : (
            <Grid item key={name} xs={sizes?.xs ?? 12} sm={sizes?.sm ?? 6}>
              <ControlledInput
                name={getName(`address.${name}`)}
                label={t(`address.${name}`)}
                control={control}
                error={getError(`address.${name}`)}
                {...field}
              />
            </Grid>
          )
        ) : null,
      )}
    </>
  );
};

export default AddressFields;
