import {
  BackofficePermissions,
  DashboardPermissions,
  DossierPermissions,
  EstatePermissions,
  PersonPermissions,
  UserPermissions,
} from 'consts/permissions';
import i18n from 'i18n';
import {
  BusinessesIcon,
  CodeIcon,
  DashboardIcon,
  DossierIcon,
  EstateIcon,
  LanguageIcon,
  Layers,
  MoveIcon,
  PersonsIcon,
  ReportIcon,
  SettingsIcon,
} from 'icons';
import routes, {
  dossierDocumentsTabRoutes,
  dossierTabRoutes,
  estateTabRoutes,
  personTabRoutes,
} from 'router/routes';
import { apiUrl } from 'api/const';
import { NavItem } from './types';

const getTabRoutes = (tabRoutes: object, baseRoute: string): string[] =>
  Object.values(tabRoutes).map((route) => `${baseRoute}/${route}`);

const navItems: NavItem[] = [
  {
    to: routes.dashboard,
    label: 'dashboard',
    Icon: DashboardIcon,
    permission: DashboardPermissions.Read,
  },
  {
    to: routes.dossiers,
    label: 'dossiers',
    Icon: DossierIcon,
    permission: DossierPermissions.Read,
    subLinks: [
      routes.newDossier,
      routes.rentalDossier(':dossierId'),
      ...getTabRoutes(dossierTabRoutes, routes.rentalDossier(':dossierId')),
      ...getTabRoutes(
        dossierDocumentsTabRoutes,
        routes.rentalDossierTab(':dossierId').documents,
      ),
      routes.rentalDossierProduct(':dossierId', ':productId'),
    ],
  },
  {
    to: routes.estates,
    label: 'estates',
    Icon: EstateIcon,
    permission: EstatePermissions.Read,
    subLinks: [
      routes.newEstate,
      routes.estate(':estateId'),
      ...getTabRoutes(estateTabRoutes, routes.estate(':estateId')),
    ],
  },
  {
    to: routes.persons,
    label: 'persons',
    Icon: PersonsIcon,
    permission: PersonPermissions.Read,
    subLinks: [
      routes.legalPerson(':legalPersonId'),
      ...getTabRoutes(personTabRoutes, routes.legalPerson(':legalPersonId')),
      routes.newPerson,
      routes.naturalPerson(':naturalPersonId'),
      ...getTabRoutes(personTabRoutes, routes.naturalPerson(':naturalPersonId')),
    ],
  },
];

const externalServices: NavItem[] = [
  {
    to: '',
    label: 'myMove',
    Icon: MoveIcon,
    external: true,
    permission: DashboardPermissions.Read,
    fetchRedirectionLink: apiUrl.myMoveRedirection,
  },
  {
    to: '',
    label: 'checkNet',
    Icon: ReportIcon,
    external: true,
    permission: DashboardPermissions.Read,
    fetchRedirectionLink: apiUrl.checknetRedirection,
  },
];

const preferencesItems: NavItem[] = [
  {
    to: '#',
    label: 'settings',
    Icon: SettingsIcon,
    disabled: true,
  },
  {
    to: routes.users,
    label: 'users',
    Icon: PersonsIcon,
    permission: UserPermissions.Write,
  },
];

const backofficeItems: NavItem[] = [
  {
    to: routes.businesses,
    label: 'businesses',
    Icon: BusinessesIcon,
    permission: BackofficePermissions.Read,
  },
  {
    to: routes.migrationQueue,
    label: 'migrationQueue',
    Icon: Layers,
    permission: BackofficePermissions.Read,
  },
  {
    to: routes.translations,
    label: 'translations',
    Icon: LanguageIcon,
    permission: BackofficePermissions.Read,
  },
  {
    to: '',
    label: 'cimode',
    Icon: CodeIcon,
    permission: BackofficePermissions.Read,
    onClick: () => i18n.changeLanguage('cimode'),
    customClickAction: true,
  },
];

export const lists = {
  mainMenu: navItems,
  services: externalServices,
  preferences: preferencesItems,
  backoffice: backofficeItems,
} as const;
