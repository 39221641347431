import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { TextDivider } from 'atoms/TextDivider/TextDivider';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { PersonType } from 'api/person/types';
import { ControlledInput } from 'components/controlledInputs';
import { getBasicInformationFields } from './fields';

interface BasicInformationProps {
  index: number;
  type: 'landlords' | 'tenants';
  personType: PersonType;
}

export const BasicInformation = ({ index, type, personType }: BasicInformationProps) => {
  const { t } = useTranslation(['formFields', 'modals']);
  const { control, formState } = useFormContext<RegisterAddendumForm>();

  const fields = getBasicInformationFields(personType);

  return (
    <Box>
      <TextDivider
        label={t(
          'modals:productActionModal.RentalContractRegisterAddendum.personsSections.basicInformation',
        )}
      />
      <Grid container mt={2} columnSpacing={2} rowSpacing={0.5}>
        {fields.map(({ name, ...field }) => (
          <Grid key={name} xs={6} item>
            <ControlledInput
              control={control}
              name={`${type}.${index}.${name}`}
              label={t(`formFields:${name}`)}
              error={formState.errors[type]?.[index]?.[name]}
              {...field}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
