import { useContext, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { UserPermissions } from 'consts/permissions';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import { fetchEstateOwners } from 'modules/DossierForm/utils';
import { AddEstateModal } from 'modules/ModalForm/AddEstateModal';
import { AddPersonModal } from 'modules/ModalForm/AddPersonModal';
import { MAX_OWNERS, MAX_RESPONSIBLE_PEOPLE } from 'modules/OwnershipCard/static-data';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import { RelationData } from 'modules/Relation/types';
import {
  estateToRelation,
  fetchEstateOptions,
  fetchPersonRelationOptions,
  fetchUsersOptions,
  getPersonsIdsToFilter,
  personToRelation,
  selectPersonWithPartner,
  userToRelation,
} from 'modules/Relation/utils';
import { useUpdateEffect } from 'usehooks-ts';
import hasPermission from 'utils/hasPermission';
import { FetchedEstateListItem } from 'api/estate/types';
import { FetchedPersonData } from 'api/person/types';
import { FetchedUserData } from 'api/users/types';
import { getErrorMessage } from 'components/controlledInputs/utils';

const ParametersStep = () => {
  const { estateOptions, landlordOptions, tenantOptions, dossierMethods } =
    useContext(DossierFormContext);

  const {
    setValue,
    getValues,
    formState: { errors },
  } = dossierMethods;

  const estate = getValues('estate');
  const [estatesToPreview, setEstatesToPreview] = useState<
    RelationData<FetchedEstateListItem>[]
  >(estate ? [estateToRelation(estate)] : []);

  const [landlordsToPreview, setLandlordsToPreview] = useState<
    RelationData<FetchedPersonData>[]
  >(getValues('landlords').map(personToRelation));

  const [tenantsToPreview, setTenantsToPreview] = useState<
    RelationData<FetchedPersonData>[]
  >(getValues('tenants').map(personToRelation));

  const [responsibleUsersToPreview, setResponsibleUsersToPreview] = useState<
    RelationData<FetchedUserData>[]
  >(getValues('responsibleUsers').map((user) => userToRelation(user)));

  const estatesToFilter = useMemo(
    () => estatesToPreview.map(({ id }) => id),
    [estatesToPreview],
  );

  const personsIdsToFilter = useMemo(
    () => getPersonsIdsToFilter([...landlordsToPreview, ...tenantsToPreview]),
    [landlordsToPreview.length, tenantsToPreview.length],
  );

  const usersToFilter = useMemo(
    () => [...responsibleUsersToPreview.map(({ id }) => id)],
    [responsibleUsersToPreview],
  );

  useUpdateEffect(() => {
    const updateEstate = async () => {
      const estate = estatesToPreview.at(0);
      setValue('estate', estate ?? null, { shouldValidate: true });

      const owners = await fetchEstateOwners(estate, personsIdsToFilter);
      setLandlordsToPreview(owners.map(personToRelation));
    };

    updateEstate();
  }, [estatesToPreview]);

  useUpdateEffect(() => {
    setValue('responsibleUsers', responsibleUsersToPreview, { shouldValidate: true });
  }, [responsibleUsersToPreview]);

  useUpdateEffect(() => {
    setValue('tenants', tenantsToPreview, { shouldValidate: true });
  }, [tenantsToPreview]);

  useUpdateEffect(() => {
    setValue('landlords', landlordsToPreview, { shouldValidate: true });
  }, [landlordsToPreview]);

  return (
    <Grid container rowSpacing={2} width="100%" maxWidth={750}>
      <Grid item xs={12} data-testid="dossier-estate-section">
        <AddRelation
          translationKey="dossierEstate"
          maxRelations={1}
          relationsToFilter={estatesToFilter}
          fetchOptions={fetchEstateOptions}
          setRelationsToPreview={setEstatesToPreview}
          AddNewOptionModal={AddEstateModal}
          errorMessage={getErrorMessage(errors.estate)}
          contextOptions={estateOptions}
          hideHelperTextGap={false}
        />
        <RelationPreviewList
          showIcons
          withPrompt
          relationsToPreview={estatesToPreview}
          setRelationsToPreview={setEstatesToPreview}
        />
      </Grid>
      <Grid item xs={12} data-testid="dossier-landlord-section">
        <AddRelation
          translationKey="landlord"
          maxRelations={MAX_OWNERS}
          relationsToFilter={personsIdsToFilter}
          selectedRelations={landlordsToPreview.map(({ id }) => id)}
          fetchOptions={fetchPersonRelationOptions}
          AddNewOptionModal={AddPersonModal}
          errorMessage={getErrorMessage(errors.landlords)}
          contextOptions={landlordOptions}
          hideHelperTextGap={false}
          onSelectOption={selectPersonWithPartner(
            setLandlordsToPreview,
            personsIdsToFilter,
          )}
        />
        <RelationPreviewList
          showIcons
          withPrompt
          relationsToPreview={landlordsToPreview}
          setRelationsToPreview={setLandlordsToPreview}
        />
      </Grid>
      <Grid item xs={12} data-testid="dossier-tenant-section">
        <AddRelation
          translationKey="tenant"
          maxRelations={MAX_OWNERS}
          relationsToFilter={personsIdsToFilter}
          selectedRelations={tenantsToPreview.map(({ id }) => id)}
          fetchOptions={fetchPersonRelationOptions}
          AddNewOptionModal={AddPersonModal}
          errorMessage={getErrorMessage(errors.tenants)}
          onSelectOption={selectPersonWithPartner(
            setTenantsToPreview,
            personsIdsToFilter,
          )}
          contextOptions={tenantOptions}
          hideHelperTextGap={false}
        />
        <RelationPreviewList
          showIcons
          withPrompt
          relationsToPreview={tenantsToPreview}
          setRelationsToPreview={setTenantsToPreview}
        />
      </Grid>
      {hasPermission(UserPermissions.Read) && (
        <Grid item xs={12} data-testid="dossier-responsible-persons-section">
          <AddRelation
            translationKey="responsibleUser"
            // we have to subtract one as backend adds creator of dossier automatically
            maxRelations={MAX_RESPONSIBLE_PEOPLE - 1}
            relationsToFilter={usersToFilter}
            fetchOptions={fetchUsersOptions}
            setRelationsToPreview={setResponsibleUsersToPreview}
            errorMessage={getErrorMessage(errors.responsibleUsers)}
            creatable={false}
            shouldFilter
            withHelperText
          />
          <RelationPreviewList
            withPrompt
            relationsToPreview={responsibleUsersToPreview}
            setRelationsToPreview={setResponsibleUsersToPreview}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ParametersStep;
