import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PersonPermissions } from 'consts/permissions';
import { Documents } from 'modules/DocumentsCard';
import hasPermission from 'utils/hasPermission';
import { LegalPersonOutletContext } from '../types';

const LegalPersonDocumentsTab = () => {
  const { legalPersonId } = useOutletContext<LegalPersonOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Documents
          entityId={legalPersonId}
          scope="legalPerson"
          editable={hasPermission(PersonPermissions.Write)}
        />
      </Grid>
    </Grid>
  );
};

export default LegalPersonDocumentsTab;
