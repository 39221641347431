import {
  addressSchema,
  bicSchema,
  ibanSchema,
  languageSchema,
  phoneSchema,
} from 'formData/common/schema';
import { TKey } from 'i18next';
import yup from 'utils/yup';
import dateOfBirthSchema from './dateOfBirth/schema';
import nationalRegisterNumberSchema from './nationalRegisterNumber/schema';
import placeOfBirthSchema from './placeOfBirth/schema';
import taxResidencesSchema from './taxResidences/schema';

const naturalPersonSchema = yup.object({
  address: addressSchema,
  firstName: yup.string().label('firstName').required(),
  name: yup.string().label('name').required(),
  givenName: yup.string().label('givenName').nullable().default(null),
  email: yup.string().email().label('email').required(),
  phone: phoneSchema.nullable(),
  language: languageSchema.required(),
  sex: yup.string<TKey<'enums', 'sex'>>().label('sex').required(),
  dateOfBirth: dateOfBirthSchema,
  placeOfBirth: placeOfBirthSchema,
  nationality: yup.string().label('nationality').nullable().default(null),
  nationalRegisterNumber: nationalRegisterNumberSchema,
  iban: ibanSchema,
  bic: bicSchema,
  isPoliticallyExposedPerson: yup.boolean().default(false),
  taxResidenceBelgium: yup
    .string<TKey<'enums', 'taxResidences'>>()
    .nullable()
    .label('taxResidenceBelgium')
    .default(null),
  taxResidences: taxResidencesSchema,
});

export default naturalPersonSchema;
