import { useMemo } from 'react';
import { Card, CardActionArea, CardContent, Divider, Stack } from '@mui/material';
import CustomLink from 'atoms/Link';
import routes from 'router/routes';
import AssignedDossierEstate from './AssignedDossierEstate';
import AssignedDossierHeader from './AssignedDossierHeader';
import AssignedDossierParties from './AssignedDossierParties';
import { AssignedDossierCardProps } from './types';
import { getAssignedDossierCardHeight } from './utils';

const AssignedDossierCard = ({
  id,
  entityId,
  type,
  landlords,
  tenants,
  estate,
  ...headerProps
}: AssignedDossierCardProps) => {
  const role = useMemo(() => {
    let role: 'Landlord' | 'Tenant' | null = null;
    if (type === 'Person') {
      if (landlords.some((landlord) => landlord.personId === entityId)) {
        role = 'Landlord';
      } else if (tenants.some((tenant) => tenant.personId === entityId)) {
        role = 'Tenant';
      }
    }
    return role;
  }, [entityId]);

  return (
    <Card
      variant="outlined"
      sx={({ mixins }) => ({
        ...mixins.actionCard,
        minHeight: getAssignedDossierCardHeight(type),
      })}
    >
      <CardActionArea sx={{ height: '100%' }}>
        <CustomLink to={routes.rentalDossierTab(id).products}>
          <CardContent>
            <AssignedDossierHeader {...headerProps} role={role} />
            {type === 'Person' && (
              <Stack gap={1}>
                <AssignedDossierEstate estate={estate} />
                <Divider />
              </Stack>
            )}
            <AssignedDossierParties role={role} tenants={tenants} landlords={landlords} />
          </CardContent>
        </CustomLink>
      </CardActionArea>
    </Card>
  );
};

export default AssignedDossierCard;
