import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import getLng from 'utils/getLng';
import useEstateDescription from './useEstateEdition';

type Props = Pick<ReturnType<typeof useEstateDescription>, 'descriptions'>;

const EstateDescription = ({ descriptions }: Props) => {
  const { t } = useTranslation('formFields');

  return (
    <Box sx={({ mixins }) => ({ ...mixins.scrollableCardContent(230), p: 2 })}>
      {descriptions[getLng()] ? (
        <Typography whiteSpace="pre-wrap" data-testid="estateDescription">
          {descriptions[getLng()]}
        </Typography>
      ) : (
        <Box minHeight={100} sx={({ mixins }) => mixins.centered}>
          <Typography fontStyle="italic" color="GrayText">
            {Object.values(descriptions).some(Boolean)
              ? t('missingEstateDescription', {
                  lng: t(`enums:translations.${getLng()}`, ''),
                })
              : t('noEstateDescriptionAdded')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EstateDescription;
