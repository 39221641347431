import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TableCell, TableRow } from '@mui/material';
import { documentsSchema } from 'formData/documents/schema';
import { resolver } from 'utils/yup';
import { DocumentData } from 'api/documents/types';
import { ControlledInput } from 'components/controlledInputs';
import DocumentExpander from '../cells/DocumentExpander';
import DocumentFiles from '../DocumentFiles';
import { actionCellStyles, tableCellStyles } from '../styles';
import { DocumentsContextInterface } from '../types';
import DocumentActions from './DocumentActions';
import DocumentEditionButtons from './DocumentEditionButtons';
import { DocumentRowProps, DocumentsTableForm } from './types';
import { getDocumentFieldValue, saveEditedDocument } from './utils';

const EditableDocumentRow = <
  TData extends DocumentData,
  TContext extends DocumentsContextInterface<TData>,
>({
  columns,
  document,
  context,
  getActions,
  updateDocuments,
}: DocumentRowProps<TData, TContext>) => {
  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { documentConfigData, entityId, scope } = context;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DocumentsTableForm>({
    defaultValues: { ...document },
    context: { documentConfigData },
    resolver: resolver(documentsSchema),
  });

  const toggleExpand = () => setExpanded((prev) => !prev);
  const toggleEditing = () => setEditing((prev) => !prev);

  const onSubmit: SubmitHandler<DocumentsTableForm> = async (data) => {
    setSubmitting(true);
    const { ok } = await saveEditedDocument(
      { documentId: document.id, entityId, scope },
      data,
    );
    if (ok) {
      await updateDocuments();
      setEditing(false);
    }
    setSubmitting(false);
  };

  return (
    <>
      <TableRow>
        <DocumentExpander
          expanded={expanded}
          toggleExpand={toggleExpand}
          document={document}
        />
        {columns.map(({ name, type, Component, editable, valueGetter }) => (
          <TableCell sx={tableCellStyles} key={`${name}-${document.id}-cell`}>
            {editable && editing ? (
              <ControlledInput
                name={name as keyof DocumentsTableForm}
                type={type}
                control={control}
                error={errors[name as keyof DocumentsTableForm]}
                hideHelperTextGap
                dateInputProps={{
                  disableFuture: documentConfigData?.date?.type === 'Past',
                  disablePast: documentConfigData?.date?.type === 'Future',
                }}
              />
            ) : Component ? (
              <Component
                document={document}
                updateDocuments={updateDocuments}
                {...context}
              />
            ) : (
              valueGetter?.(document, scope) || getDocumentFieldValue(name, document)
            )}
          </TableCell>
        ))}
        <TableCell sx={actionCellStyles}>
          {editing ? (
            <DocumentEditionButtons
              submitting={submitting}
              onSave={handleSubmit(onSubmit)}
              onCancel={toggleEditing}
            />
          ) : (
            <DocumentActions
              document={document}
              actions={getActions(document, entityId, scope, toggleEditing)}
              updateDocuments={updateDocuments}
            />
          )}
        </TableCell>
      </TableRow>
      <DocumentFiles
        open={expanded}
        document={document}
        context={context}
        updateDocuments={updateDocuments}
      />
    </>
  );
};

export default EditableDocumentRow;
