import useDocumentsData from 'hooks/useDocumentsData';
import useDocumentsModal from 'hooks/useDocumentsModal';
import DocumentsTable from 'templates/DocumentsTable';
import {
  documentsColumns,
  getDocumentActions,
} from 'templates/DocumentsTable/static-data';
import { DocumentsModal } from 'components/modals';
import { DocumentsProps } from './types';

export const Documents = ({ entityId, scope, editable = true }: DocumentsProps) => {
  const { isOpen, openModal, closeModal } = useDocumentsModal();
  const { documentsData, loading, fetchDocuments } = useDocumentsData(entityId, scope);

  return (
    <>
      <DocumentsTable
        data={documentsData}
        columns={documentsColumns}
        getActions={getDocumentActions}
        context={{ entityId, scope }}
        editable={editable}
        updateDocuments={fetchDocuments}
        loading={loading}
        toolbarProps={{ actionProps: { onClick: openModal } }}
      />
      {isOpen && (
        <DocumentsModal
          scope={scope}
          entityId={entityId}
          onClose={closeModal}
          onSubmit={fetchDocuments}
        />
      )}
    </>
  );
};
