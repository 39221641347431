import { useCallback, useEffect, useState } from 'react';
import { UserPermissions } from 'consts/permissions';
import { RelationData } from 'modules/Relation/types';
import hasPermission from 'utils/hasPermission';
import transformName from 'utils/transformName';
import { updateRentalDossier } from 'api/dossier/requests';
import { getUsers } from 'api/users/requests';
import { FetchedUserData } from 'api/users/types';
import { DossierCardProps } from '../types';
import { DossierResponsibleUser } from './types';

export const useResponsibleUsers = ({
  dossierId,
  dossierData,
  fetchRentalDossier,
}: DossierCardProps) => {
  const [loading, setLoading] = useState(true);
  const [createdBy, setCreatedBy] = useState<DossierResponsibleUser>();
  const [responsibleUsersToPreview, setResponsibleUsersToPreview] = useState<
    RelationData<FetchedUserData>[]
  >([]);
  const [responsibleUsers, setResponsibleUsers] = useState<DossierResponsibleUser[]>([]);

  const getUsersData = useCallback(async () => {
    if (hasPermission(UserPermissions.Read)) {
      setLoading(true);
      const { ok, response } = await getUsers({
        limit: 10,
        ids: dossierData.responsibleUsers?.join(','),
      });
      if (ok) {
        const responsibleUsers = response.data.map((user) => ({
          id: user.id,
          name: transformName(user),
        }));
        const creator = responsibleUsers.find(({ id }) => id === dossierData.createdBy);
        if (creator) {
          setCreatedBy(creator);
          setResponsibleUsers([
            creator,
            ...responsibleUsers.filter(({ id }) => id !== creator.id),
          ]);
        } else {
          setResponsibleUsers(responsibleUsers);
        }
      }
    }
    setLoading(false);
  }, [dossierId]);

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  const deleteResponsibleUser = async (userId: string) => {
    const filteredUsers = responsibleUsers.filter(({ id }) => id !== userId);
    const response = await updateRentalDossier(dossierId, {
      ...dossierData,
      responsibleUsers: filteredUsers.map(({ id }) => id),
    });
    if (response.ok) {
      await fetchRentalDossier();
      setResponsibleUsers(filteredUsers);
    }
    return response;
  };

  return {
    loading,
    createdBy,
    responsibleUsers,
    setResponsibleUsers,
    deleteResponsibleUser,
    responsibleUsersToPreview,
    setResponsibleUsersToPreview,
  };
};
