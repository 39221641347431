import { useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import usePrompt from 'hooks/usePrompt';
import { updateRentalDossier } from 'api/dossier/requests';
import { DossierCardProps } from '../types';
import { DossierStaticData } from './types';
import { useResponsibleUsers } from './useResponsibleUsers';

export const useDossierDetails = ({
  dossierId,
  dossierData,
  fetchRentalDossier,
}: DossierCardProps) => {
  const initOwnReference = useMemo(() => {
    return dossierData.ownReference ?? '';
  }, [dossierData.ownReference]);

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [ownReference, setOwnReference] = useState(initOwnReference);

  const {
    loading,
    createdBy,
    responsibleUsers,
    setResponsibleUsers,
    responsibleUsersToPreview,
    setResponsibleUsersToPreview,
    deleteResponsibleUser,
  } = useResponsibleUsers({ dossierId, dossierData, fetchRentalDossier });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const staticData: DossierStaticData = useMemo(() => {
    const { address, postCode, city, type } = dossierData.estate;
    const estateAddress = `${address}, ${postCode} ${city}`;

    return {
      estate: matches ? estateAddress : undefined,
      estateType: matches ? type : undefined,
      createdBy: createdBy?.name,
      createdAt: dossierData.createdAt,
    };
  }, [dossierData.createdAt, createdBy?.name, dossierData.estate, matches]);

  const isDirty = useMemo(
    () => initOwnReference !== ownReference || !!responsibleUsersToPreview.length,
    [initOwnReference, ownReference, responsibleUsersToPreview],
  );

  const toggleEditing = () => setEditing((prev) => !prev);

  const onCancel = () => {
    toggleEditing();
    setOwnReference(initOwnReference);
    setResponsibleUsersToPreview([]);
  };

  const onSave = async () => {
    setSaving(true);
    const usersToSave = [...responsibleUsers, ...responsibleUsersToPreview];

    const { ok } = await updateRentalDossier(dossierId, {
      ...dossierData,
      responsibleUsers: usersToSave.map(({ id }) => id),
      ownReference,
    });

    if (ok) {
      await fetchRentalDossier();
      setResponsibleUsersToPreview([]);
      setResponsibleUsers(usersToSave);
      toggleEditing();
    }

    setSaving(false);
  };

  usePrompt({ when: isDirty, id: 'dossierDetails' });

  return {
    onSave,
    onCancel,
    onEdit: toggleEditing,
    loading,
    saving,
    saveDisabled: !isDirty,
    isEditing: editing,
    staticData,
    ownReferenceState: [ownReference, setOwnReference] as const,
    responsibleUsersProps: {
      createdBy,
      responsibleUsers,
      deleteResponsibleUser,
      responsibleUsersToPreview,
      setResponsibleUsersToPreview,
    },
  };
};
