import { matchPath } from 'react-router-dom';
import { TKey } from 'i18next';
import hasPermission from 'utils/hasPermission';
import { getObjectEntries } from 'utils/object';
import { ProductName } from 'api/dossier/products/types';
import { FetchedDossierChecklistItem } from 'api/dossier/types';
import { lists } from './static-data';
import { NavItem } from './types';

export const isMatchPath = (pathname: string, to: string, subLinks: string[]) =>
  pathname === to || subLinks.some((path) => Boolean(matchPath(path, pathname)));

const transformNavItemLabel = (
  label: TKey<'common', 'aside.items'>,
): ProductName | undefined => {
  switch (label) {
    case 'myMove':
      return 'MyMove';
    case 'checkNet':
      return 'StateReport';
  }
};

const getVisibleNavItems = (
  rentalDossierChecklist: FetchedDossierChecklistItem[],
  { permission, label }: NavItem,
) => {
  const productName = transformNavItemLabel(label);
  const permitted = permission ? hasPermission(permission) : true;

  if (productName) {
    if (rentalDossierChecklist.find(({ name }) => name === productName)) {
      return permitted;
    }
    return false;
  }
  return permitted;
};

export const getNavItems = (rentalDossierChecklist: FetchedDossierChecklistItem[]) =>
  getObjectEntries(lists)
    .map(([subheader, items]) => ({
      subheader,
      items: items.filter((item) => getVisibleNavItems(rentalDossierChecklist, item)),
    }))
    .filter(({ items }) => !!items.length);
