import { getAddressDisplayValues, getFormAddress } from 'formData/common/address/utils';
import phoneSchema from 'formData/common/phone/schema';
import { naturalPersonSchema } from 'formData/naturalPerson';
import IBAN from 'iban';
import { Language } from 'types/language';
import { formatDate } from 'utils/date';
import getObjectKeys from 'utils/object/getObjectKeys';
import transformPhone from 'utils/transformPhone';
import { NaturalPersonData, NaturalPersonFormData } from 'api/person/natural/types';
import { formatPhone } from 'components/inputs/utils';

const getTaxResidenceBelgium = (taxResidenceBelgium: boolean) =>
  taxResidenceBelgium ? 'Belgium' : 'NotBelgium';

export const getNaturalPersonDefaultValues = ({
  referenceNumber: _referenceNumber,
  dateOfBirth,
  address,
  phone,
  taxResidenceBelgium,
  iban,
  ...initialValues
}: NaturalPersonData): NaturalPersonFormData => ({
  ...naturalPersonSchema.getDefault(),
  ...initialValues,
  address: getFormAddress(address),
  iban: iban ? IBAN.printFormat(iban) : null,
  taxResidenceBelgium:
    taxResidenceBelgium === null ? null : getTaxResidenceBelgium(taxResidenceBelgium),
  dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd'),
  phone:
    phone && getObjectKeys(phone).every((key) => phone?.[key] !== null)
      ? {
          ...phone,
          number: formatPhone(phone.number, phone.code, phone.countryId),
        }
      : phoneSchema.getDefault(),
});

export const getNaturalPersonDisplayData = (
  lng: Language,
  {
    referenceNumber: _referenceNumber,
    address,
    firstName,
    name,
    givenName,
    email,
    language,
    sex,
    phone,
    taxResidences,
    taxResidenceBelgium,
    iban,
    ...rest
  }: NaturalPersonData,
) => ({
  address: getAddressDisplayValues(address, lng),
  general: {
    name,
    firstName,
    givenName,
    sex,
    language,
    email,
    phone: phone?.number ? transformPhone(phone) : null,
  },
  details: {
    ...rest,
    iban: iban ? IBAN.printFormat(iban) : null,
    taxResidenceBelgium:
      taxResidenceBelgium === null ? null : getTaxResidenceBelgium(taxResidenceBelgium),
    taxResidences: taxResidences?.map(
      ({ taxResidenceCountry, taxIdentificationNumber }) =>
        `${taxResidenceCountry} ${taxIdentificationNumber}`,
    ),
  },
});
