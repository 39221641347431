import { PartyFieldsConfig } from 'modules/ProductParties/types';

export const rentalDepositTenantsConfig: Partial<PartyFieldsConfig> = {
  context: 'rentalDeposit',
  maxFieldsCount: 2,
  shouldDisplaySecondaryField: ({ type }) => type !== 'LegalPerson',
  showPartner: 'asSecondaryContact',
  partnerIncludedInDossier: true,
  showRelationDropdown: true,
};

export const rentalDepositLandlordsConfig: Partial<PartyFieldsConfig> = {
  context: 'rentalDeposit',
  maxFieldsCount: 2,
  shouldDisplaySecondaryField: ({ type }) => type !== 'LegalPerson',
  showPartner: 'asSecondaryContact',
  partnerIncludedInDossier: true,
  showRelationDropdown: true,
};
