import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import { SelectOption } from 'components/inputs/types';

export const fireInsuranceSelectorOptions: SelectOption[] = getObjectKeys(
  namespaces.enums.fireInsuranceSelectors,
).map((value) => ({
  label: value,
  value: value,
  translationKey: `fireInsuranceSelectors.${value}`,
}));
