import { Navigate, RouteObject } from 'react-router-dom';
import { personTabRoutes } from 'router/routes';
import LegalPersonContactsTab from 'pages/Persons/LegalPersons/Details/tabs/Contacts';
import LegalPersonDetailsTab from 'pages/Persons/LegalPersons/Details/tabs/Details';
import LegalPersonDocumentsTab from 'pages/Persons/LegalPersons/Details/tabs/Documents';
import LegalPersonDossiersTab from 'pages/Persons/LegalPersons/Details/tabs/Dossiers';
import LegalPersonEstatesTab from 'pages/Persons/LegalPersons/Details/tabs/Estates';

export const legalPersonTabs: RouteObject[] = [
  { path: personTabRoutes.details, element: <LegalPersonDetailsTab /> },
  { path: personTabRoutes.contacts, element: <LegalPersonContactsTab /> },
  { path: personTabRoutes.estates, element: <LegalPersonEstatesTab /> },
  { path: personTabRoutes.dossiers, element: <LegalPersonDossiersTab /> },
  { path: personTabRoutes.documents, element: <LegalPersonDocumentsTab /> },
  { path: '*', element: <Navigate to={personTabRoutes.contacts} /> },
];
