import { createFilterOptions } from '@mui/material';
import i18n from 'i18n';
import { AsYouType, CountryCode, getExampleNumber } from 'libphonenumber-js/max';
import examples from 'libphonenumber-js/mobile/examples';
import getLng from 'utils/getLng';
import { Country } from 'api/config/types';
import {
  GetFilterOptions,
  SearchInputOption,
  SearchInputProps,
  SelectOption,
} from './types';

export const getFilterOptions: GetFilterOptions =
  ({ config, shouldFilter, creatable, loading }) =>
  (options, params) => {
    let filtered = [...options];

    if (shouldFilter) {
      filtered = createFilterOptions<SearchInputOption>(config)(options, params).filter(
        ({ hidden }) => !hidden,
      );
    }

    if (!loading && creatable) {
      const { inputValue } = params;
      const isExisting = options.some(
        (option) => inputValue === option.label || inputValue === option.value,
      );
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          value: inputValue,
          label: i18n.t('selectInput.addNewText', {
            input: inputValue,
            interpolation: { escapeValue: false },
          }),
          created: true,
        });
      }
    }

    return filtered;
  };

export const getInitOption = <T extends object = object>(
  options: SearchInputOption<T>[],
  value: SearchInputProps['value'],
): SearchInputOption<T> | null => {
  const option = options.find((option) => option.value === value);
  return option ?? null;
};

export const formatPhone = (input: string, code: string, countryId: CountryCode) => {
  const asYouType = new AsYouType(countryId);
  if (!code) {
    return asYouType.input(input);
  }

  const formatted = asYouType.input(`${code} ${input}`);
  return formatted.slice(code.length + 1).trim();
};

export const getPhonePlaceholder = (countryId: CountryCode) =>
  getExampleNumber(countryId, examples)
    ?.formatInternational()
    .slice(countryId.length + 1)
    .trim();

export const mapPhoneCodes = ({
  alpha2Code,
  phoneCode,
  name,
}: Country): SelectOption => ({
  code: phoneCode,
  label: name[getLng()],
  value: alpha2Code,
  suffix: phoneCode,
});
