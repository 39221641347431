import { ChangeEventHandler, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { BelgiumCountry } from 'formData/common/address/static-data';
import useCountryOptions from 'hooks/useCountryOptions';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js/max';
import { BaseInput } from './BaseInput';
import { Select } from './Select';
import { PhoneInputProps, SelectInputProps } from './types';
import { formatPhone, getPhonePlaceholder, mapPhoneCodes } from './utils';

export const PhoneInput = ({
  number: initNumber = '',
  code: initCode = BelgiumCountry.phoneCode,
  countryId: initCountryId = BelgiumCountry.alpha2Code,
  hideHelperTextGap,
  onNumberChange,
  onCountryChange,
  ...props
}: PhoneInputProps) => {
  const [input, setInput] = useState(initNumber);
  const [code, setCode] = useState(initCode);
  const [countryId, setCountryId] = useState(initCountryId);

  const [options, groupedOptions] = useCountryOptions(mapPhoneCodes);

  const handleCountryChange: SelectInputProps['onChange'] = (value) => {
    const countryId = value as CountryCode;
    setCountryId(countryId);

    const optionCode = options?.find(({ value }) => value === countryId)?.code;
    const code = optionCode ? String(optionCode) : '';

    setCode(code);
    const { nationalNumber } = parsePhoneNumberFromString(input, countryId) ?? {};
    const formatted = formatPhone(nationalNumber ?? input, code, countryId);
    setInput(formatted);

    if (onCountryChange) {
      onCountryChange(countryId, code, formatted);
    }
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const input = e.target.value;
    if (input !== '+') {
      const formatted = formatPhone(input, code, countryId);
      setInput(formatted);

      if (onNumberChange) {
        onNumberChange(formatted);
      }
    }
  };

  return (
    <Grid container spacing={0.5} justifyContent="space-between">
      <Grid item minWidth={70}>
        <Select
          name="phone_country"
          options={options}
          groupedOptions={groupedOptions}
          value={countryId}
          onChange={handleCountryChange}
          hideHelperTextGap={hideHelperTextGap}
          renderValue={({ value }) => value}
        />
      </Grid>
      <Grid item width={options ? 'calc(100% - 75px)' : '100%'}>
        <BaseInput
          {...props}
          type="tel"
          startAdornment={
            code ? <Typography lineHeight={0}>{code}</Typography> : undefined
          }
          value={input}
          placeholder={getPhonePlaceholder(countryId)}
          onChange={handleInputChange}
          hideHelperTextGap={hideHelperTextGap}
        />
      </Grid>
    </Grid>
  );
};
