import { ReactNode } from 'react';
import { IconButton, Tooltip } from '@mui/material';

interface Props {
  tooltipTitle: ReactNode;
  Icon: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export const TooltipIconButton = ({ tooltipTitle, Icon, onClick, disabled }: Props) => (
  <Tooltip title={tooltipTitle}>
    <span>
      <IconButton disabled={disabled} onClick={onClick}>
        {Icon}
      </IconButton>
    </span>
  </Tooltip>
);
