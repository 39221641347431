import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { DossierDetailsCard } from 'modules/DossierView';
import { DossierOutletContext } from '../types';

const DossierDetailsTab = () => {
  const context = useOutletContext<DossierOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DossierDetailsCard {...context} />
      </Grid>
    </Grid>
  );
};

export default DossierDetailsTab;
