import { Button as MUIButton, ButtonProps, CircularProgress } from '@mui/material';

interface Props extends ButtonProps {
  role?: 'cta' | 'primary' | 'secondary' | 'text' | 'unsafe';
  loading?: boolean;
}

// Roles of buttons are important and designed as followed:
// CTA - button that calls for action, used for actions that we want user to do (default)
// Primary - button that help user to get to goal, for example 'Next'
// Secondary - button that supports CTA and Primary, they are less important, for example 'Back'
// Text - button with the least important action, for example for Cancel
// Unsafe - button with unsafe action, ex. deleting person from system

export const Button = ({
  role = 'cta',
  variant = 'contained',
  loading,
  disabled,
  color,
  ...props
}: Props) => {
  switch (role) {
    case 'primary':
      return (
        <MUIButton
          color="primary"
          variant="contained"
          disabled={loading || disabled}
          endIcon={
            loading && <CircularProgress size={20} color="primary" sx={{ ml: 1 }} />
          }
          {...props}
        />
      );
    case 'secondary':
      return (
        <MUIButton
          color="primary"
          variant="outlined"
          disabled={loading || disabled}
          endIcon={
            loading && <CircularProgress size={20} color="secondary" sx={{ ml: 1 }} />
          }
          {...props}
        />
      );
    case 'text':
      return (
        <MUIButton
          color={color || 'primary'}
          variant="text"
          disabled={loading || disabled}
          {...props}
        />
      );
    case 'unsafe':
      return (
        <MUIButton
          color="error"
          variant={variant}
          disabled={loading || disabled}
          endIcon={
            loading && <CircularProgress size={20} color="primary" sx={{ ml: 1 }} />
          }
          {...props}
        />
      );
    default:
      return (
        <MUIButton
          color="secondary"
          variant={variant}
          disabled={loading || disabled}
          endIcon={
            loading && <CircularProgress size={20} color="secondary" sx={{ ml: 1 }} />
          }
          {...props}
        />
      );
  }
};
