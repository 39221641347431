import { useCallback, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import useLoader from 'hooks/useLoader';
import {
  DossierEstateCard,
  DossierViewHeader,
  Parties,
  RentalConditionsCard,
} from 'modules/DossierView';
import routes, { dossierDocumentsTabRoutes, dossierTabRoutes } from 'router/routes';
import { FetchedDossierData } from 'api/dossier/types';
import { RouteTabs } from 'components/tabs';
import {
  getDossierConfig,
  getDossierData,
  getDossierRentalConditionsConfig,
} from './utils';

export const DossierDetails = () => {
  const { dossierId } = useParams();
  if (!dossierId) return null;

  const { data, updateData, Loader } = useLoader(getDossierConfig(dossierId));
  const { data: rentalConditions, updateData: fetchRentalConditions } = useLoader(
    getDossierRentalConditionsConfig(dossierId),
  );

  const getDossierProps = useCallback(
    (data: FetchedDossierData) => ({
      dossierId,
      dossierData: getDossierData(data),
      fetchRentalDossier: updateData,
    }),
    [dossierId],
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const dossierRoutes = useMemo(() => {
    if (matches) {
      const {
        parties: _parties,
        rentalConditions: _rentalConditions,
        ...routes
      } = dossierTabRoutes;
      return routes;
    }
    const { residents: _, ...routes } = dossierTabRoutes;
    return routes;
  }, [matches]);

  return (
    <Loader>
      {data && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              <DossierViewHeader
                dossierId={dossierId}
                dossierData={data}
                fetchDossier={updateData}
              />
              <RouteTabs
                routes={dossierRoutes}
                subRoutes={{
                  documents: {
                    tKey: 'dossierDocuments',
                    defaultRoute: 'products',
                    routes: dossierDocumentsTabRoutes,
                  },
                  products: routes.rentalDossierProduct(':dossierId', ':productId'),
                }}
                parentPath={routes.rentalDossier(dossierId)}
                translationKey="dossierView"
              />
            </Stack>
            <Outlet
              context={{
                ...getDossierProps(data),
                rentalConditions,
                fetchRentalConditions,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            mb="auto"
            display={{ xs: 'none', lg: 'block' }}
          >
            <Stack spacing={3}>
              <DossierEstateCard dossierData={getDossierData(data)} />
              <Parties partyType="landlords" {...getDossierProps(data)} />
              <Parties partyType="tenants" {...getDossierProps(data)} />
              <RentalConditionsCard
                {...getDossierProps(data)}
                rentalConditions={rentalConditions}
                fetchRentalConditions={fetchRentalConditions}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};
