import { useMemo } from 'react';
import {
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { WarningIcon } from 'icons';
import { charactersLimit } from './static-data';
import { getBaseInputStyles } from './styles';
import { BaseInputProps } from './types';

export const HelperText = ({
  helperText,
  warning,
  error,
}: Required<Pick<BaseInputProps, 'helperText' | 'warning' | 'error'>>) => (
  <Stack
    component="span"
    direction="row"
    alignItems="center"
    columnGap={0.5}
    sx={{ ml: 0, mr: 0, fontSize: 14 }}
  >
    {!error && warning && <WarningIcon fontSize="inherit" color="warning" />}
    <Typography
      component="div"
      width="100%"
      color={error ? 'error' : 'default'}
      sx={{ fontSize: 11 }}
    >
      {helperText}
    </Typography>
  </Stack>
);

export const BaseInput = ({
  name,
  type = 'text',
  size = 'small',
  fullWidth = true,
  helperText,
  hideHelperTextGap = false,
  variant = 'outlined',
  startAdornment,
  endAdornment,
  InputProps,
  inputProps,
  error = false,
  warning = false,
  fullWidthWarning,
  disabled,
  loading,
  value,
  ...props
}: BaseInputProps) => {
  const helperTextContent = useMemo(() => {
    if (helperText) {
      return <HelperText helperText={helperText} warning={warning} error={error} />;
    }
    return hideHelperTextGap ? '' : ' ';
  }, [helperText, warning, error]);

  return (
    <TextField
      id={name}
      value={value ?? ''}
      name={name}
      size={size}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      helperText={helperTextContent}
      disabled={disabled || loading}
      error={error}
      sx={getBaseInputStyles(error, warning, fullWidthWarning)}
      // @ts-ignore it exists
      FormHelperTextProps={{ component: 'div' }}
      inputProps={{
        'data-testid': name,
        maxLength:
          'multiline' in props && props.multiline
            ? charactersLimit.textArea
            : charactersLimit.textInput,
        ...inputProps,
      }}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start" data-testid={`${name}_start-adornment`}>
            {startAdornment}
          </InputAdornment>
        ),
        endAdornment: (endAdornment || loading) && (
          <InputAdornment position="end" data-testid={`${name}_end-adornment`}>
            {loading ? <CircularProgress size={20} /> : endAdornment}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...props}
    />
  );
};
