import { BaseSyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { UploadPhotoContext } from 'contexts/photoUpload/uploadPhotoContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { RelationData } from 'modules/Relation/types';
import routes from 'router/routes';
import { addEstate, updateEstate, updateEstateDetails } from 'api/estate/requests';
import { EstateDetailsForm, EstateFormData } from 'api/estate/types';
import { FetchedPersonData } from 'api/person/types';
import { estateFormSchema } from './static-data';
import { EstateFormInterface, EstateFormProps } from './types';
import { areCopiedOwnersUnchanged, getEstateFormDefaultValues } from './utils';

const useEstateForm = (handleSave: EstateFormProps['handleSave']) => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(!!state?.estateId);

  const [shouldCreateCopyAfterSave, setShouldCreateCopyAfterSave] = useState(false);
  const [shouldResetStepper, setShouldResetStepper] = useState(false);

  const [savedEstateId, setSavedEstateId] = useState('');
  const [savedEstate, setSavedEstate] = useState<EstateFormData | null>(null);
  const [savedEstateDetails, setSavedEstateDetails] = useState<EstateDetailsForm | null>(
    null,
  );
  const [copiedOwners, setCopiedOwners] = useState<RelationData<FetchedPersonData>[]>([]);

  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const { reset } = useFormContext<EstateFormInterface>();
  const { saveOwners, ownersToPreview, setOwnersToPreview } =
    useContext(EstateFormContext);
  const { uploadPhoto } = useContext(UploadPhotoContext);
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);
  const { saveDocuments, documents, markEmptyDocuments, clearDocuments } =
    useContext(FilesUploadContext);

  const fetchDefaultValues = useCallback(async () => {
    const { form, owners } = await getEstateFormDefaultValues(state?.estateId);
    setLoading(false);
    reset({ ...estateFormSchema.getDefault(), ...form });
    setOwnersToPreview(owners);
    setCopiedOwners(owners);
  }, []);

  useEffect(() => {
    fetchDefaultValues();
  }, []);

  const save = async (
    { estate, estateDetails }: EstateFormInterface,
    event?: BaseSyntheticEvent,
  ) => {
    let shouldProceed = true;
    let estateId = savedEstateId;

    const isUpdating = !!savedEstateId;
    const isEstateChanged = JSON.stringify(savedEstate) !== JSON.stringify(estate);
    const isEstateDetailsChanged =
      JSON.stringify(savedEstateDetails) !== JSON.stringify(estateDetails);

    if (isEstateChanged) {
      if (isUpdating) {
        const { ok } = await updateEstate(savedEstateId, estate);
        shouldProceed = ok;
      } else {
        const { ok, response } = await addEstate(estate);
        shouldProceed = ok;
        estateId = ok ? response.id : estateId;
      }
    }

    if (shouldProceed && estateId) {
      setSavedEstate(estate);
      setSavedEstateId(estateId);

      if (isEstateDetailsChanged) {
        const { ok } = await updateEstateDetails(estateId, estateDetails);
        setSavedEstateDetails(estateDetails);
        shouldProceed = ok;
      }

      const ownersSaved = await saveOwners(estateId);
      const photoUploaded = await uploadPhoto(estateId);
      const documentsStatus = await saveDocuments(event, estateId);
      const validDocuments =
        documentsStatus === 'ALL_SAVED' || documentsStatus === 'NOTHING_TO_SAVE';

      if (!ownersSaved || !photoUploaded || !validDocuments) {
        shouldProceed = false;
      }

      if (shouldProceed) {
        if (isUpdating && (isEstateChanged || isEstateDetailsChanged)) {
          toast.success(t('successMessages.EstateUpdatedSuccessfully'), {
            toastId: estateId,
          });
        } else if (!isUpdating && isEstateChanged) {
          toast.success(t('successMessages.EstateSavedSuccessfully'), {
            toastId: estateId,
          });
        }

        if (shouldCreateCopyAfterSave) {
          setCopiedOwners(ownersToPreview);
          setShouldResetStepper(true);
          setShouldCreateCopyAfterSave(false);
          setSavedEstateId('');
          setSavedEstate(null);
          setSavedEstateDetails(null);
          clearDocuments();
          reset({ estate, estateDetails }, { keepValues: true });

          setTimeout(() => {
            setShouldResetStepper(false);
          }, 0);
        }

        if (handleSave) {
          handleSave(estate, estateId, shouldCreateCopyAfterSave);
        } else if (!shouldCreateCopyAfterSave) {
          navigate(routes.estate(estateId), { withoutPrompt: true });
        }
      }
    }
  };

  const onSave = async (form: EstateFormInterface, event?: BaseSyntheticEvent) => {
    if (
      !!documents.length &&
      documents.some(({ documentType, files }) => documentType && !files.length)
    ) {
      return markEmptyDocuments();
    }

    if (areCopiedOwnersUnchanged(copiedOwners, ownersToPreview)) {
      setUpAndOpenConfirmationModal({
        type: 'confirmation',
        translationKey: 'sameOwnerForCopiedEstate',
        shouldCloseOnSubmit: true,
        onSubmit: () => save(form, event),
      });
    } else {
      return save(form, event);
    }
  };

  return {
    loading,
    saveForm: onSave,
    setShouldCreateCopyAfterSave,
    shouldCreateCopyAfterSave,
    shouldResetStepper,
  };
};

export default useEstateForm;
