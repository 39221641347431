import { CustomDateOptions } from 'types/yup';
import yup from 'utils/yup';

export const dateOfBirthOptions: CustomDateOptions = {
  disableFuture: true,
  disableToday: true,
};

const dateOfBirthSchema = yup
  .string()
  .label('dateOfBirth')
  .nullable()
  .default(null)
  .customDate({
    ...dateOfBirthOptions,
    minPastRange: { key: 'dateOfBirthMinRange', years: 17, months: 6 },
    maxPastRange: { key: 'dateOfBirthMaxRange', years: 120 },
  });

export default dateOfBirthSchema;
