import { useCallback, useContext, useEffect, useState } from 'react';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { getUserFields } from 'formData/users/fields';
import { getUserRoles } from 'api/config/requests';
import { SelectOption } from 'components/inputs/types';
import { getUserRolesOptions } from './utils';

export const useUserFields = () => {
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);

  const { userData } = useContext(AccountContext);

  const fetchUserRoles = useCallback(async () => {
    const { ok, response } = await getUserRoles();
    if (ok) {
      const userRoles = getUserRolesOptions(response.roles, userData?.role);
      setRoleOptions(userRoles);
    }
  }, []);

  useEffect(() => {
    fetchUserRoles();
  }, [fetchUserRoles]);

  return getUserFields(roleOptions);
};
