import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { EstateFormInterface } from 'modules/EstateForm/types';
import EstateDescriptionField from 'modules/TranslationField';
import { ControlledTextInput } from 'components/controlledInputs';
import { UploadPhotoProps } from 'components/UploadPhoto/types';
import { UploadPhoto } from 'components/UploadPhoto/UploadPhoto';

interface Props {
  uploadPhotoProps: UploadPhotoProps;
}

const DescriptionStep = ({ uploadPhotoProps }: Props) => {
  const { t } = useTranslation('formFields');

  const {
    formState: { errors },
    control,
  } = useFormContext<EstateFormInterface>();

  return (
    <Grid container rowSpacing={2} maxWidth={750}>
      <Grid item xs={12}>
        <ControlledTextInput
          control={control}
          name="estate.ownReference"
          label={t('ownReference')}
          error={errors.estate?.ownReference}
          hideHelperTextGap
        />
      </Grid>
      <Grid item xs={12}>
        <EstateDescriptionField
          render={(lng) => (
            <Box pt={1}>
              <ControlledTextInput
                name={`estateDetails.descriptions.${lng}`}
                placeholder={t('description')}
                error={errors.estateDetails?.descriptions?.[lng]}
                control={control}
                multiline
                rows={10}
              />
            </Box>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <UploadPhoto {...uploadPhotoProps} showStatus />
      </Grid>
    </Grid>
  );
};

export default DescriptionStep;
