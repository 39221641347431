import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from 'atoms/Button';
import { NavigationContext } from 'contexts/navigation/navigationContext';
import ModalTitle from './ModalTitle';
import ModalWrapper from './ModalWrapper';

export const PromptModal = () => {
  const { isPrompt, navData, closePrompt, resetPrompt } = useContext(NavigationContext);
  const navigate = useNavigate();
  const { t } = useTranslation('modals', { keyPrefix: 'promptModal' });

  const redirect = () => {
    if (typeof navData?.to === 'number') {
      navigate(navData.to);
    } else if (navData) {
      navigate(navData.to, navData.options);
    }
    resetPrompt();
    closePrompt();
  };

  return (
    <ModalWrapper open={isPrompt} onClose={closePrompt}>
      <ModalTitle title={t(`title`)} closeModal={closePrompt} />
      <DialogContent>
        <DialogContentText>{t(`content`)}</DialogContentText>
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button role="text" onClick={redirect}>
          {t('redirectButton')}
        </Button>
        <Button onClick={closePrompt}>{t('cancelRedirectionButton')}</Button>
      </DialogActions>
    </ModalWrapper>
  );
};
