import { ChangeEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import DividerStack from 'atoms/DividerStack';
import routes from 'router/routes';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import PageCard from 'templates/PageCard';
import SectionRows from 'templates/SectionRows';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { TextInput } from 'components/inputs';
import { DossierCardProps } from '../types';
import DossierResponsibleUsers from './DossierResponsibleUsers';
import { useDossierDetails } from './useDossierDetails';

const DossierDetailsCard = ({
  dossierId,
  dossierData,
  fetchRentalDossier,
}: DossierCardProps) => {
  const { t } = useTranslation('formFields');

  const {
    loading,
    staticData,
    ownReferenceState: [ownReference, setOwnReference],
    responsibleUsersProps: { responsibleUsers, ...responsibleUsersProps },
    ...edition
  } = useDossierDetails({ dossierId, dossierData, fetchRentalDossier });

  const handleOwnReference: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setOwnReference(target.value);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const editable = useMemo(
    () => shouldDossierFeatureBeActive(dossierData.status),
    [dossierData.status],
  );

  const titleProps = useMemo(
    () => ({
      startIcon:
        matches && dossierData.estate.photo ? (
          <Avatar
            src={dossierData.estate.photo}
            sx={({ mixins }) => mixins.estatePhoto}
          />
        ) : null,
    }),
    [matches, dossierData.estate.photo],
  );

  return (
    <PageCard
      tKey="dossierDetails"
      loading={loading}
      headerProps={{
        titleProps,
        editionProps: editable ? edition : undefined,
      }}
    >
      <Box>
        {edition.isEditing ? (
          <Stack spacing={1.5}>
            <DividerStack>
              <SectionRows data={staticData} />
            </DividerStack>
            <Stack spacing={2} px={2.5} pb={1.5}>
              <InputLabelWrapper label={t('ownReference')}>
                <TextInput
                  value={ownReference ?? ''}
                  onChange={handleOwnReference}
                  hideHelperTextGap
                />
              </InputLabelWrapper>
              <DossierResponsibleUsers
                responsibleUsers={responsibleUsers}
                {...responsibleUsersProps}
              />
            </Stack>
          </Stack>
        ) : (
          <SectionRows
            data={{
              ...staticData,
              ownReference,
              responsibleUsers: responsibleUsers.length
                ? responsibleUsers.map(({ name }) => name)
                : null,
            }}
            links={{ estate: routes.estateTab(dossierData.estate.estateId).estateInfo }}
          />
        )}
      </Box>
    </PageCard>
  );
};

export default DossierDetailsCard;
