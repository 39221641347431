import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { Select } from 'components/inputs';
import { ControlledSelectProps } from './types';
import { getErrorMessage } from './utils';

export const ControlledSelect: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledSelectProps<T, N>,
) => React.ReactElement = ({
  name,
  control,
  error,
  warning,
  helperText,
  options,
  onChange: onSelectChange,
  onClear,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, value, onChange, ...field } }) => (
      <Select
        options={options}
        value={options.length ? value ?? '' : ''}
        onChange={(value, option) => {
          if (onSelectChange) {
            onSelectChange(value, option);
          }
          onChange(value);
        }}
        onClear={() => {
          onChange('');
          if (onClear) {
            onClear();
          }
        }}
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        helperText={getErrorMessage(error ?? warning) || helperText}
        {...inputProps}
        {...field}
      />
    )}
  />
);
