import { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@mui/material';
import Button from 'atoms/Button';
import { DeleteIcon } from 'icons';
import { ControlledSelect } from 'components/controlledInputs';
import { ContactField, defaultFieldsConfig, relationOptions } from './static-data';
import {
  PartyOption,
  PartyPerson,
  ProductPartiesForm,
  ProductPartiesProps,
  ProductParty,
  SelectedContact,
} from './types';
import {
  fetchPrivateRelation,
  fetchRepresentatives,
  getEmptyField,
  getPersonsOptions,
  getSecondaryFieldRelation,
  updateSecondaryField,
} from './utils';

export const ProductParties = ({ persons, partyType, config }: ProductPartiesProps) => {
  const {
    context,
    addingFields,
    maxFieldsCount,
    shouldDisplaySecondaryField,
    selectableRepresentatives,
    partnerIncludedInDossier,
    showLegalPersonInSecondaryContact,
    showPartner,
    showRelationDropdown,
  } = useMemo(() => ({ ...defaultFieldsConfig, ...config }), [config]);

  const { t } = useTranslation('modals', {
    keyPrefix: 'productActionModal.contactComponent',
  });

  const {
    control,
    formState: { errors },
  } = useFormContext<ProductPartiesForm>();

  const { fields, update, append, remove, replace } = useFieldArray({
    control,
    name: partyType,
    keyName: 'fieldId',
  });

  const watchedFields = useWatch({ control, name: partyType });

  const getOptions = (
    index: number,
    personId: string,
    partnerOption?: PartyPerson | null,
  ) => {
    let options = persons;

    if (partnerOption) {
      options = [partnerOption, ...persons];
    }

    if (!showLegalPersonInSecondaryContact && index === ContactField.Secondary) {
      options = options.filter(({ type }) => type !== 'LegalPerson');
    }

    const filteredIds = fields
      .filter((field) => field.personId !== personId)
      .map(({ personId }) => personId);

    return getPersonsOptions(options, filteredIds);
  };

  const selectPersonWithPartner = async (index: number, person: SelectedContact) => {
    let representatives: ProductParty['representatives'];
    let partner: ProductParty['partner'];
    let relation: ProductParty['relation'];
    let hasPrivateRelation = false;

    if (person.type === 'LegalPerson') {
      representatives = await fetchRepresentatives(person.personId);
    } else if (person.hasPrivateRelation) {
      partner = await fetchPrivateRelation(person.personId);
      hasPrivateRelation = !!partner;
    }

    if (showRelationDropdown && showPartner === 'asSecondaryContact') {
      if (index === ContactField.Primary) {
        relation = partner?.relation ?? null;
      } else if (index === ContactField.Secondary) {
        const primaryContact = fields[ContactField.Primary];
        relation = getSecondaryFieldRelation(primaryContact, partner?.personId);
      }
    }

    if (partnerIncludedInDossier) {
      const isPartner = persons.some(({ personId }) => personId === partner?.personId);
      partner = isPartner ? partner : undefined;
    }

    const contactField: ProductParty = {
      ...fields[index],
      index,
      personId: person.personId,
      personType: person.type,
      representativeId: person.representativeId || undefined,
      representatives,
      partner,
      partnerId: partner?.personId,
      hasPrivateRelation,
      relation,
    };

    update(index, contactField);

    if (
      showPartner === 'asSecondaryContact' &&
      persons.length > 1 &&
      maxFieldsCount === 2 &&
      index === ContactField.Primary
    ) {
      const secondaryField = fields.at(ContactField.Secondary);
      const updatedSecondaryField = updateSecondaryField(
        persons,
        contactField,
        secondaryField,
      );

      if (!shouldDisplaySecondaryField(contactField)) {
        remove(ContactField.Secondary);
      } else if (!secondaryField) {
        append(updatedSecondaryField);
      } else {
        update(ContactField.Secondary, updatedSecondaryField);
      }
    }
  };

  useEffect(() => {
    if (persons.length === 1) {
      // Set first input with person data when only one person is available
      selectPersonWithPartner(ContactField.Primary, persons[0]);
    } else if (addingFields === 'afterSelectingPreviousField') {
      // Display one field as empty
      replace([getEmptyField()]);
    } else if (!addingFields) {
      const count = persons.length >= maxFieldsCount ? maxFieldsCount : persons.length;
      // Display provided max number of fields as empty
      replace(new Array(count).fill(null).map((_, index) => getEmptyField(index)));
    }
  }, []);

  const clearField = (index: number) =>
    update(index, {
      ...getEmptyField(index),
      partnerOption: fields[index].partnerOption,
      relation: null,
    });

  return (
    <Grid item container spacing={2}>
      {fields.map(
        (
          {
            fieldId,
            personId,
            personType,
            representatives,
            partner,
            hasPrivateRelation,
            partnerOption,
            relation,
          },
          index,
        ) => {
          const options = getOptions(index, personId, partnerOption);
          const primaryContact = fields.at(ContactField.Primary);
          const isRelationDisabled =
            !personId ||
            hasPrivateRelation ||
            !!primaryContact?.hasPrivateRelation ||
            !!primaryContact?.representativeId;

          return options.length > 0 ? (
            <Grid key={fieldId} item container spacing={1}>
              <Grid xs item>
                <ControlledSelect
                  control={control}
                  name={`${partyType}.${index}.personId`}
                  label={t(`${partyType}.label`, { count: index + 1, context })}
                  error={errors[partyType]?.[index]?.personId}
                  options={options}
                  onChange={(_value, option) =>
                    selectPersonWithPartner(index, option as PartyOption)
                  }
                  hideHelperTextGap
                  clearable={addingFields !== 'byButton' && index > 0}
                  onClear={() => clearField(index)}
                />
              </Grid>
              {personType === 'LegalPerson' && representatives && (
                <Grid xs item>
                  <ControlledSelect
                    control={control}
                    name={`${partyType}.${index}.representativeId`}
                    label={t('representative')}
                    error={errors[partyType]?.[index]?.representativeId}
                    disabled={!selectableRepresentatives}
                    options={getPersonsOptions(representatives)}
                    hideHelperTextGap
                  />
                </Grid>
              )}
              {showPartner === 'nextToPerson' &&
                personType === 'NaturalPerson' &&
                partner && (
                  <Grid xs item>
                    <ControlledSelect
                      control={control}
                      name={`${partyType}.${index}.partnerId`}
                      label={t('partner')}
                      error={errors.tenants?.[index]?.partnerId}
                      options={getPersonsOptions([partner])}
                      hideHelperTextGap
                    />
                  </Grid>
                )}
              {showRelationDropdown &&
                index === ContactField.Secondary &&
                showPartner === 'asSecondaryContact' &&
                personType !== 'LegalPerson' &&
                relation !== undefined && (
                  <Grid xs item>
                    <ControlledSelect
                      control={control}
                      name={`${partyType}.${index}.relation`}
                      label={t('relation')}
                      error={errors.tenants?.[index]?.relation}
                      disabled={isRelationDisabled}
                      options={relationOptions}
                      helperText={
                        personId &&
                        !relation &&
                        isRelationDisabled &&
                        primaryContact?.personType === 'NaturalPerson'
                          ? t('relationExists', { contactType: partyType })
                          : undefined
                      }
                      warning={
                        personId &&
                        watchedFields.at(index)?.relation !== 'Other' &&
                        !isRelationDisabled
                          ? { message: { key: 'contactRelation.addingNewRelation' } }
                          : undefined
                      }
                    />
                  </Grid>
                )}
              {addingFields === 'byButton' && (
                <Grid item alignSelf="center">
                  <IconButton
                    onClick={() => remove(index)}
                    disabled={fields.length === 1}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ) : null;
        },
      )}
      {addingFields === 'byButton' && (
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => append(getEmptyField(fields.length))}
            disabled={
              persons.length === fields.length || maxFieldsCount === fields.length
            }
          >
            {t(`${partyType}.add`)}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
