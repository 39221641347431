import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { EstateDetailsCard } from 'modules/EstateView';
import { EstateOutletContext } from '../types';

const EstateCompositionTab = () => {
  const { estateDetails, fetchEstateDetails } = useOutletContext<EstateOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} marginBottom="auto">
        <EstateDetailsCard
          type="composition"
          estateDetails={estateDetails}
          fetchEstateDetails={fetchEstateDetails}
        />
      </Grid>
    </Grid>
  );
};

export default EstateCompositionTab;
