import { useOutletContext } from 'react-router-dom';
import CustomTodoList from 'modules/DossierView/CustomTodoList';
import { DossierOutletContext } from 'pages/Dossiers/Details/types';

export const ProductTasks = () => {
  const { dossierId, dossierData } = useOutletContext<DossierOutletContext>();

  // TODO: Pass productId once backend will be ready
  return <CustomTodoList dossierId={dossierId} dossierStatus={dossierData.status} />;
};
