import { useContext, useMemo } from 'react';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { FetchedEstateOwner } from 'api/relations/types';
import { Details } from './components/Details';
import { getOwners } from './utils';

interface Props {
  ownerships: FetchedEstateOwner[];
  updateList: () => Promise<void>;
}

const OwnersDetails = ({ ownerships, updateList }: Props) => {
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const ownerActionProps = {
    setUpAndOpenConfirmationModal,
    updateList,
  };

  const owners = useMemo(() => getOwners(ownerships, ownerActionProps), [ownerships]);

  return (
    <>
      {owners.map((owner) => (
        <Details key={`owner-${owner.personId}`} {...owner} />
      ))}
    </>
  );
};

export default OwnersDetails;
