import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import SectionRows from 'templates/SectionRows';
import getLng from 'utils/getLng';
import { EstateDetailsForm } from 'api/estate/types';

const concatCompositionItems = (composition: EstateDetailsForm['estateComposition']) => {
  const { additionalItems, ...items } = composition ?? {};
  return {
    ...(items ?? {}),
    ...additionalItems?.reduce(
      (prev, { name, count }) => ({
        ...prev,
        [name[getLng()]]: count,
      }),
      {},
    ),
  };
};

export const CompositionContent = ({
  estateComposition = null,
}: Pick<EstateDetailsForm, 'estateComposition'>) => {
  const { i18n } = useTranslation();

  const data = useMemo(
    () => concatCompositionItems(estateComposition),
    [estateComposition, i18n.language],
  );

  return Object.keys(data).length ? (
    <Stack spacing={1}>
      <SectionRows data={data} translationPrefix="enums:estateComposition" />
    </Stack>
  ) : null;
};
