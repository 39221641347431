import { PersonPermissions } from 'consts/permissions';
import RelationItem from 'modules/Relation/RelationItem';
import { RepresentationData } from 'modules/RepresentationCard/types';
import routes from 'router/routes';
import hasPermission from 'utils/hasPermission';
import { apiUrl } from 'api/const';
import { PersonType } from 'api/person/types';

interface Props {
  relations: RepresentationData[];
  updateList: () => Promise<void>;
  personType: PersonType;
}

export const RelationDetails = ({ relations, updateList, personType }: Props) => (
  <>
    {relations.map((relation) => (
      <RelationItem
        key={relation.id}
        relation={{
          ...relation,
          details: relation.address,
          link:
            personType === 'NaturalPerson'
              ? routes.legalPersonTab(relation.personId).details
              : routes.naturalPersonTab(relation.personId).details,
        }}
        deleteUrl={apiUrl.deleteRepresentation(relation.id)}
        onDeleteSuccess={updateList}
        removable={hasPermission(PersonPermissions.Write)}
        disableDelete={!relation.isDeletable}
        relationType={personType === 'LegalPerson' ? 'representative' : 'representee'}
        reason={
          personType === 'LegalPerson'
            ? 'representativeNoDeletable'
            : 'representeeNoDeletable'
        }
      />
    ))}
  </>
);
