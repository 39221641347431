import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { HandleCheckboxChange } from 'types/handleChange';
import { EstateDetailsForm } from 'api/estate/types';
import { ControlledTextInput } from 'components/controlledInputs';

export const SyndicEdition = () => {
  const { t } = useTranslation('formFields');

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<EstateDetailsForm>();

  const onChange: HandleCheckboxChange = (_e, checked) => {
    if (!checked) {
      setValue('syndic', null, { shouldDirty: true });
    }
  };

  const updateCheckbox = () => {
    if (!getValues('hasSyndic')) {
      setValue('hasSyndic', true);
    }
  };

  const initiallyExpanded = !!(getValues('hasSyndic') && getValues('syndic'));

  return (
    <Stack direction="row" spacing={1}>
      <AccordionWithCheckbox
        control={control}
        error={errors.syndic}
        name="hasSyndic"
        label={t('syndic')}
        onChange={onChange}
        initiallyExpanded={initiallyExpanded}
      >
        <InputLabelWrapper label={t('text')}>
          <ControlledTextInput
            name="syndic"
            control={control}
            error={errors.syndic}
            onChange={updateCheckbox}
            hideHelperTextGap
          />
        </InputLabelWrapper>
      </AccordionWithCheckbox>
    </Stack>
  );
};
