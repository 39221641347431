import {
  currencyOptions,
  dossierDurations,
} from 'formData/dossier/rentalConditions/selectOptions';
import { RentalConditions, RentalDuration } from 'api/dossier/types';
import { InputField } from 'components/controlledInputs/types';

export const getRentalConditionsStepFields = (
  duration: string | null,
  onChange: (duration: RentalDuration) => void,
): InputField<
  Pick<RentalConditions, 'rentalPrice' | 'charges' | 'entryDate' | 'duration' | 'endDate'>
>[] => [
  {
    name: 'rentalPrice',
    options: currencyOptions,
    type: 'payment',
  },
  {
    name: 'charges',
    options: currencyOptions,
    type: 'payment',
  },
  {
    name: 'entryDate',
    type: 'date',
  },
  {
    name: 'duration',
    options: dossierDurations,
    onSelectChange: (value) => onChange(value as RentalDuration),
  },
  {
    name: 'endDate',
    type: 'date',
    disabled: duration !== 'Other',
  },
];
