import levenshtein from 'js-levenshtein';

const POPULAR_DOMAINS = [
  'gmail.com',
  'mail.be',
  'telenet.be',
  'outlook.com',
  'yahoo.com',
  'hotmail.com',
  'hotmail.be',
];

export const compareEmails = (
  email: string,
): null | { message: string; emails?: string } => {
  const domainWithSuffix = email?.split('@')[1]?.split('.').slice(-2).join('.'); //only domain part
  if (domainWithSuffix) {
    const isSameDomain = POPULAR_DOMAINS.some((domain) => domain === domainWithSuffix);
    if (isSameDomain) {
      return null;
    }
    const suggestions: [string, number][] = POPULAR_DOMAINS.map((domain) => [
      domain,
      levenshtein(domainWithSuffix, domain),
    ]);

    const closest = suggestions
      .sort(([, valuea], [, valueb]) => valuea - valueb)
      .filter(([, value]) => value < 3);

    if (closest.length) {
      return {
        message: 'similarEmailDomain',
        emails: closest.map(([mail]) => mail).join(', '),
      };
    }
    return {
      message: 'notKnownEmailDomain',
    };
  }
  return null;
};
