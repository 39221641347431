import { estateDetailsSchema, estateSchema } from 'formData/estate';
import yup from 'utils/yup';

export enum EstateSteps {
  General,
  Description,
  Details,
  Composition,
  Documents,
}

export const estateFormSchema = yup.object({
  estate: estateSchema,
  estateDetails: estateDetailsSchema,
});
