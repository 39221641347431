import { LoaderConfig } from 'hooks/useLoader/types';
import routes from 'router/routes';
import {
  getDossierDocuments,
  getRentalConditions,
  getRentalDossier,
} from 'api/dossier/requests';
import {
  DossierData,
  DossierDocumentsData,
  FetchedDossierData,
  RentalConditions,
} from 'api/dossier/types';

export const getDossierData = ({
  id: _id,
  tenants,
  landlords,
  ...dossier
}: FetchedDossierData): DossierData => ({
  ...dossier,
  tenants: tenants.map(({ id: _id, ...tenant }) => tenant),
  landlords: landlords.map(({ id: _id, ...landlord }) => landlord),
});

export const getDossierConfig = (
  dossierId: string,
): LoaderConfig<FetchedDossierData> => ({
  id: dossierId,
  scope: 'dossier',
  fetch: getRentalDossier,
  shouldRedirect: (pathname) => pathname === routes.rentalDossier(dossierId),
  redirectUrl: routes.rentalDossierTab(dossierId).products,
});

export const getDossierDocumentsConfig = (
  dossierId: string,
): LoaderConfig<DossierDocumentsData> => ({
  id: dossierId,
  scope: 'dossierDocuments',
  fetch: getDossierDocuments,
  withContext: false,
});

export const getDossierRentalConditionsConfig = (
  dossierId: string,
): LoaderConfig<RentalConditions> => ({
  id: dossierId,
  scope: 'dossierRentalConditions',
  fetch: getRentalConditions,
  withContext: false,
});
