import { useMemo, useState } from 'react';
import { PersonsDetails } from 'modules/DetailsCards';
import { AddPersonModal } from 'modules/ModalForm/AddPersonModal';
import { MAX_OWNERS } from 'modules/OwnershipCard/static-data';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import { RelationData } from 'modules/Relation/types';
import {
  fetchPersonRelationOptions,
  getPersonsIdsToFilter,
  personToRelation,
  relationToDossierPerson,
  selectPersonWithPartner,
} from 'modules/Relation/utils';
import PageCard from 'templates/PageCard';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { updateRentalDossier } from 'api/dossier/requests';
import { FetchedPersonData } from 'api/person/types';
import { DossierCardProps } from '../types';

interface Props extends DossierCardProps {
  partyType: 'tenants' | 'landlords';
}

const DossierParties = ({
  partyType,
  dossierId,
  dossierData,
  fetchRentalDossier,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [personsToPreview, setPersonsToPreview] = useState<
    RelationData<FetchedPersonData>[]
  >([]);

  const onEdit = () => {
    setEditing(true);
  };

  const onCancel = () => {
    setPersonsToPreview([]);
    setEditing(false);
  };

  const personRelations = useMemo(
    () =>
      dossierData[partyType].map(({ personId, ...data }) =>
        personToRelation({ ...data, id: personId }),
      ),
    [dossierData[partyType]],
  );

  const onSave = async () => {
    setSaving(true);
    const { ok } = await updateRentalDossier(dossierId, {
      ...dossierData,
      [partyType]: [...personRelations, ...personsToPreview].map(relationToDossierPerson),
    });
    if (ok) {
      await fetchRentalDossier();
      setEditing(false);
      setPersonsToPreview([]);
    }
    setSaving(false);
  };

  const oppositePartyType = useMemo(
    () => (partyType === 'landlords' ? 'tenants' : 'landlords'),
    [partyType],
  );

  const personsIdsToFilter = useMemo(
    () =>
      getPersonsIdsToFilter([
        ...personRelations,
        ...personsToPreview,
        ...dossierData[oppositePartyType],
      ]),
    [personRelations, personsToPreview, dossierData[oppositePartyType]],
  );

  const editable = useMemo(
    () => shouldDossierFeatureBeActive(dossierData.status, true),
    [dossierData.status],
  );

  return (
    <PageCard
      tKey={partyType}
      blank={!dossierData[partyType].length && !editing}
      headerProps={{
        editionProps: {
          label: 'add',
          onEdit,
          editDisabled: !editable,
          isEditing: editing,
          onSave,
          saving,
          saveDisabled: !personsToPreview.length,
          onCancel,
        },
      }}
    >
      {editing && (
        <>
          <AddRelation
            translationKey={partyType === 'landlords' ? 'landlord' : 'tenant'}
            maxRelations={MAX_OWNERS}
            relationsToFilter={personsIdsToFilter}
            selectedRelations={personsToPreview.map(({ id }) => id)}
            fetchOptions={fetchPersonRelationOptions}
            setRelationsToPreview={setPersonsToPreview}
            AddNewOptionModal={AddPersonModal}
            onSelectOption={selectPersonWithPartner(
              setPersonsToPreview,
              personsIdsToFilter,
            )}
            withPadding
          />
          <RelationPreviewList
            relationsToPreview={personsToPreview}
            setRelationsToPreview={setPersonsToPreview}
            withPrompt
            isDivider
          />
        </>
      )}
      <PersonsDetails
        dossier={{ dossierId, dossierData }}
        personsType={partyType}
        persons={dossierData[partyType]}
        updateList={fetchRentalDossier}
      />
    </PageCard>
  );
};

export default DossierParties;
