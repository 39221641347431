export const getVatPlaceholder = (
  mask: Array<string | RegExp> | false,
  vatPrefix?: string,
) => {
  if (!mask || vatPrefix === 'GB') return undefined;

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((code) => String.fromCharCode(code));

  let number = 1;
  const chars: Array<string | number> = [];

  mask.forEach((char) => {
    if (String(char) === String(/\d/) || String(char) === String(/\w|\d/)) {
      chars.push(number);
      number === 9 ? (number = 0) : number++;
    } else if (typeof char === 'string') {
      chars.push(char);
    } else {
      const letters = alphabet.filter((letter) => letter.match(char));
      const letter = letters.find((letter) => letter === 'X') ?? letters[0];
      chars.push(letter);
    }
  });
  return chars.join('');
};
