import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { InfoIcon } from 'icons';
import { ActionCell } from 'templates/Table/Cells';
import { TableAction } from 'templates/Table/types';
import { DocumentData } from 'api/documents/types';

interface Props {
  document: DocumentData;
  actions: TableAction<'documents'>[];
  updateDocuments: () => Promise<void>;
}

const DocumentActions = ({ actions, document, updateDocuments }: Props) => {
  const { t } = useTranslation('table', { keyPrefix: 'documents' });

  return (
    <Stack direction="row" gap={0.5} justifyItems={'flex-end'}>
      {actions.every(({ hidden }) => hidden) ? (
        <IconTooltip
          className="status-tooltip"
          title={t(
            `tooltips.${document.documentClassification}`,
            document.documentClassification,
          )}
        >
          <InfoIcon sx={{ fontSize: 20 }} color="primary" />
        </IconTooltip>
      ) : (
        <ActionCell tKey="documents" actions={actions} updateRows={updateDocuments} />
      )}
    </Stack>
  );
};

export default DocumentActions;
