import { DossierTableDocumentData } from 'templates/DossierDocumentsTable/types';
import { DocumentData, DocumentType } from 'api/documents/types';
import { ProductInterface } from 'api/dossier/products/types';
import { AttachedDocument, DossierDocumentData } from 'api/dossier/types';

export const transformDocumentsData = (
  documentsData: DocumentData[],
  attachedDocuments: AttachedDocument[],
): DossierTableDocumentData[] =>
  documentsData.map((document) => {
    const attachedDocument = attachedDocuments.find(
      ({ documentId }) => document.id === documentId,
    );

    return attachedDocument
      ? {
          ...document,
          isAttachable: true,
          attached: true,
          attachmentId: attachedDocument.id,
          sentOut: attachedDocument.sentOut,
          source: 'Internal',
          isMissing: false,
        }
      : {
          ...document,
          isAttachable: true,
          attached: false,
          attachmentId: null,
          sentOut: false,
          source: 'Internal',
          isMissing: false,
        };
  });

export const transformDossierDocumentsData = (
  documentsData: DossierDocumentData[],
  attachedDocuments: AttachedDocument[],
): DossierTableDocumentData[] =>
  documentsData.map((document) => {
    const attachedDocument = attachedDocuments.find(
      ({ documentId }) => document.id === documentId,
    );

    return {
      ...document,
      attached: document.source === 'External' ? true : document.attached,
      isAttachable: document.source !== 'External',
      attachmentId: attachedDocument?.id ?? null,
      isMissing: false,
    };
  });

export const createMissingDocumentType = (
  missingType: DocumentType,
): DossierTableDocumentData => ({
  documentType: missingType,
  id: 0,
  documentName: '',
  attached: false,
  sentOut: false,
  isMissing: true,
  files: [],
  isAttachable: false,
  documentClassification: 'Document',
  createdAt: null,
  attachmentId: null,
  date: null,
  source: 'Internal',
});

export const getExcludedDossierDocuments = (products: ProductInterface[]) => {
  const rentalDeposit = products.find(
    ({ productName }) => productName === 'RentalDeposit',
  );
  const fireInsurance = products.find(
    ({ productName }) => productName === 'FireInsurance',
  );

  const excluded: DocumentType[] = [];

  if (
    !rentalDeposit ||
    (rentalDeposit.status !== 'AbolishmentDraft' &&
      rentalDeposit.status !== 'InAbolishment')
  ) {
    excluded.push('SignedReleaseForm', 'Verdict');
  }

  if (!rentalDeposit || rentalDeposit.status === 'Draft') {
    excluded.push('SignedRentalDepositProposition');
  }

  if (!fireInsurance || fireInsurance.status === 'Draft') {
    excluded.push('SignedFireInsuranceProposition');
  }

  return excluded;
};
