import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { EstateFormInterface, UseEstateFormStep } from 'modules/EstateForm/types';
import EstateDocuments from './Documents';

export const useDocumentsStep: UseEstateFormStep = ({ onCancel, saveForm }) => {
  const { formMethods, validateDocuments } = useContext(FilesUploadContext);

  const { t } = useTranslation();
  const { handleSubmit, formState } = useFormContext<EstateFormInterface>();

  return {
    isValid: formMethods.formState.isValid,
    Component: <EstateDocuments />,
    cancelButton: {
      onClick: onCancel,
    },
    backButton: {
      onClick: validateDocuments,
    },
    labelButton: {
      onClick: validateDocuments,
    },
    submitButton: {
      label: t('save'),
      onClick: handleSubmit(saveForm),
      saving: formState.isSubmitting,
    },
  };
};
