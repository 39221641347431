import { useContext, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { DocumentsSaveStatus, UploadContext } from 'contexts/fileUpload/types';
import { uploadDocumentsSchema } from 'formData/documents/schema';
import { resolver } from 'utils/yup';
import { DocumentsForm, DocumentToUpload, HandleSingleDocumentSave } from './types';
import {
  areDocumentsSavedOrDraft,
  getDocumentToUpload,
  groupDocumentsByStatus,
  saveDocument,
  setWarningForEmptyDocument,
  updateDocumentStatus,
} from './utils';

interface Props {
  onSingleDocumentSave?: HandleSingleDocumentSave;
  defaultFields?: DocumentsForm['documents'];
}

export const useFileUpload = (props?: Props): UploadContext => {
  const { documentsConfig } = useContext(ConfigContext);

  const formMethods = useForm<DocumentsForm>({
    defaultValues: { documents: props?.defaultFields ?? [] },
    resolver: resolver(uploadDocumentsSchema),
    context: { documentConfigData: documentsConfig },
  });

  const { t } = useTranslation();

  const {
    fields: documents,
    append,
    remove,
    update,
    replace,
  } = useFieldArray({
    control: formMethods.control,
    name: 'documents',
  });

  const addDocument: UploadContext['addDocument'] = (data) => {
    const document = getDocumentToUpload(data);
    append(document);
  };

  const updateDocument: UploadContext['updateDocument'] = (fieldId, document) => {
    const index = documents.findIndex(({ id }) => id === fieldId);
    const data = { ...formMethods.getValues('documents')[index], ...document };

    update(index, { ...data, status: updateDocumentStatus(data) });
  };

  const removeDocument: UploadContext['removeDocument'] = (fieldId) => {
    const index = documents.findIndex(({ id }) => id === fieldId);
    remove(index);
  };

  const saveDocuments: UploadContext['saveDocuments'] = async (event, entityId) => {
    let documentsStatus: DocumentsSaveStatus = 'NOT_VALID';

    await formMethods.handleSubmit(async ({ documents: documentsToUpload }) => {
      const documents: DocumentToUpload[] = documentsToUpload.map((document) => ({
        ...document,
        entityId: entityId || document.entityId,
      }));

      const processedDocuments: DocumentToUpload[] = [];

      if (!documents.length || areDocumentsSavedOrDraft(documents)) {
        documentsStatus = 'NOTHING_TO_SAVE';
      } else {
        replace(
          documents.map((document) =>
            document.status === 'READY' || document.status === 'ERROR'
              ? { ...document, status: 'PENDING' }
              : document,
          ),
        );

        for (const document of documents) {
          if (document.status === 'READY' || document.status === 'ERROR') {
            const { documentId, status, message } = await saveDocument(document);
            if (props?.onSingleDocumentSave && documentId) {
              await props.onSingleDocumentSave(
                documentId,
                document.scope,
                document.entityId,
              );
            }
            processedDocuments.push({ ...document, documentId, status, message });
          } else {
            processedDocuments.push(setWarningForEmptyDocument(document));
          }
        }

        const { savedDocuments, rejectedDocuments, emptyDocuments } =
          groupDocumentsByStatus(processedDocuments);

        if (areDocumentsSavedOrDraft(processedDocuments)) {
          documentsStatus = 'ALL_SAVED';
          toast.success(
            t('successMessages.AllDocumentsUploadedSuccessfully', {
              count: savedDocuments.length,
            }),
          );
        } else if (processedDocuments.length === emptyDocuments.length) {
          documentsStatus = 'ALL_EMPTY';
        } else if (processedDocuments.length === rejectedDocuments.length) {
          documentsStatus = 'ALL_ERROR';
        } else if (!rejectedDocuments.length) {
          documentsStatus = 'SOME_SAVED_WITHOUT_ERROR';
        } else {
          documentsStatus = 'SOME_SAVED';
        }

        replace(processedDocuments);
      }
    })(event);

    return documentsStatus;
  };

  const validateDocuments: UploadContext['validateDocuments'] = (e, onValid = () => {}) =>
    formMethods.handleSubmit(onValid)(e);

  const isAnyPending = useMemo(
    () => documents.some(({ status }) => status === 'PENDING'),
    [documents],
  );

  return {
    addDocument,
    updateDocument,
    removeDocument,
    saveDocuments,
    validateDocuments,
    markEmptyDocuments: () => replace(documents.map(setWarningForEmptyDocument)),
    clearDocuments: () => replace([]),
    isAnyPending,
    documents,
    formMethods,
  };
};
