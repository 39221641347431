import { bicSchema, ibanSchema } from 'formData/common/schema';
import yup, { numberSchema } from 'utils/yup';

export const getOtherToDivide = (personType: 'landlord' | 'broker') =>
  personType === 'landlord' ? 'broker' : 'landlord';

export const getAbolishmentPersonSchema = (
  personType: 'tenant' | 'landlord' | 'broker',
) =>
  yup
    .object({
      amount: numberSchema
        .label('amount')
        .nullable()
        .test('amount', (value, { createError, from, options: { context } }) => {
          const form = from?.at(-1)?.value;
          if (
            (personType === 'landlord' || personType === 'broker') &&
            form.depositDistribution === 'depositDivided'
          ) {
            const theOtherOneType = getOtherToDivide(personType);
            const otherPersonAmount = Number(form[theOtherOneType].amount ?? 0);
            const deposit = context?.depositValue ?? 0;
            if (
              value &&
              (otherPersonAmount < deposit || value > deposit) &&
              value > deposit - otherPersonAmount
            ) {
              const shouldSubtract = otherPersonAmount < deposit;
              return createError({
                message: {
                  key: 'number.max',
                  values: {
                    field: 'amount',
                    max: deposit - (shouldSubtract ? otherPersonAmount : 0),
                  },
                },
              });
            }
          }
          return true;
        }),
      accountNumber: yup
        .object()
        .nullable()
        .shape(
          {
            iban: ibanSchema.customWhen({
              is: (form, parent) => !form.emailConsent && !!parent.bic,
              then: (schema) => schema.required(),
            }),
            bic: bicSchema.customWhen({
              is: (form, parent) => !form.emailConsent && !!parent.iban,
              then: (schema) => schema.required(),
            }),
          },
          [['bic', 'iban']],
        ),
      email: yup
        .string()
        .label('email')
        .nullable()
        .email()
        .customWhen({
          is: (form) =>
            personType === 'tenant' &&
            form.depositDistribution !== 'depositLandlord' &&
            form.emailConsent,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(),
        }),
    })
    .nullable();
