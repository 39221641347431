import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import IconTooltip from 'atoms/IconTooltip';
import ProductIcon from 'icons/ProductIcon';
import { ProductStatus } from 'api/dossier/types';
import { getProductIconColor, sortProductsIcons } from './utils';

const ProductsStatuses = <T extends { productsStatuses?: ProductStatus[] }>({
  row: { productsStatuses },
}: GridRenderCellParams<T>) => {
  const { t } = useTranslation('dossierProducts');

  if (!productsStatuses) {
    return null;
  }

  return (
    <Box display="flex" gap={1.5}>
      {productsStatuses.sort(sortProductsIcons).map(({ product, status, isPaid }) => (
        <IconTooltip
          key={product}
          title={t(`${product}.name`, product)}
          data-testid="productStatusIcon"
        >
          <ProductIcon
            name={product}
            fontSize="small"
            sx={{ color: getProductIconColor(status, isPaid) }}
          />
        </IconTooltip>
      ))}
    </Box>
  );
};

export default ProductsStatuses;
