import { namespaces } from 'i18n';
import { DossierFormChecklist } from 'modules/DossierForm/types';
import { mapObject, sortByOrder } from 'utils/object';
import { ProductName } from 'api/dossier/products/types';
import { FieldArrayWithIdProperty, GroupedFields } from './types';

export const updateFields = (
  fields: FieldArrayWithIdProperty[],
  name: ProductName,
  actualFields: DossierFormChecklist,
) => {
  const isChecked = (name: ProductName) =>
    !!actualFields.find((field) => field.name === name)?.checked;

  return [...fields].map((field) => {
    let disabledFields: Partial<Record<ProductName, boolean>> = {};

    if (
      (name === 'RentalDeposit' || name === 'StateReport') &&
      (isChecked('RentalDeposit') || isChecked('StateReport'))
    ) {
      disabledFields = { EndStateReport: true, IntermediateStateReport: true };
    } else if (
      (name === 'EndStateReport' || name === 'IntermediateStateReport') &&
      (isChecked('EndStateReport') || isChecked('IntermediateStateReport'))
    ) {
      disabledFields = { RentalDeposit: true, StateReport: true };
    }

    return { ...field, disabled: disabledFields[field.name] };
  });
};

export const groupFields = (fields: FieldArrayWithIdProperty[]) => {
  const init = mapObject(namespaces.enums.productGroups, () => []);
  const sortedFields = fields
    .map((field, index) => ({ ...field, index }))
    .sort(sortByOrder);
  const groupedFields = sortedFields.reduce<GroupedFields>(
    (prev, curr) => ({ ...prev, [curr.group]: [...prev[curr.group], curr] }),
    init,
  );
  return groupedFields;
};
