import { estateGasFields } from 'formData/estate/details/fields';
import useFormMethods from 'hooks/useFormMethods';
import { HandleCheckboxChange } from 'types/handleChange';
import { EstateDetailsForm } from 'api/estate/types';

export const useGasFields = (prefix?: string) => {
  const { setValue, getValues, clearErrors } = useFormMethods<EstateDetailsForm>(prefix);

  const onCheckboxChange: HandleCheckboxChange = (_e, checked) => {
    if (!checked) {
      estateGasFields.forEach(({ name }) => {
        clearErrors(`gas.${name}`);
      });
    }
  };

  const updateCheckbox = () => {
    if (!getValues('hasGas')) {
      setValue('hasGas', true);
    }
  };

  return { fields: estateGasFields, onCheckboxChange, updateCheckbox };
};
