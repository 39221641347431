import { useMemo } from 'react';
import { getDynamicAddressFields } from 'formData/common/address/fields';
import { UseAddressFields } from './types';
import { useAddressData } from './useAddressData';

const useAddressFields: UseAddressFields = ({
  onCountryChange,
  prefix,
  googleParams,
  disableCountrySelection,
  regionChanged,
}) => {
  const addressData = useAddressData({ onCountryChange, prefix, googleParams });

  const fields = useMemo(
    () => getDynamicAddressFields(addressData, disableCountrySelection, regionChanged),
    [addressData, disableCountrySelection, regionChanged],
  );
  return fields;
};

export default useAddressFields;
