import { useMemo } from 'react';
import DividerStack from 'atoms/DividerStack';
import SectionRows from 'templates/SectionRows';
import isTruthyValueInObject from 'utils/object/isTruthyValueInObject';
import { EstateDetailsContentProps } from './types';
import { getSectionsData } from './utils';

const EstateDetailsContent = (details: EstateDetailsContentProps) => {
  const data = useMemo(() => {
    return isTruthyValueInObject(details) ? getSectionsData(details) : [];
  }, [details]);

  return (
    <>
      {!!data.length && (
        <DividerStack>
          {data.map(({ key, value }) => (
            <SectionRows key={key} {...value} />
          ))}
        </DividerStack>
      )}
    </>
  );
};

export default EstateDetailsContent;
