import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import { estateCompositionFields } from 'formData/estate/details/fields';
import useComposition, { NewFieldInput } from 'hooks/useComposition';
import { DeleteIcon, EditIcon } from 'icons';
import { EstateFormInterface } from 'modules/EstateForm/types';
import getLng from 'utils/getLng';
import { ControlledNumberInput } from 'components/controlledInputs';
import { EditNewCompositionField } from './EditNewCompositionField';

const CompositionStep = () => {
  const { t } = useTranslation('enums');

  const {
    control,
    formState: { errors },
  } = useFormContext<EstateFormInterface>();

  const { fields, removeItem, newFieldInputProps, editItem, editAdditionalFieldProps } =
    useComposition('estateDetails');

  return (
    <Grid container maxWidth={750}>
      <Grid container item xs={12} rowSpacing={2} columnSpacing={4}>
        {estateCompositionFields.map(({ name, ...props }) => (
          <Grid item key={name} xs={12} sm={6} md={4}>
            <ControlledNumberInput
              label={t(`estateComposition.${name}`)}
              control={control}
              name={`estateDetails.estateComposition.${name}`}
              error={errors.estateDetails?.estateComposition?.[name]}
              hideHelperTextGap
              {...props}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <NewFieldInput {...newFieldInputProps} />
      </Grid>
      <Grid item container rowGap={2} xs={12}>
        {fields.map(({ id, name }, index) => (
          <Grid item key={id} xs={12} sm={6} width="100%">
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={1}
              pl={{ sm: index % 2 === 0 ? 0 : 4 }}
            >
              <Box flex={1}>
                <ControlledNumberInput
                  control={control}
                  label={name[getLng()]}
                  error={
                    errors.estateDetails?.estateComposition?.additionalItems?.[index]
                      ?.count
                  }
                  name={`estateDetails.estateComposition.additionalItems.${index}.count`}
                  isInteger
                  hideHelperTextGap
                />
              </Box>
              <IconButton onClick={editItem(index)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={removeItem(index)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Grid>
        ))}
        {editAdditionalFieldProps?.editingIndex !== undefined && (
          <EditNewCompositionField {...editAdditionalFieldProps} />
        )}
      </Grid>
    </Grid>
  );
};

export default CompositionStep;
