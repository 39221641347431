import { namespaces } from 'i18n';
import { PartyFieldsConfig } from 'modules/ProductParties/types';
import getObjectKeys from 'utils/object/getObjectKeys';
import { InputField } from 'components/controlledInputs/types';
import { SelectOption } from 'components/inputs/types';
import {
  CommonRentalContractForm,
  RegisterRentalContractForm,
  RegisterStateReportForm,
} from './types';

export const rentalContractTenantsConfig: Partial<PartyFieldsConfig> = {
  context: 'multiple',
  maxFieldsCount: 5,
  addingFields: 'byButton',
  showPartner: 'nextToPerson',
  showLegalPersonInSecondaryContact: true,
  selectableRepresentatives: true,
};

export const rentalContractLandlordsConfig: Partial<PartyFieldsConfig> = {
  context: 'multiple',
  maxFieldsCount: 5,
  addingFields: 'byButton',
  selectableRepresentatives: true,
};

const languageOptions: SelectOption[] = getObjectKeys(
  namespaces.enums.documentLanguage,
).map((value) => ({
  value,
  label: value,
  translationKey: `documentLanguage.${value}`,
}));

export const commonRegisterContractFields: InputField<CommonRentalContractForm>[] = [
  {
    name: 'biv',
    type: 'text',
  },
  {
    name: 'language',
    options: languageOptions,
  },
];

export const registerRentalContractFields: InputField<
  Omit<RegisterRentalContractForm, 'tenants' | 'landlords'>
>[] = [
  {
    name: 'contractSigningDate',
    type: 'date',
    warningKey: 'contractSigningDate',
  },
  ...commonRegisterContractFields,
];

export const registerStateReportFields: InputField<RegisterStateReportForm>[] = [
  {
    name: 'stateReportSigningDate',
    type: 'date',
    warningKey: 'stateReportSigningDate',
  },
  ...commonRegisterContractFields,
];
