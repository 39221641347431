import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import usePrompt from 'hooks/usePrompt';
import useRentalConditionsFields from 'hooks/useRentalConditionsFields';
import routes, { dossierTabRoutes } from 'router/routes';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { RentalConditions } from 'api/dossier/types';
import { ControlledInput } from 'components/controlledInputs';

const pathnamesToExclude = Object.values(dossierTabRoutes).map(
  (route) => `${routes.rentalDossier(':dossierId')}/${route}`,
);

const RentalConditionsEdition = () => {
  const { t } = useTranslation('formFields');

  const methods = useFormContext<RentalConditions>();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  usePrompt({
    when: methods.formState.isDirty,
    id: 'rentalConditions',
    pathnamesToExclude: matches ? pathnamesToExclude : [],
  });

  const fields = useRentalConditionsFields(methods);

  return (
    <Stack spacing={1} flex={1} padding={2.5}>
      {fields.map(({ name, hidden, ...field }) =>
        hidden ? null : (
          <InputLabelWrapper key={name} label={t(name)}>
            <ControlledInput
              name={name}
              control={methods.control}
              error={methods.formState.errors[name]}
              hideHelperTextGap
              {...field}
            />
          </InputLabelWrapper>
        ),
      )}
    </Stack>
  );
};

export default RentalConditionsEdition;
