import { useOutletContext } from 'react-router-dom';
import { DossierPermissions } from 'consts/permissions';
import { DossierDocumentsCard } from 'modules/DocumentsCard';
import hasPermission from 'utils/hasPermission';
import { DossierDocumentsContext } from './types';

const DossierProductsDocuments = () => {
  const { dossierId, dossierData, dossierDocuments, fetchDocuments } =
    useOutletContext<DossierDocumentsContext>();

  return (
    <DossierDocumentsCard
      dossierData={{
        dossierId,
        dossierStatus: dossierData.status,
        dossierDocumentsInfo: dossierDocuments.dossier,
        entityType: 'dossier',
      }}
      editable={hasPermission(DossierPermissions.Write)}
      fetchDocuments={fetchDocuments}
    />
  );
};

export default DossierProductsDocuments;
