import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListSubheader, Stack } from '@mui/material';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { BackOfficeContext } from 'contexts/backofficeContext';
import { ConfigContext } from 'contexts/config/ConfigContext';
import BusinessData from './BusinessData';
import NavItem from './NavItem';
import { navListStyles } from './styles';
import { getNavItems } from './utils';

interface Props {
  onNavItem: () => void;
  isSidebarExpanded: boolean;
  expandSidebar: () => void;
}

export const Navigation = ({ onNavItem, isSidebarExpanded, expandSidebar }: Props) => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'aside' });
  const [selected, setSelected] = useState('');
  const { rentalDossierChecklist } = useContext(ConfigContext);
  const { impersonateId } = useContext(BackOfficeContext);
  const { roles } = useContext(AccountContext);

  const select = (label: string) => () => {
    setSelected(label);
    onNavItem();
  };

  const navItems = useMemo(
    () => getNavItems(rentalDossierChecklist),
    [rentalDossierChecklist, impersonateId],
  );

  return (
    <Stack component="nav" sx={navListStyles}>
      {/* {roles.includes('BackofficeUser') && ( */}
      {roles.includes('SuperAdmin') && (
        <BusinessData
          isSidebarExpanded={isSidebarExpanded}
          select={select}
          selected={selected}
          expandSidebar={expandSidebar}
        />
      )}
      {navItems.map(({ subheader, items }) => (
        <List
          key={subheader}
          subheader={
            <ListSubheader
              title={i18n.language === 'cimode' ? t(`subheader.${subheader}`) : ''}
              sx={{ opacity: isSidebarExpanded ? 1 : 0 }}
            >
              {t(`subheader.${subheader}`)}
            </ListSubheader>
          }
        >
          <Box>
            {items.map(({ to, label, onClick, ...props }) => (
              <NavItem
                key={`${label}-${to}`}
                to={to}
                label={t(`items.${label}`)}
                isSelected={selected === label}
                select={onClick || select(label)}
                isSidebarExpanded={isSidebarExpanded}
                resetSelection={select('')}
                {...props}
              />
            ))}
          </Box>
        </List>
      ))}
    </Stack>
  );
};
