import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { calculateEndOfRental } from 'utils/calculateEndOfRental/calculateEndOfRental';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { RentalDuration } from 'api/dossier/types';
import { ControlledInput } from 'components/controlledInputs';
import { getRentalConditionsStepFields } from './fields';

export const RentalConditionsStep = () => {
  const { control, setValue, getValues, formState, trigger } =
    useFormContext<RegisterAddendumForm>();
  const { t } = useTranslation(['modals', 'formFields']);

  const handleChangeDuration = (duration: RentalDuration) => {
    setValue(
      'rentalConditions.endDate',
      calculateEndOfRental(getValues('rentalConditions.entryDate'), duration),
    );
  };

  const duration = useWatch({ control, name: 'rentalConditions.duration' });
  const fields = getRentalConditionsStepFields(duration, handleChangeDuration);

  const onChange = (name: keyof RegisterAddendumForm['rentalConditions']) => () => {
    if (formState.errors.rentalConditions?.[name]) {
      trigger(`rentalConditions.${name}`);
    }

    switch (name) {
      case 'entryDate':
        if (formState.errors.rentalConditions?.endDate) {
          trigger('rentalConditions.endDate');
        }
        break;
      case 'endDate':
        if (formState.errors.rentalConditions?.entryDate) {
          trigger('rentalConditions.entryDate');
        }
        break;
    }
  };

  return (
    <Box sx={{ pt: 1 }}>
      <Grid container columnSpacing={2} rowSpacing={0.5}>
        {fields
          .filter(({ hidden }) => !hidden)
          .map(({ name, ...field }) => (
            <Grid key={name} item xs={6}>
              <ControlledInput
                name={`rentalConditions.${name}`}
                control={control}
                onInputChange={onChange(name)}
                error={formState.errors.rentalConditions?.[name]}
                label={t(`formFields:${name}`)}
                {...field}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
