import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import useLoader from 'hooks/useLoader';
import UserDetailsCard from 'modules/UserDetailsCard';
import { getUserConfig, getUserData } from './utils';

export const UserDetails = () => {
  const { userId } = useParams();
  if (!userId) return null;

  const { data, updateData, Loader } = useLoader(getUserConfig(userId));

  return (
    <Loader>
      {data && (
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12} lg={6} xl={5} order={{ xs: 1, lg: 2 }} marginBottom="auto">
            <UserDetailsCard
              userData={getUserData(data)}
              userId={userId}
              fetchUserData={updateData}
            />
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};
