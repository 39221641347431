import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dossierFormDefaultValues } from 'formData/dossier/defaultValues';
import { rentalConditionsSchema } from 'formData/dossier/rentalConditions';
import { dossierFormValidation } from 'formData/dossier/validation';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useGetRegion from 'hooks/useGetRegion';
import { DossierFormInterface } from 'modules/DossierForm/types';
import { getRentalConditionsDefaultValues } from 'modules/DossierView/RentalConditions/utils';
import routes from 'router/routes';
import { StepperErrors } from 'templates/StepWizard/types';
import { useUpdateEffect } from 'usehooks-ts';
import setValidationErrors from 'utils/setValidationErrors';
import { resolver } from 'utils/yup';
import { addRentalDossier, updateRentalConditions } from 'api/dossier/requests';
import { RentalConditions } from 'api/dossier/types';
import { DossierFormSteps } from './static-data';
import { SaveDossierFormProps, UpdateFormErrorsProps } from './types';

const useDossierForm = () => {
  const [errors, setErrors] = useState<StepperErrors>(null);
  const [saving, setSaving] = useState(false);
  const [dossierFormData, setDossierFormData] = useState(dossierFormDefaultValues);
  const [rentalConditionsData, setRentalConditionsData] =
    useState<RentalConditions | null>(null);

  const dossierMethods = useForm<DossierFormInterface>({
    defaultValues: dossierFormDefaultValues,
    resolver: resolver(dossierFormValidation),
  });

  const region = useGetRegion(
    dossierFormData.estate?.postCode,
    dossierFormData.estate?.city,
  );
  const rentalConditionsMethods = useForm<RentalConditions>({
    defaultValues: rentalConditionsSchema.getDefault(),
    resolver: resolver(rentalConditionsSchema),
    context: { region },
  });

  const navigate = useCustomNavigate();

  const saveForm = async (props?: SaveDossierFormProps) => {
    const { dossierForm = dossierFormData, rentalConditions = rentalConditionsData } =
      props ?? {};
    const isDossierFormValid = dossierMethods.formState.isValid;
    const isRentalConditionsFormValid =
      !rentalConditions || rentalConditionsMethods.formState.isValid;

    updateErrors({ isDossierFormValid, isRentalConditionsFormValid });

    if (isDossierFormValid && isRentalConditionsFormValid) {
      setSaving(true);
      const dossierResponse = await addRentalDossier(dossierForm);

      if (dossierResponse.ok) {
        let shouldRedirect = false;

        const { id } = dossierResponse.response;
        shouldRedirect = true;
        if (rentalConditions) {
          const res = await updateRentalConditions(id, rentalConditions);
          shouldRedirect = res.ok;
        }

        if (shouldRedirect) {
          navigate(routes.rentalDossier(id), { withoutPrompt: true });
        }
      } else {
        setValidationErrors(dossierResponse.validation, dossierMethods.setError);
        updateErrors({});
      }
      setSaving(false);
    }
  };

  useEffect(() => {
    const defaultValues = getRentalConditionsDefaultValues(
      rentalConditionsData,
      dossierFormData.estate,
    );
    rentalConditionsMethods.reset(defaultValues, { keepDirty: false });
  }, [rentalConditionsData, dossierFormData.estate]);

  useUpdateEffect(() => {
    if (errors?.[DossierFormSteps.ParametersStep] && dossierMethods.formState.isValid) {
      setErrors((prev) => ({ ...prev, [DossierFormSteps.ParametersStep]: false }));
    }
  }, [dossierMethods.formState.isValid, errors]);

  useUpdateEffect(() => {
    if (
      errors?.[DossierFormSteps.RentalConditionsStep] &&
      rentalConditionsMethods.formState.isValid
    ) {
      setErrors((prev) => ({ ...prev, [DossierFormSteps.RentalConditionsStep]: false }));
    }
  }, [rentalConditionsMethods.formState.isValid, errors]);

  const updateDossierForm = () => {
    setDossierFormData(dossierMethods.getValues());
  };

  const updateRentalConditionsForm = useCallback(() => {
    if (rentalConditionsMethods.formState.isDirty) {
      setRentalConditionsData(rentalConditionsMethods.getValues());
    }
  }, [rentalConditionsMethods.formState.isDirty]);

  const updateErrors = ({
    isDossierFormValid = dossierMethods.formState.isValid,
    isRentalConditionsFormValid = !rentalConditionsData ||
      rentalConditionsMethods.formState.isValid,
  }: UpdateFormErrorsProps) => {
    setErrors({
      [DossierFormSteps.ParametersStep]: !isDossierFormValid,
      [DossierFormSteps.RentalConditionsStep]: !isRentalConditionsFormValid,
    });
    if (!isDossierFormValid) {
      dossierMethods.trigger();
    }

    if (!isRentalConditionsFormValid) {
      rentalConditionsMethods.trigger();
    }
  };

  return {
    saving,
    errors,
    saveForm,
    updateDossierForm,
    updateRentalConditionsForm,
    updateErrors,
    dossierMethods,
    rentalConditionsMethods,
  };
};

export default useDossierForm;
