import { rentalConditionsSchema } from 'formData/dossier/rentalConditions';
import { formatDate } from 'utils/date';
import paymentToString from 'utils/paymentToString';
import { RentalConditions, RentType } from 'api/dossier/types';
import { FetchedEstateListItem } from 'api/estate/types';

export const getDefaultRentType = ({
  destination,
  type,
}: Pick<FetchedEstateListItem, 'destination' | 'type'>): RentType => {
  if (destination === 'Living' && type) {
    switch (type) {
      case 'House':
      case 'Apartment':
        return 'Habitation';
      default:
        return 'CommonLaw';
    }
  }
  if (type === 'GarageParking') {
    return 'CommonLaw';
  }
  return 'Commercial';
};

export const getRentalConditionsDefaultValues = (
  rentalConditions: RentalConditions | null,
  estate?: Pick<FetchedEstateListItem, 'destination' | 'type'> | null,
): RentalConditions => ({
  ...rentalConditionsSchema.getDefault(),
  ...(rentalConditions || rentalConditionsSchema.getDefault()),
  contractSigningDate: formatDate(rentalConditions?.contractSigningDate, 'yyyy-MM-dd'),
  entryDate: formatDate(rentalConditions?.entryDate, 'yyyy-MM-dd'),
  endDate: formatDate(rentalConditions?.endDate, 'yyyy-MM-dd'),
  rentType:
    rentalConditions?.rentType ||
    (estate ? getDefaultRentType(estate) : rentalConditionsSchema.getDefault().rentType),
});

export const getRentalConditionsDisplayData = ({
  rentalPrice,
  charges,
  depositAmount,
  isTaxPayableByTenant,
  taxPayableByTenant,
  ...data
}: RentalConditions): Partial<
  Record<keyof RentalConditions, string | number | null | boolean>
> => ({
  ...data,
  rentalPrice: !rentalPrice ? null : paymentToString(rentalPrice),
  charges: !charges ? null : paymentToString(charges),
  depositAmount: !depositAmount ? null : paymentToString(depositAmount),
  isTaxPayableByTenant: isTaxPayableByTenant
    ? formatTaxPayableByTenant(taxPayableByTenant)
    : null,
});

const formatTaxPayableByTenant = (tax: RentalConditions['taxPayableByTenant']) => {
  if (tax && tax.unit === 'Percent') {
    return `${tax.value}%`;
  } else if (tax) {
    return `${tax.value} ${tax.unit}`;
  }
  return null;
};
