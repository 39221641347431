import { useCallback, useEffect, useMemo, useState } from 'react';
import useDocumentsModal from 'hooks/useDocumentsModal';
import { HandleSingleDocumentSave } from 'hooks/useFileUpload/types';
import { getDocumentToUpload } from 'hooks/useFileUpload/utils';
import useProductsDocumentsModal from 'hooks/useProductsDocumentsModal';
import { PRODUCT_ACTION_DOCUMENTS } from 'modules/UploadDocuments/static-data';
import DossierDocumentsTable from 'templates/DossierDocumentsTable';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { DocumentType } from 'api/documents/types';
import { getProducts } from 'api/dossier/products/requests';
import { ProductInterface } from 'api/dossier/products/types';
import { attachDossierTypeDocument } from 'api/dossier/requests';
import { DocumentsModal, ProductActionModal } from 'components/modals';
import { DossierDocumentsCardProps } from './types';
import {
  createMissingDocumentType,
  getExcludedDossierDocuments,
  transformDossierDocumentsData,
} from './utils';

export const DossierDocumentsCard = ({
  editable,
  fetchDocuments,
  dossierData: {
    dossierId,
    dossierDocumentsInfo: {
      documents,
      missingDocumentTypes = [],
      requiredDocumentTypes,
      attachedDocuments,
    },
    dossierStatus,
    entityType,
  },
}: DossierDocumentsCardProps) => {
  const [products, setProducts] = useState<ProductInterface[]>([]);

  const transformedDocuments = useMemo(
    () => transformDossierDocumentsData(documents, attachedDocuments),
    [documents, attachedDocuments],
  );

  const [documentsDataToDisplay, setDocumentsDataToDisplay] =
    useState(transformedDocuments);

  const { openProductActionModal, productActionModalProps } =
    useProductsDocumentsModal(products);
  const {
    isOpen,
    missingDocumentType,
    openModal,
    closeModal: closeDocumentsModal,
  } = useDocumentsModal();

  const onSingleDocumentSave: HandleSingleDocumentSave = async (documentId) => {
    await attachDossierTypeDocument(dossierId, documentId, { attached: true });
    if (missingDocumentType) {
      closeDocumentsModal();
    }
  };

  const fetchProducts = useCallback(async () => {
    const { ok, response } = await getProducts(dossierId);
    if (ok) {
      setProducts(response);
    }
  }, [dossierId]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    const missingDocuments = missingDocumentTypes.map(createMissingDocumentType);
    setDocumentsDataToDisplay([...transformedDocuments, ...missingDocuments]);
  }, [transformedDocuments, missingDocumentTypes]);

  const handleProductActionDocuments = (documentType?: DocumentType) => {
    if (documentType === 'SignedReleaseForm') {
      openProductActionModal('RentalDeposit', 'RentalDepositUploadSignedReleaseForm');
    } else if (documentType === 'Verdict') {
      openProductActionModal('RentalDeposit', 'RentalDepositUploadVerdict');
    }
  };

  const openDocumentsModal = (documentType?: DocumentType) => {
    if (documentType && PRODUCT_ACTION_DOCUMENTS.includes(documentType)) {
      handleProductActionDocuments(documentType);
    } else {
      openModal(documentType);
    }
  };

  const handleDocumentTypeChange = (documentType: DocumentType) => {
    if (documentType && PRODUCT_ACTION_DOCUMENTS.includes(documentType)) {
      handleProductActionDocuments(documentType);
      closeDocumentsModal();
    }
  };

  const excludedDocumentTypes = useMemo(
    () => getExcludedDossierDocuments(products),
    [products],
  );

  return (
    <>
      <DossierDocumentsTable
        data={documentsDataToDisplay}
        updateDocuments={fetchDocuments}
        openDocumentsModal={openDocumentsModal}
        context={{
          scope: 'dossier',
          entityId: dossierId,
          showFileActions: shouldDossierFeatureBeActive(dossierStatus),
          fileDeletion: {
            disable: (document) => document.source === 'External' || document.sentOut,
            reason: 'documentProcessed',
          },
          dossierId,
          dossierStatus,
          entityType,
        }}
        editable={editable}
      />
      {isOpen && (
        <DocumentsModal
          scope="dossier"
          entityId={dossierId}
          missingDocuments={
            missingDocumentType
              ? [
                  getDocumentToUpload({
                    entityId: dossierId,
                    scope: 'dossier',
                    documentType: missingDocumentType,
                  }),
                ]
              : undefined
          }
          onClose={closeDocumentsModal}
          onSubmit={fetchDocuments}
          onSingleDocumentSave={onSingleDocumentSave}
          requiredDocumentTypes={requiredDocumentTypes}
          excludedDocumentTypes={excludedDocumentTypes}
          onDocumentTypeChange={handleDocumentTypeChange}
        />
      )}
      {productActionModalProps && <ProductActionModal {...productActionModalProps} />}
    </>
  );
};
