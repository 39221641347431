import { useCallback, useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { registerRentalContractSchema } from 'formData/dossier/products/rentalContract/schema';
import ProductParties from 'modules/ProductParties';
import { resolver } from 'utils/yup';
import {
  ProductActionContent,
  ProductActionEstateInfo,
  ProductInputFields,
} from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import RentalConditionDetails from './RentalConditionsDetails';
import {
  registerRentalContractFields,
  rentalContractLandlordsConfig,
  rentalContractTenantsConfig,
} from './static-data';
import { RegisterRentalContractForm } from './types';
import { transformRentalContractData } from './utils';

const RegisterRentalContract = ({
  sendRequest,
  dossier: { rentalConditions, estate, tenants, landlords },
}: ProductActionComponentProps) => {
  const methods = useForm<RegisterRentalContractForm>({
    defaultValues: registerRentalContractSchema.getDefault(),
    resolver: resolver(registerRentalContractSchema),
  });

  const { userData } = useContext(AccountContext);

  const setBiv = useCallback(() => {
    methods.setValue('biv', userData?.biv || null);
  }, [userData]);

  useEffect(() => {
    setBiv();
  }, [setBiv]);

  const onSubmit: SubmitHandler<RegisterRentalContractForm> = (data) => {
    sendRequest(transformRentalContractData(data));
  };

  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container gap={4} mt={1}>
        <Box width="100%">
          <ProductActionEstateInfo estate={estate} />
          {rentalConditions && (
            <RentalConditionDetails rentalConditions={rentalConditions} />
          )}
        </Box>
        <FormProvider {...methods}>
          <ProductParties
            persons={tenants}
            partyType="tenants"
            config={rentalContractTenantsConfig}
          />
          <ProductParties
            persons={landlords}
            partyType="landlords"
            config={rentalContractLandlordsConfig}
          />
          <ProductInputFields fields={registerRentalContractFields} />
        </FormProvider>
      </Grid>
    </ProductActionContent>
  );
};

export default RegisterRentalContract;
