import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { productMissingDataSchema } from 'formData/dossier/products/productMissingData/schema';
import { estateDetailsSchema } from 'formData/estate';
import { EstateMissingDataForm } from 'modules/ProductMissingData/forms';
import { resolver } from 'utils/yup';
import { ProductActionContent } from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import EstateDetailsAdditionalDataForm from './EstateDetailsAdditionalDataForm';
import { EstateForFireInsuranceForm } from './types';
import { getEstateFormData, handleUpdateEstate } from './utils';

const FireInsuranceAdditionalData = ({
  sendRequest,
  onClose,
  dossier: { estate },
  setLoading,
}: ProductActionComponentProps) => {
  const [showEstateData, setShowEstateData] = useState(false);

  const methods = useForm<EstateForFireInsuranceForm>({
    resolver: resolver(
      productMissingDataSchema.shape({ estateDetails: estateDetailsSchema }),
    ),
  });

  const fetchEstate = useCallback(async () => {
    setLoading(true);

    const form = await getEstateFormData(estate, methods.getValues());
    setShowEstateData(!!form.estate);
    methods.reset(form, { keepDirtyValues: true });

    setLoading(false);
  }, [estate.estateId, estate.type]);

  useEffect(() => {
    fetchEstate();
  }, [fetchEstate]);

  const onSubmit: SubmitHandler<EstateForFireInsuranceForm> = async (data) => {
    const { dirtyFields } = methods.formState;

    if (Object.keys(dirtyFields).length) {
      sendRequest(
        null,
        handleUpdateEstate(data, estate.estateId, dirtyFields),
        fetchEstate,
      );
    } else {
      onClose();
    }
  };

  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack spacing={1}>
        {showEstateData && (
          <EstateMissingDataForm methods={methods} id={estate.estateId} />
        )}
        <EstateDetailsAdditionalDataForm {...methods} />
      </Stack>
    </ProductActionContent>
  );
};

export default FireInsuranceAdditionalData;
