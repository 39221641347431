import { TranslatedOption } from 'formData/types';
import getObjectEntries from 'utils/object/getObjectEntries';
import { DocumentsConfig, DocumentScope, DocumentType } from 'api/documents/types';

export const getDocumentTypeOptions = (
  documentsConfig: DocumentsConfig,
  scope: DocumentScope,
): TranslatedOption<DocumentType>[] => {
  if (!documentsConfig[scope]) {
    return [];
  }

  return getObjectEntries(documentsConfig[scope])
    .filter(([_, { source }]) => source === 'Internal')
    .sort((a, b) => (a === b ? 0 : a > b ? 1 : -1))
    .map(([value]) => ({
      value,
      label: value,
      translationKey: `documents:${scope}.${value}`,
    }));
};
