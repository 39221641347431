import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, ListItem, ListItemText } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import IconWithLoader from 'atoms/IconWithLoader';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { DeleteIcon, DownloadIcon, EyeShowIcon } from 'icons';
import { getFileNameWithoutExtension } from 'utils/handleFile';
import { DocumentData } from 'api/documents/types';
import { DocumentsContextInterface } from '../types';
import { getDocumentUrl } from '../utils';
import { DocumentFileComponentProps } from './types';
import { getFileUrl, handleFile } from './utils';

const DocumentFileComponent = <
  TData extends DocumentData,
  TContext extends DocumentsContextInterface<TData>,
>({
  document,
  file,
  context: { scope, entityId, showFileActions = true, fileDeletion },
  updateDocuments,
}: DocumentFileComponentProps<TData, TContext>) => {
  const { t } = useTranslation('table', { keyPrefix: 'documents' });
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const [pendingDownload, setPendingDownload] = useState(false);
  const [pendingPreview, setPendingPreview] = useState(false);

  const handleDownload = async () => {
    setPendingDownload(true);
    await handleFile('download', { document, file, entityId, scope });
    setPendingDownload(false);
  };

  const handlePreview = async () => {
    setPendingPreview(true);
    await handleFile('preview', { document, file, entityId, scope });
    setPendingPreview(false);
  };

  const handleDelete = () => {
    if (document.files.length === 1) {
      setUpAndOpenConfirmationModal({
        url: getDocumentUrl[scope](entityId, document.id),
        translationKey: 'file',
        onSuccess: updateDocuments,
        translationValues: {
          context: 'last',
          fileName: file.fileName,
          documentName: document.documentName,
        },
      });
    } else {
      setUpAndOpenConfirmationModal({
        url: getFileUrl[scope](entityId, document.id, file.id),
        translationKey: 'file',
        onSuccess: updateDocuments,
        translationValues: { fileName: file.fileName },
      });
    }
  };

  return (
    <ListItem dense sx={{ pl: 7.5 }} alignItems="center">
      <ListItemText
        primary={getFileNameWithoutExtension(file.fileName)}
        secondary={file.contentType}
      />
      {showFileActions ? (
        <Box display="flex" gap={1} pr={1}>
          <IconTooltip title={t('actions.download')} disableInteractive>
            <IconButton
              size="small"
              color="primary"
              edge="end"
              onClick={handleDownload}
              disabled={pendingDownload}
            >
              <IconWithLoader Icon={DownloadIcon} loading={pendingDownload} />
            </IconButton>
          </IconTooltip>
          <IconTooltip title={t('actions.preview')} disableInteractive>
            <IconButton
              size="small"
              color="primary"
              edge="end"
              onClick={handlePreview}
              disabled={pendingPreview}
            >
              <IconWithLoader Icon={EyeShowIcon} loading={pendingPreview} />
            </IconButton>
          </IconTooltip>
          <IconTooltip
            title={t(
              fileDeletion?.disable(document)
                ? `tooltips.${fileDeletion.reason}`
                : 'actions.delete',
            )}
            disableInteractive
          >
            <IconButton
              size="small"
              color="primary"
              edge="end"
              onClick={handleDelete}
              disabled={fileDeletion?.disable(document)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </IconTooltip>
        </Box>
      ) : null}
    </ListItem>
  );
};

export default DocumentFileComponent;
