import { Grid } from '@mui/material';
import { UploadDocumentsWithContext } from 'modules/UploadDocuments';

const EstateDocuments = () => (
  <Grid container maxWidth={750} width={{ md: 750 }}>
    <Grid item xs={12}>
      <UploadDocumentsWithContext scope="estate" />
    </Grid>
  </Grid>
);

export default EstateDocuments;
