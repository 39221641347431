import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@mui/material';
import Button from 'atoms/Button';
import { naturalPersonSchema } from 'formData/naturalPerson';
import { GeneralStep } from 'modules/PersonForm/NaturalPersonForm/steps/general/GeneralStep';
import { personToRelation } from 'modules/Relation/utils';
import addressToString from 'utils/addressToString';
import transformName from 'utils/transformName';
import { resolver } from 'utils/yup';
import { addNaturalPerson } from 'api/person/natural/requests';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { FetchedPersonData } from 'api/person/types';
import ModalTitle from 'components/modals/ModalTitle';
import { FormModal } from './FormModal';
import { FormModalContentProps, FormModalProps } from './types';

export const NaturalPersonFormContent = ({
  closeModal,
  onSubmit,
}: FormModalContentProps<FetchedPersonData>) => {
  const { t } = useTranslation();

  const methods = useForm<NaturalPersonFormData>({
    defaultValues: naturalPersonSchema.getDefault(),
    resolver: resolver(naturalPersonSchema),
  });

  const onSave: SubmitHandler<NaturalPersonFormData> = async (
    data: NaturalPersonFormData,
  ) => {
    const { ok, response } = await addNaturalPerson(data);
    if (ok) {
      const relationData = personToRelation({
        id: response.id,
        name: transformName(data),
        type: 'NaturalPerson',
        address: addressToString(data.address),
      });

      onSubmit(relationData);
      closeModal();
    }
  };

  return (
    <FormProvider {...methods}>
      <DialogContent sx={{ scrollbarGutter: 'stable', mt: 2, pb: 0 }}>
        <GeneralStep />
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button
          role="text"
          onClick={closeModal}
          disabled={methods.formState.isSubmitting}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={methods.handleSubmit(onSave)}
          loading={methods.formState.isSubmitting}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </FormProvider>
  );
};

const AddNaturalPersonModal = ({
  open,
  closeModal,
  onSubmit,
}: FormModalProps<FetchedPersonData>) => {
  const { t } = useTranslation();

  return (
    <FormModal open={open} closeModal={closeModal} maxWidth="xl">
      <ModalTitle title={t('stepWizard.naturalPerson.title')} closeModal={closeModal} />
      <NaturalPersonFormContent closeModal={closeModal} onSubmit={onSubmit} />
    </FormModal>
  );
};

export default AddNaturalPersonModal;
