import { conformToMask } from 'react-text-mask';
import i18n from 'i18n';
import { normalizeString } from 'utils/string';
import { ProductDetailsType, ProductName } from 'api/dossier/products/types';

const formatOGM = (ogm: string) =>
  `***${
    conformToMask(ogm, [
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]).conformedValue
  }***`;

const mapProductDetails = (
  productName: ProductName,
  { paymentReferenceNumber = null, balance = null, ...details }: ProductDetailsType,
): ProductDetailsType =>
  Object.entries({
    ...details,
    ...(paymentReferenceNumber
      ? { paymentReferenceNumber: formatOGM(paymentReferenceNumber) }
      : {}),
    ...(balance !== null ? { balance: `${balance} EUR` } : {}),
  })
    .map(([key, value]) => ({
      key: i18n.t(`dossierProducts:${productName}.details.${key}`, normalizeString(key)),
      value,
    }))
    .reduce((prev, { key, value }) => ({ ...prev, [key]: value }), {});

export default mapProductDetails;
