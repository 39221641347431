import palette from 'themes/palette';
import { ProductName } from 'api/dossier/products/types';
import { ProductStatus } from 'api/dossier/types';

const productsOrder: ProductName[] = [
  'RentalDeposit',
  'FireInsurance',
  'LegalAssistance',
  'RentalContract',
  'MyMove',
  'StateReport',
  'IntermediateStateReport',
  'EndStateReport',
];

export const sortProductsIcons = (a: ProductStatus, b: ProductStatus) =>
  productsOrder.indexOf(a.product) > productsOrder.indexOf(b.product) ? 1 : -1;

export const getProductIconColor = (status: string, isPaid?: boolean) => {
  if ((isPaid && (status == 'Draft' || status == 'OrderSent')) || status === 'Paid') {
    return palette.primary.light;
  }
  switch (status) {
    case 'OrderSent':
    case 'InAbolishment':
    case 'Sent':
    case 'Failed':
    case 'Rejected':
    case 'WaitingForPayment':
    case 'Planned':
    case 'Requested':
      return palette.warning.main;
    case 'Running':
    case 'Released':
    case 'Cancelled':
    case 'Final':
    case 'Registered':
    case 'Completed':
      return palette.success.main;
    default:
      return palette.error.main;
  }
};
