import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { isEstateComposition } from 'formData/estate/details/utils';
import useEstateDetails from 'hooks/useEstateDetails';
import useLoader from 'hooks/useLoader';
import { EstateMainCard, EstateViewHeader } from 'modules/EstateView';
import { OwnerCard } from 'modules/OwnershipCard';
import routes, { estateTabRoutes } from 'router/routes';
import { RouteTabs } from 'components/tabs';
import { getEstateConfig, getEstateData } from './utils';

export const EstateDetails = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const { estateId } = useParams();

  if (!estateId) return null;

  const {
    data: estate,
    updateData: fetchEstate,
    Loader,
  } = useLoader(getEstateConfig(estateId));

  const estateRoutes = useMemo(() => {
    let estateRoutes: Partial<typeof estateTabRoutes> = estateTabRoutes;

    if (!isEstateComposition(estate)) {
      const { composition: _, ...routesWithoutComposition } = estateRoutes;
      estateRoutes = routesWithoutComposition;
    }

    if (matches) {
      const { contacts: _contacts, ...routes } = estateRoutes;
      estateRoutes = routes;
    }
    return estateRoutes;
  }, [estate?.type, matches]);

  const { estateDetails, fetchEstateDetails } = useEstateDetails(estateId);

  return (
    <Loader>
      {estate && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              <EstateViewHeader data={getEstateData(estate)} estateId={estateId} />
              <RouteTabs
                routes={estateRoutes}
                parentPath={routes.estate(estateId)}
                hiddenTabs={!isEstateComposition(estate) ? ['composition'] : []}
                translationKey="estateView"
              />
            </Stack>
            <Outlet
              context={{
                estate: getEstateData(estate),
                estateId,
                estateDetails,
                fetchEstate,
                fetchEstateDetails,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            display={{ xs: 'none', lg: 'block' }}
            mb="auto"
          >
            <Stack spacing={3}>
              <EstateMainCard
                estate={getEstateData(estate)}
                estateId={estateId}
                fetchEstate={fetchEstate}
                estateDetails={estateDetails}
                fetchEstateDetails={fetchEstateDetails}
              />
              <OwnerCard estateId={estateId} />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};
