import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UploadPhotoContext } from 'contexts/photoUpload/uploadPhotoContext';
import { EstateFormInterface, UseEstateFormStep } from 'modules/EstateForm/types';
import DescriptionStep from './DescriptionStep';

export const useDescriptionStep: UseEstateFormStep = ({ onCancel, saveForm }) => {
  const { t } = useTranslation();

  const uploadPhotoProps = useContext(UploadPhotoContext);

  const { handleSubmit, formState } = useFormContext<EstateFormInterface>();

  return {
    isValid: formState.isValid,
    Component: <DescriptionStep uploadPhotoProps={uploadPhotoProps} />,
    cancelButton: {
      onClick: onCancel,
    },
    backButton: true,
    nextButton: true,
    submitButton: {
      label: t('save'),
      onClick: handleSubmit(saveForm),
      saving: formState.isSubmitting,
    },
  };
};
