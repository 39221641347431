import { useContext } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import { DossierFormInterface } from 'modules/DossierForm/types';
import { StepInterface } from 'templates/StepWizard/types';
import { DossierFormStep } from '../types';
import GeneralStep from './GeneralStep';

const useGeneralStep = ({ onCancel }: DossierFormStep): StepInterface => {
  const { t } = useTranslation();
  const { dossierMethods, saveForm, updateErrors, updateDossierForm, saving } =
    useContext(DossierFormContext);

  const onSave: SubmitHandler<DossierFormInterface> = (dossierForm) => {
    saveForm({ dossierForm });
  };

  const onError = () => updateErrors({ isDossierFormValid: false });

  const { tenants, landlords, estate } = dossierMethods.getValues();

  return {
    labelButton: {
      onClick: updateDossierForm,
    },
    required: true,
    Component: <GeneralStep />,
    cancelButton: {
      onClick: onCancel,
    },
    backButton: { disable: true },
    nextButton: {
      onClick: updateDossierForm,
    },
    submitButton: {
      label: t('saveAndClose'),
      onClick: dossierMethods.handleSubmit(onSave, onError),
      disable: !(tenants.length || landlords.length || estate) || saving,
      saving,
    },
  };
};

export default useGeneralStep;
