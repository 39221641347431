import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@mui/material';
import Button from 'atoms/Button';
import { legalPersonSchema } from 'formData/legalPerson';
import { GeneralStep } from 'modules/PersonForm/LegalPersonForm/general/GeneralStep';
import { personToRelation } from 'modules/Relation/utils';
import addressToString from 'utils/addressToString';
import { resolver } from 'utils/yup';
import { addLegalPerson } from 'api/person/legal/requests';
import { LegalPersonFormData } from 'api/person/legal/types';
import { FetchedPersonData } from 'api/person/types';
import ModalTitle from 'components/modals/ModalTitle';
import { FormModal } from './FormModal';
import { FormModalContentProps, FormModalProps } from './types';

export const LegalPersonFormContent = ({
  closeModal,
  onSubmit,
}: FormModalContentProps<FetchedPersonData>) => {
  const { t } = useTranslation();

  const methods = useForm<LegalPersonFormData>({
    defaultValues: legalPersonSchema.getDefault(),
    resolver: resolver(legalPersonSchema),
  });

  const onSave: SubmitHandler<LegalPersonFormData> = async (
    data: LegalPersonFormData,
  ) => {
    const { ok, response } = await addLegalPerson(data);
    if (ok) {
      const relationData = personToRelation({
        id: response.id,
        name: data.name,
        type: 'LegalPerson',
        address: addressToString(data.address),
      });

      onSubmit(relationData);
      closeModal();
    }
  };

  return (
    <FormProvider {...methods}>
      <DialogContent sx={{ scrollbarGutter: 'stable', mt: 2, pb: 0 }}>
        <GeneralStep />
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button
          role="text"
          onClick={closeModal}
          disabled={methods.formState.isSubmitting}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={methods.handleSubmit(onSave)}
          loading={methods.formState.isSubmitting}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </FormProvider>
  );
};

const AddLegalPersonModal = ({
  open,
  closeModal,
  onSubmit,
}: FormModalProps<FetchedPersonData>) => {
  const { t } = useTranslation();

  return (
    <FormModal open={open} closeModal={closeModal} maxWidth="xl">
      <ModalTitle title={t('stepWizard.legalPerson.title')} closeModal={closeModal} />
      <LegalPersonFormContent onSubmit={onSubmit} closeModal={closeModal} />
    </FormModal>
  );
};

export default AddLegalPersonModal;
