import { Navigate, useOutletContext } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { RentalConditionsCard, ResidentsCard } from 'modules/DossierView';
import routes from 'router/routes';
import { DossierOutletContext } from '../types';

const DossierRentalConditionsTab = () => {
  const context = useOutletContext<DossierOutletContext>();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return matches ? (
    <Navigate to={routes.rentalDossierTab(context.dossierId).residents} replace />
  ) : (
    <Grid container spacing={3}>
      <Grid
        container
        item
        xs={12}
        md={7}
        lg={12}
        xl={6}
        rowSpacing={3}
        marginBottom="auto"
      >
        <Grid item xs={12}>
          <RentalConditionsCard {...context} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={5}
        lg={12}
        xl={6}
        rowSpacing={3}
        marginBottom="auto"
      >
        <Grid item xs={12}>
          <ResidentsCard
            dossierId={context.dossierId}
            dossierData={context.dossierData}
            fetchRentalDossier={context.fetchRentalDossier}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DossierRentalConditionsTab;
