import React, { useContext, useState } from 'react';
import { FilesUploadProvider } from 'contexts/fileUpload/filesUploadContext';
import {
  PersonFormContext,
  PersonFormProvider,
} from 'contexts/personForm/personFormContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import StepWizard from 'templates/StepWizard';
import { useGeneralStep } from './general/useGeneralStep';
import { useRelationsAndDocumentsStep } from './relationsAndDocuments/useRelationsAndDocumentsStep';
import { LegalPersonFormProps } from './types';

const LegalPersonFormStepper = ({
  handleCancel,
  handleSave,
  type = 'page',
}: LegalPersonFormProps) => {
  const [legalPersonId, setLegalPersonId] = useState<string | null>(null);
  const navigate = useCustomNavigate();

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      // probably here should be added confirmation modal, if user wants to cancel current progress, if form is not saved
      navigate(-1);
    }
  };

  const props = { legalPersonId, onCancel, handleSave };
  const generalStep = useGeneralStep({ ...props, setLegalPersonId });
  const relationsAndDocumentsStep = useRelationsAndDocumentsStep(props);

  const { submitting, errors } = useContext(PersonFormContext);

  return (
    <StepWizard
      steps={[generalStep, relationsAndDocumentsStep]}
      translationKey="legalPerson"
      disabledLabels={!legalPersonId || !generalStep.isValid || submitting}
      errors={errors}
      minWidth={{ lg: 1024 }}
      hideTitle
      withDialogComponents={type === 'modal'}
    />
  );
};

export const LegalPersonForm = (props: LegalPersonFormProps) => (
  <FilesUploadProvider scope="legalPerson">
    <PersonFormProvider>
      <LegalPersonFormStepper {...props} />
    </PersonFormProvider>
  </FilesUploadProvider>
);
