import { bivSchema } from 'formData/common/schema';
import { TKey } from 'i18next';
import yup from 'utils/yup';

const userSchema = yup.object({
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  name: yup.string().required(),
  biv: bivSchema,
  language: yup.string<TKey<'enums', 'language'>>().required(),
  role: yup.string<TKey<'enums', 'userRoles'>>().required(),
});

export default userSchema;
