import addressToString from 'utils/addressToString';
import getLng from 'utils/getLng';
import { AddressInterface } from 'api/common/types';

export const formatEstateAddress = (address: AddressInterface) => ({
  title: addressToString(address, { isFullAddress: false }),
  subtitle: `${address.country?.[getLng()]}, ${address.postCode} ${
    address.city?.[getLng()]
  }`.trim(),
});
