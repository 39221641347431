import { useCallback, useMemo, useState } from 'react';
import { SearchOptionsParams } from 'hooks/useSearchInput/types';
import { RelationDetails } from 'modules/DetailsCards/RelationDetails';
import AddLegalPersonModal from 'modules/ModalForm/AddLegalPersonModal';
import AddNaturalPersonModal from 'modules/ModalForm/AddNaturalPersonModal';
import RelationCard from 'modules/Relation/RelationCard';
import { fetchPersonRelationOptions } from 'modules/Relation/utils';
import {
  getLegalPersonRepresentatives,
  getNaturalPersonRepresentatives,
} from 'api/relations/requests';
import { MAX_RELATIONS } from './static-data';
import { RepresentationCardProps, RepresentationData } from './types';
import { addNewRepresentations, getRepresentationsList } from './utils';

export const RepresentationCard = <T extends boolean = true>({
  personId,
  personType,
  personName,
  editable,
  shouldFetchOnMount = true,
  fetchRepresentations,
  representations,
  loadingRepresentations,
}: RepresentationCardProps<T>) => {
  const [list, setList] = useState<RepresentationData[]>([]);
  const [loading, setLoading] = useState(shouldFetchOnMount);

  const getRepresentations = useCallback(async () => {
    setLoading(true);
    if (fetchRepresentations) {
      fetchRepresentations();
    } else {
      const { ok, response } =
        personType === 'NaturalPerson'
          ? await getNaturalPersonRepresentatives(personId)
          : await getLegalPersonRepresentatives(personId);
      if (ok) {
        const list = await getRepresentationsList(response);
        setList(list);
      }
    }
    setLoading(false);
  }, [personId]);

  const fetchOptions = (params: SearchOptionsParams) =>
    fetchPersonRelationOptions({
      ...params,
      type: personType === 'NaturalPerson' ? 'LegalPerson' : 'NaturalPerson',
    });

  const relations = useMemo(() => representations ?? list, [representations, list]);

  return (
    <RelationCard
      translationKey={personType === 'NaturalPerson' ? 'representee' : 'representative'}
      saveRelation={addNewRepresentations(personId, personType, personName)}
      fetchRelations={getRepresentations}
      shouldFetchOnMount={shouldFetchOnMount}
      fetchOptions={fetchOptions}
      maxRelations={MAX_RELATIONS}
      relations={relations.map(({ personId }) => personId)}
      AddNewOptionModal={
        personType === 'NaturalPerson' ? AddLegalPersonModal : AddNaturalPersonModal
      }
      editable={editable}
      loading={loading || loadingRepresentations}
      showListIcons
    >
      <RelationDetails
        relations={relations}
        updateList={getRepresentations}
        personType={personType}
      />
    </RelationCard>
  );
};
