import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import IconTooltip from 'atoms/IconTooltip';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { TKey } from 'i18next';
import { DeleteIcon } from 'icons';
import RelationDetails from './RelationDetails';
import { RelationData } from './types';

interface Props<T> {
  deleteUrl?: string;
  onDeleteSuccess?: () => void;
  relation: T;
  isPreview?: boolean;
  type?: 'ownership' | 'relation';
  disableDelete?: boolean;
  headers?: [string, string];
  removable?: boolean;
  relationType?: 'private' | 'representee' | 'representative';
  reason?: TKey<'common', 'tooltips'>;
  showIcon?: boolean;
}

const RelationItem = <T extends RelationData>({
  deleteUrl,
  onDeleteSuccess,
  relation,
  isPreview,
  disableDelete,
  type = 'relation',
  removable = true,
  relationType = 'private',
  showIcon = true,
  reason,
}: Props<T>) => {
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);
  const { t } = useTranslation('common', { keyPrefix: 'tooltips' });
  return (
    <Box sx={({ mixins }) => mixins.cardItem}>
      <RelationDetails relation={relation} isPreview={isPreview} showIcon={showIcon} />
      {removable && (
        <IconTooltip title={disableDelete && reason ? t(reason) : ''}>
          <IconButton
            onClick={() =>
              setUpAndOpenConfirmationModal({
                url: deleteUrl || '',
                translationKey: type,
                onSuccess: onDeleteSuccess,
                translationValues: { personName: relation.name, relationType },
              })
            }
            sx={{ visibility: deleteUrl ? 'visible' : 'hidden' }}
            disabled={disableDelete}
            size="small"
            disableRipple
          >
            <DeleteIcon />
          </IconButton>
        </IconTooltip>
      )}
    </Box>
  );
};

export default RelationItem;
