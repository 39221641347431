import { Language } from 'types/language';
import getLng from 'utils/getLng';
import isTruthyValueInObject from 'utils/object/isTruthyValueInObject';
import { testStringToJSON } from 'utils/string';
import {
  AddressFormInterface,
  AddressInterface,
  TranslationFieldInterface,
} from 'api/common/types';
import addressSchema from './schema';
import { BelgiumCountry, BrusselCity } from './static-data';

const getStreetTranslation = (street: TranslationFieldInterface, lng: Language) => {
  if ((lng === 'en' || lng === 'nl') && street['fr'] !== street[lng]) {
    return street['fr'];
  }
  if (lng === 'fr' && street['en'] !== street[lng]) {
    return street['en'];
  }
  return '';
};

export const formatCity = (city: string): TranslationFieldInterface =>
  testStringToJSON(city) ? JSON.parse(city) : { en: city, fr: city, nl: city };

const formatStreet = (city: string, street: string, streetTranslation?: string) => {
  const lng = getLng();
  if (BrusselCity[lng] !== formatCity(city)[lng]) {
    return { en: street, fr: street, nl: street };
  }

  const translatedStreet = streetTranslation || street;
  const isEnOrNl = lng === 'nl' || lng === 'en';
  return {
    en: isEnOrNl ? street : translatedStreet,
    fr: isEnOrNl ? translatedStreet : street,
    nl: isEnOrNl ? street : translatedStreet,
  };
};

export const getFormAddress = ({
  street,
  city,
  country,
  ...address
}: AddressInterface): AddressFormInterface => {
  const lng = getLng();

  return {
    ...addressSchema.getDefault(),
    ...address,
    street: street[lng],
    city: country?.[lng] === BelgiumCountry.name[lng] ? JSON.stringify(city) : city[lng],
    country: isTruthyValueInObject(country)
      ? JSON.stringify(country)
      : addressSchema.getDefault().country,
    streetTranslation: getStreetTranslation(street, lng),
  };
};

export const transformFormAddress = ({
  street,
  city,
  country,
  streetTranslation,
  ...address
}: AddressFormInterface): AddressInterface => ({
  ...address,
  street: formatStreet(city, street, streetTranslation),
  city: formatCity(city),
  country: country && JSON.parse(country),
});

export const getAddressDisplayValues = (
  { street, city, country, ...address }: AddressInterface,
  lng: Language,
): AddressFormInterface => ({
  ...address,
  street: street[lng],
  city: city[lng],
  country: country[lng],
});
