import { SvgIconProps } from '@mui/material';
import {
  CalendarIcon,
  CourtIcon,
  DepositIcon,
  DocumentIcon,
  FileIcon,
  FireIcon,
  MoveIcon,
  TranslationsIcon,
} from 'icons';
import { ProductName } from 'api/dossier/products/types';

interface Props extends SvgIconProps {
  name: ProductName;
}

const ProductIcon = ({ name, ...props }: Props) => {
  switch (name) {
    case 'LegalAssistance':
      return <CourtIcon {...props} />;
    case 'MyMove':
      return <MoveIcon {...props} />;
    case 'FireInsurance':
      return <FireIcon {...props} />;
    case 'StateReport':
      return <CalendarIcon {...props} />;
    case 'IntermediateStateReport':
      return <FileIcon {...props} />;
    case 'RentalDeposit':
      return <DepositIcon {...props} />;
    case 'EndStateReport':
      return <TranslationsIcon {...props} />;
    case 'RentalContract':
      return <DocumentIcon {...props} />;
    default:
      return null;
  }
};

export default ProductIcon;
