import { Modify } from 'types/modify';
import { transformAddress } from 'utils/transformAddressResponse';
import { AddressInterface, FetchedAddress } from 'api/common/types';

export const transformPersonAddress = <T extends object & { address: FetchedAddress }>(
  person: T,
): Modify<T, { address: AddressInterface }> => ({
  ...person,
  address: transformAddress(person.address),
});
