import { useCallback, useEffect, useState } from 'react';
import { estateEpcFields } from 'formData/estate/details/fields';
import useFormMethods from 'hooks/useFormMethods';
import { HandleCheckboxChange } from 'types/handleChange';
import { getEstateEpcTypes } from 'api/config/requests';
import { EstateDetailsForm } from 'api/estate/types';
import { EstateTypeOption } from './types';
import { getEpcTypesOptions } from './utils';

export const useEpcFields = (postCode: string, prefix?: string) => {
  const [fields, setFields] = useState(estateEpcFields);

  const { setValue, getValues, clearErrors } = useFormMethods<EstateDetailsForm>(prefix);

  const updateCheckbox = () => {
    if (!getValues('hasEpc')) {
      setValue('hasEpc', true);
    }
  };

  const onTypeChange = (_value: string, option?: EstateTypeOption) => {
    setValue('epc.primaryEnergyConsumption', option?.primaryEnergyConsumption ?? null);
    updateCheckbox();
  };

  const fetchEpcOptions = useCallback(async () => {
    if (postCode) {
      const { ok, response } = await getEstateEpcTypes(postCode);
      if (ok) {
        setFields((fields) =>
          fields.map((field) =>
            field.name === 'type'
              ? {
                  ...field,
                  options: getEpcTypesOptions(response),
                  onSelectChange: onTypeChange,
                }
              : field,
          ),
        );

        const currentEpc = getValues('epc');
        const epc = response.find(({ type }) => type === currentEpc?.type);
        setValue('epc.type', epc?.type || null);
        setValue('epc.primaryEnergyConsumption', epc?.primaryEnergyConsumption || null);
        if (!epc && !getValues('epc.referenceEnergyConsumption')) {
          setValue('hasEpc', false);
        }
      }
    }
  }, [postCode]);

  useEffect(() => {
    fetchEpcOptions();
  }, [fetchEpcOptions]);

  const onCheckboxChange: HandleCheckboxChange = (_e, checked) => {
    if (!checked) {
      fields.forEach(({ name }) => {
        clearErrors(`epc.${name}`);
      });
    }
  };

  return { fields, onCheckboxChange, updateCheckbox };
};
