import { useMemo } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import { FilterHandlers } from '../TableFilters/types';
import { TableFilter } from '../types';

const useFilters = (field: string): FilterHandlers => {
  const apiRef = useGridApiContext();

  const initState = useMemo(() => {
    const filterValues: TableFilter[] =
      apiRef.current.state.filter.filterModel.quickFilterValues ?? [];
    const filterField = filterValues.find(({ name }) => name === field);
    return filterField?.value ?? '';
  }, []);

  const onFilterChange = (input: string) => {
    const { state, setQuickFilterValues } = apiRef.current;
    const filters: TableFilter[] = state.filter.filterModel.quickFilterValues ?? [];

    const currentFilter = filters.find((item) => item.name === field);
    if (currentFilter && currentFilter.value !== input) {
      const updatedFilters = filters.map((item) =>
        item.name === field ? { name: field, value: input } : item,
      );
      setQuickFilterValues(updatedFilters);
    } else if (!currentFilter) {
      setQuickFilterValues([...filters, { name: field, value: input }]);
    }
  };

  return { onFilterChange, initState };
};

export default useFilters;
