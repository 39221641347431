import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigContext } from 'contexts/config/ConfigContext';
import {
  DossierFormContext,
  DossierFormProvider,
} from 'contexts/dossierForm/DossierFormContext';
import { dossierFormDefaultValues } from 'formData/dossier/defaultValues';
import useCustomNavigate from 'hooks/useCustomNavigate';
import usePrompt from 'hooks/usePrompt';
import StepWizard from 'templates/StepWizard';
import { StepInterface } from 'templates/StepWizard/types';
import useGeneralStep from './steps/general/useGeneralStep';
import useParametersStep from './steps/parameters/useParametersStep';
import useRentalConditionsStep from './steps/rentalConditions/useRentalConditionsStep';
import { DossierLocationState } from './types';
import {
  fetchDefaultValues,
  fetchOwnedEstates,
  formatRentalDossierChecklist,
} from './utils';

const DossierForm = () => {
  const {
    updateEstatesOptions,
    updateLandlordOptions,
    updateTenantOptions,
    errors,
    dossierMethods,
  } = useContext(DossierFormContext);

  const { rentalDossierChecklist, loadingChecklist } = useContext(ConfigContext);

  const { state } = useLocation();

  const checklist = useMemo(
    () => rentalDossierChecklist.map(formatRentalDossierChecklist),
    [rentalDossierChecklist],
  );

  const fetchData = useCallback(async () => {
    const fetchedValues = await fetchDefaultValues(state);
    updateLandlordOptions(fetchedValues.landlords);
    updateTenantOptions(fetchedValues.tenants);

    const { landlordIds } = (state as DossierLocationState | null) ?? {};
    if (landlordIds?.length) {
      const estates = await fetchOwnedEstates(fetchedValues.landlords[0]);
      dossierMethods.reset({
        ...dossierFormDefaultValues,
        ...fetchedValues,
        estate: estates.length === 1 ? estates[0] : null,
        checklistItems: checklist,
      });
      updateEstatesOptions(estates);
    } else {
      dossierMethods.reset({
        ...dossierFormDefaultValues,
        ...fetchedValues,
        checklistItems: checklist,
      });
      updateEstatesOptions(fetchedValues.estate ? [fetchedValues.estate] : []);
    }
  }, [checklist]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const navigate = useCustomNavigate();
  const onCancel = () => {
    navigate(-1);
  };

  usePrompt({ when: dossierMethods.formState.isDirty });

  const generalStep = useGeneralStep({ onCancel });
  const parametersStep = useParametersStep({ onCancel });
  const rentalConditionsStep = useRentalConditionsStep({ onCancel });

  const steps: StepInterface[] = [generalStep, parametersStep, rentalConditionsStep];

  return (
    <StepWizard
      steps={steps}
      translationKey="dossier"
      disabledLabels={!generalStep.isValid}
      minWidth={{ md: 750 }}
      errors={errors}
      loadingContent={loadingChecklist}
    />
  );
};

const DossierFormWrapper = () => (
  <DossierFormProvider>
    <DossierForm />
  </DossierFormProvider>
);

export default DossierFormWrapper;
