import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { DossierStatus } from 'api/dossier/types';

const getColors = (status: DossierStatus) => {
  switch (status) {
    case 'Draft':
      return {
        bgcolor: 'grey.400',
      };
    case 'Running':
      return {
        bgcolor: 'success.main',
      };
    case 'Cancelled':
      return {
        bgcolor: 'grey.500',
      };

    case 'InTreatment':
      return {
        bgcolor: 'secondary.main',
      };

    case 'Migrated':
      return {
        bgcolor: 'info',
      };
    default:
      return {
        bgcolor: 'grey.300',
      };
  }
};

const DossierStatusChip = ({ status }: { status: DossierStatus }) => {
  const { t } = useTranslation('enums');

  return (
    <Box
      sx={{
        ...getColors(status),
        fontWeight: 400,
        borderRadius: 2,
        px: 1,
        py: 1,
        width: 'fit-content',
        fontSize: 14,
        height: '32px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography color="common.white">
        {t(`dossierStatuses.${status}`, status)}
      </Typography>
    </Box>
  );
};

export default DossierStatusChip;
