import { CountryCode } from 'libphonenumber-js';
import yup from 'utils/yup';
import { isTinValid } from './utils';

const taxResidencesSchema = yup
  .array()
  .of(
    yup.object({
      taxResidenceCountry: yup.string<CountryCode>().default('NL'),
      taxIdentificationNumber: yup.string().default(''),
    }),
  )
  .default([])
  .transform((value) =>
    value.filter(
      (tax: { taxIdentificationNumber?: string }) => tax?.taxIdentificationNumber,
    ),
  )
  .test('tin-validation', (value, { createError, path }) => {
    if (value) {
      for (const [index, tin] of value.entries()) {
        if (
          tin &&
          tin.taxIdentificationNumber &&
          !isTinValid(tin.taxResidenceCountry, tin.taxIdentificationNumber)
        ) {
          return createError({
            message: 'tin.invalid',
            type: 'invalid-tin',
            path: `${path}.${index}`,
          });
        }
      }
    }
    return true;
  });

export default taxResidencesSchema;
