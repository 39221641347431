import { format, parseISO } from 'date-fns';
import { DateFormat } from 'types/date';
import { isDate, isISODateString } from '../index';

/**
 * Formats date when passed value is valid. Otherwise returns null.
 *
 * @param value - value to be formatted
 * @param dateFormat - format of the date, default: dd/MM/yyyy
 */
export const formatDate = (
  value: unknown,
  dateFormat: DateFormat = 'dd/MM/yyyy',
): string | null => {
  if (!value) {
    return null;
  }

  let date = value as Date | null;
  if (isISODateString(date)) {
    date = parseISO(date);
  }

  if (isDate(date)) {
    date = new Date(date);
    const dateWithTimezone = date.getTime() - date.getTimezoneOffset() * 60000;
    return format(dateWithTimezone, dateFormat);
  }

  return null;
};
