import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Stack } from '@mui/material';
import useEpcFields from 'hooks/useEpcFields';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { isTruthyValueInObject } from 'utils/object';
import { EstateDetailsForm } from 'api/estate/types';
import { ControlledInput } from 'components/controlledInputs';
import { EstateOutletContext } from 'pages/Estates/Details/types';

export const EpcEdition = () => {
  const { t } = useTranslation('formFields');
  const { estate } = useOutletContext() as EstateOutletContext;

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<EstateDetailsForm>();

  const { fields, onCheckboxChange, updateCheckbox } = useEpcFields(
    estate.address.postCode,
  );

  const initiallyExpanded = !!(
    getValues('hasEpc') && isTruthyValueInObject(getValues('epc'))
  );

  return (
    <Stack direction="row" spacing={1}>
      <AccordionWithCheckbox
        control={control}
        error={errors.epc as FieldError}
        name="hasEpc"
        label={t('epc.self')}
        onChange={onCheckboxChange}
        initiallyExpanded={initiallyExpanded}
      >
        <Stack spacing={1}>
          {fields.map(({ name, ...field }) => (
            <InputLabelWrapper key={name} label={t(`epc.${name}`)}>
              <ControlledInput
                control={control}
                name={`epc.${name}`}
                error={errors.epc?.[name] as FieldError}
                onInputChange={updateCheckbox}
                dateInputProps={{ onChange: updateCheckbox }}
                hideHelperTextGap
                {...field}
              />
            </InputLabelWrapper>
          ))}
        </Stack>
      </AccordionWithCheckbox>
    </Stack>
  );
};
