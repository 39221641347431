import { useMemo, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { SearchInput } from './SearchInput';
import { GooglePlaceOption, GooglePlacesSelectProps, SelectInputProps } from './types';

const API_KEY = window._env_.REACT_APP_GOOGLE_API_KEY;

export const GooglePlacesSelect = ({
  onChange,
  country,
  isLabel,
}: GooglePlacesSelectProps) => {
  const [input, setInput] = useState('');

  const { t, i18n } = useTranslation('common', { keyPrefix: 'selectInput' });

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: API_KEY ?? '',
    debounce: 500,
    options: {
      componentRestrictions: {
        country,
      },
      fields: ['address_components'],
      types: ['address'],
    },
  });

  const handleChange = (option: GooglePlaceOption | null) => {
    if (option) {
      placesService.getDetails(
        {
          placeId: option.place.place_id,
          language: (i18n.language === 'en' ? 'nl' : i18n.language) ?? 'nl',
        },
        onChange,
      );
    }
  };

  const noOptionsText: SelectInputProps['noOptionsText'] = useMemo(() => {
    if (!input) {
      return 'typeForAddress';
    } else if (country) {
      return 'estateGoogleSelect';
    }
    return 'googleSelect';
  }, [input.length]);

  return API_KEY ? (
    <Grid item xs={12}>
      <SearchInput
        options={placePredictions.map((place) => ({
          value: place.description,
          label: place.description,
          place,
        }))}
        loading={isPlacePredictionsLoading}
        noOptionsText={noOptionsText}
        label={isLabel ? t('label.googleSelect') : undefined}
        placeholder={t(`placeholder.${country ? 'estateGoogleSelect' : 'googleSelect'}`)}
        Icon={<i className="pi pi-map-marker" />}
        onInputChange={(input) => {
          setInput(input);
          getPlacePredictions({
            input,
            language: i18n.language ?? 'en',
          });
        }}
        onChange={handleChange}
      />
    </Grid>
  ) : null;
};
