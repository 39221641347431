import { PropsWithChildren } from 'react';
import { Breakpoint, Grid } from '@mui/material';
import PageCard from 'templates/PageCard';
import { PAGE_MARGIN_BOTTOM } from 'themes/mixins';

export const FullPageCard = ({
  children,
  shrinkHeight = false,
  minWidth,
}: PropsWithChildren<{
  shrinkHeight?: boolean;
  minWidth?: Partial<Record<Breakpoint, number | string>>;
}>) => (
  <Grid
    container
    justifyContent="center"
    sx={{
      height: shrinkHeight
        ? 'unset'
        : ({ mixins }) =>
            `calc(100vh - ${
              (Number(mixins.toolbar.minHeight) || 80) + PAGE_MARGIN_BOTTOM
            }px)`,
    }}
  >
    <Grid
      item
      minWidth={{ xs: '-webkit-fill-available', md: 600, ...minWidth }}
      height="100%"
    >
      <PageCard>{children}</PageCard>
    </Grid>
  </Grid>
);
