import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { userSchema } from 'formData/users';
import useCustomNavigate from 'hooks/useCustomNavigate';
import usePrompt from 'hooks/usePrompt';
import useUserFields from 'hooks/useUserFields';
import routes from 'router/routes';
import { FormComponent, FormContainer } from 'templates/FormTemplates';
import setValidationErrors from 'utils/setValidationErrors';
import { resolver } from 'utils/yup';
import { addUser } from 'api/users/requests';
import { UserFormData } from 'api/users/types';
import { ControlledInput } from 'components/controlledInputs';
import { EmailInformationModal } from 'components/modals/EmailInformationModal';

export const UserForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [userId, setUserId] = useState('');

  const { t } = useTranslation(['common', 'formFields']);
  const navigate = useCustomNavigate();
  const fields = useUserFields();

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors, isDirty },
  } = useForm<UserFormData>({
    defaultValues: userSchema.getDefault(),
    resolver: resolver(userSchema),
  });

  usePrompt({ when: isDirty });

  const onCancel = () => {
    navigate(-1);
  };

  const onSave = async (data: UserFormData) => {
    setSubmitting(true);
    const res = await addUser(data);
    if (res.ok) {
      setUserId(res.response.id);
    } else {
      if (res.validation) {
        setValidationErrors(res.validation, setError);
      } else if (res.status === 422) {
        setError('email', {
          // @ts-ignore react-hook-form does not know, that its message also supports objects
          message: {
            key: 'userAlreadyExist',
            values: { field: 'email' },
          },
        });
      }
    }
    setSubmitting(false);
  };

  const closeModal = () => {
    navigate(routes.user(userId), { withoutPrompt: true });
  };

  return (
    <>
      <FormComponent
        title={t('common:addNew.user')}
        onCancel={onCancel}
        onSubmit={handleSubmit(onSave)}
        submitting={submitting}
      >
        <FormContainer maxWidth={750} columnSpacing={1}>
          {fields.map(({ name, ...field }) => (
            <Grid item xs={12} md={6} key={name}>
              <ControlledInput
                name={name}
                label={t(`formFields:${name}`)}
                control={control}
                error={errors[name]}
                {...field}
              />
            </Grid>
          ))}
        </FormContainer>
      </FormComponent>
      <EmailInformationModal open={!!userId} closeModal={closeModal} />
    </>
  );
};
