import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useEpcFields from 'hooks/useEpcFields';
import { EstateFormInterface } from 'modules/EstateForm/types';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import { isTruthyValueInObject } from 'utils/object';
import { ControlledInput } from 'components/controlledInputs';

const EpcFields = () => {
  const { t } = useTranslation('formFields');

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<EstateFormInterface>();

  const postCode = useWatch({ control, name: 'estate.address.postCode' });

  const { fields, onCheckboxChange, updateCheckbox } = useEpcFields(
    postCode,
    'estateDetails',
  );

  const initiallyExpanded = !!(
    getValues('estateDetails.hasEpc') &&
    isTruthyValueInObject(getValues('estateDetails.epc'))
  );

  return (
    <Grid item xs={12}>
      <AccordionWithCheckbox
        control={control}
        error={errors.estateDetails?.epc as FieldError}
        name="estateDetails.hasEpc"
        label={t('epc.self')}
        onChange={onCheckboxChange}
        initiallyExpanded={initiallyExpanded}
      >
        <Grid item container spacing={2}>
          {fields.map(({ name, ...field }) => (
            <Grid key={name} item xs={6}>
              <ControlledInput
                name={`estateDetails.epc.${name}`}
                label={t(`epc.${name}`)}
                onInputChange={updateCheckbox}
                dateInputProps={{ onChange: updateCheckbox }}
                control={control}
                error={errors?.estateDetails?.epc?.[name] as FieldError}
                {...field}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionWithCheckbox>
    </Grid>
  );
};

export default EpcFields;
