import { useContext, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from '@mui/material';
import { DossierFormContext } from 'contexts/dossierForm/DossierFormContext';
import { dossierTypes } from 'formData/dossier/selectOptions';
import getObjectEntries from 'utils/object/getObjectEntries';
import { ProductName } from 'api/dossier/products/types';
import { ControlledSelect } from 'components/controlledInputs';
import ChecklistItem from './ChecklistItem';
import { GroupedFields } from './types';
import { groupFields, updateFields } from './utils';

const GeneralStep = () => {
  const [groupedFields, setGroupedFields] = useState<GroupedFields>();

  const { t } = useTranslation('formFields');
  const { dossierMethods } = useContext(DossierFormContext);

  const {
    formState: { errors },
    control,
    getValues,
  } = dossierMethods;

  const { fields } = useFieldArray({
    control,
    name: 'checklistItems',
    keyName: 'fieldId',
  });

  useEffect(() => {
    setGroupedFields(groupFields(fields));
  }, [fields]);

  const handleInputChange = (inputName: ProductName) => {
    if (inputName !== 'MyMove' && inputName !== 'RentalContract') {
      const updatedFields = updateFields(fields, inputName, getValues('checklistItems'));
      setGroupedFields(groupFields(updatedFields));
    }
  };

  return (
    <Grid container rowSpacing={3} width="100%" maxWidth={750}>
      <Grid container item columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} md={6}>
          <ControlledSelect
            name="dossierType"
            label={t('dossierType')}
            control={control}
            error={errors.dossierType}
            options={dossierTypes}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container item columnSpacing={2} rowSpacing={1}>
        {getValues('dossierType') && groupedFields
          ? getObjectEntries(groupedFields).map(
              ([groupName, products]) =>
                !!products.length && (
                  <Grid container item key={`group-${groupName}`} xs={12}>
                    <Grid item xs={12}>
                      <Divider>
                        {t(`productGroups.${groupName}`, { ns: 'enums' })}
                      </Divider>
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Box sx={{ columnCount: { md: 2 }, columnGap: 2 }}>
                        {products.map((product) => (
                          <ChecklistItem
                            key={`item-${product.id}`}
                            subProducts={products.filter(
                              ({ parentId }) => parentId === product.id,
                            )}
                            handleInputChange={handleInputChange}
                            {...product}
                          />
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                ),
            )
          : null}
      </Grid>
    </Grid>
  );
};

export default GeneralStep;
