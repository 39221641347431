import { useCallback, useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { format } from 'date-fns';
import { ClearIcon } from 'icons';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { ControlledInput } from 'components/controlledInputs';
import { ProductInputFields } from 'components/modals/ProductActionModal';
import { UploadButton } from 'components/UploadButton/UploadButton';
import { uploadAddendumFields } from './fields';

export const UploadAddendumStep = () => {
  const { t } = useTranslation(['formFields', 'modals', 'documents']);
  const { userData } = useContext(AccountContext);
  const { control, setValue, trigger, formState } =
    useFormContext<RegisterAddendumForm>();

  const setBiv = useCallback(() => {
    setValue(
      'uploadedAddendum.biv',
      userData?.biv || formState.defaultValues?.uploadedAddendum?.biv || null,
    );
  }, [userData]);

  useEffect(() => {
    setBiv();
  }, [setBiv]);

  const handleUploadedFile = (file: File | null) => {
    if (file) {
      setValue('uploadedAddendum.addendumFile', file);
      setValue(
        'uploadedAddendum.documentName',
        `${t('documents:dossier.Addendum')}_${format(new Date(), 'dd-MM-yyyy')}`,
      );
      trigger('uploadedAddendum.addendumFile');
    }
  };

  const removeFile = () => {
    setValue('uploadedAddendum.addendumFile', null);
    setValue('uploadedAddendum.documentName', '');
  };

  const file = useWatch({ name: 'uploadedAddendum.addendumFile' });

  const onInputChange = (name: keyof RegisterAddendumForm['uploadedAddendum']) => () => {
    if (formState.errors.uploadedAddendum?.[name]) {
      trigger(`uploadedAddendum.${name}`);
    }
  };

  return (
    <Box>
      <Stack spacing={1}>
        <ProductInputFields
          fields={uploadAddendumFields}
          namePrefix="uploadedAddendum"
          onInputChange={onInputChange}
        />
        <InputLabelWrapper label={t('addendumDocument')}>
          {file ? (
            <Stack alignItems="start" justifyContent="space-between" spacing={1}>
              <Box display="flex" width="100%">
                <ControlledInput
                  control={control}
                  name="uploadedAddendum.documentName"
                  error={formState.errors.uploadedAddendum?.documentName}
                  hideHelperTextGap
                  onInputChange={onInputChange('documentName')}
                  placeholder={t('documentName')}
                />
                <IconButton onClick={removeFile}>
                  <ClearIcon />
                </IconButton>
              </Box>
            </Stack>
          ) : (
            <UploadButton
              handleUploadedFile={handleUploadedFile}
              isMissing={!!formState.errors.uploadedAddendum?.addendumFile}
            />
          )}
        </InputLabelWrapper>
        {file && (
          <InputLabelWrapper label="">
            <Typography>
              <Trans
                t={t}
                i18nKey="modals:productActionModal.RentalContractRegisterAddendum.addendumFileName"
                values={{ filename: file.name }}
                components={{ bold: <strong /> }}
              />
            </Typography>
          </InputLabelWrapper>
        )}
      </Stack>
    </Box>
  );
};
