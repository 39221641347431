import { useMemo } from 'react';
import DocumentsTable from 'templates/DocumentsTable';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { dossierDocumentsColumns, getDossierDocumentActions } from './static-data';
import { DossierDocumentsTableProps } from './types';

const DossierDocumentsTable = ({
  openDocumentsModal,
  context,
  toolbarProps,
  ...props
}: DossierDocumentsTableProps) => {
  const actionProps = useMemo(
    () => ({
      onClick: () => openDocumentsModal(),
      disabled: props.loading,
      hidden: !shouldDossierFeatureBeActive(context.dossierStatus),
    }),
    [props.loading, context.dossierStatus],
  );

  return (
    <DocumentsTable
      columns={dossierDocumentsColumns}
      getActions={getDossierDocumentActions(openDocumentsModal)}
      toolbarProps={{ actionProps, ...toolbarProps }}
      context={context}
      {...props}
    />
  );
};

export default DossierDocumentsTable;
