import { useCallback } from 'react';
import { Box } from '@mui/material';
import { TranslatedTableColumn } from '../types';
import TableDateFilter from './TableDateFilter';
import TableDateRangeFilter from './TableDateRangeFilter';
import TableSelectFilter from './TableSelectFilter';
import TableTextFilter from './TableTextFilter';

const TableFilter = (column: TranslatedTableColumn) => {
  const Input = useCallback(() => {
    switch (column.filterType) {
      case 'select':
        return <TableSelectFilter {...column} />;
      case 'date':
        return <TableDateFilter {...column} />;
      case 'dateRange':
        return <TableDateRangeFilter {...column} />;
      default:
        return <TableTextFilter {...column} />;
    }
  }, [column.filterType, column.filterLabel]);

  return (
    <Box flex={column.flex} visibility={column.filterable ? 'visible' : 'hidden'}>
      <Input />
    </Box>
  );
};

export default TableFilter;
