import { Navigate, useOutletContext } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { OwnerCard } from 'modules/OwnershipCard';
import routes from 'router/routes';
import { EstateOutletContext } from '../types';

const EstateContactsTab = () => {
  const { estateId } = useOutletContext<EstateOutletContext>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return matches ? (
    <Navigate to={routes.estateTab(estateId).estateInfo} replace />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12} marginBottom="auto">
        <OwnerCard estateId={estateId} />
      </Grid>
    </Grid>
  );
};

export default EstateContactsTab;
