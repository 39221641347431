import { Grid } from '@mui/material';
import PersonRelations from 'modules/PersonForm/PersonRelations';
import { UploadDocumentsWithContext } from 'modules/UploadDocuments';

interface Props {
  legalPersonId: string;
}

const RelationsAndDocumentsStep = ({ legalPersonId }: Props) => (
  <Grid container marginBottom="auto" rowSpacing={4} maxWidth={1024} width={{ lg: 1024 }}>
    <PersonRelations personType="LegalPerson" />
    <Grid item xs={12}>
      <UploadDocumentsWithContext entityId={legalPersonId} scope="legalPerson" />
    </Grid>
  </Grid>
);

export default RelationsAndDocumentsStep;
