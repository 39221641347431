import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { EstateDetailsCard, EstateGeneralCard, EstateMainCard } from 'modules/EstateView';
import { EstateOutletContext } from '../types';

const EstateInfoTab = () => {
  const context = useOutletContext<EstateOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} display={{ lg: 'none' }}>
        <EstateMainCard {...context} />
      </Grid>
      <Grid item xs={12} md={6} lg={12} xl={6} marginBottom="auto">
        <EstateGeneralCard {...context} />
      </Grid>
      <Grid item xs={12} md={6} lg={12} xl={6} marginBottom="auto">
        <EstateDetailsCard
          type="details"
          estateDetails={context.estateDetails}
          fetchEstateDetails={context.fetchEstateDetails}
        />
      </Grid>
    </Grid>
  );
};

export default EstateInfoTab;
