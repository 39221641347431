import { LoaderConfig } from 'hooks/useLoader/types';
import routes from 'router/routes';
import { getLegalPerson } from 'api/person/legal/requests';
import { FetchedLegalPersonData, LegalPersonData } from 'api/person/legal/types';

export const getLegalPersonData = (
  data: FetchedLegalPersonData | null,
): LegalPersonData => {
  const { businessId: _businessId, id: _id, ...initialValues } = data ?? {};
  return initialValues as LegalPersonData;
};

export const getLegalPersonConfig = (
  legalPersonId: string,
): LoaderConfig<FetchedLegalPersonData> => ({
  id: legalPersonId,
  scope: 'legalPerson',
  fetch: getLegalPerson,
  shouldRedirect: (pathname) => pathname === routes.legalPerson(legalPersonId),
  redirectUrl: routes.legalPersonTab(legalPersonId).details,
});
