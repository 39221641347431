import { productOptions } from 'formData/common/selectOptions';
import i18n from 'i18n';
import routes from 'router/routes';
import { BoldCell, PersonsCell } from 'templates/Table/Cells';
import {
  CheckboxesFilter,
  DateRangeFilter,
  EstateFilter,
} from 'templates/Table/TableFilterSidebar/inputs';
import { TableColumn, TableToolbarProps } from 'templates/Table/types';
import { optionsToFilterOptions } from 'templates/Table/utils';
import { formatDate } from 'utils/date';
import { deleteNotifications } from 'api/notifications/requests';
import { ProductNotification } from 'api/notifications/types';

export const getLinkToProduct = (
  _notificationId: string,
  { dossierId, productId }: ProductNotification,
) => routes.rentalDossierProduct(dossierId, productId);

export const notificationsColumns: TableColumn<ProductNotification, 'notifications'>[] = [
  {
    field: 'title',
    flex: 1.5,
    renderCell: BoldCell,
    translateValue: (title, notification) =>
      i18n.t(`notifications:${notification.type}`, {
        notification,
        tenants: notification.tenants.map(({ name }) => name).join(', '),
        landlords: notification.landlords.map(({ name }) => name).join(', '),
        defaultValue: title,
      }),
  },
  {
    field: 'timestamp',
    flex: 1,
    sortable: true,
    valueGetter: (value) => formatDate(value),
  },
  {
    field: 'address',
    flex: 1.5,
    sortable: true,
    valueGetter: (_value, row) => row.estate.address,
  },
  {
    field: 'entryDate',
    flex: 1,
    sortable: true,
    valueGetter: (value) => formatDate(value),
  },
  {
    field: 'landlords',
    flex: 2,
    renderCell: PersonsCell,
  },
  {
    field: 'tenants',
    flex: 2,
    renderCell: PersonsCell,
  },
  {
    field: 'productName',
    flex: 1,
    translateValue: (value) =>
      value ? i18n.t(`dossierProducts:${value}.name`, value) : value,
  },
];

export const notificationsToolbarProps: TableToolbarProps<ProductNotification> = {
  rowSelection: {
    handleSelectedRows: (rows, { setUpAndOpenConfirmationModal, updateRows }) => {
      setUpAndOpenConfirmationModal({
        translationKey: 'notificationsDeleteSelected',
        onSubmit: () => deleteNotifications({ ids: rows.join(',') }),
        onSuccess: updateRows,
      });
    },
  },
  filters: [
    {
      name: 'estate',
      filterName: 'estateIds',
      Component: EstateFilter,
    },
    {
      name: 'entryDate',
      filterName: 'entryDate',
      Component: DateRangeFilter,
    },
    {
      name: 'products',
      filterName: 'productName',
      options: optionsToFilterOptions(productOptions),
      Component: CheckboxesFilter,
    },
  ],
};
