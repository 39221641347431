import { addMonths, subMonths, subYears } from 'date-fns';
import { sortFields } from 'formData/utils';
import { RentalConditions } from 'api/dossier/types';
import { InputField } from 'components/controlledInputs/types';
import { rentalConditionsOrder } from './order';
import {
  currencyOptions,
  dossierDurations,
  numberOfMonthlyRentOptions,
  rentFrequencyOptions,
  rentTypes,
} from './selectOptions';

export type RentalConditionsFields = Partial<Record<keyof RentalConditions, boolean>>;
export type HandleRentalConditionFieldChange = (
  field: keyof RentalConditions,
  input?: string | boolean | Date | null,
) => void;

export const getRentalConditionsFields = (
  onChange: HandleRentalConditionFieldChange,
  hiddenFields: RentalConditionsFields,
  disabledFields: RentalConditionsFields,
  missingFieldsModal?: boolean,
): InputField<RentalConditions>[] =>
  sortFields(
    [
      {
        name: 'rentType',
        options: rentTypes,
        onSelectChange: (value) => onChange('rentType', value),
      },
      {
        name: 'contractSigningDate',
        type: 'date',
        dateInputProps: {
          onChange: () => onChange('contractSigningDate'),
        },
      },
      {
        name: 'entryDate',
        type: 'date',
        dateInputProps: {
          minDate: missingFieldsModal
            ? subMonths(new Date(), 6)
            : subYears(new Date(), 20),
          maxDate: addMonths(new Date(), 6),
          onChange: (value) => onChange('entryDate', value),
        },
      },
      {
        name: 'duration',
        options: dossierDurations,
        onSelectChange: (value) => onChange('duration', value),
      },
      {
        name: 'durationNumberOfMonths',
        type: 'number',
        isInteger: true,
        onBlur: (e) => onChange('durationNumberOfMonths', e.target.value),
        hidden: hiddenFields.durationNumberOfMonths,
      },
      {
        name: 'rentFrequency',
        options: rentFrequencyOptions,
        onSelectChange: (value) => onChange('rentFrequency', value),
      },
      {
        name: 'endDate',
        type: 'date',
        dateInputProps: {
          onChange: (value) => onChange('endDate', value),
        },
        disabled: disabledFields.endDate,
      },

      {
        name: 'rentalPrice',
        options: currencyOptions,
        type: 'payment',
        onBlur: (e) => onChange('rentalPrice', e.target.value),
      },

      {
        name: 'charges',
        options: currencyOptions,
        type: 'payment',
      },

      {
        name: 'numberOfMonthlyRent',
        options: numberOfMonthlyRentOptions,
        onSelectChange: (value) => onChange('numberOfMonthlyRent', value),
      },
      {
        name: 'depositAmount',
        options: currencyOptions,
        onBlur: (e) => onChange('depositAmount', e.target.value),
        type: 'payment',
      },
      {
        name: 'isTaxPayableByTenant',
        type: 'checkbox',
        onCheckboxChange: (checked) => onChange('isTaxPayableByTenant', checked),
        hidden: hiddenFields.isTaxPayableByTenant,
      },
      {
        name: 'taxPayableByTenant',
        options: [...currencyOptions, { value: 'Percent', label: '%' }],
        type: 'payment',
        hidden: hiddenFields.taxPayableByTenant,
      },
      {
        name: 'isVatRegimeApplicable',
        type: 'checkbox',
        onCheckboxChange: (checked) => onChange('isVatRegimeApplicable', checked),
        hidden: hiddenFields.isVatRegimeApplicable,
        sizes: { md: 12 },
      },
      {
        name: 'isAdditionalVatRegimeApplicable',
        type: 'checkbox',
        hidden: hiddenFields.isAdditionalVatRegimeApplicable,
        sizes: { md: 12 },
      },
    ],
    rentalConditionsOrder,
  );
