import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductContext } from 'contexts/product/productContext';
import { ProductActionName } from 'api/dossier/products/types';

export const useProductStage = (name: ProductActionName) => {
  const { t } = useTranslation('dossierProducts');
  const { productName } = useContext(ProductContext);

  const title = useMemo(() => t(`${productName}.actions.${name}`, name), [name]);

  const isStageAwaitingForExternalAction = useMemo(
    () => name === 'WaitForPayment' || name === 'WaitForConfirmation',
    [name],
  );

  return { title, isStageAwaitingForExternalAction };
};
