import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { personTypeOptions } from 'formData/common/selectOptions';
import { LegalPersonForm, NaturalPersonForm } from 'modules/PersonForm';
import FullPageCard from 'templates/FullPageCard';
import { PersonType } from 'api/person/types';
import { Select } from 'components/inputs';

export const AddNewPerson = () => {
  const { state } = useLocation();

  const [selectedPersonType, setSelectedPersonType] = useState<PersonType>(
    state?.personType || 'NaturalPerson',
  );

  const handleChange = (value: string) => {
    setSelectedPersonType(value as PersonType);
  };

  return (
    <FullPageCard minWidth={{ md: '-webkit-fill-available', lg: 1024 }}>
      <Box p={2.5} pb={0} maxWidth="50%">
        <Select
          value={selectedPersonType}
          options={personTypeOptions}
          onChange={handleChange}
          name="personTypeSelector"
        />
      </Box>
      {selectedPersonType === 'NaturalPerson' ? (
        <NaturalPersonForm />
      ) : (
        <LegalPersonForm />
      )}
    </FullPageCard>
  );
};
