import { useMemo, useState } from 'react';
import useUploadPhoto from 'hooks/useUploadPhoto';
import { deleteEstatePhoto } from 'api/estate/requests';
import { EstateData } from 'api/estate/types';

interface Props {
  estateId: string;
  photoUrl: EstateData['photo'];
  onSaveSuccess?: () => Promise<void> | void;
}

const useEstatePhoto = ({ estateId, photoUrl, onSaveSuccess }: Props) => {
  const [submitting, setSubmitting] = useState(false);

  const uploadPhotoProps = useUploadPhoto(photoUrl);
  const { uploadedFile, fileDataUrl, uploadPhoto } = uploadPhotoProps;

  const savePhoto = async () => {
    setSubmitting(true);

    let isRequestOk = true;
    if (photoUrl && !uploadedFile) {
      const { ok } = await deleteEstatePhoto(estateId);
      isRequestOk = ok;
    } else if (uploadedFile?.status !== 'SAVED') {
      isRequestOk = await uploadPhoto(estateId);
    }

    if (isRequestOk && onSaveSuccess) {
      await onSaveSuccess();
    }
    setSubmitting(false);
    return isRequestOk;
  };

  const isPhotoChanged = useMemo(() => photoUrl !== fileDataUrl, [photoUrl, fileDataUrl]);

  return { savePhoto, submitting, isPhotoChanged, ...uploadPhotoProps };
};

export default useEstatePhoto;
