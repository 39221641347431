import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Divider, Paper } from '@mui/material';
import RedirectButton from 'atoms/RedirectButton';
import { getTabButtonStyles, getTabContainerStyles } from './styles';
import { RouteTabsProps, SubTabsTranslationKey, TabsTranslationKey } from './types';
import { getRoutesToDisplay } from './utils';

export const RouteTabs = <T extends TabsTranslationKey, S extends SubTabsTranslationKey>(
  props: RouteTabsProps<T, S>,
) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const routesToDisplay = useMemo(
    () => getRoutesToDisplay(props, pathname),
    [props.routes, props.subRoutes, pathname],
  );

  return (
    <Paper variant="outlined" sx={getTabContainerStyles(routesToDisplay.length)}>
      {routesToDisplay.map(({ tKey, label, to, subRoutes, isRouteActive }) => (
        <Fragment key={label}>
          <Box className="tabs-container">
            <RedirectButton
              to={to}
              role={isRouteActive ? 'primary' : 'text'}
              sx={getTabButtonStyles(isRouteActive)}
            >
              {t(`tabs.${tKey}.${label}`, label)}
            </RedirectButton>
          </Box>
          {isRouteActive && !!subRoutes.length && (
            <>
              <Divider className="tabs-divider" />
              <Box className="subTabs-container">
                {subRoutes.map(({ tKey, label, to, isSubRouteActive }) => (
                  <RedirectButton
                    size="small"
                    key={label}
                    to={to}
                    role={isSubRouteActive ? 'secondary' : 'text'}
                    sx={getTabButtonStyles(isSubRouteActive, 'small')}
                  >
                    {t(`subTabs.${tKey}.${label}`, label)}
                  </RedirectButton>
                ))}
              </Box>
            </>
          )}
        </Fragment>
      ))}
    </Paper>
  );
};
