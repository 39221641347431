import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from 'atoms/Button';
import { TKey } from 'i18next';
import ModalTitle from './ModalTitle';
import ModalWrapper from './ModalWrapper';

export interface ConfirmationModalProps {
  textContentKey: TKey<'modals', 'confirmationModal', false>;
  onClose: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  action: 'submitting' | 'cancelling' | null;
  translationValues?: object;
  type?: 'deletion' | 'confirmation';
}

export const ConfirmationModal = ({
  textContentKey,
  onClose,
  onCancel,
  onSubmit,
  action,
  translationValues,
  type = 'deletion',
}: ConfirmationModalProps) => {
  const { t } = useTranslation(['modals', 'common']);

  return (
    <ModalWrapper open onClose={onClose}>
      <ModalTitle
        title={t(`confirmationModal.${textContentKey}.title`, {
          ...translationValues,
          defaultValue: textContentKey,
        })}
        disableClosing={!!action}
        closeModal={onClose}
      />
      <DialogContent>
        <DialogContentText>
          {t(`confirmationModal.${textContentKey}.content`, {
            ...translationValues,
            interpolation: { escapeValue: false },
            defaultValue: textContentKey,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button role="text" onClick={onCancel} disabled={!!action}>
          {t(`confirmationModal.${textContentKey}.cancelButton`, t('common:cancel'))}
        </Button>
        <Button
          onClick={onSubmit}
          disabled={!!action}
          loading={action === 'submitting'}
          role={type === 'deletion' ? 'unsafe' : 'cta'}
        >
          {t(
            `confirmationModal.${textContentKey}.submitButton`,
            t(type === 'deletion' ? 'common:delete' : 'modals:submitButton.confirm'),
          )}
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};
