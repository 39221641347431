import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import { Estate } from 'modules/DetailsCards/components/Estate';
import PageCard from 'templates/PageCard';
import { DossierCardProps } from '../types';

const DossierEstateCard = ({
  dossierData: {
    estate: { photo, type, estateId, address, city, postCode },
  },
}: Pick<DossierCardProps, 'dossierData'>) => {
  const { t } = useTranslation('enums');

  const titleProps = useMemo(
    () => ({
      startIcon: photo && <Avatar src={photo} sx={({ mixins }) => mixins.estatePhoto} />,
    }),
    [photo],
  );

  return (
    <PageCard
      tKey="estate"
      headerProps={{
        titleProps,
      }}
    >
      <Estate
        estate={{
          address,
          city,
          postCode,
          type: type ? t(`estateType.${type}`, type) : '',
        }}
        estateId={estateId}
      />
    </PageCard>
  );
};

export default DossierEstateCard;
