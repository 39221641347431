import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import AssignedDossiersGrid from 'modules/AssignedDossiersGrid';
import { LegalPersonOutletContext } from '../types';

const LegalPersonDossiersTab = () => {
  const { legalPersonId } = useOutletContext<LegalPersonOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AssignedDossiersGrid entityId={legalPersonId} type="Person" />
      </Grid>
    </Grid>
  );
};

export default LegalPersonDossiersTab;
