import { languageOptions } from 'formData/common/selectOptions';
import { sortFields } from 'formData/utils';
import { UserFormData } from 'api/users/types';
import { InputField } from 'components/controlledInputs/types';
import { SelectOption } from 'components/inputs/types';
import { userFieldsOrder } from './order';

export const getUserFields = (roleOptions: SelectOption[]): InputField<UserFormData>[] =>
  sortFields(
    [
      {
        name: 'email',
        type: 'text',
      },
      {
        name: 'firstName',
        type: 'text',
      },
      {
        name: 'name',
        type: 'text',
      },
      {
        name: 'biv',
        type: 'text',
      },
      {
        name: 'language',
        options: languageOptions,
      },
      {
        name: 'role',
        options: roleOptions,
      },
    ],
    userFieldsOrder,
  );
