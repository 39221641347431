import { PropsWithChildren, ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';

export const InputLabelWrapper = ({
  label,
  labelFlex = 1,
  inputFlex = 1,
  children,
}: PropsWithChildren<{ label: ReactNode; labelFlex?: number; inputFlex?: number }>) => (
  <Stack direction="row" spacing={2} alignItems="center">
    <Box flex={labelFlex}>
      <Typography>{label}</Typography>
    </Box>
    <Box flex={inputFlex}>{children}</Box>
  </Stack>
);
