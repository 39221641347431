import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { CountryCode } from 'libphonenumber-js';
import { getTaxConformedValue } from 'utils/getConformedValue/getTaxConformedValue';
import { getErrorMessage } from 'components/controlledInputs/utils';
import { TaxResidenceInput } from 'components/inputs';
import { ControlledTaxResidenceInputProps } from './types';

export const ControlledTaxResidenceInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledTaxResidenceInputProps<T, N>,
) => React.ReactElement = ({ name, error, warning, helperText, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, value, onChange, ...field } }) => {
      return (
        <TaxResidenceInput
          inputRef={ref}
          error={!!error}
          warning={!!warning}
          taxIdentificationNumber={value?.taxIdentificationNumber}
          taxResidenceCountry={value?.taxResidenceCountry}
          onNumberChange={(taxIdentificationNumber) =>
            onChange({ ...value, taxIdentificationNumber })
          }
          onCountryChange={(taxResidenceCountry) =>
            onChange({
              taxResidenceCountry,
              taxIdentificationNumber: getTaxConformedValue(
                value?.taxIdentificationNumber,
                taxResidenceCountry as CountryCode,
              ),
            })
          }
          helperText={getErrorMessage(error ?? warning) || helperText}
          {...field}
          {...props}
        />
      );
    }}
  />
);
