import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { EstateFormInterface } from 'modules/EstateForm/types';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import { HandleCheckboxChange } from 'types/handleChange';
import { ControlledDateInput } from 'components/controlledInputs';

const ConformityField = () => {
  const { t } = useTranslation('formFields');
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<EstateFormInterface>();

  const onChange: HandleCheckboxChange = (_e, checked) => {
    if (!checked) {
      setValue('estateDetails.conformity', null);
    }
  };

  const updateCheckbox = () => {
    if (!getValues('estateDetails.hasConformity')) {
      setValue('estateDetails.hasConformity', true);
    }
  };

  const initiallyExpanded = !!(
    getValues('estateDetails.hasConformity') && getValues('estateDetails.conformity')
  );

  return (
    <Grid item xs={12}>
      <AccordionWithCheckbox
        control={control}
        error={errors.estateDetails?.conformity}
        name="estateDetails.hasConformity"
        label={t('conformity')}
        onChange={onChange}
        initiallyExpanded={initiallyExpanded}
      >
        <ControlledDateInput
          name="estateDetails.conformity"
          label={t('date')}
          control={control}
          onChange={updateCheckbox}
          error={errors?.estateDetails?.conformity}
          helperText={t('helperText.conformity', { ns: 'common' })}
          disableFuture
        />
      </AccordionWithCheckbox>
    </Grid>
  );
};

export default ConformityField;
