import getObjectEntries from '../getObjectEntries';
import isKeyInObject from '../isKeyInObject';
import sortByOrder from '../sortByOrder';

export const objectToSortedArray = <T extends object>(
  object: T,
  order: Record<keyof T, number>,
) =>
  getObjectEntries(object)
    .map(([key, value]) => ({
      key,
      value,
      order: isKeyInObject(key, order) ? order[key] : 0,
    }))
    .sort(sortByOrder);
