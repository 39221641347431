import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@mui/material';
import Button from 'atoms/Button';
import { UploadPhotoProps } from 'components/UploadPhoto/types';
import { UploadPhoto } from 'components/UploadPhoto/UploadPhoto';
import ModalTitle from './ModalTitle';
import ModalWrapper from './ModalWrapper';

interface Props extends UploadPhotoProps {
  open: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  isSubmitDisabled?: boolean;
}

export const PhotoModal = ({
  open,
  isSubmitDisabled,
  closeModal,
  onSubmit,
  ...uploadPhotoProps
}: Props) => {
  const { t } = useTranslation(['modals', 'common']);

  return (
    <ModalWrapper open={open} onClose={closeModal} fullWidth>
      <ModalTitle title={t('title.uploadPhoto')} closeModal={closeModal} />
      <DialogContent sx={{ pb: 0 }}>
        <UploadPhoto {...uploadPhotoProps} />
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button role="text" onClick={closeModal}>
          {t('common:close')}
        </Button>
        <Button
          disableElevation
          disabled={isSubmitDisabled}
          onClick={onSubmit}
          loading={uploadPhotoProps.submitting}
        >
          {t('common:save')}
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};
