import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { errorPageStyles } from './styles';

const ErrorPage = () => {
  const { t } = useTranslation('errors');

  return (
    <Stack sx={errorPageStyles} alignItems="center">
      <Trans
        t={t}
        i18nKey="errorPage"
        defaults="<Alert>Oops!</Alert><Info>Something went wrong</Info><Instruction>Please, try again later</Instruction>"
        components={{
          Alert: <Typography sx={{ fontSize: '5rem', color: 'secondary.main' }} />,
          Info: <Typography sx={{ fontSize: '3rem' }} />,
          Instruction: <Typography sx={{ fontSize: '2rem' }} />,
        }}
      />
    </Stack>
  );
};

export default ErrorPage;
