import { ChangeEventHandler, useMemo, useState } from 'react';
import { translationSchema } from 'formData/common/schema';
import { estateDetailsSchema } from 'formData/estate';
import usePrompt from 'hooks/usePrompt';
import routes, { estateTabRoutes } from 'router/routes';
import { Language } from 'types/language';
import { updateEstateDetails } from 'api/estate/requests';
import { EstateCardProps } from '../types';
import useEstatePhoto from './useEstatePhoto';

const pathnamesToExclude = Object.values(estateTabRoutes).map(
  (route) => `${routes.estate(':estateId')}/${route}`,
);

const useEstateEdition = ({
  estateId,
  estate,
  estateDetails,
  fetchEstate,
  fetchEstateDetails,
}: EstateCardProps) => {
  const currentDescriptions = useMemo(
    () => estateDetails?.descriptions || translationSchema.getDefault(),
    [estateDetails?.descriptions],
  );

  const [saving, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [descriptions, setDescriptions] = useState(currentDescriptions);

  const { isPhotoChanged, savePhoto, fetchSavedPhoto, ...uploadPhotoProps } =
    useEstatePhoto({
      estateId,
      photoUrl: estate.photo,
    });

  const isDescriptionChanged = useMemo(
    () => JSON.stringify(descriptions) !== JSON.stringify(currentDescriptions),
    [descriptions, currentDescriptions],
  );

  const onEdit = async () => {
    await fetchSavedPhoto();
    setIsEditing(true);
    setDescriptions(currentDescriptions);
  };

  const onCancel = () => {
    setIsEditing(false);
    setDescriptions(currentDescriptions);
    uploadPhotoProps.clearFile();
  };

  const onSave = async () => {
    setSaving(true);
    let isRequestOk = false;

    if (isDescriptionChanged) {
      const details = estateDetails || estateDetailsSchema.getDefault();
      const { ok } = await updateEstateDetails(estateId, { ...details, descriptions });
      isRequestOk = ok;
      if (ok) {
        await fetchEstateDetails();
      }
    }

    if (isPhotoChanged) {
      const isPhotoSaved = await savePhoto();
      isRequestOk = isPhotoSaved;
      if (isPhotoSaved) {
        await fetchEstate();
      }
    }

    if (isRequestOk) {
      setIsEditing(false);
    }
    setSaving(false);
  };

  const onDescriptionChange =
    (lng: Language): ChangeEventHandler<HTMLTextAreaElement> =>
    ({ target }) => {
      setDescriptions((prev) => ({ ...prev, [lng]: target.value }));
    };

  const isDirty = useMemo(
    () => isDescriptionChanged || isPhotoChanged,
    [isDescriptionChanged, isPhotoChanged],
  );

  usePrompt({ when: isEditing && isDirty, id: 'estateDescription', pathnamesToExclude });

  return {
    uploadPhotoProps: {
      ...uploadPhotoProps,
      submitting: saving,
    },
    descriptions,
    onDescriptionChange,
    editionProps: {
      saveDisabled: !isDirty,
      isEditing,
      saving,
      onSave,
      onEdit,
      onCancel,
    },
  };
};

export default useEstateEdition;
