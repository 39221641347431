import { Trans, useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { PhoneIcon } from 'icons';
import { getAgentContactBoxStyles } from './styles';

interface Props {
  isSidebarExpanded: boolean;
  onClick: () => void;
}

const AgentContactBox = ({ isSidebarExpanded, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Box onClick={onClick} sx={getAgentContactBoxStyles(isSidebarExpanded)}>
      <PhoneIcon fontSize="medium" sx={({ mixins }) => mixins.sidebarIcon} />
      <Stack flex={1}>
        <Trans
          t={t}
          i18nKey="aside.agentNumber"
          defaults="<Phone>+32 (0)56 43 11 55</Phone><Text>Your real estate agent line</Text>"
          components={{
            Phone: <Typography fontWeight="bold" />,
            Text: <Typography variant="caption" sx={{ textWrap: 'wrap' }} />,
          }}
        />
      </Stack>
    </Box>
  );
};

export default AgentContactBox;
