import { PartyPerson } from 'modules/ProductParties/types';
import { fetchPrivateRelation, fetchRepresentatives } from 'modules/ProductParties/utils';
import { ProductParty } from 'modules/ProductParties/V2/types';
import { RentalDepositChosenParties } from 'api/dossier/products/rentalDeposit/types';
import { PersonInDossier } from 'api/dossier/types';
import { addPrivateRelation, addRepresentation } from 'api/relations/requests';
import { RentalDepositChoosePartiesForm } from './types';

export const transformRentalDepositChoosePartiesData = ({
  tenants,
  landlords,
  multipleTenants,
}: RentalDepositChoosePartiesForm): RentalDepositChosenParties => {
  const [firstTenant, secondTenant] = tenants;
  const [firstLandlord, secondLandlord] = landlords;

  return {
    multipleTenants,
    tenantId: firstTenant.personId,
    tenantRepresentativeId: firstTenant.representativeId || undefined,
    tenantPartnerId: secondTenant?.personId || undefined,
    tenantsRelation: secondTenant?.personId ? secondTenant.relation : undefined,
    landlordId: firstLandlord.personId,
    landlordRepresentativeId: firstLandlord.representativeId || undefined,
    landlordPartnerId: secondLandlord?.personId || undefined,
    landlordsRelation: secondLandlord?.personId ? secondLandlord.relation : undefined,
  };
};

export const savePrivateRelations = async ([
  firstPerson,
  secondPerson,
]: RentalDepositChoosePartiesForm['landlords' | 'tenants']) => {
  // Return when any person is already in relation
  if (firstPerson.partnerId || secondPerson.partnerId) {
    return;
  }
  // input value for relation
  const relationType = secondPerson.relation;

  if (relationType && relationType !== 'Other') {
    await addPrivateRelation({
      side1: firstPerson.personId,
      side2: secondPerson.personId,
      type: relationType,
    });
  }
};

export const saveRepresentatives = async (
  persons: RentalDepositChoosePartiesForm['landlords' | 'tenants'],
) => {
  for (const { representativeId, representatives = [], personId, type } of persons) {
    if (
      type === 'LegalPerson' &&
      representativeId &&
      !representatives.some(({ personId }) => personId === representativeId)
    ) {
      await addRepresentation({
        representativeId,
        representativeType: 'NaturalPerson',
        representeeId: personId,
        representeeType: type,
      });
    }
  }
};

export const getFormEditionValues = async (
  { multipleTenants, ...chosenParties }: NonNullable<RentalDepositChosenParties>,
  tenantsInDossier: PersonInDossier[],
  landlordsInDossier: PersonInDossier[],
): Promise<RentalDepositChoosePartiesForm> => {
  const tenants = await getPartyList(
    {
      partyId: chosenParties.tenantId,
      partyPartnerId: chosenParties.tenantPartnerId,
      relation: chosenParties.tenantsRelation,
      partyRepresentativeId: chosenParties.tenantRepresentativeId,
    },
    tenantsInDossier,
  );

  const landlords = await getPartyList(
    {
      partyId: chosenParties.landlordId,
      partyPartnerId: chosenParties.landlordPartnerId,
      relation: chosenParties.landlordsRelation,
      partyRepresentativeId: chosenParties.landlordRepresentativeId,
    },
    landlordsInDossier,
  );

  return { landlords, tenants, multipleTenants };
};

interface PartyData {
  partyId: string;
  partyPartnerId?: string | null;
  partyRepresentativeId?: string | null;
  relation: RentalDepositChosenParties['tenantsRelation' | 'landlordsRelation'];
}

const getPartyList = async (
  { partyId, partyPartnerId, relation, partyRepresentativeId }: PartyData,
  personsInDossier: PersonInDossier[],
) => {
  let parties: ProductParty[] = [];

  const party = personsInDossier.find(({ personId }) => personId === partyId);
  const partyPartner = personsInDossier.find(
    ({ personId }) => personId === partyPartnerId,
  );

  let partyRepresentatives: PartyPerson[] = [];
  if (partyRepresentativeId) {
    partyRepresentatives = (await fetchRepresentatives(partyId)) ?? [];
  }

  let assignedPartner: PartyPerson | undefined = undefined;
  if (party?.hasPrivateRelation) {
    assignedPartner = await fetchPrivateRelation(party.personId);
  }

  if (party) {
    parties = [
      {
        ...party,
        representatives: partyRepresentatives,
        representativeId: partyRepresentativeId,
        partner: partyPartner,
        partnerId: assignedPartner?.personId,
        relation: assignedPartner?.relation,
      },
    ];

    if (partyPartner) {
      parties = [...parties, { ...partyPartner, relation }];
    }
  }

  return parties;
};
