import { RefCallback } from 'react';
import Input from 'react-text-mask';
import { BaseInput } from './BaseInput';
import { MaskedInputProps } from './types';

export const MaskedInput = ({
  mask,
  pipe,
  onChange: handleChange,
  onBlur,
  value,
  label,
  name,
  error,
  warning,
  hideHelperTextGap,
  disabled,
  helperText,
  placeholder,
  startAdornment,
  endAdornment,
  inputRef,
}: MaskedInputProps) => (
  <Input
    mask={mask}
    guide={false}
    onBlur={onBlur}
    pipe={pipe}
    render={(ref, { onChange, ...props }) => (
      <BaseInput
        onChange={(e) => {
          onChange(e);
          if (handleChange) {
            handleChange(e.target.value);
          }
        }}
        inputRef={(instance) => {
          if (inputRef) {
            (inputRef as RefCallback<HTMLInputElement>)(instance);
          }
          ref(instance);
        }}
        value={value}
        label={label}
        name={name}
        error={error}
        warning={warning}
        hideHelperTextGap={hideHelperTextGap}
        disabled={disabled}
        helperText={helperText}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        placeholder={placeholder}
        {...props}
      />
    )}
  />
);
