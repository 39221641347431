import { useContext, useMemo } from 'react';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { PersonInDossier } from 'api/dossier/types';
import { Details } from './components/Details';
import { DossierData, PersonActionProps } from './types';
import { getDossierPersons } from './utils';

interface Props {
  personsType: 'landlords' | 'tenants';
  persons: PersonInDossier[];
  dossier: DossierData;
  updateList: () => Promise<void>;
}

const PersonsDetails = ({ persons, personsType, dossier, updateList }: Props) => {
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const personActionProps: PersonActionProps = {
    type: personsType,
    setUpAndOpenConfirmationModal,
    updateList,
    data: dossier,
  };

  const personsData = useMemo(
    () => getDossierPersons(persons, personActionProps),
    [persons],
  );

  return (
    <>
      {personsData
        .filter(({ isRepresentative }) => !isRepresentative)
        .map((person) => (
          <Details key={person.personId} {...person} />
        ))}
    </>
  );
};

export default PersonsDetails;
