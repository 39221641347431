import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { sortTypeOptions } from 'formData/common/selectOptions';
import { assignableDossierStatuses } from 'formData/dossier/selectOptions';
import { SetState } from 'types/setState';
import { SortDirection } from 'utils/sortUtils/types';
import { Select } from 'components/inputs';

interface Props {
  sortBy: SortDirection | '';
  setSortBy: SetState<SortDirection | ''>;
  filterStatuses: string[];
  setFilterStatuses: SetState<string[]>;
}

const AssignedDossiersToolbar = ({
  sortBy,
  setSortBy,
  filterStatuses,
  setFilterStatuses,
}: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'selectInput' });

  const handleStatusChange = (value: string | string[]) => {
    setFilterStatuses(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item xs={12} sm={6} lg={3}>
        <Select
          hideHelperTextGap
          placeholder={t('placeholder.sortBy')}
          options={sortTypeOptions}
          clearable
          value={sortBy}
          onChange={(value) => setSortBy(value as SortDirection)}
          onClear={() => setSortBy('')}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Select
          hideHelperTextGap
          placeholder={t('placeholder.status')}
          options={assignableDossierStatuses}
          multiple
          clearable
          value={filterStatuses}
          onChange={handleStatusChange}
          onClear={() => setFilterStatuses([])}
        />
      </Grid>
    </Grid>
  );
};

export default AssignedDossiersToolbar;
