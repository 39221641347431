import { DossierFormInterface } from 'modules/DossierForm/types';

export const dossierFormDefaultValues: DossierFormInterface = {
  dossierType: 'RentalDossier',
  checklistItems: [],
  landlords: [],
  tenants: [],
  estate: null,
  responsibleUsers: [],
};
