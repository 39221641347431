import { TranslatedOption } from 'formData/types';
import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import {
  NumberOfMonthlyRent,
  RentalDuration,
  RentFrequency,
  RentType,
} from 'api/dossier/types';
import { SelectOption } from 'components/inputs/types';

export const currencyOptions: SelectOption[] = [{ value: 'EUR', label: 'EUR' }];

export const dossierDurations: TranslatedOption<NonNullable<RentalDuration>>[] =
  getObjectKeys(namespaces.enums.rentalDossierDuration).map((value) => ({
    value,
    label: value,
    translationKey: `rentalDossierDuration.${value}`,
  }));

export const numberOfMonthlyRentOptions: TranslatedOption<
  NonNullable<NumberOfMonthlyRent>
>[] = getObjectKeys(namespaces.enums.dossierNumberOfMonthlyRent).map((value) => ({
  value,
  label: value,
  translationKey: `dossierNumberOfMonthlyRent.${value}`,
}));

export const rentTypes: TranslatedOption<NonNullable<RentType>>[] = getObjectKeys(
  namespaces.enums.rentType,
).map((value) => ({
  value,
  label: value,
  translationKey: `rentType.${value}`,
}));

export const rentFrequencyOptions: TranslatedOption<NonNullable<RentFrequency>>[] =
  getObjectKeys(namespaces.enums.rentFrequency).map((value) => ({
    value,
    label: value,
    translationKey: `rentFrequency.${value}`,
  }));
