import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import useLoader from 'hooks/useLoader';
import { NaturalPersonDetailsCard, PersonViewHeader } from 'modules/PersonView';
import routes, { personTabRoutes } from 'router/routes';
import { getPrivateRelation } from 'api/relations/requests';
import { FetchedPrivateRelation } from 'api/relations/types';
import { RouteTabs } from 'components/tabs';
import { getNaturalPersonConfig, getNaturalPersonData } from './utils';

const NaturalPersonDetails = () => {
  const { naturalPersonId } = useParams();
  if (!naturalPersonId) return null;

  const [loadingRelation, setLoadingRelation] = useState(false);
  const [privateRelation, setPrivateRelation] = useState<FetchedPrivateRelation | null>(
    null,
  );

  const { data, updateData, Loader } = useLoader(getNaturalPersonConfig(naturalPersonId));

  const naturalPersonData = useMemo(() => getNaturalPersonData(data), [data]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const personRoutes = useMemo(() => {
    const { details, ...routesWithoutDetails } = personTabRoutes;
    return matches ? routesWithoutDetails : { details, ...routesWithoutDetails };
  }, [matches]);

  const fetchRelation = useCallback(async () => {
    setLoadingRelation(true);
    const { ok, response } = await getPrivateRelation(naturalPersonId);
    if (ok && response) {
      setPrivateRelation(response);
    }
    setLoadingRelation(false);
  }, [naturalPersonId]);

  useEffect(() => {
    fetchRelation();
  }, [fetchRelation]);

  return (
    <Loader>
      {naturalPersonData && (
        <Grid container sx={({ mixins }) => mixins.stickyCardWrapper}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack sx={({ mixins }) => mixins.viewTabsWrapper}>
              <PersonViewHeader
                type="NaturalPerson"
                id={naturalPersonId}
                data={naturalPersonData}
              />
              <RouteTabs
                routes={personRoutes}
                parentPath={routes.naturalPerson(naturalPersonId)}
                translationKey="personView"
              />
            </Stack>
            <Outlet
              context={{
                fetchNaturalPersonData: updateData,
                naturalPersonData: naturalPersonData,
                naturalPersonId,
                privateRelation,
                loadingRelation,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            mb="auto"
            display={{ xs: 'none', lg: 'block' }}
          >
            <NaturalPersonDetailsCard
              fetchNaturalPersonData={updateData}
              naturalPersonData={naturalPersonData}
              naturalPersonId={naturalPersonId}
            />
          </Grid>
        </Grid>
      )}
    </Loader>
  );
};

export default NaturalPersonDetails;
