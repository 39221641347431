import { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@mui/material';
import Button from 'atoms/Button';
import useComposition from 'hooks/useComposition';
import { nameExists } from 'hooks/useComposition/utils';
import TranslationField from 'modules/TranslationField';
import { Language } from 'types/language';
import { TranslationFieldInterface } from 'api/common/types';
import { TextInput } from 'components/inputs';
import ModalTitle from 'components/modals/ModalTitle';
import ModalWrapper from 'components/modals/ModalWrapper';

export const EditNewCompositionField = ({
  openModal,
  closeModal,
  update,
  fields,
  editingIndex,
}: NonNullable<ReturnType<typeof useComposition>['editAdditionalFieldProps']>) => {
  const [value, setValue] = useState<TranslationFieldInterface>(
    fields[editingIndex].name,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation(['modals', 'common', 'validation']);

  const onInputChange =
    (lng: Language): ChangeEventHandler<HTMLInputElement> =>
    ({ target }) => {
      setValue((prev) => ({ ...prev, [lng]: target.value }));
      setErrorMessage('');
    };

  const onEdit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (
      nameExists(
        value,
        fields.filter((_, index) => index !== editingIndex),
      )
    ) {
      setErrorMessage(t('validation:composition.fieldExists'));
    } else {
      update(editingIndex, { ...fields[editingIndex], name: value });
      setErrorMessage('');
      closeModal();
    }
  };

  return (
    <ModalWrapper open={openModal} onClose={closeModal}>
      <ModalTitle title={t('title.editNewCompositionField')} closeModal={closeModal} />
      <DialogContent>
        <TranslationField
          render={(lng) => (
            <TextInput
              placeholder={t('newCompositionField', { ns: 'formFields' })}
              onChange={onInputChange(lng)}
              helperText={errorMessage}
              error={!!errorMessage}
              value={value[lng]}
              sx={{ mt: 1 }}
            />
          )}
        />
      </DialogContent>
      <DialogActions sx={({ mixins }) => mixins.modalFooter}>
        <Button role="text" onClick={closeModal}>
          {t('common:cancel')}
        </Button>
        <Button onClick={onEdit}>{t('common:confirm')}</Button>
      </DialogActions>
    </ModalWrapper>
  );
};
