import { useEffect } from 'react';
import { EstateData } from 'api/estate/types';
import { PhotoModal } from 'components/modals';
import useEstatePhoto from './useEstatePhoto';

interface Props {
  estate: EstateData;
  estateId: string;
  fetchEstate: () => Promise<void>;
  closeModal: () => void;
}

const EstatePhotoModal = ({ estate, estateId, closeModal, fetchEstate }: Props) => {
  const onSaveSuccess = async () => {
    await fetchEstate();
    closeModal();
  };

  const { isPhotoChanged, savePhoto, fetchSavedPhoto, ...uploadPhotoProps } =
    useEstatePhoto({
      estateId,
      photoUrl: estate.photo,
      onSaveSuccess,
    });

  useEffect(() => {
    fetchSavedPhoto();
  }, []);

  return (
    <PhotoModal
      open
      closeModal={closeModal}
      onSubmit={savePhoto}
      isSubmitDisabled={!isPhotoChanged}
      {...uploadPhotoProps}
    />
  );
};

export default EstatePhotoModal;
