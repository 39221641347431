import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Backdrop, Box, IconButton } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Logo from 'atoms/Logo';
import Navigation from 'modules/Navigation';
import AgentContactBox from './AgentContactBox';
import {
  getDrawerStyles,
  getSidebarHeaderStyles,
  toggleSidebarButtonStyles,
} from './styles';

interface Props {
  expanded: boolean;
  toggleSidebar: () => void;
  matchesBreakpoint: boolean;
}

export const Sidebar = ({ expanded, matchesBreakpoint, toggleSidebar }: Props) => {
  const onNavItem = () => {
    if (expanded && !matchesBreakpoint) {
      toggleSidebar();
    }
  };

  const expandSidebar = () => {
    if (!expanded) {
      toggleSidebar();
    }
  };

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={getDrawerStyles(expanded)}
        open={expanded}
      >
        <Box sx={getSidebarHeaderStyles(expanded)}>
          <Logo size={expanded ? 'medium' : 'small'} />
        </Box>
        <Navigation
          onNavItem={onNavItem}
          isSidebarExpanded={expanded}
          expandSidebar={expandSidebar}
        />
        <AgentContactBox onClick={expandSidebar} isSidebarExpanded={expanded} />
        <Box sx={toggleSidebarButtonStyles}>
          <IconButton size="large" onClick={toggleSidebar}>
            {expanded ? (
              <ChevronLeft fontSize="large" />
            ) : (
              <ChevronRight fontSize="large" />
            )}
          </IconButton>
        </Box>
      </Drawer>
      <Backdrop
        open={expanded && !matchesBreakpoint}
        sx={{ zIndex: ({ zIndex }) => zIndex.drawer - 1 }}
        onClick={toggleSidebar}
      />
    </>
  );
};
