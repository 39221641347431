import { Stack } from '@mui/material';
import isTruthyValue from 'utils/isTruthyValue';
import getObjectEntries from 'utils/object/getObjectEntries';
import SectionRow from './SectionRow';
import { SectionRowProps, SectionRowsProps } from './types';

export const SectionRows = <Data extends object>({
  data,
  translationPrefix,
  translateValue,
  links,
  translateOptions,
  header,
  titleGrow,
}: SectionRowsProps<Data>) => (
  <Stack
    flex={1}
    data-testid="section-container"
    sx={{ ':nth-of-type(1)': { marginTop: 0 } }}
  >
    {header && <SectionRow isHeader {...header} titleGrow={titleGrow} />}
    {getObjectEntries(data)
      .filter(([_, value]) => isTruthyValue(value))
      .map(([title, value]) => (
        <SectionRow
          key={String(title)}
          title={String(title)}
          value={value as SectionRowProps['value']}
          translationPrefix={translationPrefix}
          translateValue={translateValue?.[title]}
          link={links?.[title]}
          translateOptions={translateOptions?.[title]}
          titleGrow={titleGrow}
        />
      ))}
  </Stack>
);
