import { useCallback, useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import { registerStateReportSchema } from 'formData/dossier/products/rentalContract/schema';
import { resolver } from 'utils/yup';
import {
  ProductActionContent,
  ProductDocumentPreview,
  ProductInputFields,
} from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import { registerStateReportFields } from './RegisterRentalContract/static-data';
import { RegisterStateReportForm } from './RegisterRentalContract/types';

const RegisterStateReport = ({
  sendRequest,
  dossier: { dossierId },
}: ProductActionComponentProps) => {
  const methods = useForm<RegisterStateReportForm>({
    defaultValues: registerStateReportSchema.getDefault(),
    resolver: resolver(registerStateReportSchema),
  });
  const { userData } = useContext(AccountContext);

  const setBiv = useCallback(() => {
    methods.setValue('biv', userData?.biv || null);
  }, [userData]);

  useEffect(() => {
    setBiv();
  }, [setBiv]);

  const onSubmit: SubmitHandler<RegisterStateReportForm> = (data) => {
    sendRequest(data);
  };

  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container gap={4} mt={1}>
        <FormProvider {...methods}>
          <ProductInputFields fields={registerStateReportFields} />
          {/* probably instead of StateReport there should be ProofOfRegistrationStateReport */}
          <ProductDocumentPreview dossierId={dossierId} type="StateReport" />
        </FormProvider>
      </Grid>
    </ProductActionContent>
  );
};

export default RegisterStateReport;
