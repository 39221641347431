import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useGasFields from 'hooks/useGasFields';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { getOnInputChange } from 'modules/EstateForm/utils';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import { isTruthyValueInObject } from 'utils/object';
import { ControlledInput } from 'components/controlledInputs';

const GasFields = () => {
  const { t } = useTranslation('formFields');

  const {
    control,
    formState: { errors, isSubmitted },
    getValues,
    trigger,
  } = useFormContext<EstateFormInterface>();

  const { fields, onCheckboxChange, updateCheckbox } = useGasFields('estateDetails');

  const initiallyExpanded = !!(
    getValues('estateDetails.hasGas') &&
    isTruthyValueInObject(getValues('estateDetails.gas'))
  );

  return (
    <Grid item xs={12}>
      <AccordionWithCheckbox
        control={control}
        error={errors.estateDetails?.gas}
        name="estateDetails.hasGas"
        label={t('gas.self')}
        onChange={onCheckboxChange}
        initiallyExpanded={initiallyExpanded}
      >
        <Grid item container spacing={2}>
          {fields.map(({ name, ...props }) => (
            <Grid key={name} item xs={6}>
              <ControlledInput
                name={`estateDetails.gas.${name}`}
                control={control}
                label={t(`gas.${name}`)}
                error={errors?.estateDetails?.gas?.[name]}
                onInputChange={getOnInputChange({
                  fieldName: name,
                  isSubmitted,
                  trigger,
                  typeToTrigger: 'estateDetails.electricity',
                  updateCheckbox,
                })}
                {...props}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionWithCheckbox>
    </Grid>
  );
};

export default GasFields;
