import { Navigate } from 'react-router-dom';
import hasPermission, { getPermissions } from 'utils/hasPermission';
import routes from './routes';

interface Props {
  children: JSX.Element;
  permission: string;
  fallback?: string | ((rolePermission: string[]) => string | undefined);
}

const ProtectedRoute = ({ permission, fallback = routes.dashboard, children }: Props) => {
  if (hasPermission(permission)) {
    return children;
  }

  const permissions = getPermissions();
  const redirect =
    typeof fallback === 'string' ? fallback : fallback(permissions) || routes.dashboard;

  return <Navigate to={redirect} replace />;
};

export default ProtectedRoute;
