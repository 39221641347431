import { testStringToJSON } from 'utils/string';
import yup, { numberSchema } from 'utils/yup';
import { BelgiumCountry } from './static-data';

const addressSchema = yup.object({
  street: yup.string().label('address.street').required(),
  streetTranslation: yup.string(),
  houseNumber: numberSchema.positive().label('address.houseNumber').required(),
  extension: yup
    .string()
    .label('address.extension')
    .nullable()
    .default(null)
    .exactLength(1),
  apartmentNumber: yup
    .string()
    .label('address.apartmentNumber')
    .max(5)
    .nullable()
    .default(null),
  postCode: yup.string().label('address.postCode').required(),
  city: yup
    .string()
    .label('address.city')
    .transform((value) => {
      if (testStringToJSON(value)) {
        return JSON.parse(value).en ? value : '';
      }
      return value;
    })
    .required(),
  country: yup
    .string()
    .label('address.country')
    .default(JSON.stringify(BelgiumCountry.name))
    .required(),
});

export default addressSchema;
