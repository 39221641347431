import { Box } from '@mui/material';
import { UserPermissions } from 'consts/permissions';
import routes from 'router/routes';
import Table from 'templates/Table';
import hasPermission from 'utils/hasPermission';
import { getUsers } from 'api/users/requests';
import AddNewButton from 'components/AddNewButton';
import PageTitle from 'components/PageTitle';
import { getLinkToUser, usersColumns } from './static-data';

export const UsersList = () => {
  const addable = hasPermission(UserPermissions.Write);

  return (
    <Box sx={({ mixins }) => mixins.tablePageWrapper}>
      <Box display="flex" justifyContent="space-between">
        <PageTitle page="users" />
        {addable && <AddNewButton translationKey="user" to={routes.newUser} />}
      </Box>
      <Table
        tKey="users"
        get={getUsers}
        withUrlSearchParams
        columns={usersColumns}
        // getActions={getUsersActions}
        rowRedirectionLink={getLinkToUser}
      />
    </Box>
  );
};
