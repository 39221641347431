import { ChangeEvent, FocusEventHandler, ReactElement } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import EmailField from 'modules/EmailField';
import VatField from 'modules/VatField';
import ControlledCheckbox from './ControlledCheckbox';
import { ControlledDateInput } from './ControlledDateInput';
import { ControlledMaskedInput } from './ControlledMaskedInput';
import { ControlledNumberInput } from './ControlledNumberInput';
import { ControlledPaymentInput } from './ControlledPaymentInput';
import { ControlledPhoneInput } from './ControlledPhoneInput';
import { ControlledSearchInput } from './ControlledSearchInput';
import { ControlledSelect } from './ControlledSelect';
import { ControlledTextInput } from './ControlledTextInput';
import { ControlledInputProps } from './types';

export const ControlledInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledInputProps<T, N>,
) => ReactElement = ({
  dateInputProps,
  options,
  type,
  isInteger,
  allowNegative,
  precision,
  searchInputProps,
  endAdornment,
  multiline,
  rows,
  mask,
  onInputChange,
  onSelectChange,
  onCheckboxChange,
  sizes: _sizes,
  ...props
}) => {
  const changeValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (onInputChange) {
      onInputChange(e.target.value);
    }
  };

  if (mask) {
    return <ControlledMaskedInput mask={mask} onChange={onInputChange} {...props} />;
  }

  if (options && type !== 'tel' && type !== 'payment' && type !== 'taxResidence') {
    return <ControlledSelect options={options} {...props} onChange={onSelectChange} />;
  }

  if (searchInputProps) {
    return <ControlledSearchInput {...searchInputProps} type={type} {...props} />;
  }

  switch (type) {
    case 'email': {
      return <EmailField onChange={changeValue} endAdornment={endAdornment} {...props} />;
    }
    case 'checkbox': {
      const { label = '', hideHelperTextGap, onBlur, ...checkboxProps } = props;
      return (
        <ControlledCheckbox
          {...checkboxProps}
          onChange={(_, checked) => onCheckboxChange?.(checked)}
          onBlur={onBlur as FocusEventHandler<HTMLButtonElement>}
          label={label}
          sx={{ mb: !hideHelperTextGap ? 1.5 : 0 }}
        />
      );
    }
    case 'payment':
      return <ControlledPaymentInput options={options} {...props} />;
    case 'date':
      return (
        <ControlledDateInput
          {...dateInputProps}
          {...props}
          onChange={(value) => {
            dateInputProps?.onChange?.(value);
            onInputChange?.(value);
          }}
        />
      );
    case 'number':
      return (
        <ControlledNumberInput
          isInteger={isInteger}
          precision={precision}
          allowNegative={allowNegative}
          endAdornment={endAdornment}
          onChange={changeValue}
          {...props}
        />
      );
    case 'tel':
      return <ControlledPhoneInput {...props} />;
    case 'vat':
      return (
        <VatField
          label={props.label}
          hideHelperTextGap={props.hideHelperTextGap}
          disabled={props.disabled}
          name={props.name}
        />
      );
    default:
      return (
        <ControlledTextInput
          multiline={multiline}
          rows={rows}
          onChange={changeValue}
          endAdornment={endAdornment}
          {...props}
        />
      );
  }
};
