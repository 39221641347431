import { Icons } from './Icons';

const {
  EstateIcon,
  NaturalPersonIcon,
  LegalPersonIcon,
  DossierIcon,
  PersonsIcon,
  ErrorIcon,
  FileIcon,
  WarningIcon,
  SearchIcon,
  KebabMenuIcon,
  AddIcon,
  ClearIcon,
  DeleteIcon,
  RemoveIcon,
  SettingsIcon,
  TranslationsIcon,
  DownloadIcon,
  OpenInNewIcon,
  CancelIcon,
  SaveIcon,
  MenuIcon,
  DashboardIcon,
  NotificationIcon,
  CheckIcon,
  CalendarIcon,
  Layers,
  LanguageIcon,
  BusinessesIcon,
  LogoutIcon,
  UploadIcon,
  FilterIcon,
  HelpIcon,
  DepositIcon,
  FireIcon,
  EditIcon,
  MoveIcon,
  CourtIcon,
  RegistrationIcon,
  InfoIcon,
  ReportIcon,
  CodeIcon,
  DocumentIcon,
  PhoneIcon,
  DropIcon,
  AttachIcon,
  FolderIcon,
  RouterIcon,
  LightningIcon,
  HourglassIcon,
  EyeShowIcon,
  MapTagIcon,
  MailIcon,
  LockIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  SortIcon,
  PlayIcon,
} = Icons;

export {
  EstateIcon,
  NaturalPersonIcon,
  LegalPersonIcon,
  DossierIcon,
  PersonsIcon,
  ErrorIcon,
  FileIcon,
  WarningIcon,
  SearchIcon,
  KebabMenuIcon,
  AddIcon,
  ClearIcon,
  DeleteIcon,
  RemoveIcon,
  SettingsIcon,
  TranslationsIcon,
  DownloadIcon,
  OpenInNewIcon,
  CancelIcon,
  SaveIcon,
  MenuIcon,
  DashboardIcon,
  NotificationIcon,
  CheckIcon,
  CalendarIcon,
  LanguageIcon,
  BusinessesIcon,
  LogoutIcon,
  UploadIcon,
  FilterIcon,
  HelpIcon,
  DepositIcon,
  FireIcon,
  EditIcon,
  MoveIcon,
  CourtIcon,
  RegistrationIcon,
  InfoIcon,
  ReportIcon,
  CodeIcon,
  DocumentIcon,
  PhoneIcon,
  DropIcon,
  AttachIcon,
  FolderIcon,
  RouterIcon,
  LightningIcon,
  HourglassIcon,
  EyeShowIcon,
  MapTagIcon,
  MailIcon,
  LockIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  SortIcon,
  Layers,
  PlayIcon,
};
