import { ChangeEventHandler } from 'react';
import { sortFields } from 'formData/utils';
import i18n from 'i18n';
import { Residents } from 'api/dossier/types';
import { InputField } from 'components/controlledInputs/types';
import { residentsOrder } from './order';
import { MAX_CHILDREN_NUMBER } from './static-data';

export const getResidentsFields: (
  onInputChange: ChangeEventHandler<HTMLInputElement>,
  numberOfChildren: number | null,
) => InputField<Omit<Residents, 'childrenDateOfBirth'>>[] = (
  onInputChange,
  numberOfChildren,
) =>
  sortFields(
    [
      {
        name: 'numberOfAdults',
        type: 'number',
        isInteger: true,
      },
      {
        name: 'numberOfChildren',
        type: 'number',
        onChange: onInputChange,
        isInteger: true,
        helperText:
          numberOfChildren && numberOfChildren > MAX_CHILDREN_NUMBER
            ? i18n.t('helperText.maxNumberOfChildren', { number: MAX_CHILDREN_NUMBER })
            : undefined,
      },
    ],
    residentsOrder,
  );
