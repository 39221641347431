import { numberOfMonthlyRentValues } from 'formData/dossier/rentalConditions/static-data';
import getObjectEntries from 'utils/object/getObjectEntries';

export const calculateNumberOfMonthlyRent = (
  rentalPriceValue: number,
  depositValue: number,
) => {
  const [numberOfMonthlyRent] =
    getObjectEntries(numberOfMonthlyRentValues).find(
      ([_, value]) => value === depositValue / rentalPriceValue,
    ) ?? [];

  return numberOfMonthlyRent ?? 'Other';
};
