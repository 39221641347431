import i18n from 'i18n';
import { formatDate } from 'utils/date';
import { DocumentData } from 'api/documents/types';
import { DocumentDateCell } from './cells';
import { DocumentsColumn, DocumentsContextInterface, GetDocumentActions } from './types';
import { handleDeleteDocument, handleDownloadDocument } from './utils';

export const getDocumentActions: GetDocumentActions = (
  document,
  entityId,
  scope,
  turnEditMode,
) => [
  {
    name: 'download',
    hidden: document.documentClassification !== 'Document',
    onClick: handleDownloadDocument(document, entityId, scope),
  },
  {
    name: 'edit',
    hidden: !turnEditMode,
    onClick: () => turnEditMode?.(),
  },
  {
    name: 'delete',
    onClick: handleDeleteDocument(document, entityId, scope),
  },
];

export const documentsColumns: DocumentsColumn<
  DocumentData,
  DocumentsContextInterface<DocumentData>
>[] = [
  {
    name: 'documentName',
    type: 'text',
    editable: true,
  },
  {
    name: 'documentType',
    valueGetter: ({ documentType }, scope) =>
      i18n.t(`documents:${scope}.${documentType}`, documentType),
  },
  {
    name: 'date',
    type: 'date',
    editable: true,
    Component: DocumentDateCell,
  },
  {
    name: 'createdAt',
    valueGetter: ({ createdAt }) => formatDate(createdAt, 'dd/MM/yyyy HH:mm') || '',
  },
];
