import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import useRentalConditionsFields from 'hooks/useRentalConditionsFields';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { ControlledInput } from 'components/controlledInputs';
import { MissingDataComponentsProps } from './types';
import { getRentalConditionsFields } from './utils';

const RentalConditionsMissingDataForm = ({
  fields,
  methods,
}: MissingDataComponentsProps) => {
  const { t } = useTranslation('formFields');

  const rentalConditionsFields = useRentalConditionsFields(methods, {
    prefix: 'rentalConditions',
    isFormForMissingData: true,
  });

  const { requiredFields, notRequiredFields } = useMemo(
    () => getRentalConditionsFields(fields, rentalConditionsFields),
    [fields, rentalConditionsFields],
  );

  return (
    <Stack spacing={1}>
      {!!requiredFields.length &&
        requiredFields.map(({ name, hidden, ...field }) =>
          hidden ? null : (
            <InputLabelWrapper key={name} label={`${t(name)}*`}>
              <ControlledInput
                name={`rentalConditions.${name}`}
                control={methods.control}
                error={methods.formState.errors.rentalConditions?.[name]}
                hideHelperTextGap
                {...field}
              />
            </InputLabelWrapper>
          ),
        )}
      {!!notRequiredFields.length &&
        notRequiredFields.map(({ name, hidden, ...field }) =>
          hidden ? null : (
            <InputLabelWrapper key={name} label={t(name)}>
              <ControlledInput
                name={`rentalConditions.${name}`}
                control={methods.control}
                error={methods.formState.errors.rentalConditions?.[name]}
                hideHelperTextGap
                {...field}
              />
            </InputLabelWrapper>
          ),
        )}
    </Stack>
  );
};

export default RentalConditionsMissingDataForm;
