import { RelationData, RelationResponses, SaveRelations } from 'modules/Relation/types';
import { saveRelations } from 'modules/Relation/utils';
import { FetchedPersonData, PersonType } from 'api/person/types';
import { addRepresentation } from 'api/relations/requests';
import {
  AddRepresentation,
  AddRepresentationError,
  FetchedRepresentation,
} from 'api/relations/types';

export const isNaturalPersonRelation = (
  relation: FetchedRepresentation<PersonType>,
): relation is FetchedRepresentation<'NaturalPerson'> => {
  return !!(relation as FetchedRepresentation<'NaturalPerson'>)?.representee;
};

export const createNewRelation = (
  relation: RelationData<FetchedPersonData>,
  personId: string,
  personType: PersonType,
): AddRepresentation => {
  const representativeId = personType === 'NaturalPerson' ? personId : relation.id;
  const representativeType: PersonType = 'NaturalPerson';

  const representeeId = personType === 'NaturalPerson' ? relation.id : personId;
  const representeeType: PersonType = 'LegalPerson';

  return {
    representativeId,
    representativeType,
    representeeId,
    representeeType,
  };
};

const getErrorMessage = (
  personName: string,
  { type, name }: RelationData<FetchedPersonData>,
  { errorCode, errorMessage }: AddRepresentationError,
): RelationData['errorMessage'] => ({
  key: `${errorCode}_${type}`,
  values: {
    defaultValue: errorMessage,
    side1: personName,
    side2: name,
    name,
  },
});

export const addNewRepresentations =
  (
    personId: string,
    personType: PersonType,
    personName: string,
  ): SaveRelations<FetchedPersonData> =>
  async (relationsToPreview, setRelationsToPreview, showSaved) => {
    const responses: RelationResponses = [];

    for (const relation of relationsToPreview) {
      const newRelation = createNewRelation(relation, personId, personType);
      const { ok, response } = await addRepresentation(newRelation);
      const message =
        !ok && response ? getErrorMessage(personName, relation, response) : '';
      responses.push({ ok, message, id: relation.id });
    }

    return saveRelations<FetchedPersonData>(responses)(
      relationsToPreview,
      setRelationsToPreview,
      showSaved,
    );
  };

export const getRepresentationsList = async <T extends PersonType>(
  relations: FetchedRepresentation<T>[],
) =>
  relations.map((relation) => {
    const { id, ...person } = isNaturalPersonRelation(relation)
      ? relation.representee
      : relation.representative;
    return {
      id: relation.relationId,
      personId: id,
      ...person,
      isDeletable: relation?.deletable,
    };
  });
