import { useContext, useMemo, useState } from 'react';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { estateOrder } from 'formData/estate';
import { sortFields } from 'formData/utils';
import useFormMethods from 'hooks/useFormMethods';
import { useUpdateEffect } from 'usehooks-ts';
import getObjectKeys from 'utils/object/getObjectKeys';
import {
  EstateDestination,
  EstatePurpose,
  EstateSubType,
  EstateType,
} from 'api/estate/types';
import { InputField } from 'components/controlledInputs/types';
import { SelectInputProps } from 'components/inputs/types';
import {
  createDestinationOptions,
  createSubTypeOptions,
  createTypeOptions,
} from './utils';

export const useEstatePurposeFields = (
  prefix?: string,
  onTypeChange?: (type: EstateType) => void,
) => {
  const { destinationConfig, subTypesConfig } = useContext(ConfigContext);

  const { getValues, setValue, trigger, getError, defaultValues } =
    useFormMethods<EstatePurpose>(prefix);

  const [destination, setDestination] = useState<EstateDestination>(
    getValues('destination') || '',
  );
  const [type, setType] = useState<EstateType>(getValues('type') || null);
  const [subTypes, setSubTypes] = useState<NonNullable<EstateSubType>[]>(
    type ? subTypesConfig[type] : [],
  );

  useUpdateEffect(() => {
    if (defaultValues?.destination) {
      setDestination(defaultValues.destination);
    }
    if (defaultValues?.type) {
      setType(defaultValues.type);
      setSubTypes(subTypesConfig[defaultValues.type]);
    }
  }, [defaultValues]);

  const handleDestinationChange: SelectInputProps['onChange'] = (value) => {
    setDestination(value as EstateDestination);

    setValue('type', null);
    setType(null);

    setValue('subType', null);
    setSubTypes([]);

    if (getError('type')) {
      trigger('type');
    }
    if (getError('subType')) {
      trigger('subType');
    }
  };

  const handleTypeChange: SelectInputProps['onChange'] = async (value) => {
    const type = value as EstateType;
    setType(type);
    setValue('subType', null);

    if (getError('subType')) {
      trigger('subType');
    }

    if (type) {
      setSubTypes(subTypesConfig[type]);
    }
    if (type !== 'Apartment') {
      setValue('floorLevel', null);
    }

    if (onTypeChange) {
      onTypeChange(type);
    }
  };

  const estateDestinations = useMemo(
    () => createDestinationOptions(getObjectKeys(destinationConfig)),
    [destinationConfig],
  );

  const estateTypes = useMemo(
    () => (destination ? createTypeOptions(destinationConfig[destination]) : []),
    [destination],
  );

  const estateSubTypes = useMemo(() => createSubTypeOptions(subTypes), [subTypes]);

  const isTypeDisabled = useMemo(
    () => !!(destination && !estateTypes.length),
    [destination, estateTypes.length],
  );

  const fields: InputField<EstatePurpose>[] = [
    {
      name: 'destination',
      options: estateDestinations,
      onSelectChange: handleDestinationChange,
    },
    {
      name: 'type',
      options: estateTypes,
      onSelectChange: handleTypeChange,
      noOptionsText: 'estateType',
      disabled: isTypeDisabled,
    },
    {
      name: 'subType',
      options: estateSubTypes,
      disabled: isTypeDisabled || !!(type && !subTypes.length),
      noOptionsText: 'estateSubType',
    },
    {
      name: 'floorLevel',
      type: 'number',
      isInteger: true,
      hidden: !type || type !== 'Apartment',
    },
  ];

  return sortFields(fields, estateOrder);
};
