import { VatInterface } from 'api/person/legal/types';
import { getVatMask } from './getVatMask';
import { getVatNumberWithMask } from './getVatNumberWithMask';

export const formatVatWithMask = ({ number, prefix }: VatInterface): VatInterface => {
  // check empty strings
  if (!number || !prefix) {
    return { number, prefix };
  }

  const mask = getVatMask(prefix);

  return { prefix, number: getVatNumberWithMask(number, mask) };
};
