import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { AssignedDossierCardProps } from './types';

const AssignedDossierEstate = ({ estate }: Pick<AssignedDossierCardProps, 'estate'>) => {
  const { t } = useTranslation('common', { keyPrefix: 'assignedDossierCard' });

  return (
    <Box display="flex" justifyContent="space-between" gap={1} alignItems="center">
      <Typography color="grey.500">{t('estate')}</Typography>
      <Typography color="primary.main" fontWeight="bold" textAlign="right">
        {estate.address}
      </Typography>
    </Box>
  );
};

export default AssignedDossierEstate;
