import { getDocumentTypeOptions } from 'formData/documents/selectOptions';
import { TranslatedOption } from 'formData/types';
import { DocumentToUpload } from 'hooks/useFileUpload/types';
import i18n from 'i18n';
import {
  DocumentConfigData,
  DocumentsConfig,
  DocumentScope,
  DocumentType,
} from 'api/documents/types';
import { PRODUCT_ACTION_DOCUMENTS } from './static-data';

interface UploadDocumentHelperTextProps {
  documentConfigData: DocumentConfigData | undefined;
  documentType: DocumentType | null;
  isUploadingFileRequired?: boolean;
}

export const getUploadDocumentHelperText = ({
  documentType,
  documentConfigData,
  isUploadingFileRequired,
}: UploadDocumentHelperTextProps) => {
  const { filesLimit } = documentConfigData ?? {};

  if (isUploadingFileRequired) {
    return i18n.t('helperText.requiredUploadingFile');
  }
  if (!documentType) {
    return i18n.t('helperText.selectDocumentTypeBeforeUpload');
  }
  if (filesLimit) {
    return i18n.t('helperText.maxNumberOfFiles', { count: filesLimit });
  }
};

export const isDocumentFull = (
  files: File[],
  documentConfigData: DocumentConfigData | undefined,
) => {
  const { filesLimit } = documentConfigData ?? {};
  return !!filesLimit && files.length === filesLimit;
};

export const getDropzoneConfig = (documentConfigData?: DocumentConfigData) => {
  const { fileTypes, filesLimit } = documentConfigData ?? {};

  return {
    maxFiles: filesLimit ?? undefined,
    accept: fileTypes?.reduce((prev, curr) => ({ ...prev, [curr]: [] }), {}),
  };
};

export const getFilteredDocumentTypeOptions = (
  config: DocumentsConfig,
  scope: DocumentScope | undefined,
  excludedDocumentTypes?: DocumentType[],
): TranslatedOption<DocumentType>[] => {
  if (!scope) {
    return [];
  }

  const options = getDocumentTypeOptions(config, scope);

  if (excludedDocumentTypes) {
    return options.filter(({ value }) => !excludedDocumentTypes.includes(value));
  }

  return options;
};

export const getDocumentOptionsToDisplay = (
  options: TranslatedOption<DocumentType>[],
  currentIndex: number,
  selectedDocuments: DocumentToUpload[],
) =>
  options
    .filter(
      ({ value }) =>
        !selectedDocuments.some(
          ({ documentType }, index) => documentType === value && currentIndex !== index,
        ),
    )
    .map((document) => ({
      ...document,
      disabled:
        PRODUCT_ACTION_DOCUMENTS.includes(document.value) &&
        selectedDocuments.some(({ files }) => !!files.length),
      caption: PRODUCT_ACTION_DOCUMENTS.includes(document.value)
        ? i18n.t('helperText.uploadingComplexDocument')
        : undefined,
    }));
