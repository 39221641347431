import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EstatePermissions } from 'consts/permissions';
import PageCard from 'templates/PageCard';
import hasPermission from 'utils/hasPermission';
import { EstateCardProps } from '../types';
import EstateDescription from './EstateDescription';
import EstateDescriptionEdition from './EstateDescriptionEdition';
import EstatePhoto from './EstatePhoto';
import EstatePhotoModal from './EstatePhotoModal';
import useEstateEdition from './useEstateEdition';
import { formatEstateAddress } from './utils';

const EstateMainCard = (props: EstateCardProps) => {
  const { i18n } = useTranslation();
  const { uploadPhotoProps, descriptions, onDescriptionChange, editionProps } =
    useEstateEdition(props);

  const { title, subtitle } = useMemo(
    () => formatEstateAddress(props.estate.address),
    [i18n.language, props.estate.address],
  );

  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const togglePhotoModal = () => setIsPhotoModalOpen((prev) => !prev);

  return (
    <PageCard
      headerProps={{
        variant: 'light',
        size: 'large',
        editionProps,
        titleProps: {
          title,
          subtitle,
          startIcon: (
            <EstatePhoto
              editable={!editionProps.isEditing && hasPermission(EstatePermissions.Write)}
              estate={props.estate}
              onClick={togglePhotoModal}
            />
          ),
        },
      }}
    >
      {editionProps.isEditing ? (
        <EstateDescriptionEdition
          uploadPhotoProps={uploadPhotoProps}
          descriptions={descriptions}
          onDescriptionChange={onDescriptionChange}
        />
      ) : (
        <EstateDescription descriptions={descriptions} />
      )}
      {isPhotoModalOpen && <EstatePhotoModal {...props} closeModal={togglePhotoModal} />}
    </PageCard>
  );
};

export default EstateMainCard;
