import { useEffect } from 'react';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { naturalPersonGeneralFields } from 'formData/naturalPerson/fields';
import useNaturalPersonDetailsFields from 'hooks/useNaturalPersonDetailsFields';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import {
  FormContainer,
  FormSection,
  FormSide,
  FormVerticalDivider,
} from 'templates/FormTemplates';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { ControlledInput } from 'components/controlledInputs';

export const GeneralStep = () => {
  const { t } = useTranslation();
  const methods = useFormContext<NaturalPersonFormData>();

  const {
    formState: { errors },
    control,
    trigger,
  } = methods;

  const dateOfBirth = useWatch({ control, name: 'dateOfBirth' });
  const sex = useWatch({ control, name: 'sex' });

  useEffect(() => {
    if ((dateOfBirth || sex) && errors.nationalRegisterNumber) {
      trigger('nationalRegisterNumber');
    }
  }, [dateOfBirth, sex]);

  const naturalPersonDetailsFields = useNaturalPersonDetailsFields(methods);

  return (
    <FormContainer maxWidth={1024}>
      <FormSide>
        <FormSection label={t('stepWizard.naturalPerson.sections.basicInformation')}>
          {naturalPersonGeneralFields.map(({ name, sizes, ...field }) => (
            <Grid item key={name} xs={12} sm={sizes?.sm ?? 6} md={sizes?.md ?? 6}>
              <ControlledInput
                name={name}
                label={t(name, { ns: 'formFields' })}
                control={control}
                error={errors[name]}
                {...field}
              />
            </Grid>
          ))}
        </FormSection>
        <FormSection label={t('stepWizard.naturalPerson.sections.address')}>
          <AddressFieldsWithGoogle />
        </FormSection>
      </FormSide>
      <FormVerticalDivider />
      <FormSide>
        <FormSection label={t('stepWizard.naturalPerson.sections.details')}>
          {naturalPersonDetailsFields.map(
            ({ name, hidden, Component, sizes, ...field }) =>
              !hidden && (
                <Grid
                  item
                  key={name}
                  xs={12}
                  sm={sizes?.sm ?? 6}
                  md={sizes?.md ?? 6}
                  lg={sizes?.lg}
                >
                  {Component ? (
                    <Component />
                  ) : (
                    <ControlledInput
                      name={name}
                      control={control}
                      label={t(name, { ns: 'formFields' })}
                      error={errors[name] as FieldError}
                      {...field}
                    />
                  )}
                </Grid>
              ),
          )}
        </FormSection>
      </FormSide>
    </FormContainer>
  );
};
