import { languageOptions } from 'formData/common/selectOptions';
import { ibanConfig, sortFields } from 'formData/utils';
import { HandleBlurChange } from 'types/handleChange';
import { LegalPersonFormData } from 'api/person/legal/types';
import { InputField } from 'components/controlledInputs/types';
import { legalPersonOrder } from './order';

type GeneralFields = Pick<LegalPersonFormData, 'name' | 'language'>;
type DetailsFields = Omit<LegalPersonFormData, 'address' | keyof GeneralFields>;

export const legalPersonGeneralFields: InputField<GeneralFields>[] = sortFields(
  [
    {
      name: 'name',
    },
    {
      name: 'language',
      options: languageOptions,
      testId: 'languageSelector',
    },
  ],
  legalPersonOrder,
);

interface DetailsFieldsData {
  onIbanBlur: HandleBlurChange;
  loadingBic: boolean;
}

export const getLegalPersonDetailsFields = ({
  onIbanBlur,
  loadingBic,
}: DetailsFieldsData): InputField<DetailsFields>[] =>
  sortFields(
    [
      {
        name: 'vat',
        type: 'vat',
        sizes: { sm: 12, md: 12 },
      },
      {
        name: 'iban',
        onBlur: onIbanBlur,
        ...ibanConfig,
        sizes: { md: 12 },
      },
      {
        name: 'bic',
        loading: loadingBic,
        sizes: { md: 12 },
      },
    ],
    legalPersonOrder,
  );
