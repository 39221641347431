import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PersonPermissions } from 'consts/permissions';
import { EstateCard } from 'modules/OwnershipCard';
import hasPermission from 'utils/hasPermission';
import { LegalPersonOutletContext } from '../types';

const LegalPersonEstatesTab = () => {
  const { legalPersonId } = useOutletContext<LegalPersonOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <EstateCard
          personId={legalPersonId}
          personType="LegalPerson"
          editable={hasPermission(PersonPermissions.Write)}
        />
      </Grid>
    </Grid>
  );
};

export default LegalPersonEstatesTab;
