import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { getVatMask, getVatNumberWithMask, getVatPlaceholder } from 'utils/vat';
import { MaskedInput } from './MaskedInput';
import { Select } from './Select';
import { VatInputProps } from './types';

export const VatInput = ({
  vatNumber = '',
  vatPrefix = 'BE',
  options,
  groupedOptions,
  hideHelperTextGap,
  onPrefixChange,
  onNumberChange,
  disabled,
  ...props
}: VatInputProps) => {
  const mask = useMemo(
    () => getVatMask(vatPrefix, false, vatNumber),
    [vatPrefix, vatNumber],
  );
  const placeholder = useMemo(
    () => getVatPlaceholder(mask, vatPrefix),
    [mask, vatPrefix],
  );

  return (
    <Grid container spacing={0.5} justifyContent="space-between">
      <Grid item xs={3}>
        <Select
          name="vat_prefix"
          value={vatPrefix}
          options={options}
          groupedOptions={groupedOptions}
          onChange={onPrefixChange}
          hideHelperTextGap={hideHelperTextGap}
          disabled={disabled}
          renderValue={({ value }) => value}
        />
      </Grid>
      <Grid item xs={9}>
        <MaskedInput
          mask={mask}
          onChange={onNumberChange}
          value={getVatNumberWithMask(vatNumber, mask)}
          hideHelperTextGap={hideHelperTextGap}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
      </Grid>
    </Grid>
  );
};
