import { PropsWithChildren } from 'react';
import { Divider, Stack } from '@mui/material';
import { ThemedStyle } from 'types/themedStyle';

interface Props extends PropsWithChildren {
  sx?: ThemedStyle;
}

export const DividerStack = ({ sx, children }: Props) => (
  <Stack sx={sx} divider={<Divider orientation="horizontal" />}>
    {children}
  </Stack>
);
