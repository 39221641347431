import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import usePrompt from 'hooks/usePrompt';
import { namespaces } from 'i18n';
import AddNaturalPersonModal from 'modules/ModalForm/AddNaturalPersonModal';
import {
  HandleNewOptionSubmit,
  PersonOption,
  RelationToPreview,
} from 'modules/Relation/types';
import { SetState } from 'types/setState';
import { useDebounce } from 'usehooks-ts';
import getObjectKeys from 'utils/object/getObjectKeys';
import { getPersons } from 'api/person/requests';
import { FetchedPersonData } from 'api/person/types';
import { PrivateRelationForm } from 'api/relations/types';
import { getErrorMessage } from 'components/controlledInputs/utils';
import { SearchInput } from 'components/inputs';
import {
  HandleSearchChange,
  HandleSearchClose,
  HandleSearchInputChange,
} from 'components/inputs/types';

interface Props {
  relationToPreview: RelationToPreview | null;
  setRelationToPreview: SetState<RelationToPreview | null>;
  naturalPersonId: string;
}

export const EditPrivateRelation = ({
  relationToPreview,
  setRelationToPreview,
  naturalPersonId,
}: Props) => {
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [options, setOptions] = useState<PersonOption[]>([]);

  const [input, setInput] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const debouncedInput = useDebounce<string>(input, 500);

  const { t } = useTranslation();
  const {
    control,
    formState: { errors, isDirty },
    setValue,
    clearErrors,
  } = useFormContext<PrivateRelationForm>();

  usePrompt({ when: isDirty, id: 'privateRelation' });

  useEffect(() => {
    setQuery(debouncedInput);
  }, [debouncedInput]);

  const fetchPersons = useCallback(async () => {
    const { ok, response } = await getPersons({
      name: query,
      limit: 25,
      type: 'NaturalPerson',
    });
    if (ok) {
      const persons = response.data
        .filter(({ id }) => id !== relationToPreview?.id && id !== naturalPersonId)
        .map((person) => ({
          value: person.id,
          label: person.name,
          person,
        }));

      setOptions(persons);
    }
  }, [query, relationToPreview?.id]);

  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);

  const onChange: HandleSearchChange<PersonOption> = (option) => {
    if (option?.created) {
      setOpenAddNewModal(true);
    } else if (option) {
      setRelationToPreview(option.person);
      setValue('side2', option.person.id, { shouldDirty: true });
      if (errors.side2) {
        clearErrors('side2');
      }
    }
  };

  const onInputChange: HandleSearchInputChange = (input, reason) => {
    if (reason === 'input') {
      setInput(input);
    } else if (reason === 'clear') {
      setQuery('');
    }
  };

  const onClose: HandleSearchClose<PersonOption> = (option, reason) => {
    if (reason === 'blur') {
      setQuery('');
      setInput('');
    }
  };

  const onNewPersonSubmit: HandleNewOptionSubmit<FetchedPersonData> = async (person) => {
    setRelationToPreview(person);
    setValue('side2', person.id, { shouldDirty: true });
    setQuery('');
    setInput('');
  };

  return (
    <Box display="flex" gap={1}>
      <Box flexGrow={1}>
        <Controller
          name="type"
          control={control}
          render={({ field: { ref, onChange, ...field } }) => (
            <RadioGroup
              name="relations"
              value={field.value}
              onChange={onChange}
              ref={ref}
            >
              {getObjectKeys(namespaces.enums.privateRelation).map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={t(`privateRelation.${value}`, { ns: 'enums' })}
                />
              ))}
            </RadioGroup>
          )}
        />
      </Box>
      <Box flexGrow={2}>
        <SearchInput
          name="side2"
          options={options}
          onChange={onChange}
          onInputChange={onInputChange}
          onClose={onClose}
          creatable
          error={!!errors.side2?.message}
          helperText={getErrorMessage(errors.side2)}
          placeholder={t('relationInput.privateRelation.placeholder')}
          label={t('relationInput.privateRelation.label')}
          fillInputWithOption={false}
        />
      </Box>
      <AddNaturalPersonModal
        open={openAddNewModal}
        closeModal={() => setOpenAddNewModal(false)}
        onSubmit={onNewPersonSubmit}
      />
    </Box>
  );
};
