import { cleanObject, isTruthyValueInObject } from 'utils/object';
import { AbolishmentRequestData } from 'api/dossier/products/rentalDeposit/types';
import { RentalDepositData } from '../types';
import { AbolishmentForm, AbolishmentReason } from './types';

export const formatAbolishmentData = (data: AbolishmentForm): AbolishmentRequestData => {
  const { landlord, tenant, broker, emailConsent, depositDistribution } = data;
  let { abolishmentReason, deceasedPersonReference } = data;

  abolishmentReason =
    abolishmentReason === 'onlyTenantHasDied' ? 'allTenantsDied' : abolishmentReason;

  deceasedPersonReference =
    abolishmentReason === 'oneOfTenantsDied' ? deceasedPersonReference : undefined;

  const cleanedLandlord = cleanObject(landlord);
  const cleanedTenant = { emailConsent, ...cleanObject(tenant) };
  const cleanedBroker = cleanObject(broker);

  return {
    abolishmentReason,
    depositDistribution,
    deceasedPersonReference,
    landlord:
      isTruthyValueInObject(cleanedLandlord) &&
      depositDistribution !== 'depositTenant' &&
      depositDistribution !== 'depositUnknown'
        ? cleanedLandlord
        : null,
    tenant:
      isTruthyValueInObject(cleanedTenant) && depositDistribution !== 'depositLandlord'
        ? cleanedTenant
        : null,
    broker:
      isTruthyValueInObject(cleanedBroker) && depositDistribution === 'depositDivided'
        ? cleanedBroker
        : null,
  };
};

export const formatAbolishmentValues = (
  data: RentalDepositData | null,
  reason: AbolishmentReason | null,
): Partial<AbolishmentForm> => {
  const tenantEmail = (data?.tenantRepresentative ?? data?.tenant)?.email;

  const tenant = {
    email: reason !== 'allTenantsDied' ? tenantEmail : null,
    accountNumber: {
      iban: reason !== 'allTenantsDied' ? data?.tenant?.iban ?? null : null,
      bic: reason !== 'allTenantsDied' ? data?.tenant?.bic ?? null : null,
    },
  };

  const landlord = {
    accountNumber: {
      iban: reason !== 'landlordDied' ? data?.landlord?.iban ?? null : null,
      bic: reason !== 'landlordDied' ? data?.landlord?.bic ?? null : null,
    },
  };

  return { tenant, landlord };
};
