import { getAddressDisplayValues, getFormAddress } from 'formData/common/address/utils';
import { legalPersonSchema } from 'formData/legalPerson';
import { Language } from 'types/language';
import { formatVatWithMask, vatToString } from 'utils/vat';
import { LegalPersonData, LegalPersonFormData } from 'api/person/legal/types';

export const getLegalPersonDefaultValues = ({
  referenceNumber: _referenceNumber,
  address,
  vat,
  ...initialValues
}: LegalPersonData): LegalPersonFormData => ({
  ...legalPersonSchema.getDefault(),
  ...initialValues,
  address: getFormAddress(address),
  vat: vat ? formatVatWithMask(vat) : null,
});

export const getLegalPersonDisplayData = (
  {
    referenceNumber: _referenceNumber,
    address,
    vat,
    iban,
    bic,
    ...general
  }: LegalPersonData,
  lng: Language,
) => ({
  general,
  address: getAddressDisplayValues(address, lng),
  details: {
    vat: vat ? vatToString(vat) : null,
    iban,
    bic,
  },
});
