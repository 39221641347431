import yup, { numberSchema } from 'utils/yup';

const taxPayableByTenantSchema = yup
  .object({
    value: numberSchema
      .label('value')
      .nullable()
      .default(null)
      .customWhen({
        is: ({ isTaxPayableByTenant }) => isTaxPayableByTenant,
        then: (schema) => schema.required(),
      })
      .when('unit', {
        is: 'Percent',
        then: (schema) => schema.max(100),
      }),
    unit: yup.string().default('EUR'),
  })
  .from('currency', 'unit')
  .when('isTaxPayableByTenant', {
    is: true,
    then: (schema) => schema.transform((value) => value ?? { value: null, unit: 'EUR' }),
    otherwise: (schema) => schema.nullable().transform(() => null),
  });

export default taxPayableByTenantSchema;
