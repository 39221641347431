import { DocumentConfigData, DocumentInputs } from 'api/documents/types';
import { InputField } from 'components/controlledInputs/types';

export const getDocumentFields = (
  documentConfigData: DocumentConfigData | undefined,
): InputField<Omit<DocumentInputs, 'documentType'>>[] => [
  {
    name: 'documentName',
  },
  {
    name: 'date',
    type: 'date',
    dateInputProps: documentConfigData
      ? {
          disableFuture: documentConfigData.date?.type === 'Past',
          disablePast: documentConfigData.date?.type === 'Future',
        }
      : undefined,
  },
];
