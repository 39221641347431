import { useTranslation } from 'react-i18next';
import { formatAddressOnEstateInfo } from 'utils/addressToString/addressToString';
import { EstateInDossier } from 'api/dossier/types';
import ProductActionInfoRow from './ProductActionInfoRow';

interface Props {
  estate: EstateInDossier;
}

const ProductActionEstateInfo = ({ estate }: Props) => {
  const { t } = useTranslation('modals');

  return (
    <ProductActionInfoRow
      title={t('data.estateAddress')}
      value={formatAddressOnEstateInfo(estate)}
    />
  );
};

export default ProductActionEstateInfo;
