import { useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import { AccountContext } from 'contexts/accountContext/AccountContext';
import Table from 'templates/Table';
import { getBusinesses, migrateBusinesses } from 'api/businesses/requests';
import PageTitle from 'components/PageTitle';
import BusinessesToolbarButtons from './BusinessesToolbarButtons';
import ImpersonateAction from './ImpersonateAction';
import { businessesColumns } from './static-data';
import useBusinessSelection from './useBusinessSelection';

const BusinessesList = () => {
  const { businessAction, onRowSelection, isRowSelectable, setBusinessAction } =
    useBusinessSelection();

  const { roles } = useContext(AccountContext);

  const columns: typeof businessesColumns = useMemo(
    () =>
      roles.includes('SuperAdmin')
        ? [
            ...businessesColumns,
            {
              field: 'impersonate',
              flex: 2,
              renderCell: ({ row: { migrated, guid } }) =>
                migrated ? <ImpersonateAction id={guid} /> : null,
            },
          ]
        : businessesColumns,
    [businessesColumns],
  );
  return (
    <Box sx={({ mixins }) => mixins.tablePageWrapper}>
      <PageTitle page="businesses" />
      <Table
        tKey="businesses"
        rowIdKey="guid"
        get={getBusinesses}
        rowsPerPage={[10, 25, 50, 100]}
        withUrlSearchParams
        columns={columns}
        toolbarProps={{
          Component: !businessAction
            ? () => <BusinessesToolbarButtons setBusinessAction={setBusinessAction} />
            : undefined,
          rowSelection: {
            disableMultipleRowSelection: !businessAction,
            hideButton: !businessAction,
            buttonLabel:
              businessAction === 'migrateBusinesses'
                ? 'businesses.header.migrateSelected'
                : 'businesses.header.migrateDataForSelected',
            buttonRole: 'cta',
            handleSelectedRows: async (
              rowsIds,
              { rows, setUpAndOpenConfirmationModal, updateRows },
            ) =>
              setUpAndOpenConfirmationModal({
                type: 'confirmation',
                translationKey: 'migrateBusinesses',
                translationValues: { count: rowsIds.length, name: rows.at(0)?.name },
                onSubmit: () => migrateBusinesses({ idsToMigrate: rowsIds.map(String) }),
                onSuccess: updateRows,
              }),
            onRowSelection,
            isRowSelectable,
          },
        }}
      />
    </Box>
  );
};

export default BusinessesList;
