import IBAN from 'iban';
import { firstCharUpperCase } from 'utils/string';
import { MissingField } from 'api/missingData/types';

export const sortFields = <T extends object, D extends { name: keyof T }>(
  data: D[],
  order: Record<keyof T, number>,
) => data.sort((a, b) => (order[a.name] > order[b.name] ? 1 : -1));

export const isRequiredInMissingFields = <T extends object>(
  fields: MissingField[],
  name: keyof T,
) => !!fields?.find((field) => field?.required && name === field?.propertyName);

export const firstLetterUppercaseInputConfig = {
  mask: (value: string) => new Array(value.length).fill(/./),
  pipe: (value: string) => value && firstCharUpperCase(value),
};

export const ibanConfig = {
  mask: (value: string) => {
    const code = value.slice(0, 2).toUpperCase();
    const { example } = IBAN.countries[code] ?? {};
    if (example) {
      return IBAN.printFormat(example)
        .split('')
        .map((letter) => {
          if (letter.match(/[a-zA-Z0-9]/)) {
            return /[a-zA-Z0-9]/;
          }
          return letter;
        });
    }
    return new Array(value.length).fill(/./);
  },
  pipe: (value: string) => value.toUpperCase(),
};
