import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import TooltipIconButton from 'atoms/TooltipIconButton';
import { DeleteIcon } from 'icons';
import { ActionProps } from '../types';

const Action = ({ onClick, Icon = DeleteIcon, disabled, reason }: ActionProps) => {
  const { t } = useTranslation('table');

  return disabled && reason ? (
    <TooltipIconButton
      tooltipTitle={t(`dossierPerson.tooltips.${reason}`, reason)}
      onClick={onClick}
      disabled={disabled}
      Icon={<Icon />}
    />
  ) : (
    <IconButton onClick={onClick} disabled={disabled}>
      <Icon />
    </IconButton>
  );
};

export default Action;
