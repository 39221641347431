import { transformFormAddress } from 'formData/common/address/utils';
import { LegalPersonData, LegalPersonFormData } from './types';

export const transformData = ({
  address,
  ...data
}: LegalPersonFormData): Omit<LegalPersonData, 'referenceNumber'> => ({
  ...data,
  address: transformFormAddress(address),
});
