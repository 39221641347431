import { BelgiumCountry } from 'formData/common/address/static-data';
import getLng from 'utils/getLng';
import { testStringToJSON } from 'utils/string';
import { AddressFormInterface } from 'api/common/types';
import { Country, PostCodesWithCities } from 'api/config/types';
import { SelectOption } from 'components/inputs/types';
import { PostCodeAndCityOption } from './types';

export const translateCity = (city: string, lng = getLng()): string =>
  testStringToJSON(city) ? JSON.parse(city)[lng] : city;

export const createCityOption = ({
  city,
  postCode,
}: PostCodesWithCities): PostCodeAndCityOption => {
  return {
    label: postCode && city ? `${translateCity(city)}, ${postCode}` : translateCity(city),
    value: city,
    postCode,
    city,
    translatedCity: translateCity(city),
  };
};

export const createPostCodeOption = ({
  city,
  postCode,
}: PostCodesWithCities): PostCodeAndCityOption => ({
  label: city && postCode ? `${postCode}, ${translateCity(city)}` : postCode,
  value: postCode,
  postCode,
  city,
  translatedCity: translateCity(city),
});

export const createCountryOption = (country: Country): SelectOption => ({
  label: country.name[getLng()],
  value: JSON.stringify(country.name),
  country,
});

export const getInitValues = (defaultAddress: AddressFormInterface | undefined) => {
  const { country = '', city = '', postCode = '' } = defaultAddress ?? {};

  const initIsBelgium = country === JSON.stringify(BelgiumCountry.name);
  const initCityValue = city ? createCityOption({ postCode, city }) : null;
  const initPostCodeValue = postCode ? createPostCodeOption({ postCode, city }) : null;
  const initParams = { city: translateCity(city, 'en'), postCode };

  return { initIsBelgium, initCityValue, initPostCodeValue, initParams };
};
