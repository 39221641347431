import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { legalPersonGeneralFields } from 'formData/legalPerson/fields';
import useLegalPersonDetailsFields from 'hooks/useLegalPersonDetailsFields';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import {
  FormContainer,
  FormSection,
  FormSide,
  FormVerticalDivider,
} from 'templates/FormTemplates';
import { Country } from 'api/config/types';
import { LegalPersonFormData } from 'api/person/legal/types';
import { ControlledInput } from 'components/controlledInputs';

export const GeneralStep = () => {
  const { t } = useTranslation();
  const methods = useFormContext<LegalPersonFormData>();
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = methods;

  const onCountryChange = (country?: Country) => {
    const vatValue = getValues('vat');
    if (vatValue && !vatValue.number) {
      setValue('vat', {
        ...vatValue,
        prefix: country?.vatPrefix || 'BE',
      });
    }
  };

  const legalPersonDetailsFields = useLegalPersonDetailsFields(methods);

  return (
    <FormContainer maxWidth={1024}>
      <FormSide>
        <FormSection label={t('stepWizard.legalPerson.sections.basicInformation')}>
          {legalPersonGeneralFields.map(({ name, testId, ...field }) => (
            <Grid item xs={12} sm={6} key={name}>
              <ControlledInput
                name={name}
                label={t(name, { ns: 'formFields' })}
                control={control}
                error={errors[name]}
                data-testid={testId}
                {...field}
              />
            </Grid>
          ))}
        </FormSection>
        <FormSection label={t('stepWizard.legalPerson.sections.address')}>
          <AddressFieldsWithGoogle onCountryChange={onCountryChange} />
        </FormSection>
      </FormSide>
      <FormVerticalDivider />
      <FormSide>
        <FormSection label={t('stepWizard.legalPerson.sections.details')}>
          {legalPersonDetailsFields.map(({ name, label, sizes, ...field }) => (
            <Grid item xs={12} sm={sizes?.sm ?? 6} md={sizes?.md ?? 6} key={name}>
              <ControlledInput
                name={name}
                control={control}
                label={t(label ?? String(name), name, { ns: 'formFields' })}
                error={errors[name]}
                {...field}
              />
            </Grid>
          ))}
        </FormSection>
      </FormSide>
    </FormContainer>
  );
};
