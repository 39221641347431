import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ErrorElement from 'atoms/ErrorElement';
import LoadingElement from 'atoms/LoadingElement';
import { LoadersContext } from 'contexts/loaders/loadersContext';
import { LoaderData } from 'contexts/loaders/types';
import { useUpdateEffect } from 'usehooks-ts';
import useCustomNavigate from '../useCustomNavigate';
import { LoaderConfig } from './types';

export const useLoader = <T extends object>({
  id,
  scope,
  fetch,
  redirectUrl,
  shouldRedirect,
  withContext = true,
}: LoaderConfig<T>) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { setLoaderData } = useContext(LoadersContext);
  const { pathname } = useLocation();
  const navigate = useCustomNavigate();
  const { t, i18n } = useTranslation('errors', { keyPrefix: 'routes' });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const { ok, response, status } = await fetch(id);
    if (ok) {
      if (shouldRedirect && shouldRedirect(pathname, response) && redirectUrl) {
        navigate(redirectUrl, { replace: true });
      }
      setError(null);
      setData(response);
      if (withContext) {
        setLoaderData({ scope, data: response, id } as LoaderData);
      }
    } else {
      setError(t(`${scope}.${String(status)}`, t(`${scope}.unspecific`)));
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback(async () => {
    const { ok, response } = await fetch(id);
    if (ok) {
      setData(response);
    }
  }, [id]);

  useUpdateEffect(() => {
    updateData();
  }, [i18n.language]);

  const Loader = useMemo(
    () =>
      ({ children }: PropsWithChildren) => {
        if (error) {
          return <ErrorElement error={error} />;
        } else if (loading) {
          return <LoadingElement />;
        }
        return <>{children}</>;
      },
    [error, loading],
  );

  return { data, updateData, Loader };
};
