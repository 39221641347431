import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import Button from 'atoms/Button';
import { FetchedBusinessListItem } from 'api/businesses/types';
import { BusinessAction } from './types';

interface Props {
  setBusinessAction: (action: BusinessAction) => void;
}

const BusinessesToolbarButtons = ({ setBusinessAction }: Props) => {
  const { t } = useTranslation('table', { keyPrefix: 'businesses.header' });
  const apiRef = useGridApiContext();

  const handleAction = (action: BusinessAction) => () => {
    setBusinessAction(action);

    const rows = Object.values(
      apiRef.current.state.rows.dataRowIdToModelLookup,
    ) as FetchedBusinessListItem[];

    const filteredRows = rows.filter(({ migrated }) =>
      action === 'migrateBusinesses' ? !migrated : migrated,
    );

    // Table must be updated first to enable multiple row selection
    setTimeout(() => {
      apiRef.current.setRowSelectionModel(filteredRows.map(({ guid }) => guid));
    }, 0);
  };

  return (
    <Box display="flex" gap={2}>
      <Button onClick={handleAction('migrateBusinesses')}>
        {t('selectAllToMigrate')}
      </Button>
      {/* <Button onClick={handleAction('migrateBusinessesData')}>
        {t('selectAllToMigrateData')}
      </Button> */}
    </Box>
  );
};

export default BusinessesToolbarButtons;
