import { UserPermissions } from 'consts/permissions';
import hasPermission from 'utils/hasPermission';
import { MainCardAction } from 'components/MainCardHeader/types';

export const getUserActions = (
  userId: string,
  userName: string,
  toggleEditing: () => void,
): MainCardAction[] => [
  {
    name: 'edit',
    hidden: !hasPermission(UserPermissions.Write),
    onClick: toggleEditing,
  },
  // {
  //   name: 'deleteUser',
  //   hidden: !hasPermission(UserPermissions.Write),
  //   onClick: ({ setUpAndOpenConfirmationModal, navigate }) => {
  //     setUpAndOpenConfirmationModal({
  //       translationKey: 'user',
  //       url: apiUrl.deleteUser(userId),
  //       onSuccess: () => navigate(routes.users),
  //       translationValues: { userName },
  //     });
  //   },
  // },
];
