import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import addressToString from 'utils/addressToString';
import { apiUrl } from 'api/const';
import { EstateData } from 'api/estate/types';

interface Props {
  estateId: string;
  data: EstateData;
}

const EstateViewHeader = ({ estateId, data }: Props) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const onCopy = () => {
    navigate(routes.newEstate, { state: { estateId } });
  };

  const onDelete = () =>
    setUpAndOpenConfirmationModal({
      translationKey: 'estates',
      translationValues: {
        estateAddress: addressToString(data.address, { isFullAddress: false }),
      },
      url: apiUrl.estate(estateId),
      errorMessage: { status: 409, text: 'estates.409' },
      onSuccess: () => {
        navigate(routes.estates);
      },
    });

  return (
    <Box sx={({ mixins }) => mixins.entityViewHeader}>
      <Typography variant="h5" color="primary.main" mr="auto">
        {data.referenceNumber}
      </Typography>
      <Button onClick={onCopy} variant="outlined" size="small">
        {t('copy')}
      </Button>
      <Button onClick={onDelete} role="unsafe" variant="outlined" size="small">
        {t('delete')}
      </Button>
    </Box>
  );
};

export default EstateViewHeader;
