import common from './common.json';
import documents from './documents.json';
import dossierProducts from './dossierProducts.json';
import enums from './enums.json';
import errors from './errors.json';
import formFields from './formFields.json';
import modals from './modals.json';
import notifications from './notifications.json';
import table from './table.json';
import validation from './validation.json';

const locales = {
  common,
  validation,
  formFields,
  enums,
  errors,
  table,
  modals,
  dossierProducts,
  notifications,
  documents,
};

export default locales;
