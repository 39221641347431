import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import useElectricityFields from 'hooks/useElectricityFields';
import { getOnInputChange } from 'modules/EstateForm/utils';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { isTruthyValueInObject } from 'utils/object';
import { EstateDetailsForm } from 'api/estate/types';
import { ControlledInput } from 'components/controlledInputs';

export const ElectricityEdition = () => {
  const { t } = useTranslation('formFields');

  const {
    control,
    formState: { errors, isSubmitted },
    getValues,
    trigger,
  } = useFormContext<EstateDetailsForm>();

  const { fields, onCheckboxChange, updateCheckbox } = useElectricityFields();

  const initiallyExpanded = !!(
    getValues('hasElectricity') && isTruthyValueInObject(getValues('electricity'))
  );

  return (
    <Stack direction="row" spacing={1}>
      <AccordionWithCheckbox
        control={control}
        error={errors.electricity as FieldError}
        name="hasElectricity"
        label={t('electricity.self')}
        onChange={onCheckboxChange}
        initiallyExpanded={initiallyExpanded}
      >
        <Stack spacing={1}>
          {fields.map(({ name, hidden, suffix, ...field }) =>
            !hidden ? (
              <InputLabelWrapper key={name} label={t(`electricity.${name}`, { suffix })}>
                <ControlledInput
                  control={control}
                  name={`electricity.${name}`}
                  error={errors.electricity?.[name] as FieldError}
                  onInputChange={getOnInputChange({
                    fieldName: name,
                    isSubmitted,
                    trigger,
                    typeToTrigger: 'gas',
                    updateCheckbox,
                  })}
                  onSelectChange={updateCheckbox}
                  hideHelperTextGap
                  {...field}
                />
              </InputLabelWrapper>
            ) : null,
          )}
        </Stack>
      </AccordionWithCheckbox>
    </Stack>
  );
};
