import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { legalPersonGeneralFields } from 'formData/legalPerson/fields';
import useLegalPersonDetailsFields from 'hooks/useLegalPersonDetailsFields';
import usePrompt from 'hooks/usePrompt';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import routes, { personTabRoutes } from 'router/routes';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { Country } from 'api/config/types';
import { LegalPersonFormData } from 'api/person/legal/types';
import { ControlledInput } from 'components/controlledInputs';

const pathnamesToExclude = Object.values(personTabRoutes).map(
  (route) => `${routes.naturalPerson(':personId')}/${route}`,
);

const LegalPersonDetailsEdition = () => {
  const { t } = useTranslation('formFields');
  const methods = useFormContext<LegalPersonFormData>();
  const {
    control,
    formState: { errors, isDirty },
    setValue,
    getValues,
  } = methods;

  usePrompt({ when: isDirty, id: 'legalPersonDetails', pathnamesToExclude });

  const onCountryChange = (country?: Country) => {
    const vatValue = getValues('vat');
    if (vatValue && !vatValue.number) {
      setValue('vat', {
        ...vatValue,
        prefix: country?.vatPrefix || 'BE',
      });
    }
  };

  const legalPersonDetailsFields = useLegalPersonDetailsFields(methods);

  return (
    <Stack spacing={4} py={2.5}>
      <Stack spacing={1}>
        <Stack spacing={1} px={2.5}>
          {legalPersonGeneralFields.map(({ name, testId, ...field }) => (
            <InputLabelWrapper label={t(name)} key={name}>
              <ControlledInput
                name={name}
                control={control}
                error={errors[name]}
                hideHelperTextGap
                data-testid={testId}
                {...field}
              />
            </InputLabelWrapper>
          ))}
        </Stack>
      </Stack>
      <Stack spacing={1} px={2.5}>
        <AddressFieldsWithGoogle separateLabel onCountryChange={onCountryChange} />
      </Stack>
      <Stack spacing={1} px={2.5}>
        {legalPersonDetailsFields.map(({ name, label, ...field }) => (
          <InputLabelWrapper label={t(label ?? String(name), name)} key={name}>
            <ControlledInput
              name={name}
              control={control}
              error={errors[name]}
              hideHelperTextGap
              {...field}
            />
          </InputLabelWrapper>
        ))}
      </Stack>
    </Stack>
  );
};

export default LegalPersonDetailsEdition;
