import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EstatePermissions } from 'consts/permissions';
import { estateDetailsSchema } from 'formData/estate';
import PageCard from 'templates/PageCard';
import hasPermission from 'utils/hasPermission';
import isTruthyValueInObject from 'utils/object/isTruthyValueInObject';
import { resolver } from 'utils/yup';
import { updateEstateDetails } from 'api/estate/requests';
import { EstateDetailsForm } from 'api/estate/types';
import { EstateDetailsCardProps } from '../types';
import { getEstateDetailsDefaultValues } from '../utils';
import { CompositionContent } from './composition/CompositionContent';
import { CompositionEdition } from './composition/CompositionEdition';
import EstateDetailsContent from './EstateDetailsContent';
import { EstateDetailsEdition } from './EstateDetailsEdition';

//TODO: Create hook adn create two separate components

const EstateDetailsCard = ({
  estateDetails,
  fetchEstateDetails,
  type,
}: EstateDetailsCardProps) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const defaultValues = useMemo(
    () => getEstateDetailsDefaultValues(estateDetails),
    [estateDetails],
  );

  const methods = useForm<EstateDetailsForm>({
    defaultValues,
    resolver: resolver(estateDetailsSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const { estateId } = useParams();
  if (!estateId) return null;

  // Without it defaultValues are not updated with data from API
  useEffect(() => {
    if (estateDetails) {
      reset(defaultValues);
    }
  }, [estateDetails]);

  const toggleEditing = () => setEditing((prev) => !prev);

  const submitAction: SubmitHandler<EstateDetailsForm> = async (data) => {
    setSaving(true);
    const { ok } = await updateEstateDetails(estateId, data);
    if (ok) {
      await fetchEstateDetails();
      toggleEditing();
      reset(data);
    }
    setSaving(false);
  };

  const cancelEditing = () => {
    reset();
    toggleEditing();
  };

  const { isContent, Content, Edition } = useMemo(() => {
    const { descriptions: _descriptions, estateComposition, ...details } = defaultValues;
    switch (type) {
      case 'composition':
        return {
          isContent: editing || isTruthyValueInObject(estateComposition),
          Content: <CompositionContent estateComposition={estateComposition} />,
          Edition: <CompositionEdition />,
        };
      default:
        return {
          isContent: editing || isTruthyValueInObject(details),
          Content: <EstateDetailsContent {...details} />,
          Edition: <EstateDetailsEdition />,
        };
    }
  }, [editing, defaultValues]);

  return (
    <PageCard
      blank={!isContent}
      tKey={type === 'details' ? 'estateDetails' : 'estateComposition'}
      headerProps={{
        editionProps: {
          isEditing: editing,
          hidden: !hasPermission(EstatePermissions.Write),
          onEdit: toggleEditing,
          onSave: handleSubmit(submitAction),
          saving: saving,
          saveDisabled: !isDirty,
          onCancel: cancelEditing,
        },
      }}
    >
      {editing ? <FormProvider {...methods}>{Edition}</FormProvider> : Content}
    </PageCard>
  );
};

export default EstateDetailsCard;
