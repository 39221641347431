import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { legalPersonGeneralFields } from 'formData/legalPerson/fields';
import useAddressFields from 'hooks/useAddressFields';
import useLegalPersonDetailsFields from 'hooks/useLegalPersonDetailsFields';
import AddressFields from 'modules/AddressFields';
import routes from 'router/routes';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { LegalPersonFormData } from 'api/person/legal/types';
import { ControlledInput } from 'components/controlledInputs';
import MissingRequiredFieldsError from './MissingRequiredFieldsError';
import { LegalPersonMissingDataFormProps } from './types';
import {
  getMissingRequiredFields,
  getRequiredAndNotRequiredFields,
  isAnyAddressFieldMissing,
} from './utils';

const LegalPersonMissingDataForm = ({
  id,
  index,
  fields,
  methods,
}: LegalPersonMissingDataFormProps) => {
  const { control, formState } = methods;
  const { t } = useTranslation('formFields');

  const prefix = useMemo(() => `legalPersons.${index}` as const, [index]);
  const errors = useMemo(
    () => formState.errors.legalPersons?.[index],
    [formState.errors.legalPersons, index],
  );

  const missingImportantFields = getMissingRequiredFields(errors, fields);
  const legalPersonDetailsFields = useLegalPersonDetailsFields(methods, prefix);

  const { requiredFields, notRequiredFields } = useMemo(
    () =>
      getRequiredAndNotRequiredFields<LegalPersonFormData>(fields, [
        ...legalPersonGeneralFields,
        ...legalPersonDetailsFields,
      ]),
    [fields, legalPersonDetailsFields],
  );

  const addressFields = useAddressFields({ prefix });

  const displayAddressFields = isAnyAddressFieldMissing(fields, addressFields);

  const name = useMemo(
    () => formState.defaultValues?.legalPersons?.[index]?.name,
    [formState.defaultValues?.legalPersons?.[index]?.name],
  );

  return (
    <Stack spacing={1} data-testid={id}>
      <Typography fontWeight="bold">{name}</Typography>
      <FormProvider {...methods}>
        <Stack spacing={1}>
          {!!requiredFields.length &&
            requiredFields.map(({ name, label, ...field }) => (
              <InputLabelWrapper label={`${t(label ?? String(name), name)}*`} key={name}>
                <ControlledInput
                  name={`${prefix}.${name}`}
                  control={control}
                  error={errors?.[name]}
                  hideHelperTextGap
                  {...field}
                />
              </InputLabelWrapper>
            ))}
          {displayAddressFields && (
            <AddressFields
              separateLabel
              fields={addressFields}
              prefix={prefix}
              withAsterisk
            />
          )}
          {!!notRequiredFields.length &&
            notRequiredFields.map(({ name, label, ...field }) => (
              <InputLabelWrapper label={t(label ?? String(name), name)} key={name}>
                <ControlledInput
                  name={`${prefix}.${name}`}
                  control={control}
                  error={errors?.[name]}
                  hideHelperTextGap
                  {...field}
                />
              </InputLabelWrapper>
            ))}
        </Stack>
      </FormProvider>
      {missingImportantFields.length ? (
        <MissingRequiredFieldsError
          tKey="missingPersonRequiredFields"
          to={routes.legalPersonTab(id).details}
        />
      ) : null}
    </Stack>
  );
};

export default LegalPersonMissingDataForm;
