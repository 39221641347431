import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Button from 'atoms/Button';
import LoadingElement from 'atoms/LoadingElement';
import AddNewDossierCard from 'modules/AddNewDossierCard';
import AssignedDossierCard from 'modules/AssignedDossiersCard';
import { sortModelToString } from 'utils/sortUtils';
import { SortDirection } from 'utils/sortUtils/types';
import { getDossiers } from 'api/dossier/requests';
import AssignedDossiersActiveFilters from './AssignedDossiersActiveFilters';
import AssignedDossiersToolbar from './AssignedDossiersToolbar';
import { AssignedDossier, AssignedDossiersGridProps } from './types';
import { getAssignedDossierData } from './utils';

const INIT_DOSSIERS_LIMIT = 11;

const AssignedDossiersGrid = ({ entityId, type }: AssignedDossiersGridProps) => {
  const [dossiers, setDossiers] = useState<AssignedDossier[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [loadingRemaining, setLoadingRemaining] = useState(false);

  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<SortDirection | ''>('');

  const { i18n, t } = useTranslation();

  const getDossiersList = useCallback(
    async (limit = INIT_DOSSIERS_LIMIT) => {
      const { ok, response } = await getDossiers({
        limit,
        [type === 'Person' ? 'personsIds' : 'estateIds']: entityId,
        sort: sortModelToString({ field: 'createdAt', direction: sortBy || null }),
        statuses: filterStatuses.join(','),
      });
      if (ok) {
        setDossiers(getAssignedDossierData(response));
        setTotalCount(response.meta.total);
      }
    },
    [entityId, i18n.language, sortBy, filterStatuses],
  );

  useEffect(() => {
    const fetchDossiers = async () => {
      setLoading(true);
      await getDossiersList();
      setLoading(false);
    };
    fetchDossiers();
  }, [getDossiersList]);

  const loadRemainingDossiers = async () => {
    setLoadingRemaining(true);
    await getDossiersList(totalCount);
    setLoadingRemaining(false);
  };

  const handleRemoveStatusFilter = (filterStatus: string) => {
    setFilterStatuses((prev) => prev.filter((status) => status !== filterStatus));
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <AssignedDossiersToolbar
        sortBy={sortBy}
        setSortBy={setSortBy}
        filterStatuses={filterStatuses}
        setFilterStatuses={setFilterStatuses}
      />
      <AssignedDossiersActiveFilters
        filterStatuses={filterStatuses}
        handleRemoveFilter={handleRemoveStatusFilter}
      />
      {!loading ? (
        <Box
          display="grid"
          gap={3}
          gridTemplateColumns="repeat(auto-fit, minmax(350px, 1fr))"
        >
          <AddNewDossierCard entityId={entityId} type={type} />
          {dossiers.map((dossier) => (
            <AssignedDossierCard
              key={dossier.id}
              entityId={entityId}
              type={type}
              {...dossier}
            />
          ))}
        </Box>
      ) : (
        <LoadingElement />
      )}
      {totalCount > dossiers.length && (
        <Box display="flex" width="100%" justifyContent="center" mt={3}>
          {loadingRemaining ? (
            <LoadingElement />
          ) : (
            <Button role="text" color="secondary" onClick={loadRemainingDossiers}>
              {t('assignedDossierCard.showMore', { count: totalCount - dossiers.length })}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AssignedDossiersGrid;
