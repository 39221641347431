import { LoaderConfig } from 'hooks/useLoader/types';
import routes from 'router/routes';
import { getNaturalPerson } from 'api/person/natural/requests';
import { FetchedNaturalPersonData, NaturalPersonData } from 'api/person/natural/types';

export const getNaturalPersonData = (
  data: FetchedNaturalPersonData | null,
): NaturalPersonData => {
  const { businessId: _businessId, id: _id, ...initialValues } = data ?? {};
  return initialValues as NaturalPersonData;
};

export const getNaturalPersonConfig = (
  naturalPersonId: string,
): LoaderConfig<FetchedNaturalPersonData> => ({
  id: naturalPersonId,
  scope: 'naturalPerson',
  fetch: getNaturalPerson,
  shouldRedirect: (pathname) => pathname === routes.naturalPerson(naturalPersonId),
  redirectUrl: routes.naturalPersonTab(naturalPersonId).details,
});
