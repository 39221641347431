import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { estateDetailsFields } from 'formData/estate/details/fields';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { ControlledInput } from 'components/controlledInputs';
import ConformityField from './ConformityField';
import ElectricityFields from './ElectricityFields';
import EpcFields from './EpcFields';
import GasFields from './GasFields';
import SyndicField from './SyndicField';

const DetailsStep = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EstateFormInterface>();
  const { t } = useTranslation('formFields');

  return (
    <Grid container rowSpacing={4} width="100%" maxWidth={750}>
      <Grid item container rowSpacing={0.5} columnSpacing={2} marginBottom="auto">
        {estateDetailsFields.map(({ name, multiline, ...field }) => (
          <Grid key={name} item xs={multiline ? 12 : 6}>
            <ControlledInput
              name={`estateDetails.${name}`}
              label={t(name, name)}
              control={control}
              error={errors?.estateDetails?.[name] as FieldError}
              multiline={multiline}
              {...field}
            />
          </Grid>
        ))}
        <Grid item container rowSpacing={2}>
          <ConformityField />
          <SyndicField />
          <ElectricityFields />
          <GasFields />
          <EpcFields />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsStep;
