import { TKey } from 'i18next';
import yup from 'utils/yup';
import eanNumberSchema from '../eanNumber/schema';

const electricitySchema = yup
  .object({
    type: yup
      .string<TKey<'enums', 'estateElectricityTypes'>>()
      .label('electricity.type')
      .nullable()
      .default(null)
      .customWhen({
        is: (form) => Boolean(form.hasElectricity),
        then: (schema) => schema.required(),
      }),
    eanNumber: eanNumberSchema.label('electricity.eanNumber').customWhen({
      is: (form, parent) => parent?.eanNumber && parent.eanNumber === form.gas?.eanNumber,
      then: (schema) => schema.invalid('eanNumber.sameGasAndElectricity'),
    }),
    meterNumber: yup
      .string()
      .label('electricity.meterNumber')
      .nullable()
      .default(null)
      .customWhen({
        is: (_, parent) =>
          parent?.meterNumber && parent.meterNumber === parent.meterNumber2,
        then: (schema) => schema.invalid('meterNumber.sameNumbers'),
      })
      .customWhen({
        is: (form, parent) =>
          parent?.meterNumber && parent.meterNumber === form.gas?.meterNumber,
        then: (schema) => schema.invalid('meterNumber.sameGasAndElectricity'),
      }),
    meterNumber2: yup
      .string()
      .label('electricity.meterNumber2')
      .nullable()
      .when('type', {
        is: (type: TKey<'enums', 'estateElectricityTypes'>) =>
          type !== 'Double' && type !== 'DoubleExclusiveNight',
        then: (schema) => schema.transform(() => null),
      })
      .customWhen({
        is: (_, parent) =>
          parent?.meterNumber && parent?.meterNumber === parent?.meterNumber2,
        then: (schema) => schema.invalid('meterNumber.sameNumbers'),
      })
      .customWhen({
        is: (form, parent) =>
          parent?.meterNumber2 && parent?.meterNumber2 === form.gas?.meterNumber,
        then: (schema) => schema.invalid('meterNumber.sameGasAndElectricity'),
      }),
  })
  .nullable()
  .when('hasElectricity', { is: false, then: (schema) => schema.transform(() => null) });

export default electricitySchema;
