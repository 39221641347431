import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, Stack, Typography } from '@mui/material';
import { CustomLink } from 'atoms/Link/CustomLink';
import { TooltipMenuItem } from 'atoms/TooltipMenuItem/TooltipMenuItem';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useMenu from 'hooks/useMenu';
import { KebabMenuIcon } from 'icons';
import CardEditionButtons from 'components/CardEditionButtons';
import { MainCardHeaderProps } from './types';

export const MainCardHeader = ({
  title,
  titleLink,
  subtitle,
  actions,
  withBorder,
  edition,
  titleIcon,
}: MainCardHeaderProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'mainCardActions' });
  const navigate = useCustomNavigate();
  const { anchorEl, closeMenu, openMenu } = useMenu();
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  return (
    <Stack
      px={2.5}
      pt={2.5}
      pb={1.5}
      sx={withBorder ? { borderBottom: 1, borderColor: 'divider' } : {}}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={1.5} justifyContent="center" alignItems="center">
          {titleIcon}
          <Stack>
            <Typography
              variant="h4"
              sx={({ palette }) => ({
                wordBreak: 'break-word',
                a: { color: palette.primary.main, textDecoration: 'none' },
              })}
            >
              {titleLink ? <CustomLink to={titleLink}>{title}</CustomLink> : title}
            </Typography>
            <Typography>{subtitle}</Typography>
          </Stack>
        </Stack>
        {edition?.editing ? (
          <CardEditionButtons {...edition} variant="light" />
        ) : (
          !!actions?.length &&
          actions.some(({ hidden }) => !hidden) && (
            <>
              <IconButton
                onClick={openMenu}
                size="small"
                data-testid="main-card-action-button"
              >
                <KebabMenuIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                {actions
                  .filter(({ hidden }) => !hidden)
                  .map(({ name, onClick, disabled }) => (
                    <TooltipMenuItem
                      key={name}
                      data-testid={name}
                      disabled={disabled}
                      onClick={() => {
                        closeMenu();
                        onClick({ navigate, setUpAndOpenConfirmationModal });
                      }}
                    >
                      {t(name)}
                    </TooltipMenuItem>
                  ))}
              </Menu>
            </>
          )
        )}
      </Stack>
    </Stack>
  );
};
