import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PersonPermissions } from 'consts/permissions';
import { EstateCard } from 'modules/OwnershipCard';
import hasPermission from 'utils/hasPermission';
import { NaturalPersonOutletContext } from '../types';

const NaturalPersonEstatesTab = () => {
  const { naturalPersonId, privateRelation } =
    useOutletContext<NaturalPersonOutletContext>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <EstateCard
          personId={naturalPersonId}
          personType="NaturalPerson"
          editable={hasPermission(PersonPermissions.Write)}
          privateRelation={privateRelation}
        />
      </Grid>
    </Grid>
  );
};

export default NaturalPersonEstatesTab;
