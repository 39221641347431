import { createContext, PropsWithChildren } from 'react';
import useUploadPhoto from 'hooks/useUploadPhoto';
import { UploadPhotoContextInterface } from './types';

const UploadPhotoContext = createContext<UploadPhotoContextInterface>({
  fileDataUrl: null,
  setFileDataUrl: () => {},
  uploadedFile: null,
  setUploadedFile: () => {},
  uploadPhoto: async () => true,
  clearFile: () => {},
});

const UploadPhotoProvider = ({ children }: PropsWithChildren) => {
  const uploadPhotoData = useUploadPhoto();

  return (
    <UploadPhotoContext.Provider value={uploadPhotoData}>
      {children}
    </UploadPhotoContext.Provider>
  );
};

export { UploadPhotoContext, UploadPhotoProvider };
