import { isEstateComposition } from 'formData/estate/details/utils';
import { EstateFormInterface } from 'modules/EstateForm/types';
import { EstateDetailsForm } from 'api/estate/types';

export const getEstateCompositionValue = ({
  estate,
  estateDetails,
}: EstateFormInterface): EstateDetailsForm['estateComposition'] => {
  if (!isEstateComposition(estate)) {
    return null;
  }

  if (!estate || !estate.subType?.match(/Apartment\dBedroom/g)) {
    return estateDetails?.estateComposition ?? null;
  }

  return {
    ...estateDetails?.estateComposition,
    bedrooms: Number(estate.subType.match(/\d+/)?.[0]),
  };
};
