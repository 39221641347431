import { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import { releaseRentalDepositSchema } from 'formData/dossier/products/rentalDeposit/schema';
import useFileUpload from 'hooks/useFileUpload';
import { HandleSingleDocumentSave } from 'hooks/useFileUpload/types';
import { getDocumentToUpload } from 'hooks/useFileUpload/utils';
import UploadDocuments from 'modules/UploadDocuments';
import { resolver } from 'utils/yup';
import { attachDossierTypeDocument } from 'api/dossier/requests';
import {
  ProductActionContent,
  ProductActionEstateInfo,
} from 'components/modals/ProductActionModal';
import { ProductActionComponentProps } from 'components/modals/ProductActionModal/types';
import RentalDepositPartiesDetails from '../RentalDepositPartiesDetails';
import { RentalDepositData } from '../types';
import { fetchRentalDepositDetails } from '../utils';
import DepositDistributionSection from './DepositDistributionSection';
import ReleaseDocumentsInfo from './ReleaseDocumentsInfo';
import { AbolishmentForm } from './types';
import { formatAbolishmentData, formatAbolishmentValues } from './utils';

const RentalDepositVerdictRelease = ({
  sendRequest,
  setDisableSubmitButton,
  setLoading,
  dossier: { dossierId, rentalConditions, estate },
  product: { productId },
}: ProductActionComponentProps) => {
  const [data, setData] = useState<RentalDepositData | null>(null);

  const methods = useForm<AbolishmentForm>({
    defaultValues: {
      ...releaseRentalDepositSchema.getDefault(),
      abolishmentReason: 'verdict',
    },
    resolver: resolver(releaseRentalDepositSchema),
    context: { depositValue: rentalConditions?.depositAmount?.value },
  });

  const attachDocument: HandleSingleDocumentSave = async (documentId) => {
    await attachDossierTypeDocument(dossierId, documentId, { attached: true });
  };

  const { saveDocuments, ...uploadFileProps } = useFileUpload({
    onSingleDocumentSave: attachDocument,
    defaultFields: [
      getDocumentToUpload({
        scope: 'dossier',
        entityId: dossierId,
        documentType: 'Verdict',
      }),
    ],
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const details = await fetchRentalDepositDetails(dossierId, productId);
    const values = formatAbolishmentValues(details, 'verdict');
    methods.reset({ ...methods.getValues(), ...values });

    setData(details);
    setLoading(false);
  }, [dossierId, productId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit: SubmitHandler<AbolishmentForm> = async (data, event) => {
    setDisableSubmitButton(true);
    const status = await saveDocuments(event);
    setDisableSubmitButton(false);
    if (status === 'ALL_SAVED' || status === 'NOTHING_TO_SAVE') {
      sendRequest(formatAbolishmentData(data));
    }
  };

  const depositDistribution = useWatch({
    control: methods.control,
    name: 'depositDistribution',
  });

  useEffect(() => {
    setDisableSubmitButton(!depositDistribution);
  }, [depositDistribution]);

  return (
    <ProductActionContent onSubmit={methods.handleSubmit(onSubmit)}>
      {data ? (
        <Stack gap={2}>
          <ProductActionEstateInfo estate={estate} />
          <RentalDepositPartiesDetails data={data} />
          <ReleaseDocumentsInfo selectedReason="verdict" data={data} />
          <UploadDocuments isUploadingMissingDocuments {...uploadFileProps} />
          <FormProvider {...methods}>
            <DepositDistributionSection
              abolishmentReason="verdict"
              depositDistribution={depositDistribution}
            />
          </FormProvider>
        </Stack>
      ) : null}
    </ProductActionContent>
  );
};

export default RentalDepositVerdictRelease;
