import { UseFormReturn } from 'react-hook-form';
import { isEstateComposition } from 'formData/estate/details/utils';
import { EstateFormInterface } from 'modules/EstateForm/types';
import {
  getEstateDefaultValues,
  getEstateDetailsDefaultValues,
} from 'modules/EstateView/utils';
import { RelationData } from 'modules/Relation/types';
import { personToRelation } from 'modules/Relation/utils';
import { getEstate, getEstateDetails } from 'api/estate/requests';
import { EstateDetailsForm, EstateElectricity, EstateGas } from 'api/estate/types';
import { FetchedPersonData } from 'api/person/types';
import { getEstateOwners } from 'api/relations/requests';
import { InputField } from 'components/controlledInputs/types';

export const getEstateCompositionValue = ({
  estate,
  estateDetails,
}: EstateFormInterface): EstateDetailsForm['estateComposition'] => {
  if (!isEstateComposition(estate)) {
    return null;
  }

  if (!estate || !estate.subType?.match(/Apartment\dBedroom/g)) {
    return estateDetails?.estateComposition ?? null;
  }

  return {
    ...estateDetails?.estateComposition,
    bedrooms: Number(estate.subType.match(/\d+/)?.[0]),
  };
};

export const getOnInputChange =
  ({
    fieldName,
    isSubmitted,
    trigger,
    typeToTrigger,
    updateCheckbox,
  }: {
    fieldName: InputField<NonNullable<EstateElectricity & EstateGas>>['name'];
    isSubmitted: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trigger: UseFormReturn<any>['trigger'];
    typeToTrigger: 'gas' | `${string}.gas` | 'electricity' | `${string}.electricity`;
    updateCheckbox: () => void;
  }) =>
  () => {
    if (fieldName === 'eanNumber' && isSubmitted) {
      trigger(`${typeToTrigger}.eanNumber`);
    }
    if (isSubmitted && (fieldName === 'meterNumber' || fieldName === 'meterNumber2')) {
      trigger(`${typeToTrigger}.meterNumber`);
      if (typeToTrigger === 'electricity') {
        trigger(`${typeToTrigger}.meterNumber2`);
      }
    }
    updateCheckbox();
  };

export const areCopiedOwnersUnchanged = (
  copiedOwners: RelationData<FetchedPersonData>[],
  currentOwners: RelationData<FetchedPersonData>[],
) => {
  if (copiedOwners.length && copiedOwners.length === currentOwners.length) {
    return (
      JSON.stringify(copiedOwners.map(({ id }) => id).sort()) ===
      JSON.stringify(currentOwners.map(({ id }) => id).sort())
    );
  }
  return false;
};

export const getEstateFormDefaultValues = async (estateId?: string) => {
  let form: Partial<EstateFormInterface> = {};
  let owners: RelationData<FetchedPersonData>[] = [];

  if (estateId) {
    const estateRes = await getEstate(estateId);
    if (estateRes.ok) {
      form = { ...form, estate: getEstateDefaultValues(estateRes.response) };
    }
    const estateDetailsRes = await getEstateDetails(estateId);
    if (estateDetailsRes.ok) {
      form = {
        ...form,
        estateDetails: getEstateDetailsDefaultValues(estateDetailsRes.response),
      };
    }
    const ownersRes = await getEstateOwners(estateId);
    if (ownersRes.ok) {
      owners = ownersRes.response.map(({ owner }) => personToRelation(owner));
    }
  }

  return { form, owners };
};
