import { ThemedStyle } from 'types/themedStyle';

export const getTabButtonStyles = (
  isActive: boolean,
  variant: 'normal' | 'small' = 'normal',
): ThemedStyle =>
  isActive
    ? {
        boxShadow: 'none',
        '&:hover': { boxShadow: 'none' },
        fontWeight: variant === 'small' ? 'bold' : 'normal',
      }
    : {
        fontWeight: 'bold',
        px: variant === 'small' ? 1.25 : 2,
      };
5;

export const getTabContainerStyles = (numberOfRoutes: number): ThemedStyle => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${numberOfRoutes - 1}, max-content) 1fr`,
  columnGap: 2,
  rowGap: 1,
  p: 1,

  '& > .tabs-container': {
    gridRow: 1,
  },

  '& > .tabs-divider': {
    gridRow: 2,
    gridColumnStart: 1,
    gridColumnEnd: numberOfRoutes + 1,
  },

  '& > .subTabs-container': {
    gridRow: 3,
    gridColumnStart: 1,
    gridColumnEnd: numberOfRoutes + 1,
    display: 'flex',
    gap: 2,
    ml: 1,
  },
});
