import { useCallback, useState } from 'react';
import { DataUrl } from 'types/data';
import { uploadEstatePhoto } from 'api/estate/requests';
import { FileToProcess } from 'components/UploadPhoto/types';
import { createPhotoBody } from './utils';

export const useUploadPhoto = (photoUrl?: string | null) => {
  const [fileDataUrl, setFileDataUrl] = useState<DataUrl>(null);
  const [uploadedFile, setUploadedFile] = useState<FileToProcess | null>(null);

  const fetchSavedPhoto = async () => {
    if (photoUrl) {
      const blob = await (await fetch(photoUrl)).blob();
      const file = new File([blob], '', { type: blob.type });
      setFileDataUrl(photoUrl);
      setUploadedFile({ file, status: 'SAVED' });
    }
  };

  const uploadPhoto = useCallback(
    async (estateId: string) => {
      if (fileDataUrl && uploadedFile) {
        const photoBody = createPhotoBody(fileDataUrl, uploadedFile.file);
        const { ok } = await uploadEstatePhoto(estateId, photoBody);
        if (!ok) {
          setUploadedFile((prev) => prev && { ...prev, status: 'ERROR' });
        }
        return ok;
      }
      return true;
    },
    [fileDataUrl, uploadedFile],
  );

  const clearFile = () => {
    setFileDataUrl(null);
    setUploadedFile(null);
  };

  return {
    fileDataUrl,
    uploadedFile,
    setFileDataUrl,
    setUploadedFile,
    uploadPhoto,
    clearFile,
    fetchSavedPhoto,
  };
};
