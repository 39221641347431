import { useMemo } from 'react';
import { getEstateElectricityFields } from 'formData/estate/details/fields';
import useFormMethods from 'hooks/useFormMethods';
import { HandleCheckboxChange } from 'types/handleChange';
import { EstateDetailsForm } from 'api/estate/types';

export const useElectricityFields = (prefix?: string) => {
  const { setValue, getValues, clearErrors, watch } =
    useFormMethods<EstateDetailsForm>(prefix);

  const type = watch('electricity.type');
  const fields = useMemo(() => getEstateElectricityFields(type), [type]);

  const onCheckboxChange: HandleCheckboxChange = (_e, checked) => {
    if (!checked) {
      fields.forEach(({ name }) => {
        clearErrors(`electricity.${name}`);
      });
    }
  };

  const updateCheckbox = () => {
    if (!getValues('hasElectricity')) {
      setValue('hasElectricity', true);
    }
  };

  return { fields, onCheckboxChange, updateCheckbox };
};
