import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ClearIcon } from 'icons';
import { removePhotoButtonStyles } from '../styles';
import { ProcessImageUploadProps } from '../types';
import { UploadStatus } from './UploadStatus';

const ProcessImageUpload = ({
  file,
  status,
  imageUrl,
  onRemove,
  showStatus = false,
}: ProcessImageUploadProps) => (
  <Stack alignItems="center" p={2}>
    <Box display="flex" alignItems="center">
      <Box position="relative" maxWidth="100%">
        <Box
          component="img"
          src={imageUrl}
          maxWidth="100%"
          borderRadius={1}
          data-testid="image-preview"
        />
        <IconButton
          onClick={onRemove}
          sx={imageUrl ? removePhotoButtonStyles : undefined}
          size="small"
          disabled={status === 'PENDING'}
          disableRipple
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography>{file.name}</Typography>
      {showStatus && <UploadStatus status={status} />}
    </Stack>
  </Stack>
);
export default ProcessImageUpload;
