import { DossierFormInterface } from 'modules/DossierForm/types';
import getLng from 'utils/getLng';
import api from 'api';
import { apiUrl } from 'api/const';
import { UpdateDocumentInterface } from 'api/documents/types';
import { ResponseWithId, UrlParams } from 'api/types';
import { parseResponse } from 'api/utils';
import {
  AttachDossierDocumentData,
  AttachDossierTypeDocumentData,
  CustomDossierTodoItem,
  CustomDossierTodoItemBody,
  DossierData,
  DossierDocumentsData,
  DossierStatus,
  FetchedDossierChecklistItem,
  FetchedDossierData,
  FetchedDossiersList,
  RentalConditions,
  ResidentsForm,
} from './types';
import {
  transformDossierResponse,
  transformPostData,
  transformPutData,
  transformRentalConditions,
  transformResidents,
} from './utils';

export const getRentalDossierChecklist = () =>
  api.get<FetchedDossierChecklistItem[]>(apiUrl.rentalDossiersChecklist);

export const addRentalDossier = (data: DossierFormInterface) =>
  api.post<ResponseWithId>(apiUrl.rentalDossiers, transformPostData(data));

export const updateRentalDossier = (dossierId: string, data: DossierData) =>
  api.put<ResponseWithId>(apiUrl.rentalDossier(dossierId), transformPutData(data));

export const getRentalDossier = (dossierId: string) =>
  api.get<FetchedDossierData>(apiUrl.rentalDossier(dossierId), {
    params: { lng: getLng() },
    transformResponse: [parseResponse, transformDossierResponse],
  });

export const updateRentalDossierStatus = (dossierId: string, status: DossierStatus) =>
  api.patch(apiUrl.rentalDossierStatus(dossierId), { dossierStatus: status });

export const getDossiers = (params: UrlParams) =>
  api.get<FetchedDossiersList>(apiUrl.dossiersWithParams({ ...params, lng: getLng() }), {
    transformResponse: [
      parseResponse,
      ({ meta, data }) => ({
        meta,
        data: data.map(transformDossierResponse),
      }),
    ],
  });

export const getRentalConditions = (dossierId: string) =>
  api.get<RentalConditions | null>(apiUrl.rentalConditions(dossierId));

export const updateRentalConditions = (dossierId: string, data: RentalConditions) =>
  api.put<void>(apiUrl.rentalConditions(dossierId), transformRentalConditions(data), {
    customErrorMessage: [
      {
        status: 400,
        text: 'rentalConditions.save',
      },
      {
        status: 422,
        text: 'rentalConditions.missingEstateAddress',
        errorCode: 'MissingAddressInformation',
      },
    ],
  });

export const updateResidents = (dossierId: string, data: ResidentsForm) =>
  api.put<void>(apiUrl.residents(dossierId), transformResidents(data));

export const getDossierDocuments = (dossierId: string) =>
  api.get<DossierDocumentsData>(apiUrl.dossierDocuments(dossierId));

export const getDossierDocument = (dossierId: string, documentId: number) =>
  api.get<Blob>(apiUrl.dossierDocument(dossierId, documentId), { responseType: 'blob' });

export const uploadDossierDocument = (dossierId: string, formData: FormData) =>
  api.post<ResponseWithId<number>>(apiUrl.dossierDocuments(dossierId), formData);

export const updateDossierDocument = (
  dossierId: string,
  documentId: number,
  data: UpdateDocumentInterface,
) => api.put<void>(apiUrl.dossierDocument(dossierId, documentId), data);

export const getDossierDocumentFile = (
  dossierId: string,
  documentId: number,
  fileId: number,
) =>
  api.get<Blob>(apiUrl.rentalDossierDocumentFile(dossierId, documentId, fileId), {
    responseType: 'blob',
  });

export const attachDossierDocument = (
  dossierId: string,
  data: AttachDossierDocumentData,
) => api.post(apiUrl.attachDossierDocument(dossierId), data);

export const attachDossierTypeDocument = (
  dossierId: string,
  documentId: number,
  data: AttachDossierTypeDocumentData,
) => api.patch(apiUrl.attachDossierTypeDocument(dossierId, documentId), data);

export const removeDossierDocument = (dossierId: string, documentId: number) =>
  api.delete(apiUrl.removeDossierDocument(dossierId, documentId));

export const updateCustomTodoList = (
  dossierId: string,
  // temporary type; order will be required and type separate ype will be created after merging PR for products reorder
  list: { id: string; order?: number }[],
) => api.patch(apiUrl.updateCustomTodoList(dossierId), list);

export const createCustomTodoItem = (
  dossierId: string,
  customTodoItem: CustomDossierTodoItemBody,
) => api.post(apiUrl.customTodoItems(dossierId), customTodoItem);

export const getCustomTodoItems = (dossierId: string) =>
  api.get<CustomDossierTodoItem[]>(apiUrl.customTodoItems(dossierId));

export const updateCustomTodoItem = (
  dossierId: string,
  todoItemId: string,
  customTodoItem: CustomDossierTodoItem,
) => api.put(apiUrl.customTodoItem(dossierId, todoItemId), customTodoItem);
