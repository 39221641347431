import { Stack, Typography } from '@mui/material';
import useProductStage from 'hooks/useProductStage';
import { HourglassIcon, InfoIcon } from 'icons';
import { ProductStageInterface } from 'api/dossier/products/types';
import ProductAction from '../ProductAction';

const ProductCurrentStage = (stage: ProductStageInterface) => {
  const { title, isStageAwaitingForExternalAction } = useProductStage(stage.name);

  if (isStageAwaitingForExternalAction) {
    return (
      <Stack direction="row" gap={1} alignItems="center">
        <HourglassIcon color="secondary" fontSize="large" />
        <Typography variant="h4">{title}</Typography>
      </Stack>
    );
  }

  if (stage.name === 'UploadDocuments') {
    return (
      <Stack direction="row" gap={1} alignItems="center">
        <InfoIcon color="primary" fontSize="large" />
        <Typography variant="h4">{title}</Typography>
      </Stack>
    );
  }

  return (
    <ProductAction
      action={stage}
      buttonProps={{ fullWidth: true, sx: { maxWidth: 300 } }}
    />
  );
};

export default ProductCurrentStage;
