import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import useGasFields from 'hooks/useGasFields';
import { getOnInputChange } from 'modules/EstateForm/utils';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import { isTruthyValueInObject } from 'utils/object';
import { EstateDetailsForm } from 'api/estate/types';
import { ControlledInput } from 'components/controlledInputs';

export const GasEdition = () => {
  const { t } = useTranslation('formFields');
  const {
    control,
    formState: { errors, isSubmitted },
    getValues,
    trigger,
  } = useFormContext<EstateDetailsForm>();

  const { fields, onCheckboxChange, updateCheckbox } = useGasFields();

  const initiallyExpanded = !!(
    getValues('hasGas') && isTruthyValueInObject(getValues('gas'))
  );

  return (
    <Stack direction="row" spacing={1}>
      <AccordionWithCheckbox
        control={control}
        error={errors.gas}
        name="hasGas"
        label={t('gas.self')}
        onChange={onCheckboxChange}
        initiallyExpanded={initiallyExpanded}
      >
        <Stack spacing={1}>
          {fields.map(({ name, ...textInputProps }) => (
            <Stack key={name} direction="row" spacing={2} alignItems="center">
              <Box flex={1}>
                <Typography>{t(`gas.${name}`)}</Typography>
              </Box>
              <Box flex={1}>
                <ControlledInput
                  name={`gas.${name}`}
                  control={control}
                  error={errors.gas?.[name]}
                  onInputChange={getOnInputChange({
                    fieldName: name,
                    isSubmitted,
                    trigger,
                    typeToTrigger: 'electricity',
                    updateCheckbox,
                  })}
                  hideHelperTextGap
                  {...textInputProps}
                />
              </Box>
            </Stack>
          ))}
        </Stack>
      </AccordionWithCheckbox>
    </Stack>
  );
};
