import { DraftDocument } from 'contexts/fileUpload/types';
import i18n from 'i18n';
import { formatDate } from 'utils/date';
import { uploadDossierDocument } from 'api/dossier/requests';
import { uploadEstateDocument } from 'api/estate/requests';
import { uploadLegalPersonDocument } from 'api/person/legal/requests';
import { uploadNaturalPersonDocument } from 'api/person/natural/requests';
import { DocumentToUpload, ProcessedFileStatus, SaveDocument, UploadFile } from './types';

const uploadFile: UploadFile = {
  estate: uploadEstateDocument,
  naturalPerson: uploadNaturalPersonDocument,
  legalPerson: uploadLegalPersonDocument,
  dossier: uploadDossierDocument,
};

export const saveDocument: SaveDocument = async ({
  entityId,
  scope,
  files,
  documentType,
  documentName,
  date,
}) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file);
  });

  if (documentType && documentName) {
    formData.append('documentName', documentName);
    formData.append('documentType', documentType);
  }

  if (date) {
    formData.append('date', formatDate(date, 'yyyy-MM-dd') ?? '');
  }

  const res = await uploadFile[scope](entityId, formData);
  return res.ok
    ? { status: 'SAVED', documentId: res.response.id }
    : { status: 'ERROR', message: res.message };
};

export const getDocumentToUpload = ({
  date = null,
  documentName,
  documentType = null,
  files = [],
  entityId = '',
  ...document
}: DraftDocument): DocumentToUpload => ({
  documentName:
    documentName ??
    (documentType
      ? i18n.t(`documents:${document.scope}.${documentType}`, documentType)
      : ''),
  documentType,
  date,
  files,
  status: files.length ? 'READY' : 'DRAFT',
  entityId,
  ...document,
});

export const setWarningForEmptyDocument = (
  document: DocumentToUpload,
): DocumentToUpload => {
  const shouldWarn = !!document.documentType && !document.files.length;
  return {
    ...document,
    status: shouldWarn ? 'WARNING' : document.status,
    message: shouldWarn ? i18n.t('warnings.documentWithoutFiles') : undefined,
  };
};

export const areDocumentsSavedOrDraft = (documents: DocumentToUpload[]) =>
  documents.every(({ status, documentType }) => status === 'SAVED' || !documentType);

export const groupDocumentsByStatus = (documents: DocumentToUpload[]) => {
  const savedDocuments = documents.filter(({ status }) => status === 'SAVED');
  const rejectedDocuments = documents.filter(({ status }) => status === 'ERROR');
  const emptyDocuments: DocumentToUpload[] = documents.filter(
    ({ status }) => status === 'DRAFT' || status === 'WARNING',
  );

  return { savedDocuments, rejectedDocuments, emptyDocuments };
};

export const updateDocumentStatus = (document: DocumentToUpload): ProcessedFileStatus => {
  if (document.files.length) {
    return 'READY';
  }
  return document.status === 'WARNING' ? 'WARNING' : 'DRAFT';
};
