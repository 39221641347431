import { TranslatedOption } from 'formData/types';
import { namespaces } from 'i18n';
import { WithRequired } from 'types/withRequired';
import getObjectKeys from 'utils/object/getObjectKeys';
import { EstateElectricityType, EstateManagementType } from 'api/estate/types';
import { SearchInputOption } from 'components/inputs/types';

export const electricityTypeOptions: TranslatedOption<
  NonNullable<EstateElectricityType>
>[] = getObjectKeys(namespaces.enums.estateElectricityTypes).map((value) => ({
  value,
  label: value,
  translationKey: `estateElectricityTypes.${value}`,
}));

export const managementTypeOptions: TranslatedOption<
  NonNullable<EstateManagementType>
>[] = getObjectKeys(namespaces.enums.estateManagementTypes).map((value) => ({
  value,
  label: value,
  translationKey: `estateManagementTypes.${value}`,
}));

export const compositionOptions: WithRequired<SearchInputOption, 'translationKey'>[] =
  getObjectKeys(namespaces.enums.estateComposition).map((value) => ({
    value,
    label: value,
    translationKey: `estateComposition.${value}`,
  }));
