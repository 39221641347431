import { EstatePermissions } from 'consts/permissions';
import routes from 'router/routes';
import { BoldCell, PersonsCell } from 'templates/Table/Cells';
import { TableAction, TableColumn } from 'templates/Table/types';
import hasPermission from 'utils/hasPermission';
import { apiUrl } from 'api/const';
import { FetchedEstateListItem } from 'api/estate/types';

export const getLinkToEstate = (id: string) => routes.estateTab(id).estateInfo;

export const estatesColumns: TableColumn<FetchedEstateListItem, 'estates'>[] = [
  {
    field: 'address',
    flex: 3,
    filterable: true,
    sortable: true,
    renderCell: BoldCell,
  },
  {
    field: 'postCode',
    flex: 2,
    filterable: true,
    sortable: true,
  },
  {
    field: 'city',
    flex: 2,
    filterable: true,
    sortable: true,
  },
  {
    field: 'owners',
    flex: 3,
    filterable: true,
    renderCell: PersonsCell,
  },
];

export const getEstatesActions = (
  estate: FetchedEstateListItem,
): TableAction<'estates'>[] => [
  {
    name: 'createDossier',
    onClick: ({ navigate }) => {
      navigate(routes.newDossier, { state: { estate } });
    },
  },
  {
    name: 'copyEstate',
    onClick: ({ navigate }) =>
      navigate(routes.newEstate, { state: { estateId: estate.id } }),
  },
  {
    name: 'delete',
    hidden: !hasPermission(EstatePermissions.Write),
    onClick: ({ setUpAndOpenConfirmationModal, updateRows }) => {
      setUpAndOpenConfirmationModal({
        url: apiUrl.estate(estate.id),
        translationKey: 'estates',
        errorMessage: [
          { status: 404, text: 'estates.404' },
          { status: 409, text: 'estates.409' },
        ],
        onSuccess: updateRows,
        translationValues: { estateAddress: estate.address },
      });
    },
  },
];
