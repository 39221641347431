import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { estateDetailsFields } from 'formData/estate/details/fields';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { ControlledInput, ControlledSelect } from 'components/controlledInputs';
import { fireInsuranceSelectorOptions } from './static-data';
import { EstateForFireInsuranceForm } from './types';
import { getFireInsuranceSelectors } from './utils';

const EstateDetailsAdditionalDataForm = ({
  control,
  formState: { errors },
  setValue,
}: UseFormReturn<EstateForFireInsuranceForm>) => {
  const { t } = useTranslation('formFields');

  return (
    <Stack spacing={4}>
      {estateDetailsFields
        .filter(({ name }) => name === 'habitableSurface')
        .map(({ name, ...field }) => (
          <InputLabelWrapper key={name} label={t(name, name)}>
            <ControlledInput
              name={`estateDetails.${name}`}
              control={control}
              error={errors.estateDetails?.[name]}
              hideHelperTextGap
              {...field}
            />
          </InputLabelWrapper>
        ))}
      <Stack spacing={2}>
        {getFireInsuranceSelectors(setValue).map(({ name, onSelectChange }) => (
          <ControlledSelect
            key={name}
            control={control}
            name={`estateDetails.${name}`}
            onChange={onSelectChange}
            label={t(`fireInsuranceSelectors.${name}`, name)}
            options={fireInsuranceSelectorOptions}
            hideHelperTextGap
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default EstateDetailsAdditionalDataForm;
