import errorOccurred from 'assets/errorOccurred.png';
import { ThemedStyle } from 'types/themedStyle';

export const layoutWrapperStyles: ThemedStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  overflow: 'auto',
};

export const layoutContainerStyles: ThemedStyle = ({ mixins }) => ({
  height: `calc(100% - ${mixins.toolbar.minHeight}px)`,
  marginLeft: { xs: `${mixins.closedDrawer.width}px`, xl: 0 },
  marginTop: 'auto',
  overflow: 'auto',
  flexGrow: 1,
  scrollbarGutter: 'stable',
  px: { xs: 2, sm: 3 },
});

export const errorPageStyles: ThemedStyle = {
  backgroundImage: `url(${errorOccurred})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  pt: 10,
  width: '100%',
  height: '100%',
  color: 'primary.main',
  fontWeight: 700,
};
