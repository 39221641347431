import { useState } from 'react';
import {
  FieldArray,
  UseFieldArrayPrepend,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Button from 'atoms/Button';
import { AddendumContactOption } from 'modules/ProductRentalContract/RegisterAddendum/types';
import {
  getPersonsOptions,
  transformToAddendumFormPerson,
} from 'modules/ProductRentalContract/RegisterAddendum/utils';
import { useUpdateEffect } from 'usehooks-ts';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { PersonInDossier } from 'api/dossier/types';
import { getLegalPerson } from 'api/person/legal/requests';
import { FetchedLegalPersonData } from 'api/person/legal/types';
import { getNaturalPerson } from 'api/person/natural/requests';
import { FetchedNaturalPersonData } from 'api/person/natural/types';
import { PersonType } from 'api/person/types';
import { Select } from 'components/inputs';

interface Props<T extends 'landlords' | 'tenants'> {
  type: T;
  prepend: UseFieldArrayPrepend<RegisterAddendumForm, T>;
  persons?: PersonInDossier[];
}

type SelectedPerson = (FetchedNaturalPersonData | FetchedLegalPersonData) & {
  type: PersonType;
  representativeId?: string | null;
};

export const AddNewContact = <T extends 'landlords' | 'tenants'>({
  type,
  prepend,
  persons,
}: Props<T>) => {
  const { t } = useTranslation('common');

  const { control } = useFormContext<RegisterAddendumForm>();

  const addedPersons = useWatch({ control, name: type });

  const [loadingPerson, setLoadingPerson] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<SelectedPerson>();
  const [availablePersons, setAvailablePersons] = useState(
    getPersonsOptions(addedPersons, persons),
  );

  useUpdateEffect(() => {
    setAvailablePersons(getPersonsOptions(addedPersons, persons));
  }, [addedPersons, persons]);

  const fetchPerson = async (
    personId: string,
    type: PersonType,
  ): Promise<FetchedNaturalPersonData | FetchedLegalPersonData | undefined> => {
    setLoadingPerson(true);
    const { ok, response } =
      type === 'NaturalPerson'
        ? await getNaturalPerson(personId)
        : await getLegalPerson(personId);
    if (ok) {
      setLoadingPerson(false);
      return response;
    }
    setLoadingPerson(false);
  };

  const onChange: (value: string, option?: AddendumContactOption) => void = async (
    value,
    option,
  ) => {
    if (option?.type) {
      const person = await fetchPerson(value, option.type);
      if (person) {
        setSelectedPerson({
          ...person,
          type: option.type,
          representativeId: option.representativeId,
        });
      }
    }
  };

  const onAdd = async () => {
    if (selectedPerson?.type === 'LegalPerson') {
      const { representativeId, ...legalPerson } = selectedPerson;
      let representative;
      if (representativeId) {
        representative = await fetchPerson(representativeId, 'NaturalPerson');
      }
      if (representative) {
        prepend([
          transformToAddendumFormPerson(legalPerson, 'LegalPerson'),
          {
            ...transformToAddendumFormPerson(
              representative,
              'NaturalPerson',
              legalPerson.name,
            ),
            legalPersonId: legalPerson.id,
          },
        ] as FieldArray<RegisterAddendumForm, typeof type>);
      }
    } else if (selectedPerson) {
      prepend(
        transformToAddendumFormPerson(selectedPerson, 'NaturalPerson') as FieldArray<
          RegisterAddendumForm,
          typeof type
        >,
      );
    }
  };

  return (
    <Box display="flex" gap={1}>
      <Box flexGrow={2}>
        <Select
          options={availablePersons}
          label={t(`relationInput.${type === 'landlords' ? 'landlord' : 'tenant'}.label`)}
          onChange={onChange}
          value={selectedPerson?.id || ''}
          disabled={loadingPerson || !availablePersons?.length}
          helperText={
            !availablePersons?.length
              ? t('helperText.noPersonsInDossier', { type })
              : undefined
          }
        />
      </Box>
      <Box>
        <Button
          disableElevation
          disabled={!availablePersons?.length}
          loading={loadingPerson}
          onClick={onAdd}
        >
          {t('add')}
        </Button>
      </Box>
    </Box>
  );
};
