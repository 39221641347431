import { TranslatedOption } from 'formData/types';
import { namespaces } from 'i18n';
import getObjectKeys from 'utils/object/getObjectKeys';
import { EstateDestination, EstateStatus } from 'api/estate/types';

export const estateStatuses: TranslatedOption<EstateStatus>[] = getObjectKeys(
  namespaces.enums.estateStatus,
).map((value) => ({
  value,
  label: value,
  translationKey: `estateStatus.${value}`,
}));

export const destinationOptions: TranslatedOption<EstateDestination>[] = getObjectKeys(
  namespaces.enums.estateDestination,
).map((value) => ({
  value,
  label: value,
  translationKey: `estateDestination.${value}`,
}));
