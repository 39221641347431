import api from 'api';
import { apiUrl } from 'api/const';
import { DocumentData, UpdateDocumentInterface } from 'api/documents/types';
import { ResponseWithId } from 'api/types';
import { parseResponse } from 'api/utils';
import { transformPersonAddress } from '../utils';
import { FetchedNaturalPersonData, NaturalPersonFormData } from './types';
import { transformData } from './utils';

export const addNaturalPerson = async (data: NaturalPersonFormData) =>
  api.post<{ id: string }>(apiUrl.naturalPersons, transformData(data));

export const getNaturalPerson = (naturalPersonId: string) =>
  api.get<FetchedNaturalPersonData>(apiUrl.naturalPerson(naturalPersonId), {
    transformResponse: [parseResponse, transformPersonAddress],
  });

export const updateNaturalPerson = (
  naturalPersonId: string,
  data: NaturalPersonFormData,
) => api.put<void>(apiUrl.naturalPerson(naturalPersonId), transformData(data));

export const uploadNaturalPersonDocument = (
  naturalPersonId: string,
  formData: FormData,
) =>
  api.post<ResponseWithId<number>>(
    apiUrl.naturalPersonDocuments(naturalPersonId),
    formData,
  );

export const getNaturalPersonDocuments = (naturalPersonId: string) =>
  api.get<DocumentData[]>(apiUrl.naturalPersonDocuments(naturalPersonId));

export const getNaturalPersonDocument = (naturalPersonId: string, documentId: number) =>
  api.get<Blob>(apiUrl.naturalPersonDocument(naturalPersonId, documentId), {
    responseType: 'blob',
  });

export const updateNaturalPersonDocument = (
  naturalPersonId: string,
  documentId: number,
  data: UpdateDocumentInterface,
) => api.put<void>(apiUrl.naturalPersonDocument(naturalPersonId, documentId), data);

export const getNaturalPersonDocumentFile = (
  naturalPersonId: string,
  documentId: number,
  fileId: number,
) =>
  api.get<Blob>(apiUrl.naturalPersonDocumentFile(naturalPersonId, documentId, fileId), {
    responseType: 'blob',
  });
