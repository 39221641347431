import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { RentalConditions } from 'api/dossier/types';
import ControlledCheckbox from 'components/controlledInputs/ControlledCheckbox';
import { OrderRentalDepositForm } from './types';

interface Props {
  methods: UseFormReturn<OrderRentalDepositForm>;
  rentalConditions: RentalConditions | null;
}

const LegalAssistanceController = ({ methods, rentalConditions }: Props) => {
  const { t } = useTranslation(['common', 'enums']);

  useEffect(() => {
    if (rentalConditions?.rentType) {
      methods.setValue('isLegalAssistance', rentalConditions.rentType === 'Habitation');
    }
  }, [rentalConditions?.rentType]);
  return (
    <Stack>
      <ControlledCheckbox
        name="isLegalAssistance"
        label={t('isLegalAssistance')}
        control={methods.control}
        disabled
      />
      {rentalConditions?.rentType && rentalConditions.rentType !== 'Habitation' && (
        <Typography variant="body2" pl={3.8} mt={-1} color="error">
          {t('common:helperText.legalAssistanceUnavailable', {
            type: t(`enums:rentType.${rentalConditions.rentType}`),
          })}
        </Typography>
      )}
    </Stack>
  );
};

export default LegalAssistanceController;
