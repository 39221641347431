import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { translationSchema } from 'formData/common/schema';
import useFormMethods from 'hooks/useFormMethods';
import { Language } from 'types/language';
import { EstateDetailsForm } from 'api/estate/types';
import { formatInput, nameExists } from './utils';

export const useComposition = (prefix?: string) => {
  const [input, setInput] = useState(translationSchema.getDefault());
  const [openModal, setOpenModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number>();
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation('validation');
  const { control, getName } = useFormMethods<EstateDetailsForm>(prefix);

  const { fields, remove, prepend, update } = useFieldArray({
    control,
    name: getName('estateComposition.additionalItems'),
  });

  const addItem: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (nameExists(input, fields)) {
      setErrorMessage(t('composition.fieldExists'));
    } else {
      prepend({ name: formatInput(input), count: 0 });
      setInput(translationSchema.getDefault());
    }
  };

  const removeItem = (index: number) => () => {
    remove(index);
    setErrorMessage('');
  };

  const onInputChange =
    (lng: Language): ChangeEventHandler<HTMLInputElement> =>
    ({ target }) => {
      setInput((prev) => ({ ...prev, [lng]: target.value }));
      setErrorMessage('');
    };

  const editItem = (index: number) => () => {
    setEditingIndex(index);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setEditingIndex(undefined);
  };

  return {
    newFieldInputProps: { addItem, onInputChange, errorMessage, input },
    removeItem,
    fields,
    editItem,
    editAdditionalFieldProps:
      editingIndex !== undefined
        ? {
            closeModal,
            openModal,
            fields,
            editingIndex,
            update,
          }
        : undefined,
  };
};
