import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { ConfigContext } from 'contexts/config/ConfigContext';
import getDocumentConfigData from 'utils/getDocumentConfigData';
import { DocumentData } from 'api/documents/types';
import DocumentsHeader from './DocumentsHeader';
import DocumentsToolbar from './DocumentsToolbar';
import { EditableDocumentRow, ReadOnlyDocumentRow } from './rows';
import { tableContainerStyles } from './styles';
import { DocumentsContextInterface, DocumentsTableProps } from './types';

export const DocumentsTable = <
  TData extends DocumentData,
  TContext extends DocumentsContextInterface<TData>,
>({
  data,
  columns,
  editable = false,
  loading = false,
  emptyMessage,
  toolbarProps,
  context,
  ...documentRowProps
}: DocumentsTableProps<TData, TContext>) => {
  const { t } = useTranslation('table');

  const { documentsConfig } = useContext(ConfigContext);

  const DocumentRow = useMemo(
    () => (editable ? EditableDocumentRow : ReadOnlyDocumentRow),
    [editable],
  );

  return (
    <>
      <DocumentsToolbar {...toolbarProps} />
      <TableContainer sx={tableContainerStyles}>
        <Table size="small">
          <DocumentsHeader columns={columns} />
          <TableBody sx={{ position: 'relative' }}>
            {data.length ? (
              data.map((document) => (
                <DocumentRow
                  key={`row-${document.id}-${document.documentType}`}
                  columns={columns}
                  document={document}
                  context={
                    {
                      ...context,
                      documentConfigData: getDocumentConfigData(
                        documentsConfig,
                        context.scope,
                        document.documentType,
                      ),
                    } as TContext
                  }
                  {...documentRowProps}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} height={60} align="center">
                  {loading ? (
                    <CircularProgress size={40} />
                  ) : (
                    emptyMessage ?? t('defaultEmpty')
                  )}
                </TableCell>
              </TableRow>
            )}
            {data.length && loading ? (
              <Box
                sx={({ mixins }) => mixins.centered}
                height="100%"
                width="100%"
                position="absolute"
                bgcolor="#7E7E7E18"
                top={0}
              >
                <CircularProgress size={40} />
              </Box>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
