import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Box, Collapse, Divider, Stack } from '@mui/material';
import { privateRelationSchema } from 'formData/privateRelation';
import RelationItem from 'modules/Relation/RelationItem';
import { RelationToPreview } from 'modules/Relation/types';
import { personToRelation } from 'modules/Relation/utils';
import PageCard from 'templates/PageCard';
import { firstCharLowerCase } from 'utils/string';
import { resolver } from 'utils/yup';
import { apiUrl } from 'api/const';
import { addPrivateRelation, deletePrivateRelation } from 'api/relations/requests';
import {
  AddPrivateRelation,
  FetchedPrivateRelation,
  PrivateRelationForm,
} from 'api/relations/types';
import { NaturalPersonOutletContext } from 'pages/Persons/NaturalPersons/Details/types';
import { EditPrivateRelation } from './EditPrivateRelation';

interface Props {
  naturalPersonId: string;
  editable?: boolean;
}

export const PrivateRelationCard = ({ naturalPersonId, editable = true }: Props) => {
  const { privateRelation, loadingRelation } =
    useOutletContext<NaturalPersonOutletContext>();

  const [relation, setRelation] = useState<FetchedPrivateRelation | null>(null);
  const [relationToPreview, setRelationToPreview] = useState<RelationToPreview | null>(
    null,
  );

  const [saving, setSaving] = useState(false);
  const [editing, setIsEditing] = useState(false);

  const methods = useForm<PrivateRelationForm>({
    defaultValues: privateRelationSchema.getDefault(),
    resolver: resolver(privateRelationSchema),
    context: { isRelation: !!relation },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (privateRelation) {
      reset({ side2: privateRelation.relative.id, type: privateRelation.type });
      setRelation(privateRelation);
      setRelationToPreview(privateRelation.relative);
      setIsEditing(false);
    }
  }, [privateRelation]);

  const onSave: SubmitHandler<PrivateRelationForm> = async (data) => {
    setSaving(true);
    const side2 = data.side2 || relation?.relative.id;
    if (relationToPreview && side2) {
      if (relation) {
        await deletePrivateRelation(relation.id);
      }
      const newRelation: AddPrivateRelation = {
        type: data.type,
        side1: naturalPersonId,
        side2,
      };
      const res = await addPrivateRelation(newRelation);
      if (res.ok) {
        const { errorMessage: _, ...relative } = relationToPreview;
        setRelation({ type: data.type, relative, ...res.response });
        setIsEditing(false);
        reset({ side2, type: data.type });
        setRelationToPreview(relative);
      } else if (res.response) {
        const { errorCode } = res.response;
        setRelationToPreview((prev) =>
          prev
            ? {
                ...prev,
                errorMessage: {
                  key: `${errorCode}_privateRelation`,
                  values: { name: prev.name },
                },
              }
            : prev,
        );
      }
    }
    setSaving(false);
  };

  const onCancel = () => {
    reset(
      relation
        ? { side2: relation.relative.id, type: relation.type }
        : privateRelationSchema.getDefault(),
    );
    setRelationToPreview(relation?.relative || null);
    setIsEditing(false);
  };

  const onDeleteSuccess = () => {
    setRelation(null);
    setRelationToPreview(null);
    reset(privateRelationSchema.getDefault());
  };

  return (
    <PageCard
      tKey="relation"
      blank={!editing && !relation}
      loading={loadingRelation}
      headerProps={{
        titleProps: {
          translationValues: {
            context:
              relation && !editing
                ? `privateRelation_${firstCharLowerCase(relation.type)}`
                : 'privateRelation',
          },
        },
        editionProps: {
          hidden: !editable,
          isEditing: editing,
          saving,
          saveDisabled: !isDirty,
          onEdit: () => setIsEditing(true),
          onSave: handleSubmit(onSave),
          onCancel,
        },
      }}
    >
      <Collapse in={editing} timeout="auto" unmountOnExit>
        <Box p={2.5} pb={1.5}>
          <FormProvider {...methods}>
            <EditPrivateRelation
              relationToPreview={relationToPreview}
              setRelationToPreview={setRelationToPreview}
              naturalPersonId={naturalPersonId}
            />
          </FormProvider>
        </Box>
      </Collapse>
      {editing && relationToPreview && (
        <Stack>
          <Divider />
          <RelationItem isPreview relation={personToRelation(relationToPreview)} />
        </Stack>
      )}
      {!editing && relation && (
        <RelationItem
          deleteUrl={apiUrl.privateRelation(relation.id)}
          onDeleteSuccess={onDeleteSuccess}
          relation={personToRelation(relation.relative)}
          removable={editable}
        />
      )}
    </PageCard>
  );
};
