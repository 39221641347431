import i18n from 'i18n';
import getLng from 'utils/getLng';
import { testStringToJSON } from 'utils/string';
import { ShortAddress, TranslationFieldInterface } from 'api/common/types';
import { AddressToString } from './types';

export const formatField = (field: string | TranslationFieldInterface) => {
  if (typeof field === 'string') {
    return testStringToJSON(field) ? JSON.parse(field)[getLng()] : field;
  }
  return field[getLng()];
};

export const addressToString: AddressToString = (addressToFormat, config) => {
  const { city, postCode, ...fields } = addressToFormat;
  const { isFullAddress = true, withPostCode = true } = config ?? {};
  let address = '';

  if ('street' in fields) {
    const { street, houseNumber, extension, apartmentNumber } = fields;
    address = `${street && formatField(street)} ${houseNumber}${extension ?? ''} ${
      apartmentNumber ? `${i18n.t('addressBox')} ${apartmentNumber}` : ''
    }`.trim();
  }
  const cityPart = withPostCode ? `${postCode} ${formatField(city)}` : formatField(city);

  return isFullAddress ? `${address}, ${cityPart}` : address;
};

export const formatAddressOnEstateInfo = (addressToFormat?: ShortAddress): string => {
  if (!addressToFormat || !addressToFormat.address) {
    return '';
  }
  const { city, postCode, address } = addressToFormat;

  return `${address}, ${postCode} ${formatField(city)}`.trim();
};
