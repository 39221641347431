import { clearVatMask, getVatMask } from 'utils/vat';
import { customValidationCountries } from 'utils/vat/getVatMask';
import yup from 'utils/yup';

const isVatLengthValid = (number: string, prefix: string) => {
  const length = number.length;
  const isChar = !/^[0-9]*$/.test(number[0]);

  switch (prefix) {
    case 'BE':
      return length === 10;
    case 'BG':
      return length === 9 || length === 10;
    case 'CZ':
      return length === 8 || length === 9 || length === 10;
    case 'GB':
      return isChar ? length === 5 : length === 12 || length === 9;
    case 'IE':
      return length === 8;
    case 'LT':
      return length === 9 || length == 12;
    case 'RO':
      return length >= 2 && length <= 10;
    default:
      return true;
  }
};

const isBelgiumVatValid = (vatNumber: string) => {
  if (vatNumber.length < 10) {
    return false;
  }
  const coreNumber = Number(vatNumber.substring(0, vatNumber.length - 2));
  const lastTwoDigits = Number(vatNumber.substring(vatNumber.length - 2));

  let checkNumber = 97 - (coreNumber % 97);
  if (checkNumber === 0) {
    checkNumber = 97;
  }
  return checkNumber === lastTwoDigits;
};

const isVatMaskValid = (number: string, prefix: string) => {
  const mask = getVatMask(prefix);
  const clearedMask = mask ? clearVatMask(mask.toString()) : null;
  return clearedMask && number.length === clearedMask.length;
};

const vatSchema = yup
  .object({
    prefix: yup.string().default('BE').required(),
    number: yup
      .string()
      .default('')
      .label('vat')
      .customWhen({
        is: (_, parent) =>
          customValidationCountries.includes(parent.prefix) &&
          !isVatLengthValid(parent.number, parent.prefix),
        then: (schema) => schema.invalid('vat.invalidFormat'),
      })
      .customWhen({
        is: (_, parent) =>
          !customValidationCountries.includes(parent.prefix) &&
          !isVatMaskValid(parent.number, parent.prefix),
        then: (schema) => schema.invalid('vat.invalidFormat'),
      })
      .customWhen({
        is: (_, parent) => parent.prefix === 'BE' && !isBelgiumVatValid(parent.number),
        then: (schema) => schema.invalid('vat.invalidFormat'),
      }),
  })
  .transform((vat) =>
    vat?.number ? { prefix: vat.prefix, number: clearVatMask(vat.number) } : null,
  )
  .nullable();

export default vatSchema;
