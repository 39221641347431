import { useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  getRentalConditionsFields,
  HandleRentalConditionFieldChange,
  RentalConditionsFields,
} from 'formData/dossier/rentalConditions/fields';
import { numberOfMonthlyRentValues } from 'formData/dossier/rentalConditions/static-data';
import calculateEndOfRental from 'utils/calculateEndOfRental';
import calculateNumberOfMonthlyRent from 'utils/calculateNumberOfMonthlyRent';
import getFormMethods from 'utils/getFormMethods';
import { NumberOfMonthlyRent, RentalConditions, RentalDuration } from 'api/dossier/types';

interface Config {
  prefix?: string;
  isFormForMissingData?: boolean;
}

export const useRentalConditionsFields = <T extends FieldValues>(
  methods: UseFormReturn<T>,
  config?: Config,
) => {
  const { prefix, isFormForMissingData } = config ?? {};
  const { getValues, setValue, trigger, getError, clearErrors } =
    getFormMethods<RentalConditions>(methods, prefix);

  const [disabledFields] = useState<RentalConditionsFields>({
    endDate: true,
  });

  const [hiddenFields, setHiddenFields] = useState<RentalConditionsFields>({
    durationNumberOfMonths: getValues('duration') !== 'Other',
    isTaxPayableByTenant: getValues('rentType') === 'Habitation',
    taxPayableByTenant: !getValues('isTaxPayableByTenant'),
    isVatRegimeApplicable: getValues('rentType') !== 'Commercial',
    isAdditionalVatRegimeApplicable: !getValues('isVatRegimeApplicable'),
  });

  const updateDepositAmount = (
    numberOfMonthlyRent: NumberOfMonthlyRent | null,
    rentalPriceValue: number | null,
  ) => {
    if (rentalPriceValue && numberOfMonthlyRent && numberOfMonthlyRent !== 'Other') {
      setValue('depositAmount', {
        value: numberOfMonthlyRentValues[numberOfMonthlyRent] * rentalPriceValue,
        currency: getValues('rentalPrice.currency'),
      });
    }

    if (getError('depositAmount')) {
      trigger('depositAmount', { shouldFocus: false });
    }
  };

  const updateNumberOfMonthlyRent = (
    rentalPriceValue: number | null,
    depositValue: number | null,
  ) => {
    if (rentalPriceValue && depositValue) {
      const calculatedNumberOfMonthlyRent = calculateNumberOfMonthlyRent(
        rentalPriceValue,
        depositValue,
      );

      const numberOfMonthlyRent = getValues('numberOfMonthlyRent');
      if (calculatedNumberOfMonthlyRent !== numberOfMonthlyRent) {
        setValue('numberOfMonthlyRent', calculatedNumberOfMonthlyRent);
      }
    }
  };

  const onChange: HandleRentalConditionFieldChange = (field, input) => {
    switch (field) {
      case 'rentType':
        setHiddenFields((prev) => ({
          ...prev,
          isTaxPayableByTenant: input === 'Habitation',
          taxPayableByTenant:
            input === 'Habitation' || !getValues('isTaxPayableByTenant'),
          isVatRegimeApplicable: input !== 'Commercial',
          isAdditionalVatRegimeApplicable:
            input !== 'Commercial' || !getValues('isVatRegimeApplicable'),
        }));
        setValue('rentFrequency', 'Month');

        if (input === 'CommonLaw' || input === 'Commercial') {
          setValue('numberOfMonthlyRent', 'Other');
        }
        break;
      case 'contractSigningDate':
        if (getError('entryDate')) {
          trigger('entryDate', { shouldFocus: false });
        }
        break;
      case 'entryDate': {
        if (getError('contractSigningDate')) {
          trigger('contractSigningDate', { shouldFocus: false });
        }
        if (getError('endDate')) {
          trigger('endDate', { shouldFocus: false });
        }
        let endOfRental: string | null = null;
        const duration = getValues('duration');
        const numberOfMonths = getValues('durationNumberOfMonths');
        const entryDate = input as string | null;
        if (duration !== 'Other') {
          endOfRental = calculateEndOfRental(entryDate, duration);
        } else if (numberOfMonths !== null) {
          endOfRental = calculateEndOfRental(entryDate, numberOfMonths);
        }
        setValue('endDate', endOfRental);
        break;
      }
      case 'duration': {
        if (getError('endDate')) {
          trigger('endDate', { shouldFocus: false });
        }
        if (getError('durationNumberOfMonths') && input !== 'Other') {
          clearErrors('durationNumberOfMonths');
        }
        const duration = input as RentalDuration;
        const entryDate = getValues('entryDate');
        if (entryDate) {
          const endOfRental = calculateEndOfRental(entryDate, duration);
          setValue('endDate', endOfRental);
        }

        setHiddenFields((prev) => ({
          ...prev,
          durationNumberOfMonths: duration !== 'Other',
        }));
        break;
      }
      case 'durationNumberOfMonths': {
        const entryDate = getValues('entryDate');
        const numberOfMonths = input ? Number(input) : null;
        if (entryDate && numberOfMonths !== null) {
          const endDate = calculateEndOfRental(entryDate, numberOfMonths);
          setValue('endDate', endDate);
        }
        break;
      }
      case 'endDate':
        if (getError('entryDate')) {
          trigger('entryDate', { shouldFocus: false });
        }
        break;
      case 'rentalPrice':
        updateDepositAmount(getValues('numberOfMonthlyRent'), Number(input));
        if (getValues('numberOfMonthlyRent') === 'Other') {
          updateNumberOfMonthlyRent(Number(input), getValues('depositAmount.value'));
        }
        break;
      case 'numberOfMonthlyRent':
        updateDepositAmount(input as NumberOfMonthlyRent, getValues('rentalPrice.value'));
        break;
      case 'depositAmount':
        updateNumberOfMonthlyRent(getValues('rentalPrice.value'), Number(input));
        break;
      case 'isTaxPayableByTenant':
        setHiddenFields((prev) => ({ ...prev, taxPayableByTenant: !input }));
        break;
      case 'isVatRegimeApplicable':
        setHiddenFields((prev) => ({ ...prev, isAdditionalVatRegimeApplicable: !input }));
        setValue('isAdditionalVatRegimeApplicable', Boolean(input));
        break;
    }
  };

  const fields = getRentalConditionsFields(
    onChange,
    hiddenFields,
    disabledFields,
    isFormForMissingData,
  );

  return fields;
};
