import { useEffect } from 'react';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { naturalPersonGeneralFields } from 'formData/naturalPerson/fields';
import useNaturalPersonDetailsFields from 'hooks/useNaturalPersonDetailsFields';
import usePrompt from 'hooks/usePrompt';
import { AddressFieldsWithGoogle } from 'modules/AddressFields';
import routes, { personTabRoutes } from 'router/routes';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { NaturalPersonFormData } from 'api/person/natural/types';
import { ControlledInput } from 'components/controlledInputs';

const pathnamesToExclude = (naturalPersonId: string) =>
  Object.values(personTabRoutes).map(
    (route) => `${routes.naturalPerson(naturalPersonId)}/${route}`,
  );

const NaturalPersonDetailsEdition = ({
  naturalPersonId,
}: {
  naturalPersonId: string;
}) => {
  const { t } = useTranslation('formFields');

  const methods = useFormContext<NaturalPersonFormData>();
  const {
    control,
    trigger,
    formState: { errors, isDirty },
  } = methods;

  usePrompt({
    when: isDirty,
    id: 'naturalPersonDetails',
    pathnamesToExclude: pathnamesToExclude(naturalPersonId),
  });

  const watchDateOfBirth = useWatch({ control, name: 'dateOfBirth' });
  const watchSex = useWatch({ control, name: 'sex' });

  useEffect(() => {
    if ((watchDateOfBirth || watchSex) && errors.nationalRegisterNumber) {
      trigger('nationalRegisterNumber');
    }
  }, [watchDateOfBirth, watchSex]);

  const naturalPersonDetailsFields = useNaturalPersonDetailsFields(methods, {
    formType: 'edition',
  });

  return (
    <Stack spacing={4} py={2.5}>
      <Stack spacing={1}>
        <Stack spacing={1} px={2.5}>
          {naturalPersonGeneralFields.map(({ name, ...field }) => (
            <InputLabelWrapper key={name} label={t(name)}>
              <ControlledInput
                name={name}
                control={control}
                error={errors[name]}
                hideHelperTextGap
                {...field}
              />
            </InputLabelWrapper>
          ))}
        </Stack>
      </Stack>
      <Stack spacing={1} px={2.5}>
        <AddressFieldsWithGoogle separateLabel />
      </Stack>
      <Stack spacing={1} px={2.5}>
        {naturalPersonDetailsFields.map(
          ({ name, hidden, Component, componentProps, ...field }) =>
            !hidden &&
            (Component ? (
              <Component key={`component-${name}`} {...componentProps} />
            ) : (
              <InputLabelWrapper key={name} label={t(name)}>
                <ControlledInput
                  name={name}
                  control={control}
                  error={errors[name] as FieldError}
                  hideHelperTextGap
                  {...field}
                />
              </InputLabelWrapper>
            )),
        )}
      </Stack>
    </Stack>
  );
};

export default NaturalPersonDetailsEdition;
