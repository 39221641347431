import { BaseSyntheticEvent, useContext } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { PersonFormContext } from 'contexts/personForm/personFormContext';
import { PersonFormData } from 'contexts/personForm/types';
import { isNaturalPersonForm } from 'contexts/personForm/utils';
import useCustomNavigate from 'hooks/useCustomNavigate';
import usePrompt from 'hooks/usePrompt';
import routes from 'router/routes';
import { useUpdateEffect } from 'usehooks-ts';
import setValidationErrors from 'utils/setValidationErrors';
import { addLegalPerson, updateLegalPerson } from 'api/person/legal/requests';
import { addNaturalPerson, updateNaturalPerson } from 'api/person/natural/requests';

enum PersonForm {
  GeneralStep,
  RelationsAndDocuments,
}

export const usePersonForm = <D extends PersonFormData>(
  personId: string | null,
  onSave?: (data: D, personId: string) => void,
  isDirty?: boolean,
  setFormError?: UseFormSetError<D>,
) => {
  const { general, setSubmitting, saveRelations, setErrors, saved, setSaved } =
    useContext(PersonFormContext);
  const { saveDocuments } = useContext(FilesUploadContext);
  const navigate = useCustomNavigate();
  usePrompt({ when: !saved, id: 'personForm' });

  const saveForm = async (event: BaseSyntheticEvent | undefined, data = general) => {
    if (!data) return;

    let id = personId;
    let shouldProceed = false;

    setSubmitting(true);
    if (personId) {
      const relationsSaved = await saveRelations(personId, getType(data));
      const documentsStatus = await saveDocuments(event, personId);
      const validDocuments =
        documentsStatus === 'ALL_SAVED' || documentsStatus === 'NOTHING_TO_SAVE';

      if (validDocuments && relationsSaved) {
        const res = await updatePerson(personId, data);
        shouldProceed = res.ok;
        if (!res.ok && setFormError) {
          setValidationErrors(res.validation, setFormError);
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          [PersonForm.RelationsAndDocuments]: !validDocuments || !relationsSaved,
        }));
      }
    } else {
      const res = await addPerson(data);
      shouldProceed = res.ok;
      id = res.ok ? res.response.id : null;

      if (!res.ok && setFormError) {
        setValidationErrors(res.validation, setFormError);
      }
    }

    if (shouldProceed && id) {
      if (onSave) {
        onSave(data as D, id);
      } else {
        const route = isNaturalPersonForm(data)
          ? routes.naturalPerson(id)
          : routes.legalPerson(id);
        navigate(route, { withoutPrompt: true });
      }
    }
    setSubmitting(false);
  };

  useUpdateEffect(() => {
    setSaved(!isDirty);
  }, [isDirty]);

  return { saveForm };
};

const updatePerson = (personId: string, data: PersonFormData) =>
  isNaturalPersonForm(data)
    ? updateNaturalPerson(personId, data)
    : updateLegalPerson(personId, data);

const addPerson = (data: PersonFormData) =>
  isNaturalPersonForm(data) ? addNaturalPerson(data) : addLegalPerson(data);

const getType = (data: PersonFormData) =>
  isNaturalPersonForm(data) ? 'NaturalPerson' : 'LegalPerson';
