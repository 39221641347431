import { useContext, useMemo } from 'react';
import { ConfigContext } from 'contexts/config/ConfigContext';
import { Country } from 'api/config/types';
import { SelectOption } from 'components/inputs/types';

export const useCountryOptions = (mapOption: (country: Country) => SelectOption) => {
  const { countries } = useContext(ConfigContext);

  const options = useMemo(() => {
    const groupedOptions = {
      main: countries.main.map(mapOption),
      other: countries.other.map(mapOption),
    };
    const options = [...groupedOptions.main, ...groupedOptions.other];

    return [options, groupedOptions] as const;
  }, [countries]);

  return options;
};
