import { FieldValues, UseFormReturn } from 'react-hook-form';
import { getLegalPersonDetailsFields } from 'formData/legalPerson/fields';
import useBicFromIban from 'hooks/useBicFromIban';
import { HandleBlurChange } from 'types/handleChange';
import getFormMethods from 'utils/getFormMethods';
import { LegalPersonFormData } from 'api/person/legal/types';

export const useLegalPersonDetailsFields = <T extends FieldValues>(
  methods: UseFormReturn<T>,
  prefix?: string,
) => {
  const { setValue } = getFormMethods<LegalPersonFormData>(methods, prefix);
  const { getBicFromIban, loadingBic } = useBicFromIban();

  const onIbanBlur: HandleBlurChange = async (e) => {
    const iban = e.target.value;
    const bic = await getBicFromIban(iban);
    setValue('bic', bic);
  };

  return getLegalPersonDetailsFields({ onIbanBlur, loadingBic });
};
