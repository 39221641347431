import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEstateComposition } from 'formData/estate/details/utils';
import { EstateFormInterface, UseEstateCompositionStep } from 'modules/EstateForm/types';
import CompositionStep from './CompositionStep';

export const useCompositionStep: UseEstateCompositionStep = ({
  onCancel,
  saveForm,
  isLastStep,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useFormContext<EstateFormInterface>();

  const type = useWatch({ control, name: 'estate.type' });
  const destination = useWatch({ control, name: 'estate.destination' });

  const disabled = useMemo(
    () => !!type && !!destination && !isEstateComposition({ type, destination }),
    [type, destination],
  );

  return {
    isValid: formState.isValid,
    disabled,
    Component: <CompositionStep />,
    cancelButton: {
      onClick: onCancel,
    },
    backButton: true,
    nextButton: !isLastStep,
    submitButton: {
      label: t('save'),
      onClick: handleSubmit(saveForm),
      saving: formState.isSubmitting,
    },
  };
};
