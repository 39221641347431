import { useOutletContext } from 'react-router-dom';
import { Grid } from '@mui/material';
import { DossierObjectDocumentsCard } from 'modules/DocumentsCard';
import { DossierDocumentsContext } from './types';

interface Props {
  type: 'landlord' | 'tenant';
}

const DossierPersonDocuments = ({ type }: Props) => {
  const { dossierData, dossierDocuments, fetchDocuments } =
    useOutletContext<DossierDocumentsContext>();

  return (
    <Grid container spacing={4}>
      {dossierDocuments?.[`${type}s`].map(
        ({ personId, personType, personName, companyName, ...props }) => (
          <Grid
            item
            key={companyName ? `${personId}_${companyName}` : personId}
            xs={12}
            marginBottom="auto"
          >
            <DossierObjectDocumentsCard
              entityType={type}
              entityId={personId}
              scope={personType === 'LegalPerson' ? 'legalPerson' : 'naturalPerson'}
              titleOptions={{
                interpolation: { escapeValue: false },
                context: companyName ? 'representative' : 'person',
                name: personName,
                companyName,
              }}
              fetchDocuments={fetchDocuments}
              dossierStatus={dossierData.status}
              {...props}
            />
          </Grid>
        ),
      )}
    </Grid>
  );
};

export default DossierPersonDocuments;
