import { useState } from 'react';
import { personTypeOptions } from 'formData/common/selectOptions';
import { FetchedPersonData, PersonType } from 'api/person/types';
import { Select } from 'components/inputs';
import ModalTitle from 'components/modals/ModalTitle';
import ModalWrapper from 'components/modals/ModalWrapper';
import { LegalPersonFormContent } from './AddLegalPersonModal';
import { NaturalPersonFormContent } from './AddNaturalPersonModal';
import { FormModalProps } from './types';

export const AddPersonModal = ({
  open,
  closeModal,
  onSubmit,
}: FormModalProps<FetchedPersonData>) => {
  const [personType, setPersonType] = useState<PersonType>('NaturalPerson');

  const handleClose = () => {
    closeModal();
  };

  const handleChange = (value: string) => {
    setPersonType(value as PersonType);
  };

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { maxWidth: 'xl' } }}
    >
      <ModalTitle closeModal={handleClose}>
        <Select
          value={personType}
          options={personTypeOptions}
          onChange={handleChange}
          hideHelperTextGap
          sx={{ maxWidth: '50%', flex: 1 }}
        />
      </ModalTitle>
      {personType === 'NaturalPerson' ? (
        <NaturalPersonFormContent onSubmit={onSubmit} closeModal={handleClose} />
      ) : (
        <LegalPersonFormContent onSubmit={onSubmit} closeModal={handleClose} />
      )}
    </ModalWrapper>
  );
};
