import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EstatesDetails } from 'modules/DetailsCards/EstatesDetails';
import { AddEstateModal } from 'modules/ModalForm/AddEstateModal';
import RelationCard from 'modules/Relation/RelationCard';
import { RelationModalData } from 'modules/Relation/types';
import { fetchEstateOptions } from 'modules/Relation/utils';
import transformName from 'utils/transformName';
import { PersonType } from 'api/person/types';
import { getLegalPersonEstates, getNaturalPersonEstates } from 'api/relations/requests';
import { FetchedOwnedEstates, FetchedPrivateRelation } from 'api/relations/types';
import { MAX_ESTATES } from './static-data';
import { addNewOwnedEstates } from './utils';

interface Props {
  personId: string;
  personType: PersonType;
  privateRelation?: FetchedPrivateRelation | null;
  editable?: boolean;
}

export const EstateCard = ({
  personId,
  personType,
  editable,
  privateRelation,
}: Props) => {
  const [ownerships, setOwnerships] = useState<FetchedOwnedEstates[]>([]);
  const [loading, setLoading] = useState(true);

  const { i18n } = useTranslation();

  const getEstates = useCallback(async () => {
    setLoading(true);
    const { ok, response } =
      personType === 'NaturalPerson'
        ? await getNaturalPersonEstates(personId)
        : await getLegalPersonEstates(personId);
    if (ok) {
      setOwnerships(response);
    }
    setLoading(false);
  }, [personId, i18n.language]);

  const modalData: RelationModalData<FetchedPrivateRelation> | undefined = useMemo(
    () =>
      privateRelation
        ? {
            relationData: privateRelation,
            translationKey: 'partnerOwnership',
            translationValues: {
              partnerName: transformName(privateRelation.relative),
            },
          }
        : undefined,
    [privateRelation],
  );

  return (
    <>
      <RelationCard
        translationKey="estate"
        maxRelations={MAX_ESTATES}
        saveRelation={addNewOwnedEstates(personId, personType)}
        fetchOptions={fetchEstateOptions}
        fetchRelations={getEstates}
        relations={ownerships.map(({ estate }) => estate.id)}
        AddNewOptionModal={AddEstateModal}
        editable={editable}
        loading={loading}
        modalData={modalData}
      >
        <EstatesDetails ownerships={ownerships} updateList={getEstates} />
      </RelationCard>
    </>
  );
};
