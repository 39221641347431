import { useState } from 'react';
import { useFilters } from 'templates/Table/hooks';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { formatDate } from 'utils/date';
import { DateInput } from 'components/inputs';
import { TranslatedTableColumn } from '../types';

const TableDateFilter = ({ field, filterName = field }: TranslatedTableColumn) => {
  const { onFilterChange, initState } = useFilters(filterName);

  const [input, setInput] = useState(initState);
  const debouncedInput = useDebounce(input, 500);

  useUpdateEffect(() => {
    onFilterChange(debouncedInput);
  }, [debouncedInput]);

  return (
    <DateInput
      hideHelperTextGap
      value={input}
      onChange={(date) => setInput(formatDate(date, 'yyyy-MM-dd') ?? '')}
    />
  );
};

export default TableDateFilter;
