import { useMemo } from 'react';
import { FieldError, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { naturalPersonGeneralFields } from 'formData/naturalPerson/fields';
import useAddressFields from 'hooks/useAddressFields';
import useNaturalPersonDetailsFields from 'hooks/useNaturalPersonDetailsFields';
import AddressFields from 'modules/AddressFields';
import routes from 'router/routes';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import transformName from 'utils/transformName';
import { ControlledInput } from 'components/controlledInputs';
import MissingRequiredFieldsError from './MissingRequiredFieldsError';
import { NaturalPersonMissingDataFormProps } from './types';
import {
  getMissingRequiredFields,
  getNaturalPersonsFields,
  isAnyAddressFieldMissing,
} from './utils';

const NaturalPersonMissingDataForm = ({
  id,
  index,
  fields,
  methods,
}: NaturalPersonMissingDataFormProps) => {
  const { control, formState } = methods;
  const { t } = useTranslation(['formFields', 'modals']);

  const prefix = useMemo(() => `naturalPersons.${index}` as const, [index]);
  const errors = useMemo(
    () => formState.errors.naturalPersons?.[index],
    [formState.errors.naturalPersons, index],
  );

  const missingImportantFields = getMissingRequiredFields(errors, fields);

  const naturalPersonDetailsFields = useNaturalPersonDetailsFields(methods, {
    formType: 'edition',
    isFormForMissingData: true,
    prefix,
  });

  const { requiredFields, notRequiredFields } = useMemo(
    () =>
      getNaturalPersonsFields(
        fields,
        [...naturalPersonGeneralFields, ...naturalPersonDetailsFields],
        prefix,
      ),
    [fields, prefix, naturalPersonDetailsFields],
  );

  const addressFields = useAddressFields({ prefix });
  const displayAddressFields = isAnyAddressFieldMissing(fields, addressFields);

  const personData = useMemo(
    () => formState.defaultValues?.naturalPersons?.[index],
    [formState.defaultValues?.naturalPersons?.[index]],
  );

  return (
    <Stack spacing={1} data-testid={id}>
      {personData && (
        <Typography fontWeight="bold">
          {personData.companyName
            ? t('modals:data.representativeLabel', {
                name: transformName(personData),
                companyName: personData.companyName,
              })
            : transformName(personData)}
        </Typography>
      )}
      <Stack spacing={1}>
        {!!requiredFields?.length &&
          requiredFields.map(({ name, label, Component, componentProps, ...field }) =>
            Component ? (
              <FormProvider key={name} {...methods}>
                <Component {...componentProps} />
              </FormProvider>
            ) : (
              <InputLabelWrapper label={`${t(label ?? String(name), name)}*`} key={name}>
                <ControlledInput
                  name={`${prefix}.${name}`}
                  control={control}
                  error={errors?.[name] as FieldError}
                  hideHelperTextGap
                  {...field}
                />
              </InputLabelWrapper>
            ),
          )}
        {displayAddressFields && (
          <AddressFields
            separateLabel
            fields={addressFields}
            prefix={prefix}
            withAsterisk
          />
        )}
        {!!notRequiredFields?.length &&
          notRequiredFields.map(({ name, label, Component, componentProps, ...field }) =>
            Component ? (
              <FormProvider key={name} {...methods}>
                <Component {...componentProps} />
              </FormProvider>
            ) : (
              <InputLabelWrapper label={t(label ?? String(name), name)} key={name}>
                <ControlledInput
                  name={`${prefix}.${name}`}
                  control={control}
                  error={errors?.[name] as FieldError}
                  hideHelperTextGap
                  {...field}
                />
              </InputLabelWrapper>
            ),
          )}
        {missingImportantFields.length ? (
          <MissingRequiredFieldsError
            tKey="missingPersonRequiredFields"
            to={routes.naturalPersonTab(id).details}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default NaturalPersonMissingDataForm;
