import { useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';
import { AddPersonModal } from 'modules/ModalForm/AddPersonModal';
import { MAX_OWNERS } from 'modules/OwnershipCard/static-data';
import AddRelation from 'modules/Relation/AddRelation';
import RelationPreviewList from 'modules/Relation/RelationPreviewList';
import {
  fetchPersonRelationOptions,
  selectPersonWithPartner,
} from 'modules/Relation/utils';
import { deleteOwnership } from 'api/relations/requests';

const EstateOwners = () => {
  const { ownersToPreview, setOwnersToPreview } = useContext(EstateFormContext);

  const relationsToFilter = useMemo(
    () => ownersToPreview.map(({ id }) => id),
    [ownersToPreview],
  );

  const onRemove = async ({ savedId, id }: (typeof ownersToPreview)[number]) => {
    if (savedId) {
      const res = await deleteOwnership(id);
      if (!res.ok) {
        return { ok: false, errorMessage: res.message };
      }
    }
    return { ok: true };
  };

  return (
    <Box pb={ownersToPreview.length ? 0 : 6.5}>
      <AddRelation
        translationKey="estateForm"
        maxRelations={MAX_OWNERS}
        relationsToFilter={relationsToFilter}
        onSelectOption={selectPersonWithPartner(setOwnersToPreview, relationsToFilter)}
        fetchOptions={fetchPersonRelationOptions}
        AddNewOptionModal={AddPersonModal}
      />
      <RelationPreviewList
        relationsToPreview={ownersToPreview}
        setRelationsToPreview={setOwnersToPreview}
        onRemove={onRemove}
        showIcons
      />
    </Box>
  );
};

export default EstateOwners;
