import { OrderMyMoveBody, OrderMyMoveForm } from './types';

export const transformOrderMyMoveData = ({
  tenants,
}: OrderMyMoveForm): OrderMyMoveBody => {
  const [primaryTenant, secondaryTenant] = tenants;

  return {
    primaryTenantId: primaryTenant.personId,
    primaryTenantRepresentativeId: primaryTenant.representativeId || undefined,
    secondaryTenantId: secondaryTenant?.personId || undefined,
  };
};
