import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { estateDetailsFields } from 'formData/estate/details/fields';
import usePrompt from 'hooks/usePrompt';
import InputLabelWrapper from 'templates/InputLabelWrapper';
import { EstateDetailsForm } from 'api/estate/types';
import { ControlledInput } from 'components/controlledInputs';
import { ConformityEdition } from './editionComponents/ConformityEdition';
import { ElectricityEdition } from './editionComponents/ElectricityEdition';
import { EpcEdition } from './editionComponents/EpcEdition';
import { GasEdition } from './editionComponents/GasEdition';
import { SyndicEdition } from './editionComponents/SyndicEdition';

export const EstateDetailsEdition = () => {
  const { t } = useTranslation('formFields');
  const {
    control,
    formState: { errors, isDirty },
  } = useFormContext<EstateDetailsForm>();

  usePrompt({ when: isDirty, id: 'estateDetails' });

  return (
    <Stack spacing={4} p={2.5}>
      <Stack spacing={1} flex={1}>
        {estateDetailsFields.map(({ name, ...field }) => (
          <InputLabelWrapper key={name} label={t(name, name)}>
            <ControlledInput
              name={name}
              control={control}
              error={errors[name] as FieldError}
              hideHelperTextGap
              {...field}
            />
          </InputLabelWrapper>
        ))}
      </Stack>
      <ConformityEdition />
      <SyndicEdition />
      <ElectricityEdition />
      <GasEdition />
      <EpcEdition />
    </Stack>
  );
};
