import { useMemo } from 'react';
import { GridValidRowModel } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import { TableColumn, TableTranslationKey, TranslatedTableColumn } from '../types';

export const useTranslateColumns = <
  TRow extends GridValidRowModel,
  Key extends TableTranslationKey = TableTranslationKey,
>(
  t: TFunction<'table', undefined, 'table'>,
  tKey: Key,
  columns: TableColumn<TRow, Key>[],
) => {
  const translatedColumns = useMemo(
    () =>
      columns.map(
        ({ translateValue, ...column }): TranslatedTableColumn<TRow> => ({
          ...column,
          headerName: t(`${tKey}.columns.${column.field}`, column.field),
          filterLabel: t(`${tKey}.filters.${column.field}`, column.field),
          filterable: column.filterable ?? false,
          sortable: column.sortable ?? false,
          valueFormatter: translateValue ?? column.valueFormatter,
        }),
      ),
    [t],
  );
  return translatedColumns;
};
