import { commonRegisterContractFields } from 'modules/ProductRentalContract/RegisterRentalContract/static-data';
import { RegisterAddendumForm } from 'api/dossier/products/rentalContract/types';
import { InputField } from 'components/controlledInputs/types';

export const uploadAddendumFields: InputField<
  RegisterAddendumForm['uploadedAddendum']
>[] = [
  {
    name: 'addendumSigningDate',
    type: 'date',
    dateInputProps: { disableFuture: true },
    warningKey: 'addendumSigningDate',
  },
  ...commonRegisterContractFields,
];
