import { LoaderConfig } from 'hooks/useLoader/types';
import { getProducts } from 'api/dossier/products/requests';
import { ProductInterface } from 'api/dossier/products/types';

export const getProductsConfig = (
  dossierId: string,
): LoaderConfig<ProductInterface[]> => ({
  scope: 'products',
  id: dossierId,
  fetch: getProducts,
  withContext: false,
});
