import { MAX_OWNERS } from 'modules/OwnershipCard/static-data';
import yup from 'utils/yup';

export const dossierFormValidation = yup.object({
  dossierType: yup.string().required(),
  checklistItems: yup.array(),
  estate: yup.object().required(),
  landlords: yup.array().min(1).max(MAX_OWNERS),
  tenants: yup.array().max(MAX_OWNERS),
  responsiblePersons: yup.array(),
});
