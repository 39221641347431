import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { EstateFormInterface } from 'modules/EstateForm/types';
import AccordionWithCheckbox from 'templates/AccordionWithCheckbox';
import { HandleCheckboxChange } from 'types/handleChange';
import { ControlledTextInput } from 'components/controlledInputs';

const SyndicField = () => {
  const { t } = useTranslation('formFields');
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<EstateFormInterface>();

  const onChange: HandleCheckboxChange = (_e, checked) => {
    if (!checked) {
      setValue('estateDetails.syndic', null, { shouldDirty: true });
    }
  };

  const updateCheckbox = () => {
    if (!getValues('estateDetails.hasSyndic')) {
      setValue('estateDetails.hasSyndic', true);
    }
  };

  const initiallyExpanded = !!(
    getValues('estateDetails.hasSyndic') && getValues('estateDetails.syndic')
  );

  return (
    <Grid item xs={12}>
      <AccordionWithCheckbox
        control={control}
        error={errors?.estateDetails?.syndic}
        name="estateDetails.hasSyndic"
        label={t('syndic')}
        onChange={onChange}
        initiallyExpanded={initiallyExpanded}
      >
        <ControlledTextInput
          name="estateDetails.syndic"
          label={t('text')}
          control={control}
          error={errors?.estateDetails?.syndic}
          onChange={updateCheckbox}
        />
      </AccordionWithCheckbox>
    </Grid>
  );
};

export default SyndicField;
