import { CustomWhenConfig } from 'types/yup';
import { ISchema, Schema } from 'yup';

export function customWhen(
  this: Schema,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { is, then, otherwise }: CustomWhenConfig<ISchema<any>>,
) {
  // @ts-ignore it exists, typescript just don't see it
  return this.when((_, schema, options) => {
    // @ts-ignore it exists - some mutation happens here, because it does not appear immediately, but there is no delay in rendering
    // we take last element of options.from because it will have access to whole form data
    const form = options.from?.at(-1)?.value;

    if (form) {
      if (is(form, options.parent)) {
        return then(schema, form, options.context);
      } else if (otherwise) {
        return otherwise(schema);
      }
    }
    return this;
  });
}
