import React, { useContext, useMemo } from 'react';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { FetchedOwnedEstates } from 'api/relations/types';
import { Estate } from './components/Estate';
import { getEstates } from './utils';

interface Props {
  ownerships: FetchedOwnedEstates[];
  updateList: () => Promise<void>;
}

export const EstatesDetails = ({ ownerships, updateList }: Props) => {
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);

  const estates = useMemo(
    () =>
      getEstates(ownerships, {
        setUpAndOpenConfirmationModal,
        updateList,
      }),
    [ownerships],
  );

  return (
    <>
      {estates.map(({ estateId, estate }) => (
        <Estate key={estateId} estateId={estateId} estate={estate} />
      ))}
    </>
  );
};
