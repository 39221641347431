import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { TKey } from 'i18next';
import { WarningIcon } from 'icons';

interface Props {
  tKey: TKey<'common', 'warnings'>;
  fullWidth?: boolean;
}

export const WarningField = ({ tKey, fullWidth }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'warnings' });

  return (
    <Stack
      direction="row"
      sx={({ palette }) => ({
        p: 1,
        gap: 2,
        alignItems: 'center',
        borderRadius: 1,
        width: fullWidth ? '100%' : '75%',
        backgroundColor: palette.warning.background,
      })}
    >
      <WarningIcon color="warning" />
      <Typography variant="caption" mr="auto" fontWeight="bold">
        {t(tKey)}
      </Typography>
    </Stack>
  );
};
