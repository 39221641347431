import { useRef, useState } from 'react';
import { GridRowParams } from '@mui/x-data-grid';
import { FetchedBusinessListItem } from 'api/businesses/types';
import { BusinessAction } from './types';

const useBusinessSelection = () => {
  const [action, setAction] = useState<BusinessAction | null>(null);
  const ref = useRef<BusinessAction | null>(null);

  const updateActionState = (action: BusinessAction | null) => {
    setAction(action);
    ref.current = action;
  };

  const onRowSelection = (row?: FetchedBusinessListItem) => {
    if (row) {
      const action = row.migrated ? 'migrateBusinessesData' : 'migrateBusinesses';
      updateActionState(action);
    } else {
      updateActionState(null);
    }
  };

  const isRowSelectable = ({ row }: GridRowParams<FetchedBusinessListItem>) => {
    // TODO: Uncomment when migrating business data will be available
    // switch(ref.current){
    //   case 'migrateBusinesses': return !row.migrated;
    //   case 'migrateBusinessesData': return row.migrated;
    //   default: return true
    // }
    return !row.migrated;
  };

  return {
    setBusinessAction: updateActionState,
    isRowSelectable,
    onRowSelection,
    businessAction: action,
  };
};

export default useBusinessSelection;
