import { DossierPermissions } from 'consts/permissions';
import hasPermission from 'utils/hasPermission';
import { DossierStatus } from 'api/dossier/types';

export const shouldDossierFeatureBeActive = (
  dossierStatus: DossierStatus,
  forDraftOnly = false,
) =>
  hasPermission(DossierPermissions.Write) &&
  (forDraftOnly ? dossierStatus === 'Draft' : dossierStatus !== 'Cancelled');
